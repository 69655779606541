import { v4 } from 'uuid';

import { ManageAccesses } from '@pages/private/superAdmin/ManageAccesses';

import { Route } from './Route';

const superAdminRoutes = [
  <Route
    key={v4()}
    path="/super-admin/access-control-panel"
    isPrivate
    grantAccess={['superAdmin']}
    component={ManageAccesses}
  />,
];

export { superAdminRoutes };
