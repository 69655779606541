import { Form } from '@unform/web';
import { FC, useCallback, useRef, useState } from 'react';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { FormRow } from '@components/elements/Form/FormRow';
import { Textarea } from '@components/elements/Form/Textarea';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { Card, CardHeader, CardContent } from './styles';
import { formValidation } from './validations';
import { IFormData } from './interfaces';

export const NewPlatform: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();

  const [loadingRequest, setLoadingRequest] = useState(false);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        setLoadingRequest(true);

        await formValidation(data);

        await api.post('/platforms', data);

        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast],
  );

  return (
    <>
      <Row>
        <URLPath paths={['Plataformas', 'Novo']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Adicionar uma nova plataforma</h1>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow>
                <InputGroup>
                  <label>Nome da plataforma</label>
                  <Input name="name" upperCase={false} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup textarea>
                  <label>Descrição</label>
                  <Textarea name="description" rows={6} maxLength={255} />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button loading={loadingRequest} styleType="success">
                  Salvar nova plataforma
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};
