import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { FiCheck, FiEdit } from 'react-icons/fi';

import { DataTable } from '@components/elements/Datatable';
import { Card, CardContent, CardHeader } from '@components/layouts/Card';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Modal } from '@components/elements/Modal';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { IProduct, IUser, ICheckbox } from './interfaces';
import { CheckboxContainer } from './styles';

const Checkbox: FC<ICheckbox> = ({ checked: checkedProps, onChange }) => {
  const [checked, setChecked] = useState(checkedProps);

  const handleOnChange = useCallback(() => {
    onChange({ value: !checked });

    setChecked(oldState => !oldState);
  }, [checked, onChange]);

  return (
    <CheckboxContainer checked={checked} onClick={handleOnChange}>
      <input type="checkbox" checked={checked} onChange={handleOnChange} />
      {checked && <FiCheck color="#151515" size={20} />}
    </CheckboxContainer>
  );
};

export const ManageAccesses: FC = () => {
  const { addToast } = useToast();

  const [products, setProducts] = useState<IProduct[]>();
  const [users, setUsers] = useState<IUser[]>();
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedPermissionsToUpdate, setSelectedPermissionsToUpdate] =
    useState<string[]>([]);

  const loadInfo = useCallback(async function loadInfo() {
    const { data: usersResponse } = await api.get('/users');
    const { data: productsResponse } = await api.get('/products');

    setProducts(productsResponse);
    setUsers(usersResponse);

    setLoading(false);
  }, []);

  useEffect(() => {
    loadInfo();
  }, [loadInfo]);

  const tableData = useMemo(() => {
    const parsedUsers =
      users?.map(user => {
        return {
          id: user.id,
          name: user.name,
          created_at: format(parseISO(user.created_at), 'dd/MM/yyyy'),
          actions: (
            <TableButtonContainer>
              <TableButton
                icon={FiEdit}
                styleType="info"
                onClick={() => {
                  setModalVisible(true);
                  setSelectedUser(user.id);
                }}
              />
            </TableButtonContainer>
          ),
        };
      }) || [];

    return parsedUsers;
  }, [users]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: 'Criado em',
        selector: 'created_at',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const user = useMemo(() => {
    if (!users || !selectedUser || !products) return undefined;

    const index = users.findIndex(item => item.id === selectedUser);

    if (index < 0) return undefined;

    const permissionIds = users[index].permissions
      .map(item => {
        const prod = products.find(
          product => product.name === item.name,
        ) as IProduct;

        if (!prod) return;

        return prod.id;
      })
      .filter(item => item !== undefined) as string[];

    setSelectedPermissionsToUpdate(permissionIds);

    return users[index];
  }, [selectedUser, users, products]);

  const handlePermissionCheckboxChange = useCallback((value: string) => {
    setSelectedPermissionsToUpdate(oldState => {
      const duplicated = oldState.some(item => item === value);

      if (duplicated) {
        const filteredState = oldState.filter(item => item !== value);

        return filteredState;
      }

      return [...oldState, value];
    });
  }, []);

  const permissionsTableData = useMemo(() => {
    if (!user || !products?.length) return [];

    const parsedProducts = products.map(product => {
      const userHasPermission = user.permissions.some(
        item => item.name === product.name,
      );

      return {
        name: product.name,
        check: (
          <Checkbox
            checked={userHasPermission || false}
            onChange={() => handlePermissionCheckboxChange(product.id)}
          />
        ),
      };
    });

    return parsedProducts;
  }, [products, user, handlePermissionCheckboxChange]);

  const permissionsTableColumns = useMemo(() => {
    return [
      {
        name: 'Permissão',
        selector: 'name',
      },
      {
        name: 'Possui',
        selector: 'check',
      },
    ];
  }, []);

  const handleSaveUserPermissions = useCallback(
    async ({ visible }) => {
      if (!user || !products) return;

      const permissionsToDelete = products.reduce<string[]>((acc, cur) => {
        const exists = selectedPermissionsToUpdate.some(
          item => item === cur.id,
        );

        if (exists) return acc;

        const permission = user.permissions.find(
          item => item.name === cur.name,
        );

        if (!permission) return acc;

        acc.push(permission.id);

        return acc;
      }, []);

      if (permissionsToDelete.length) {
        await api.delete('/permissions', {
          params: {
            id: permissionsToDelete,
          },
        });
      }

      const permissionsToCreate = {
        userId: user.id,
        products: selectedPermissionsToUpdate,
      };

      if (permissionsToCreate.products.length) {
        await api.post('/permissions', permissionsToCreate);
      }

      await loadInfo();

      addToast({
        title: 'Legal!',
        type: 'success',
        message: `As permissões de ${user.name} foram atualizadas com sucesso!`,
      });

      setTimeout(() => {
        setModalVisible(visible);
        setSelectedUser('');
        setSelectedPermissionsToUpdate([]);
      }, 500);
    },
    [selectedPermissionsToUpdate, user, products, loadInfo, addToast],
  );

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <Card>
            <CardHeader>
              <h1>Usuários</h1>
            </CardHeader>

            <CardContent>
              <DataTable data={tableData} columns={tableColumns} />
            </CardContent>
          </Card>

          <Modal
            visible={modalVisible}
            closeButtonText="Cancelar"
            headerText={`Permissões para ${user?.name || ''}`}
            hideButton
            onDismiss={({ visible }) => {
              setModalVisible(visible);
              setSelectedUser('');
              setSelectedPermissionsToUpdate([]);
            }}
            onOkClick={handleSaveUserPermissions}
            okButtonText="Salvar"
            style={{
              maxWidth: '80%',
            }}
          >
            <DataTable
              columns={permissionsTableColumns}
              data={permissionsTableData}
            />
          </Modal>
        </>
      )}
    </>
  );
};
