import * as Yup from 'yup';

interface IData {
  [key: string]: string;
}

export const formValidation = async (data: IData): Promise<void> => {
  const validationShape = Yup.object().shape({
    credentials_type: Yup.string()
      .oneOf(['PJ', 'PF'], 'Valor inválido!')
      .required('Preencha este campo!'),
    social_reason: Yup.string().required('Preencha este campo!'),
    name_fantasy: Yup.string().required('Preencha este campo!'),
    cnpj: Yup.string().required('Preencha este campo!'),
    opening_date: Yup.string()
      .min(10, 'Insira uma data válida!')
      .max(10, 'Insira uma data válida!')
      .required('Preencha este campo!'),
    phone: Yup.string().required('Preencha este campo!'),
    cellphone: Yup.string().required('Preencha este campo!'),
    infinity_id: Yup.string(),
    responsible_name: Yup.string().required('Preencha este campo!'),
    responsible_email: Yup.string()
      .email('Insira um email válido!')
      .required('Preencha este campo!'),
    responsible_cpf: Yup.string().required('Preencha este campo!'),
    responsible_birthday: Yup.string().required('Preencha este campo!'),
    responsible_cellphone: Yup.string().required('Preencha este campo!'),
    zipcode: Yup.string().required('Preencha este campo!'),
    address: Yup.string().required('Preencha este campo!'),
    neighborhood: Yup.string().required('Preencha este campo!'),
    city: Yup.string().required('Preencha este campo!'),
    state: Yup.string().required('Preencha este campo!'),
    complement: Yup.string(),
    address_number: Yup.string(),
    selling_type: Yup.string()
      .oneOf(
        ['sells_with_cashback', 'sells_without_cashback'],
        'Valor inválido!',
      )
      .required('Preencha este campo!'),
    payment_form: Yup.string()
      .oneOf(['credit_card', 'debit', 'billet'], 'Valor inválido!')
      .required('Preencha este campo!'),
    cashback_percentage: Yup.number()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .min(10, 'O minimo é 10')
      .max(99, 'O máximo é 99')
      .when({
        is: (_: string) => data.sellingType === 'sells_with_cashback',
        then: Yup.number().typeError('Insira um número válido').required(),
      }),
    business_method: Yup.string()
      .oneOf(['accession', 'sells_a930', 'sells_a50'], 'Valor inválido!')
      .required('Preencha este campo!'),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
