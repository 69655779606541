export type IRole =
  | 'client'
  | 'master'
  | 'business'
  | 'seller'
  | 'admin'
  | 'indicator';

export const rolesTranslated = {
  client: 'Cliente',
  master: 'Franq. master',
  business: 'Franq. business',
  seller: 'Vendedor',
  indicator: 'Indicador',
  admin: 'Administrador',
};
