export const statusOptions = [
  { value: 'pendente', label: 'Pendente' },
  { value: 'analise', label: 'Em análise' },
  { value: 'aprovado', label: 'Aprovado' },
  { value: 'negado', label: 'Negado' },
  { value: 'pre-aprovado', label: 'Pre Aprovado' },
];

export const modalityOptions = [
  { value: 'sem_garantia', label: 'Sem garantia' },
  { value: 'com_garantia', label: 'Com garantia' },
];

export const productOptions = [
  { value: 'vehicle', label: 'Veículo' },
  { value: 'property', label: 'Imóvel' },
  { value: 'credluz', label: 'CredLuz' },
];

export const personTypeOptions = [
  { value: 'PF', label: 'Pessoa física' },
  { value: 'PJ', label: 'Pessoa jurídica' },
];
