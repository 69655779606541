import visaBrand from '@assets/img/visa.png';
import eloBrand from '@assets/img/elo.png';
import masterBrand from '@assets/img/master.png';
import hipercardBrand from '@assets/img/hipercard.png';
import unknownBrand from '@assets/img/unknown.png';

export function getCardFlag(card: string): string {
  let imgBrand = '';

  switch (card) {
    case 'VISA':
      imgBrand = visaBrand;
      break;
    case 'ELO':
      imgBrand = eloBrand;
      break;

    case 'MASTERCARD':
      imgBrand = masterBrand;
      break;

    case 'HIPERCARD':
      imgBrand = hipercardBrand;
      break;

    default:
      imgBrand = unknownBrand;
      break;
  }

  return imgBrand;
}
