import { FC, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import format from 'date-fns/format';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';

import api from '@services/bbankApi';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { IParams, IProspect } from './interfaces';

const ProspectDetailsHomeEquity: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [prospect, setProspect] = useState<IProspect>();

  useEffect(() => {
    async function loadProspect() {
      const { data } = await api.get(
        `/credit-guarantees/properties/${params.prospectId}`,
        {
          params: {
            relations: ['indication'],
          },
        },
      );

      setProspect({
        id: data.id,
        indication: data.indication,
        name: data.name,
        createdAt: data.created_at,
        ...data,
      });
    }

    const timer = setTimeout(() => {
      loadProspect();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.prospectId]);

  console.log(prospect);

  return (
    <>
      {!prospect ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Prospectos</li>
              <li>{'>'}</li>
              <li>{prospect.name}</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes do prospecto selecionado</h1>

                <Badge type="info">
                  Propecto criado em:&nbsp;
                  {format(new Date(prospect.createdAt), 'dd/MM/yyyy')}
                </Badge>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={() => {
                    /* CODE */
                  }}
                  initialData={prospect}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Indicação</label>
                      <Input name="indication.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Comentários do Administrador</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Status </label>

                      <Input name="status" />
                    </InputGroup>

                    <InputGroup>
                      <label>Speed </label>

                      <Input name="spread" />
                    </InputGroup>
                    <InputGroup>
                      <label>Comentarios </label>
                      <Input name="commentaries" />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados do imóvel</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Endereço do imóvel </label>
                      <Input name="address" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Tipo de imóvel </label>
                      <Input name="type" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Número de matricula do imóvel </label>
                      <Input name="registration" readOnly />
                    </InputGroup>
                  </FormRow>
                  <FormRow>
                    <InputGroup>
                      <label>Número do IPTU </label>
                      <Input name="number_iptu" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Imovél possui habite-se </label>
                      <Input name="property_is_inhabited" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Metragem do imóvel </label>
                      <Input name="size" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Imóvel está em seu nome </label>
                      <Input name="own" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Nome do titular do imóvel </label>
                      <Input name="owner_name" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label> CPF do Titular do imóvel</label>
                      <Input name="owner_document" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data de nascimento do dono imóvel</label>
                      <Input
                        name="owner_birth_date"
                        readOnly
                        defaultValue={format(
                          new Date(prospect.owner_conjugate_birth_date),
                          'dd/MM/yyyy',
                        )}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Situação conjugual</label>
                      <Input name="owner_marital_status" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Nome do conjuge do imóvel</label>
                      <Input name="owner_conjugate_name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CPF do conjuge do imóvel </label>
                      <Input name="owner_conjugate_document" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Data de nascimento conjuge </label>
                      <Input
                        name="owner_conjugate_birth_date"
                        readOnly
                        defaultValue={format(
                          new Date(prospect.owner_conjugate_birth_date),
                          'dd/MM/yyyy',
                        )}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Valor do imóvel </label>
                      <Input name="amount" readOnly />
                    </InputGroup>
                  </FormRow>
                  <FormRow>
                    <InputGroup>
                      <label>Situação do imóvel </label>
                      <Input name="situation" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Valor do saldo devedor</label>
                      <Input name="debit_balance" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Nome do financiador</label>
                      <Input name="financier_name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor soliticado </label>
                      <Input name="value_requested" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Finalidade do crédito</label>
                      <Input name="credit_destiny" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados do solicitante do crédito</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome Completo</label>
                      <Input name="name" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>CPF</label>
                      <Input name="document" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Data de nascimento</label>
                      <Input
                        name="birth_date"
                        readOnly
                        defaultValue={format(
                          new Date(prospect.birth_date),
                          'dd/MM/yyyy',
                        )}
                      />
                    </InputGroup>
                  </FormRow>
                  <FormRow>
                    <InputGroup>
                      <label>Estado Civil</label>
                      <Input name="marital_status" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Profissão</label>
                      <Input name="profession" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>CNPJ</label>
                      <Input name="company_document" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Autonomo</label>
                      <Input name="autonomous_description" readOnly />
                    </InputGroup>
                    <InputGroup>
                      <label>Renda</label>
                      <Input name="income" readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ProspectDetailsHomeEquity };
