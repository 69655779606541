import * as Yup from 'yup';

import { IFormData } from './interface';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    paymentLink: Yup.string()
      .required('Preencha este campo!')
      .uuid('Inválido!'),
    paymentValue: Yup.string().required('Preencha este campo!'),
    cardholderName: Yup.string().required('Preencha este campo!'),
    cardNumber: Yup.string().required('Preencha este campo!'),
    expirationDate: Yup.string().required('Preencha este campo!'),
    securityCode: Yup.string().required('Preencha este campo!'),
    identificationType: Yup.string().required('Preencha este campo!'),
    installments: Yup.string().required('Preencha este campo!'),
    cardholderEmail: Yup.string().required('Preencha este campo!'),
    issuer: Yup.string().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
