import * as Yup from 'yup';

import { IFormData } from './interface';

export const boletoValidationShape = Yup.object().shape({});

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    paymentValue: Yup.string().required('Preencha este campo!'),
    issueDate: Yup.string()
      .required('Preencha este campo!')
      .length(10, 'Insira uma data válida!'),
    dueDate: Yup.string()
      .required('Preencha este campo!')
      .length(10, 'Insira uma data válida!'),
    payerId: Yup.string().required('Preencha este campo!').uuid('Inválido!'),
    // messageOne: Yup.string().required('Preencha este campo!'),
    // messageTwo: Yup.string().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
