import {
  FC,
  FocusEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import {
  FiFlag,
  FiGift,
  FiHash,
  FiHome,
  FiLink2,
  FiLock,
  FiMail,
  FiMap,
  FiMapPin,
  FiPhone,
  FiSave,
  FiSmartphone,
  FiTag,
  FiUser,
} from 'react-icons/fi';
import * as Yup from 'yup';
import { format } from 'date-fns';

import { Row } from '@components/layouts/Grid/Row';
import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { FormRow } from '@components/elements/Form/FormRow';
import { Select } from '@components/elements/Form/Select';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import viaCepApi from '@services/viaCepApi';
import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { removeInputMask } from '@helpers/removeInputMask';
import { removeEmptyFields } from '@helpers/removeEmptyFields';

import { personTypesOptons, statesOption, rolesOptions } from './selectOptions';
import { URLPath, Card, CardHeader, CardContent } from './styles';
import { formValidation } from './validations';

// INTERFACES
interface IRouteProps {
  clientId: string;
}

interface IUser {
  id: string;
  name: string;
  email: string;
  zipcode: string;
  address: string;
  state: string;
  city: string;
  person_type: string;
  phone: string;
  cellphone: string;
  indication?: string;
  document: string;
  role: {
    role: string;
  };
  whoIndicated: {
    name: string;
  };
  status: 'ativo' | 'pendente';
  birthdate: string;
}

interface IInputProps extends HTMLElement {
  value?: string;
}

interface IFormData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  zipcode: string;
  address: string;
  state: string;
  city: string;
  phone: string;
  cellphone: string;
  person_type: string;
  document: string;
  status: 'pendente' | 'ativo';
  birthdate: string;
}

interface IParsedData {
  [key: string]: string;
}

// COMPONENT
const EditMasterClient: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [user, setUser] = useState<IUser>();
  const [buttonLoading, setButtonLoading] = useState(false);

  const { addToast } = useToast();
  const { params } = useRouteMatch<IRouteProps>();
  const { clientId } = params;

  // Load user from API
  useEffect(() => {
    async function loadUser() {
      const { data } = await api.get<IUser>(`/users/${clientId}`);

      setUser({
        ...data,
      });
    }

    setTimeout(() => loadUser(), 1000);
  }, [clientId]);

  // Submit function
  const handleSubmit = useCallback(
    async (data: IFormData) => {
      const formData: IParsedData = {
        ...data,
      };

      try {
        formRef.current?.setErrors({});

        await formValidation(formData);

        const parsedValues = removeEmptyFields(formData);
        const parsedValuesFromInputMask = removeInputMask();

        parsedValuesFromInputMask.forEach(item => {
          parsedValues[item.name] = item.value;
        });

        const { data: userUpdated } = await api.put(
          `/users-indicated/${clientId}`,
          parsedValues,
        );

        setUser(userUpdated);

        addToast({
          title: 'Usuário atualizado com sucesso!',
          type: 'success',
        });
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message } = getClientErrors(err.response);

          addToast({
            title: `Algum erro aconteceu`,
            type: 'error',
            message,
          });

          return;
        }
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast, clientId],
  );

  // Find address request on input blur
  const handleFindZipcode = useCallback(
    async (e: FocusEvent<HTMLElement>) => {
      setButtonLoading(true);

      try {
        const addressInput = document.getElementById('address') as IInputProps;
        const cityInput = document.getElementById('city') as IInputProps;
        const stateInput = document.getElementById('state') as IInputProps;
        const passwordInput = document.getElementById(
          'password',
        ) as IInputProps;
        const { target } = e as { target: { value?: string } };

        const cep = target.value?.replace('-', '');

        const { data } = await viaCepApi.get(`${cep}/json`);

        addressInput.value = data.logradouro;
        cityInput.value = data.localidade;
        stateInput.value = data.uf;
        passwordInput.focus();
      } catch (err) {
        addToast({
          title: 'CEP não encontrado!',
          type: 'info',
        });
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast],
  );

  // Page
  return (
    <>
      {!user ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Usuário</li>
              <li>{'>'}</li>
              <li>{user.name}</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>{user.name}</h1>

                {user.status === 'ativo' ? (
                  <Badge type="success">Usuário ativo</Badge>
                ) : (
                  <Badge type="warning">Usuário não ativo</Badge>
                )}
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleSubmit} ref={formRef} initialData={user}>
                  <FormRow separator>
                    <h1>Tipo de usuário</h1>
                  </FormRow>

                  <FormRow>
                    <Select
                      name="role"
                      placeholder="Tipo de usuário"
                      options={rolesOptions}
                    />
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados</h1>
                  </FormRow>

                  <FormRow>
                    <Input
                      name="name"
                      placeholder="Nome"
                      icon={FiUser}
                      readOnly={user.status === 'ativo'}
                    />

                    <Input
                      type="email"
                      name="email"
                      placeholder="Email"
                      icon={FiMail}
                      readOnly={user.status === 'ativo'}
                    />

                    <InputMask
                      name="birthdate"
                      mask="99/99/9999"
                      noUnmask
                      defaultValue={
                        user.birthdate
                          ? format(new Date(user.birthdate), 'dd/MM/yyyy')
                          : ''
                      }
                      placeholder="Data de aniversário"
                      icon={FiGift}
                      readOnly={user.status === 'ativo'}
                    />
                  </FormRow>

                  <FormRow>
                    <InputMask
                      mask="(99) 9999-9999"
                      name="phone"
                      placeholder="Telefone"
                      icon={FiPhone}
                      readOnly={user.status === 'ativo'}
                    />

                    <InputMask
                      mask="(99) 9 9999-9999"
                      name="cellphone"
                      placeholder="Celular"
                      icon={FiSmartphone}
                      readOnly={user.status === 'ativo'}
                    />

                    {user.role.role === 'business' && (
                      <Input
                        name="nickname"
                        placeholder="Apelido"
                        upperCase={false}
                        icon={FiLink2}
                        readOnly={user.status === 'ativo'}
                      />
                    )}
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço</h1>
                  </FormRow>

                  <FormRow>
                    <InputMask
                      mask="99999-999"
                      name="zipcode"
                      placeholder="CEP"
                      icon={FiMapPin}
                      onBlur={
                        user.status === 'pendente'
                          ? e => handleFindZipcode(e)
                          : undefined
                      }
                      readOnly={user.status === 'ativo'}
                    />

                    <Input
                      name="address"
                      id="address"
                      placeholder="Endereço"
                      icon={FiHome}
                      readOnly={user.status === 'ativo'}
                      maxLength={45}
                    />

                    <Input
                      name="city"
                      id="city"
                      placeholder="Cidade"
                      icon={FiMap}
                      readOnly={user.status === 'ativo'}
                      maxLength={45}
                    />

                    <Select
                      name="state"
                      id="state"
                      icon={FiFlag}
                      options={statesOption}
                      placeholder="Estado"
                      readonly={user.status === 'ativo'}
                    />
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados de acesso</h1>
                  </FormRow>

                  {user.status === 'pendente' && (
                    <FormRow>
                      <Input
                        name="old_password"
                        placeholder="Senha antiga"
                        icon={FiLock}
                      />

                      <Input
                        name="password"
                        type="password"
                        placeholder="Senha"
                        id="password"
                        icon={FiLock}
                      />

                      <Input
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirmar senha"
                        icon={FiLock}
                      />
                    </FormRow>
                  )}

                  <FormRow>
                    <Select
                      name="person_type"
                      icon={FiTag}
                      options={personTypesOptons}
                      readonly={user.status === 'ativo'}
                    />

                    <Input
                      name="document"
                      placeholder="CPF/CNPJ"
                      icon={FiHash}
                      readOnly={user.status === 'ativo'}
                    />
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      icon={FiSave}
                      loading={buttonLoading}
                    >
                      Salvar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { EditMasterClient };
