import styled, { css } from 'styled-components';

interface IContainerProps {
  separator?: boolean;
  buttonWrapper?: number;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;

  gap: 10px;

  ${props =>
    props.buttonWrapper === 1 &&
    css`
      justify-content: flex-end;
    `}

  & + & {
    margin-top: 20px;
  }

  > h1 {
    font-size: 1.75rem;
    margin-bottom: 8px;
  }

  > div {
    flex: 1;
    max-width: 100%;

    & + div {
      margin-top: 0 !important;
    }

    > input,
    > select {
      flex: 1;
      max-width: 100%;
    }
  }

  &.vehicle-garantible,
  &.immoble-garantible {
    display: none;

    opacity: 0;
    visibility: hidden;

    transition: opacity 0.7s, visibility 0.7s;
  }

  ${props =>
    props.separator &&
    css`
      border-bottom: 1px solid #ebedf3;

      > h1 {
        font-weight: 600;
      }

      > div {
        flex: 0;
      }
    `}
`;
