import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const InputFile = styled.div`
  display: flex;
  align-items: center;

  border: 2px solid transparent;
  border-radius: 10px;

  background-color: #f3f6f9;

  height: 45px;

  padding: 0 10px;

  position: relative;

  > svg {
    color: #ccc;
  }

  > span {
    margin-left: 16px;

    color: #ccc;

    font-size: 16px;
    font-weight: 600;
  }

  > input {
    flex: 1;

    opacity: 0;

    position: absolute;

    height: 100%;
    width: 100%;

    cursor: pointer;
  }
`;

export const InputFilesContainer = styled.div`
  display: block;

  margin-top: 20px;
`;
