import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const validationShape = Yup.object().shape({
    infinity_id: Yup.string(),
    complement: Yup.string(),
    address_number: Yup.string(),
    cpf: Yup.string(),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
