import { FC, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useRouteMatch } from 'react-router-dom';
import { FiDownload } from 'react-icons/fi';
import Datatable, { TableColumn } from 'react-data-table-component';

import { Row } from '@components/layouts/Grid/Row';
import { Badge } from '@components/elements/Badge';
import { LoadingPage } from '@components/layouts/LoadingPage';

import api from '@services/bbankApi';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  DownloadButton,
} from './styles';

interface ICreditFile {
  id: string;
  title: string;
  fileUrl: string;
  created_at: string;
}

interface ICredit {
  name_fantasy: string;
  owner: {
    name: string;
  };
}

interface IResponse {
  creditFiles: Array<ICreditFile>;
  credit: ICredit;
}

interface IParams {
  id: string;
}

interface ITableData {
  title: string;
  created_at: string;
  actions: JSX.Element;
}

const CredCashFiles: FC = () => {
  const [creditFiles, setCreditFiles] = useState<Array<ICreditFile>>();
  const [, setCredit] = useState<ICredit>();
  const { params } = useRouteMatch<IParams>();
  const { id } = params;

  // Load files from API
  useEffect(() => {
    async function loadFile() {
      const { data } = await api.get<IResponse>(`/credit-files/${id}`);

      setCreditFiles(data.creditFiles);
      setCredit(data.credit);
    }

    loadFile();
  }, [id]);

  // Table columns
  const tableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'Nome do arquivo',
        selector: 'title',
      },
      {
        name: 'Data do upload',
        selector: 'created_at',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  // Table data
  const tableData = useMemo<Array<ITableData>>(() => {
    if (creditFiles) {
      return creditFiles.map(file => {
        return {
          id: file.id,
          title: file.title,
          created_at: format(new Date(file.created_at), 'dd/MM/yyyy'),
          actions: (
            <DownloadButton
              href={file.fileUrl}
              target="_blank"
              rel="noreferrer"
            >
              <FiDownload size={20} />
            </DownloadButton>
          ),
        };
      });
    }

    return [];
  }, [creditFiles]);

  return (
    <>
      {!creditFiles ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Download</li>
              <li>{'>'}</li>
              <li>CredCash</li>
              <li>{'>'}</li>
              <li>Arquivos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Arquivos</h1>

                <Badge type={creditFiles.length === 0 ? 'danger' : 'success'}>
                  Arquivos encontrados:&nbsp;
                  {creditFiles.length}
                </Badge>
              </CardHeader>

              <CardContent>
                <Datatable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { CredCashFiles };
