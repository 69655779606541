import { IOption } from '@utils/interfaces';

import { IClient } from './interfaces';

export const operationType = [
  { value: 'comissao', label: 'Comissão' },
  { value: 'pagamentos', label: 'Pagamentos' },
  { value: 'recorrencia', label: 'Recorrência' },
  { value: 'rendimentos', label: 'Rendimentos' },
  { value: 'transferencia', label: 'Transfêrencia' },
  { value: 'externo', label: 'Extorno' },
];

export const getClientsOptions = (data: IClient[]): IOption[] => {
  const options = data.map(client => ({
    value: client.id,
    label: client.name,
  }));

  return options;
};
