/* eslint-disable prettier/prettier */
import { decode } from 'jsonwebtoken';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  FiBriefcase,
  FiDollarSign,
  FiDownload,
  FiFileText,
  FiHeadphones,
  FiHelpCircle,
  FiInfo,
  FiLink,
  FiShoppingBag,
  FiTag,
  FiUsers,
} from 'react-icons/fi';

import { useAuth } from '@hooks/auth';

import { CardMenu } from './CardMenu';
import { Container, Row } from './styles';

interface IPayloadToken {
  role: string;
}

const CardMenuContainer: FC = () => {
  const [role, setRole] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      const { role: roleFromToken } = decode(user.token) as IPayloadToken;

      setRole(roleFromToken);
    }
  }, [user.token, user]);

  const adminAndSuperAdminMenus = useCallback(() => {
    if (role === 'admin' || role === 'superAdmin') {
      return (
        <>
          <CardMenu
            menuId="adminCredcash"
            icon={FiDollarSign}
            title="CredCash"
          />

          {/* <CardMenu menuId="adminBbankPay" icon={FiBook} title="Bbankpay" /> */}

          <CardMenu menuId="franquia" icon={FiUsers} title="Franquias" />

          <CardMenu
            menuId="financeiro"
            icon={FiDollarSign}
            title="Financeiro"
          />

          <CardMenu
            menuId="adminMarketing"
            icon={FiHeadphones}
            title="Marketing"
          />

          <CardMenu menuId="adminLinks" icon={FiLink} title="Links" />

          <CardMenu
            menuId="relatorios"
            icon={FiFileText}
            title="Relatórios"
          />

          <CardMenu menuId="faq" icon={FiHelpCircle} title="FAQ" />

          <CardMenu menuId="users" icon={FiUsers} title="Usuários" />

          <CardMenu
            menuId="downloads"
            icon={FiDownload}
            title="Downloads"
          />

          {/* <CardMenu
                menuId="adminBbankConnect"
                icon={FiGlobe}
                title="Bbank Connect"
              /> */}

          <CardMenu menuId="prospects" icon={FiUsers} title="Prospectos" />

          <CardMenu menuId="news" icon={FiInfo} title="Notícias" />

          <CardMenu
            menuId="departments"
            icon={FiBriefcase}
            title="Departamentos"
          />

          <CardMenu menuId="adminTickets" icon={FiTag} title="Tickets" />

          {/* <CardMenu
                menuId="adminBbankInvest"
                icon={FiDollarSign}
                title="Bbank Invest"
              /> */}

          <CardMenu
            menuId="adminECommerce"
            icon={FiShoppingBag}
            title="Pagamento de Contas"
          />

          {/* <CardMenu
                menuId="adminTransfer"
                icon={BiTransfer}
                title="Transferência"
              /> */}

          {/* <CardMenu menuId="adminDeposit" icon={BiWallet} title="Depositar" /> */}

          {/* <CardMenu
                menuId="adminWithdrawalAndChange"
                icon={BiMoney}
                title="Saque/Troco"
              /> */}

          <CardMenu
            menuId="chargesAdmin"
            icon={FiDollarSign}
            title="Cobrança"
          />
        </>
      )
    }
  }, [role]);

  return (
    <Row id="mainContent">
      <Container>
        {role === 'credcash' && (
          <>
            <CardMenu
              menuId="adminCredcash"
              icon={FiDollarSign}
              title="CredCash"
            />

            <CardMenu
              menuId="credCashDownloads"
              icon={FiDownload}
              title="Downloads"
            />

            <CardMenu menuId="clientFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu menuId="sellerClients" icon={FiUsers} title="Clientes" />
          </>
        )}

        {adminAndSuperAdminMenus()}

        {role === 'client' && (
          <>
            <CardMenu
              menuId="clientCredcash"
              icon={FiDollarSign}
              title="CredCash"
            />

            {/* <CardMenu
              menuId="clientBbankConnect"
              icon={FiGlobe}
              title="Bbank Connect"
            /> */}

            {/* <CardMenu menuId="clientBbankPay" icon={FiBook} title="Bbankpay" /> */}

            {/* <CardMenu
              menuId="sharedDeposit"
              icon={BiWallet}
              title="Depositar"
            /> */}

            {/* <CardMenu
              menuId="sharedTransfer"
              icon={BiTransfer}
              title="Transferência"
            /> */}

            {/* <CardMenu
              menuId="sharedWithdrawalAndChange"
              icon={BiMoney}
              title="Saque/Troco"
            /> */}

            {/* <CardMenu
              menuId="adminECommerce"
              icon={FiShoppingBag}
              title="Pagamento de Contas"
            /> */}

            <CardMenu menuId="clientFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            {/* <CardMenu menuId="sellerBwallet" icon={BiWallet} title="Bwalltet" /> */}
          </>
        )}

        {role === 'business' && (
          <>
            <CardMenu
              menuId="businessClients"
              icon={FiUsers}
              title="Clientes"
            />

            <CardMenu
              menuId="franchiseesSellers"
              icon={FiUsers}
              title="Promotor"
            />

            <CardMenu
              menuId="businessCredcash"
              icon={FiDollarSign}
              title="CredCash"
            />

            {/* <CardMenu
              menuId="businessBbankConnect"
              icon={FiGlobe}
              title="Bbank Connect"
            /> */}

            {/* <CardMenu
              menuId="businessUploads"
              icon={FiUpload}
              title="Uploads"
            /> */}

            {/* <CardMenu
              menuId="businessBbankPay"
              icon={FiBook}
              title="BbankPay"
            /> */}

            <CardMenu menuId="businessFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu menuId="franchiseesLinks" icon={FiLink} title="Links" />

            {/* <CardMenu
              menuId="sharedInvest"
              icon={FiDollarSign}
              title="Bbank Invest"
            /> */}

            <CardMenu
              menuId="franchiseesMarketing"
              icon={FiHeadphones}
              title="Marketing"
            />

            {/* <CardMenu
              menuId="sharedDeposit"
              icon={BiWallet}
              title="Depositar"
            /> */}
            {/*
            <CardMenu
              menuId="sharedTransfer"
              icon={BiTransfer}
              title="Transferência"
            /> */}

            {/* <CardMenu
              menuId="sharedWithdrawalAndChange"
              icon={BiMoney}
              title="Saque/Troco"
            /> */}
          </>
        )}

        {role === 'master' && (
          <>
            <CardMenu menuId="masterClients" icon={FiUsers} title="Clientes" />

            <CardMenu
              menuId="franchiseesSellers"
              icon={FiUsers}
              title="Promotores"
            />

            <CardMenu
              menuId="masterCredcash"
              icon={FiDollarSign}
              title="CredCash"
            />

            {/* <CardMenu
              menuId="masterBbankConnect"
              icon={FiGlobe}
              title="Bbank Connect"
            /> */}

            {/* <CardMenu menuId="masterUploads" icon={FiUpload} title="Uploads" /> */}

            {/* <CardMenu menuId="masterBbankPay" icon={FiBook} title="BbankPay" /> */}

            <CardMenu menuId="masterFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu menuId="franchiseesLinks" icon={FiLink} title="Links" />
          </>
        )}

        {role === 'seller' && (
          <>
            <CardMenu
              menuId="sellerProspects"
              icon={FiUsers}
              title="Prospectos"
            />

            {/* <CardMenu
              menuId="sellerBbankConnect"
              icon={FiGlobe}
              title="Bbank Connect"
            /> */}

            <CardMenu
              menuId="sellerCredcash"
              icon={FiDollarSign}
              title="CredCash"
            />

            {/* <CardMenu
              menuId="sellerBbankPay"
              icon={FiCreditCard}
              title="Bbank Pay"
            /> */}

            <CardMenu menuId="sellerClients" icon={FiUsers} title="Clientes" />

            <CardMenu menuId="sellerFaq" icon={FiHelpCircle} title="FAQs" />

            <CardMenu menuId="sellerLinks" icon={FiLink} title="Links" />

            <CardMenu menuId="sharedTickets" icon={FiTag} title="Tickets" />

            <CardMenu
              menuId="sellerMarketing"
              icon={FiHeadphones}
              title="Marketing"
            />

            <CardMenu
              menuId="sellerECommerce"
              icon={FiShoppingBag}
              title="ECommmerce"
            />
            {/* <CardMenu
              menuId="sharedDeposit"
              icon={BiWallet}
              title="Depositar"
            /> */}

            {/* <CardMenu
              menuId="sharedTransfer"
              icon={BiTransfer}
              title="Transferência"
            /> */}

            {/* <CardMenu
              menuId="sharedWithdrawalAndChange"
              icon={BiMoney}
              title="Saque/Troco"
            /> */}
          </>
        )}

        {role === 'business' ||
          (role === 'master' && (
            <>
              <CardMenu
                menuId="franchiseesMarketing"
                icon={FiHeadphones}
                title="Marketing"
              />

              <CardMenu
                menuId="franchiseesProspects"
                icon={FiUsers}
                title="Prospectos"
              />
              {/*
              <CardMenu
                menuId="franchiseeECommerce"
                icon={FiShoppingBag}
                title="ECommmerce"
              /> */}

              {/* <CardMenu
                menuId="sharedInvest"
                icon={FiDollarSign}
                title="Bbank Invest"
              /> */}

              {/* <CardMenu
                menuId="sharedDeposit"
                icon={BiWallet}
                title="Depositar"
              /> */}

              {/* <CardMenu
                menuId="sharedTransfer"
                icon={BiTransfer}
                title="Transferência"
              /> */}

              {/* <CardMenu
                menuId="sharedWithdrawalAndChange"
                icon={BiMoney}
                title="Saque/Troco"
              /> */}
            </>
          ))}

        {/* <CardMenu menuId="sharedSells" icon={FiDollarSign} title="Vendas" /> */}
      </Container>
    </Row>
  );
};

export { CardMenuContainer };
