import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isDisabled?: boolean;
  hasErrors: boolean;
  isReadOnly?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;

  background-color: #f3f6f9;

  height: 45px;
  max-width: 300px;
  width: 100%;

  border: 2px solid transparent;
  border-radius: 10px;

  padding: 0 10px;

  transition: background-color 0.2s;

  transition: border-color 0.2s;

  ${props =>
    props.isDisabled &&
    css`
      background-color: #e6e9ec;
    `}

  ${props =>
    props.isReadOnly &&
    css`
      background-color: #e6e9ec;
    `}

  & + & {
    margin-top: 8px;
  }

  > svg {
    color: #ccc;

    margin-right: 16px;

    transition: stroke 0.2s;
  }

  input {
    flex: 1;

    height: 100%;

    border: none;
    border-radius: 10px;

    background-color: inherit;

    font-size: 16px;
    color: #1e1e1e;

    transition: background-color 0.2s;

    &::placeholder {
      color: #ccc;
      font-weight: 600;
    }

    &:disabled {
      background-color: #f5f5f5;
    }
  }

  ${props =>
    props.hasErrors &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      background-color: #fff;

      border-color: #c8c6c0;

      > svg {
        color: #c8c6c0;
      }
    `}

  ${props =>
    props.isFilled &&
    css`
      > svg {
        stroke: #c8c6c0;
      }
    `};

  ${props =>
    props.isFocused &&
    props.isReadOnly &&
    css`
      background-color: #e6e9ec;

      border-color: #c8c6c0;

      > svg {
        stroke: #c8c6c0;
      }
    `}
`;
