import styled, { css } from 'styled-components';

interface IContainer {
  read: boolean;
}

export const Container = styled.div<IContainer>`
  padding: 8px;

  border-radius: 5px;

  cursor: pointer;

  transition: background-color 0.2s;

  & + * {
    margin-top: 6px;
  }

  ${props =>
    props.read &&
    css`
      background-color: #f0f0f0;

      opacity: 0.5;

      cursor: default;
    `}

  &:hover {
    background-color: #f0f0f0;
  }

  > main {
    margin-top: 6px;

    > p {
      color: #050505;
    }
  }

  > footer {
    margin-top: 6px;

    color: #a36aa0;
  }
`;
