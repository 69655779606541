import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > span {
    color: #aaa;
    font-weight: 600;
    font-size: 16px;
  }
`;

export const InputDate = styled.input`
  height: 35px;

  border: 2px solid transparent;
  border-radius: 10px;

  background-color: #f3f6f9;

  padding: 10px;

  font-size: 16px;

  transition: border-color 0.2s;

  &::placeholder {
    color: #ccc;
  }

  &:focus {
    border-color: #c8c6c0;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImgBrandContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const ImgBrand = styled.img`
  max-width: 20px;
  height: auto;
`;
