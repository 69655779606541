import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
`;

export const Site = styled.div`
  max-width: calc(100% / 4 - 30px);
  width: 100%;
  height: 200px;

  padding: 12px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 10%);

  border: 1px solid #ebedf3;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: transform 0.4s;

  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  > h1 {
    font-size: 18px;

    text-align: center;

    margin-top: 12px;
  }

  > p {
    margin-top: 6px;

    font-size: 15px;
    color: #b5b5c3;

    text-align: center;
  }

  > span {
    margin-top: 3px;

    font-size: 14px;
  }
`;
