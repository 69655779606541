import * as Yup from 'yup';

interface IFormData {
  [key: string]: string;
}

export const formValidation = async (data: IFormData): Promise<void> => {
  const validationShape = Yup.object().shape({
    confirmPassword: Yup.string()
      .required('Preencha este campo!')
      .oneOf([Yup.ref('password')], 'As senhas não coincidem!'),
    password: Yup.string().required('Preencha este campo!'),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
