import { format } from 'date-fns';
import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { FiSearch, FiUser } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';

import api from '@services/bbankApi';

import { createPagination, IPages } from '@helpers/createPagination';

import { IProspect, ILoadedProspect } from './interfaces';
import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  Prospect,
  ProspectIcon,
  ProspectInfo,
  ProspectCreatedAt,
  ButtonWrapper,
} from './styles';

const ListProspectsCredits: FC = () => {
  const { push } = useHistory();

  const [prospects, setProspects] = useState<IProspect[]>();
  const [searchString, setSearchString] = useState('');
  const [searchProspects, setSearchProspects] = useState<IProspect[]>();
  const [actualPage, setActualPage] = useState(1);

  useEffect(() => {
    async function loadProspects() {
      const { data } = await api.get<ILoadedProspect[]>('/credits');

      const parsedData = data.map(item => ({
        id: item.id,
        name: item.name,
        product: item.product,
        email: item.email,
        state: item.state,
        createdAt: format(new Date(item.created_at), 'dd/MM/yyyy'),
      }));

      setSearchProspects(parsedData);
      setProspects(parsedData);
    }

    const timer = setTimeout(() => {
      loadProspects();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const pages = useMemo((): IPages => {
    if (!prospects || prospects.length === 0) {
      return { 1: [] };
    }

    return createPagination(prospects);
  }, [prospects]);

  const handleSearchProspects = useCallback(() => {
    if (!prospects) {
      return;
    }

    if (searchString === '') {
      setSearchProspects(prospects);
      return;
    }

    const search = prospects.filter(
      prospect =>
        prospect.email.toLowerCase().search(searchString.toLowerCase()) >= 0 ||
        prospect.name.toLowerCase().search(searchString.toLowerCase()) >= 0 ||
        prospect.state.toLowerCase().search(searchString.toLowerCase()) >= 0 ||
        prospect.product.toLowerCase().search(searchString.toLowerCase()) >=
          0 ||
        prospect.createdAt.toLowerCase().search(searchString.toLowerCase()) >=
          0,
    );

    setSearchProspects(search);
  }, [searchString, prospects]);

  const handleNavigation = useCallback(
    (prospectId: string) => {
      push(`/prospects/${prospectId}/view`);
    },
    [push],
  );

  const handleForwardPagination = useCallback(() => {
    setActualPage(oldState => oldState + 1);
  }, []);

  const handleBackPagination = useCallback(() => {
    setActualPage(oldState => oldState - 1);
  }, []);

  return (
    <>
      {!searchProspects ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Prospectos</li>
              <li>{'>'}</li>
              <li>Todos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Prospectos cadastrados</h1>

                <Form
                  onSubmit={() => {
                    /* CODE */
                  }}
                >
                  <Input
                    name="search"
                    icon={FiSearch}
                    placeholder="Procurar..."
                    onChange={e => setSearchString(e.target.value)}
                    onKeyUp={handleSearchProspects}
                    upperCase={false}
                  />
                </Form>
              </CardHeader>

              <CardContent>
                {pages[actualPage].length === 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                    }}
                  >
                    <Badge type="warning">Não há registros</Badge>
                  </div>
                ) : (
                  pages[actualPage].map((prospect: any) => (
                    <Prospect
                      key={prospect.id}
                      onClick={() => handleNavigation(prospect.id)}
                    >
                      <ProspectIcon>
                        <FiUser />
                      </ProspectIcon>

                      <ProspectInfo>
                        <h1>{prospect.name}</h1>

                        <div>
                          <span>
                            Produto:&nbsp;
                            <strong>Veiculos</strong>
                          </span>
                          &nbsp;
                          <span>
                            Email:&nbsp;
                            <strong>{prospect.email}</strong>
                          </span>
                        </div>
                      </ProspectInfo>

                      <ProspectCreatedAt>
                        <span>{prospect.createdAt}</span>
                      </ProspectCreatedAt>
                    </Prospect>
                  ))
                )}

                <ButtonWrapper>
                  <button
                    type="button"
                    disabled={actualPage === 1}
                    onClick={handleBackPagination}
                  >
                    Voltar
                  </button>

                  <button
                    type="button"
                    disabled={actualPage === Object.keys(pages).length}
                    onClick={handleForwardPagination}
                  >
                    Avançar
                  </button>
                </ButtonWrapper>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListProspectsCredits };
