import { forwardRef, useCallback, useRef, useState, useEffect } from 'react';
import { Form as LibForm } from '@unform/web';
import { FormHandles } from '@unform/core';

import { IForm } from './interfaces';
import { ProgressBar, ProgressBarDot } from './styles';

const Form = forwardRef<FormHandles, IForm>(
  ({ children, progressBar, ...rest }, ref) => {
    const progressBarRef = useRef<HTMLDivElement>(null);

    const [ready, setReady] = useState(false);

    useEffect(() => {
      setTimeout(() => setReady(true), 50);
    }, []);

    const handleRenderDots = useCallback(
      (item: number) => {
        if (!progressBar || !progressBarRef.current) {
          return <></>;
        }

        const { currentStep } = progressBar;
        const stepActivated = currentStep - (item + 2);
        const { clientWidth } = progressBarRef.current;
        const { steps } = progressBar;

        return (
          <ProgressBarDot
            key={item}
            afterWidth={
              stepActivated >= 0
                ? Number(clientWidth / (steps - 1)).toString()
                : '0'
            }
          >
            <span>{item + 1}</span>
          </ProgressBarDot>
        );
      },
      [progressBar, progressBarRef],
    );

    return (
      <>
        {progressBar && progressBar.active && (
          <ProgressBar ref={progressBarRef}>
            {ready &&
              Array.from(Array(progressBar.steps).keys()).map(item =>
                handleRenderDots(item),
              )}
          </ProgressBar>
        )}
        <LibForm {...rest} ref={ref} autoComplete="off">
          {children}
        </LibForm>
      </>
    );
  },
);

export { Form };
