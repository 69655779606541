import * as Yup from 'yup';

export const formValidation = async (data: {
  [key: string]: string;
}): Promise<void> => {
  const shape = Yup.object().shape({
    name: Yup.string().required('Preencha este campo!'),
    email: Yup.string()
      .email('Insira um email válido!')
      .required('Preencha este campo!'),
    password: Yup.string(),
    confirmPassword: Yup.string()
      .when({
        is: Yup.ref('password'),
        then: Yup.string().required('Preencha este campo!'),
        otherwise: Yup.string(),
      })
      .oneOf([Yup.ref('password')], 'As senhas não correspondem!'),
    zipcode: Yup.string().required('Preencha este campo!'),
    address: Yup.string().required('Preencha este campo!'),
    state: Yup.string().required('Preencha este campo!'),
    city: Yup.string().required('Preencha este campo!'),
    phone: Yup.string().required('Preencha este campo!'),
    cellphone: Yup.string().required('Preencha este campo!'),
    person_type: Yup.string().required('Preencha este campo!'),
    document: Yup.string().required('Preencha este campo!'),
    role: Yup.string()
      .oneOf(['client', 'seller'], 'Business ou cliente?')
      .required('Selecione uma opção!'),
    nickname: Yup.string().when({
      is: (_: string) => data.role === 'business',
      then: Yup.string().required('Preencha este campo!'),
    }),
    birthdate: Yup.string()
      .required('Preencha este campo')
      .max(10, 'Insira uma data válida!')
      .min(10, 'Insira uma data válida!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
