import { FormHandles } from '@unform/core';
import { FC, useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { useRouteMatch } from 'react-router-dom';
import { Form } from '@unform/web';
import { format } from 'date-fns';

import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Badge } from '@components/elements/Badge';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Textarea } from '@components/elements/Form/Textarea';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { URLPath } from '@components/layouts/UrlPath';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { removeInputMask } from '@helpers/removeInputMask';

import {
  creditProductOptions,
  creditModalityOptions,
  getStatesOptions,
  maritalStatusOptions,
  ownPropertyOptions,
  propertyType,
} from './selectOptions';
import { Card, CardHeader, CardContent } from './styles';
import { ICredit, IParams } from './interfaces';
import {
  formForPjTypesValidation,
  formForPfTypesValidation,
} from './validations';

type IGuaranteeType = 'vehicle' | 'property' | 'credluz' | 'bndes' | undefined;

const FranchiseUpdateCredit: FC = () => {
  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [credit, setCredit] = useState<ICredit>();
  const [guaranteeType, setGuaranteeType] = useState<IGuaranteeType>();

  useEffect(() => {
    async function loadCredit() {
      const { creditId } = params;

      const { data } = await api.get(`/credits/${creditId}`);

      setCredit(data);
      setGuaranteeType(data.credit.product);
    }

    setTimeout(() => {
      loadCredit();
    }, 1000);
  }, [params]);

  const statesOptions = useMemo(() => {
    return getStatesOptions();
  }, []);

  // Form for credit PF types
  const formForPfTypes = useMemo(() => {
    return (
      <>
        <FormRow separator>
          <h1>Dados do cliente</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Nome do cliente</label>
            <Input name="credit.owner.name" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Email do cliente</label>
            <Input name="credit.owner.email" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Documento do cliente</label>
            <InputMask
              mask="999.999.999-99"
              name="credit.owner.document"
              readOnly
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Celular do cliente</label>
            <InputMask
              mask="(99) 9 9999-9999"
              name="credit.owner.cellphone"
              readOnly
            />
          </InputGroup>

          <InputGroup>
            <label>Faturamento médio do cliente</label>
            <Input
              name="credit.average_of_revenues"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>
        </FormRow>

        <FormRow separator>
          <h1>Endereço do cliente</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>CEP do cliente</label>
            <InputMask mask="99999-999" name="credit.owner.zipcode" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Endereço do cliente</label>
            <Input name="credit.owner.address" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Cidade do cliente</label>
            <Input name="credit.owner.city" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Estado do cliente</label>
            <Input name="credit.owner.state" readOnly />
          </InputGroup>
        </FormRow>
      </>
    );
  }, [credit?.credit.status]);

  // Form for credit PJ types
  const formForPjTypes = useMemo(() => {
    return (
      <>
        <FormRow separator>
          <h1>Dados da empresa do solicitante</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>CNPJ</label>
            <Input
              name="credit.cnpj"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>

          <InputGroup>
            <label>Nome fantasia</label>
            <Input
              name="credit.name_fantasy"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Razão social</label>
            <Input
              name="credit.social_reason"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Email da empresa</label>
            <Input
              name="credit.email"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>

          <InputGroup>
            <label>Telefone da empresa</label>
            <InputMask
              mask="(99) 9 9999-9999"
              name="credit.phone"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>

          <InputGroup>
            <label>Faturamento médio da empresa</label>
            <InputCurrency
              name="credit.average_of_revenues"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>
        </FormRow>

        <FormRow separator>
          <h1>Localização da empresa</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>CEP da empresa</label>
            <InputMask
              mask="99999-999"
              name="credit.zipcode"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>

          <InputGroup>
            <label>Endereço da empresa</label>
            <Input
              name="credit.address"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>

          <InputGroup>
            <label>Complemento do endereço da empresa</label>
            <Input
              name="credit.complement"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Bairro da empresa</label>
            <Input
              name="credit.neighborhood"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>

          <InputGroup>
            <label>Cidade da empresa</label>
            <Input
              name="credit.city"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>

          <InputGroup>
            <label>Estado de localidade da empresa</label>
            <Select
              name="credit.state"
              readonly={credit?.credit.status !== 'pendente'}
              options={statesOptions}
            />
          </InputGroup>
        </FormRow>

        <FormRow separator>
          <h1>Dados do responsável da empresa</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Nome do responável pela empresa</label>
            <Input
              name="credit.responsible_name"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>CPF do responável pela empresa</label>
            <InputMask
              mask="999.999.999-99"
              name="credit.responsible_cpf"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>

          <InputGroup>
            <label>Telefone celular do responável pela empresa</label>
            <InputMask
              mask="(99) 9 9999-9999"
              name="credit.responsible_cellphone"
              readOnly={credit?.credit.status !== 'pendente'}
            />
          </InputGroup>
        </FormRow>
      </>
    );
  }, [credit, statesOptions]);

  // Form validation
  const handleFormSubmit = useCallback(
    async data => {
      try {
        formRef.current?.setErrors({});

        const valuesWithoutMasks = removeInputMask();

        switch (credit?.credit.type) {
          case 'PJ':
            await formForPjTypesValidation(data);
            break;

          case 'PF':
            await formForPfTypesValidation(data);
            break;

          default:
            return;
        }

        const formData = valuesWithoutMasks.reduce((acc, { name, value }) => {
          const parsedName = name.replace('credit.', '');

          acc[parsedName] = value;

          return acc;
        }, data.credit);

        const { data: dataFromApi } = await api.put(
          `/credits/${credit.credit?.id}`,
          formData,
        );

        setCredit(dataFromApi);

        addToast({
          title: 'Crédito atualizado com sucesso!',
          type: 'success',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { status, message } = getClientErrors(err.response);

          if (status === 400 || status === 403 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Erro interno do servidor!',
              type: 'error',
            });
          }
        }
      }
    },
    [addToast, credit],
  );

  // Form for CredLuz
  const credLuzForm = useMemo(() => {
    return (
      <>
        <FormRow separator>
          <h1>Dados do CredLuz solicitado pelo cliente</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Registro geral (RG)</label>
            <Input name="guarantees[0].rg" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Orgão expeditor (RG)</label>
            <Input name="guarantees[0].issuing_body" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Data de nascimento</label>
            <Input name="guarantees[0].birthday" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Status civil</label>
            <Select
              name="guarantees[0].marital_status"
              options={maritalStatusOptions}
              readonly
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Escolaridade</label>
            <Input name="guarantees[0].schooling" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Nome do pai</label>
            <Input name="guarantees[0].father_name" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Nome da mãe</label>
            <Input name="guarantees[0].mother_name" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Sexo</label>
            <Input name="guarantees[0].sex" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Referência</label>
            <Input name="guarantees[0].reference" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Telefone da referência</label>
            <InputMask
              name="guarantees[0].reference_phone"
              mask="(99) 99999-9999"
              readOnly
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Unidade consumidora</label>
            <Input name="guarantees[0].consumer_unit" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow separator>
          <h1>Endereço</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Número</label>
            <Input name="guarantees[0].number" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Bairro</label>
            <Input name="guarantees[0].neighborhood" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Complemento</label>
            <Input name="guarantees[0].complement" readOnly />
          </InputGroup>
        </FormRow>
      </>
    );
  }, []);

  // Form for property guarantee
  const propertyGuaranteeForm = useMemo(() => {
    return (
      <>
        <FormRow separator>
          <h1>Dados da garantia de imóvel</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Registro geral (RG)</label>
            <Input name="general_registry" readOnly maxLength={50} />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Tipo do imóvel</label>
            <Select
              name="guarantees[0].property_type"
              readonly
              options={propertyType}
            />
          </InputGroup>

          <InputGroup>
            <label>Logradouro</label>
            <Input name="guarantees[0].public_place" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Número</label>
            <Input name="guarantees[0].number" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Condomínio</label>
            <Input name="guarantees[0].condominium" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Unidade</label>
            <Input name="guarantees[0].unity" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Torre</label>
            <Input name="guarantees[0].tower" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Vagas (quantidade)</label>
            <Input name="guarantees[0].vacancy" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Bairro</label>
            <Input name="guarantees[0].neighborhood" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Cidade</label>
            <Input name="guarantees[0].city" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Estado</label>
            <Input name="guarantees[0].state" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Código postal</label>
            <Input name="guarantees[0].zipcode" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Valor do imóvel</label>
            <Input name="guarantees[0].guarantee_value" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Período de financiamento</label>
            <Input name="guarantees[0].financing_period" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Imóvel próprio?</label>
            <Select
              name="guarantees[0].own_property"
              options={ownPropertyOptions}
              readonly
            />
          </InputGroup>

          <InputGroup>
            <label>Relação com o terceiro (imóvel não próprio)</label>
            <Input name="guarantees[0].relationship" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Nome do terceiro (imóvel não próprio)</label>
            <Input name="guarantees[0].third_party_name" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>CPF do terceiro (imóvel não próprio)</label>
            <Input name="guarantees[0].third_party_cpf" readOnly />
          </InputGroup>

          <InputGroup>
            <label>CPF/CNPJ do terceiro (imóvel não próprio)</label>
            <Input name="guarantees[0].cpf_cnpj" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Banco (imóvel não próprio)</label>
            <Input name="guarantees[0].bank" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Agencia (imóvel não próprio)</label>
            <Input name="guarantees[0].agency" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Conta (imóvel não próprio)</label>
            <Input name="guarantees[0].account" readOnly />
          </InputGroup>
        </FormRow>
      </>
    );
  }, []);

  const vehicleGuaranteeForm = useMemo(() => {
    const birthdayDate =
      credit &&
      credit.guarantees &&
      credit.guarantees.length > 0 &&
      credit.guarantees[0] !== null
        ? credit.guarantees[0].birthday
        : undefined;
    const admissionDate =
      credit &&
      credit.guarantees &&
      credit.guarantees.length > 0 &&
      credit.guarantees[0] !== null
        ? credit.guarantees[0].admission_date
        : undefined;

    return (
      <>
        <FormRow separator>
          <h1>Dados da garantia de veículos</h1>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Data de nascimento</label>
            <InputMask
              mask="99/99/9999"
              name="guarantees[0].birthday"
              defaultValue={
                birthdayDate ? format(new Date(birthdayDate), 'dd/MM/yyyy') : ''
              }
              readOnly
            />
          </InputGroup>

          <InputGroup>
            <label>Celular</label>
            <InputMask
              mask="(99) 9 9999-9999"
              name="guarantees[0].cellphone"
              readOnly
            />
          </InputGroup>

          <InputGroup>
            <label>Telefone</label>
            <InputMask
              mask="(99) 9999-9999"
              name="guarantees[0].phone"
              readOnly
            />
          </InputGroup>

          <InputGroup>
            <label>Telefone comercial</label>
            <InputMask
              mask="(99) 9999-9999"
              name="guarantees[0].commercial_phone"
              readOnly
            />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Faturamento</label>
            <InputCurrency name="guarantees[0].income" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Registro geral (RG)</label>
            <Input name="guarantees[0].general_registry" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Empresa</label>
            <Input name="guarantees[0].company" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Nome do cliente</label>
            <Input name="guarantees[0].client_name" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Estado civil</label>
            <Select
              name="guarantees[0].marital_status"
              options={maritalStatusOptions}
              readonly
            />
          </InputGroup>

          <InputGroup>
            <label>Tempo de conta bancária (em anos)</label>
            <Input name="guarantees[0].bank_account_age" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Nome da mãe</label>
            <Input name="guarantees[0].mother_name" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Marca do veículo</label>
            <Input name="guarantees[0].vehicle_brand" readOnly />
          </InputGroup>

          <InputGroup>
            <label>CPF do responsável</label>
            <InputMask
              mask="999.999.999-99"
              name="guarantees[0].cpf"
              readOnly
            />
          </InputGroup>

          <InputGroup>
            <label>Nacionalidade</label>
            <Input name="guarantees[0].nacionality" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Profissão</label>
            <Input name="guarantees[0].profession" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Referência</label>
            <Input name="guarantees[0].third_party" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Modelo do veículo</label>
            <Input name="guarantees[0].vehicle_model" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Ano (fabricação e modelo)</label>
            <Input name="guarantees[0].vehicle_fabrication_year" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Versão do veículo</label>
            <Input name="guarantees[0].vehicle_version" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Valor do veículo</label>
            <InputCurrency name="guarantees[0].vehicle_value" readOnly />
          </InputGroup>

          <InputGroup>
            <label>Valor do empréstimo</label>
            <InputCurrency name="guarantees[0].loan_amount" readOnly />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Data de admissão</label>
            <InputMask
              mask="99/99/9999"
              name="guarantees[0].admission_date"
              defaultValue={
                admissionDate
                  ? format(new Date(admissionDate), 'dd/MM/yyyy')
                  : ''
              }
              readOnly
            />
          </InputGroup>

          <InputGroup>
            <label>Função na empresa</label>
            <Input name="guarantees[0].employee_company_role" readOnly />
          </InputGroup>
        </FormRow>
      </>
    );
  }, [credit]);

  return (
    <>
      {!credit ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['CredCash', 'Visualizar']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>CredCash</h1>

                <Badge
                  type={
                    credit.credit.status === 'aprovado' ? 'success' : 'warning'
                  }
                >
                  {credit.credit.status}
                </Badge>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  initialData={credit}
                  ref={formRef}
                >
                  <FormRow separator>
                    <h1>Dados do crédito</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Modalidade</label>
                      <Select
                        name="credit.modality"
                        options={creditModalityOptions}
                        readonly
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Tipo de crédito (PF, PJ)</label>
                      <Input name="credit.type" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Produto</label>
                      <Select
                        name="credit.product"
                        options={creditProductOptions}
                        readonly
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor do crédito solicitado</label>
                      <InputCurrency
                        name="credit.amount"
                        readOnly={credit.credit.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  {credit.credit.type === 'PF' && formForPfTypes}

                  {credit.credit.type === 'PJ' && formForPjTypes}

                  <FormRow separator>
                    <h1>Acompanhamento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Acompanhamento e instruções</label>
                      <Textarea name="credit.commentaries" rows={20} readOnly />
                    </InputGroup>
                  </FormRow>

                  {guaranteeType === 'credluz' && credLuzForm}

                  {guaranteeType === 'property' && propertyGuaranteeForm}

                  {guaranteeType === 'vehicle' && vehicleGuaranteeForm}

                  {credit.credit.status === 'pendente' && (
                    <FormRow buttonWrapper>
                      <Button type="submit" styleType="info" icon={FiSave}>
                        Fazer alterações
                      </Button>
                    </FormRow>
                  )}
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { FranchiseUpdateCredit };
