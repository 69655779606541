interface IOption {
  value: string;
  label: string;
}

interface IFranchisee {
  id: string;
  name: string;
}

interface IClient {
  id: string;
  name: string;
}

export const statusOpions: IOption[] = [
  { label: 'Ativo', value: 'ativo' },
  { label: 'Pendente', value: 'pendente' },
];

export const businessMethodOptions: IOption[] = [
  { value: 'accession', label: 'Adesão' },
  { value: 'sells_a50', label: 'Venda A50 + adesão' },
  { value: 'sells_a930', label: 'Venda A930 + adesão' },
];

export const sellingTypeOptions: IOption[] = [
  { value: 'sells_with_cashback', label: 'Vendas com CashBack' },
  { value: 'sells_without_cashback', label: 'Vendas sem CashBack' },
];

export const getFranchiseesOptions = (data: IFranchisee[]): IOption[] => {
  const options = data.map(item => ({ value: item.id, label: item.name }));

  return options;
};

export const getFranchiseeClientsOptions = (data: IClient[]): IOption[] => {
  const options = data.map(item => ({ value: item.id, label: item.name }));

  return options;
};

export const paymentFormOptions: IOption[] = [
  { value: 'credit_card', label: 'Cartão de crédito' },
  { value: 'billet', label: 'Boleto' },
  { value: 'debit', label: 'Débito em conta' },
];

const getPortions = (): IOption[] => {
  const arr = Array.from(Array(12).keys());

  return arr.map(item => ({
    value: String(item + 1),
    label: String(item + 1),
  }));
};

export const portionsOptions = getPortions();
