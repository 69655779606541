import { FC, useEffect, useState, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FiDollarSign, FiEye } from 'react-icons/fi';
import { useLocation, useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';

import api from '@services/bbankApi';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  TableButton,
  TableButtonContainer,
} from './styles';
import { IMerchant, IMerchantFromApi } from './interfaces';

const ListByStatusNameMerchants: FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();

  const [merchants, setMerchants] = useState<IMerchant[] | undefined>(
    undefined,
  );

  useEffect(() => {
    setMerchants(undefined);

    async function loadMerchants() {
      const { data } = await api.get<IMerchantFromApi[]>(
        `/bbank-pay/merchants/status?s=${search.replace('?s=', '')}`,
      );

      const state = data.map(merchant => {
        return {
          id: merchant.id,
          infinityId: merchant.infinity_id,
          cnpj: merchant.cnpj,
          nameFantasy: merchant.name_fantasy,
          status: merchant.status,
        };
      });

      setMerchants(state);
    }

    const timeout = setTimeout(() => {
      loadMerchants();
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'CNPJ do estabelecimento',
        selector: 'search',
      },
      {
        name: 'Nome fantasia',
        selector: 'nameFantasy',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Infinity ID',
        selector: 'infinityId',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!merchants) {
      return [];
    }

    const data = merchants.map(merchant => {
      let badgeType: 'danger' | 'success' | 'warning' = 'success';

      if (merchant.status === 'parado') {
        badgeType = 'danger';
      }

      if (merchant.status === 'pendente') {
        badgeType = 'warning';
      }

      return {
        id: merchant.id,
        search: merchant.cnpj,
        infinityId: merchant.infinityId,
        nameFantasy: merchant.nameFantasy,
        status: <Badge type={badgeType}>{merchant.status}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              title="Visualizar"
              onClick={() => push(`/bbank-pay/merchants/view/${merchant.id}`)}
            >
              <FiEye size={20} />
            </TableButton>

            <TableButton
              styleType="info"
              title="Transações"
              onClick={() =>
                push(`/bbank-pay/merchants/${merchant.id}/transactions`)
              }
            >
              <FiDollarSign size={20} />
            </TableButton>
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [merchants, push]);

  return (
    <>
      {!merchants ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>BbankPay</li>
              <li>{'>'}</li>
              <li>Estabelecimentos</li>
              <li>{'>'}</li>
              <li>Todos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Estabelecimentos</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por CNPJ..."
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListByStatusNameMerchants };
