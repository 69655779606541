import { useEffect, FC, useState, useMemo, useCallback } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { Badge } from '@components/elements/Badge';
import { LoadingPage } from '@components/layouts/LoadingPage';

import api from '@services/bbankApi';

import { ITelemedicineScore, IScoreBadgeType } from './interfaces';
import {
  URLPath,
  Card,
  CardContent,
  CardHeader,
  TableButtonContainer,
  TableButton,
} from './styles';

const ListBbankConnectResearchs: FC = () => {
  const { push } = useHistory();

  const [telemedicineScores, setTelemedicineScores] = useState<
    ITelemedicineScore[]
  >();

  useEffect(() => {
    async function loadScores() {
      const { data } = await api.get(`/telemedicine/scores`, {
        params: {
          relations: ['prospect', 'seller'],
        },
      });

      setTelemedicineScores(data);
    }

    const timer = setTimeout(() => loadScores(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleNavigationToResearchDetails = useCallback(
    (telemedicineScoreId: string) => {
      push(`/bbank-connect/researchs/${telemedicineScoreId}/details`);
    },
    [push],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do prospecto',
        selector: 'prospectName',
      },
      {
        name: 'Nome do vendedor',
        selector: 'search',
      },
      {
        name: 'Pontuação na pesquisa',
        selector: 'score',
      },
      {
        name: 'Classificação',
        selector: 'classification',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!telemedicineScores) {
      return [];
    }

    const data = telemedicineScores.map(score => {
      const scoreBadgeType: IScoreBadgeType = {
        warm: {
          badgeType: 'warning',
          badgeText: 'Morno',
        },
        hot: {
          badgeType: 'success',
          badgeText: 'Quente',
        },
        cold: {
          badgeType: 'danger',
          badgeText: 'Frio',
        },
      };

      return {
        id: score.id,
        prospectName: score.prospect.name,
        search: score.seller.name,
        score: <Badge type="info">{score.score}</Badge>,
        classification: (
          <Badge type={scoreBadgeType[score.classification].badgeType}>
            {scoreBadgeType[score.classification].badgeText}
          </Badge>
        ),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              title="Ver informações completas"
              onClick={() => handleNavigationToResearchDetails(score.id)}
            >
              <FiEye size={22} />
            </TableButton>
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [telemedicineScores, handleNavigationToResearchDetails]);

  return (
    <>
      {!telemedicineScores ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Bbank Connect</li>
              <li>{'>'}</li>
              <li>Pesquisas de Mercado</li>
              <li>{'>'}</li>
              <li>Todas</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Listagem das pesquisas de marcado</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar pelo nome do vendedor"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListBbankConnectResearchs };
