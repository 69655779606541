import { FC, useCallback, useRef } from 'react';
import {
  FiUser,
  FiMail,
  FiGift,
  FiPhone,
  FiSmartphone,
  FiMapPin,
  FiHome,
  FiMap,
  FiFlag,
  FiLock,
  FiTag,
  FiSave,
  FiHash,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

import { Row } from '@components/layouts/Grid/Row';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import viaCepApi from '@services/viaCepApi';
import api from '@services/bbankApi';
import { IZipcodeResponse } from '@services/interfaces';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { removeInputMask } from '@helpers/removeInputMask';

import { Card, CardHeader, CardContent } from './styles';
import { IFormData } from './interfaces';
import { statesOptions, personTypeOptions } from './selectOptions';
import { formValidation } from './validations';

const CreateClients: FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        const valuesWithoutMask = removeInputMask();

        const parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, data);

        delete parsedData.confirmPassword;

        parsedData.indication = user.id;

        await api.post('/users', parsedData);

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'Cliente adicionado com sucesso!',
        });

        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [user.id, addToast],
  );

  const handleFindZipcode = useCallback(
    async (zipcode: string) => {
      try {
        const { data } = await viaCepApi.get<IZipcodeResponse>(
          `/${zipcode}/json`,
        );
        const zipcodeInputRef = formRef.current?.getFieldRef('zipcode');
        const stateInputRef = formRef.current?.getFieldRef('state');
        const cityInputRef = formRef.current?.getFieldRef('city');
        const addressInputRef = formRef.current?.getFieldRef('address');

        if (data.erro === true) {
          zipcodeInputRef.value = '';
          cityInputRef.value = '';
          addressInputRef.value = '';
          return;
        }

        const { cep, localidade, logradouro, uf } = data;

        zipcodeInputRef.value = cep;
        cityInputRef.value = localidade;
        addressInputRef.value = logradouro;

        const getSelectValue = statesOptions.find(state => state.value === uf);

        stateInputRef.select.setValue(getSelectValue);

        stateInputRef.focus();
      } catch (err: any) {
        if (err.response) {
          addToast({
            title: 'Algo não está certo!',
            type: 'error',
            message: 'O CEP informado não foi digitado corretamente!',
          });
        }
      }
    },
    [addToast],
  );

  return (
    <>
      <Row>
        <URLPath paths={['Clientes', 'Novo']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Preencha o formulário abaixo para adicionar um novo cliente</h1>
          </CardHeader>

          <CardContent>
            <Form
              onSubmit={handleFormSubmit}
              ref={formRef}
              // initialData={{
              //   zipcode: addressInformation?.zipcode,
              //   address: addressInformation?.address,
              //   city: addressInformation?.city,
              // }}
            >
              <FormRow>
                <InputGroup>
                  <label>Nome do cliente</label>
                  <Input name="name" icon={FiUser} />
                </InputGroup>

                <InputGroup>
                  <label>Email do cliente</label>
                  <Input type="email" name="email" icon={FiMail} />
                </InputGroup>

                <InputGroup>
                  <label>Data de aniversário do cliente</label>
                  <InputMask
                    mask="99/99/9999"
                    name="birthdate"
                    icon={FiGift}
                    noUnmask
                  />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Número de telefone do cliente</label>
                  <InputMask
                    mask="(99) 9999-9999"
                    name="phone"
                    icon={FiPhone}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Número de celular do cliente</label>
                  <InputMask
                    mask="(99) 99999-9999"
                    name="cellphone"
                    icon={FiSmartphone}
                  />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>CEP (código postal)</label>
                  <InputMask
                    mask="99999-999"
                    name="zipcode"
                    icon={FiMapPin}
                    onBlur={e => handleFindZipcode(e.target.value)}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Endereço do cliente</label>
                  <Input name="address" icon={FiHome} />
                </InputGroup>

                <InputGroup>
                  <label>Cidade residencial do cliente</label>
                  <Input name="city" icon={FiMap} />
                </InputGroup>

                <InputGroup>
                  <label>Estado residencial do cliente</label>
                  <Select options={statesOptions} name="state" icon={FiFlag} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Senha de acesso da plataforma</label>
                  <Input type="password" name="password" icon={FiLock} />
                </InputGroup>

                <InputGroup>
                  <label>Confirme a senha de acesso</label>
                  <Input type="password" name="confirmPassword" icon={FiLock} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Tipo de documentação (CPF/CNPJ)</label>
                  <Select
                    name="person_type"
                    icon={FiTag}
                    options={personTypeOptions}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Documento (CPF/CNPJ) do cliente</label>
                  <InputMask
                    name="document"
                    mask="99999999999999"
                    icon={FiHash}
                  />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button styleType="success" type="submit" icon={FiSave}>
                  Cadastrar novo cliente
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { CreateClients };
