import {} from 'use-context-selector';
import { useContext, MouseEvent } from 'react';

import { MenuContext } from '../contexts/menu';

interface IMenuContext {
  menuType: string;
  handleChangeOfMenus(menu: string, e: MouseEvent): void;
}

function useMenuTypes(): IMenuContext {
  const { handleChangeOfMenus, menuType } = useContext(MenuContext);

  return {
    handleChangeOfMenus,
    menuType,
  };
}

export { useMenuTypes };
