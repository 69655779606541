import {
  FC,
  useCallback,
  useMemo,
  useState,
  Fragment,
  useRef,
  useEffect,
} from 'react';
import { FiMinusCircle, FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';
import { v4 } from 'uuid';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputMask } from '@components/elements/Form/InputMask';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import {
  Card,
  CardHeader,
  CardContent,
  DependentsContainer,
  RemoveDependentButton,
} from './styles';
import {
  maritalStatusOptions,
  getClientsSelectOptions,
  paymentFormOptions,
  portionsOptions,
} from './selectOptions';
import { IBbankConnectDependent, IBbankConnectData } from './interfaces';
import { bbankConnectValidation } from './validations';

interface IClient {
  id: string;
  name: string;
}

const NewFranchiseesTelemedicine: FC = () => {
  const { addToast } = useToast();
  const { user } = useAuth();
  const formRef = useRef<FormHandles>(null);

  const [dependents, setDependents] = useState<JSX.Element[]>([]);
  const [clients, setClients] = useState<IClient[]>();
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [price, setPrice] = useState(34.9 * 13);

  useEffect(() => {
    async function loadIndicatedUsers() {
      const { data } = await api.get<IClient[]>('/users-indicated');

      setClients(data);
      setPageIsLoading(false);
    }

    setTimeout(() => loadIndicatedUsers(), 1000);
  }, []);

  const handleRemoveDependents = useCallback((dependentFormKey: string) => {
    setPrice(oldState => {
      if (oldState === 59.8 * 13) {
        return 34.9 * 13;
      }

      return Number(Number(oldState - 29.9 * 13).toFixed(1));
    });
    setDependents(oldState => {
      const updatedDependentsForm = oldState.filter(
        item => item.key !== dependentFormKey,
      );

      return updatedDependentsForm;
    });
  }, []);

  const getDependentsForm = useCallback(() => {
    const key = v4();

    return (
      <Fragment key={key}>
        <FormRow separator>
          <h1>
            Dependente&nbsp;
            {dependents.length + 1}
          </h1>

          <RemoveDependentButton
            onClick={() => handleRemoveDependents(key)}
            type="button"
          >
            <FiMinusCircle size={20} />
            Remover
          </RemoveDependentButton>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>CPF</label>
            <InputMask
              name={`dependents-cpf[${dependents.length}]`}
              mask="99999999999"
            />
          </InputGroup>

          <InputGroup>
            <label>Nome</label>
            <Input name={`dependents-name[${dependents.length}]`} />
          </InputGroup>

          <InputGroup>
            <label>Sexo</label>
            <Input name={`dependents-sex[${dependents.length}]`} />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Data de nascimento</label>
            <InputMask
              name={`dependents-birthday[${dependents.length}]`}
              mask="99/99/9999"
              noUnmask
            />
          </InputGroup>

          <InputGroup>
            <label>Estado civil</label>
            <Select
              name={`dependents-maritalStatus[${dependents.length}]`}
              options={maritalStatusOptions}
            />
          </InputGroup>

          <InputGroup>
            <label>Relação como dependente</label>
            <Input name={`dependents-relationship[${dependents.length}]`} />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Número do cartão nacional de saúde</label>
            <Input
              name={`dependents-nationalHealthCard[${dependents.length}]`}
            />
          </InputGroup>

          <InputGroup>
            <label>Nome da mãe</label>
            <Input name={`dependents-motherName[${dependents.length}]`} />
          </InputGroup>
        </FormRow>
      </Fragment>
    );
  }, [dependents, handleRemoveDependents]);

  const handleAddDependents = useCallback(() => {
    const dependentsForm = getDependentsForm();

    setPrice(oldState => {
      if (oldState === 34.9 * 13) {
        return 29.9 * 2 * 13;
      }

      return Number(Number(oldState + 29.9 * 13).toFixed(1));
    });
    setDependents(oldState => {
      return [...oldState, dependentsForm];
    });
  }, [getDependentsForm]);

  const bbankConnectPriceObject = useMemo(() => {
    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const priceFormatted = numberFormat(price);

    const priceFieldRef = formRef.current?.getFieldRef(
      'bbankConnect-paymentValue',
    );

    if (priceFieldRef) {
      priceFieldRef.value = priceFormatted;
    }

    return {
      priceFormatted,
      price: price.toFixed(2),
    };
  }, [price]);

  const handleFormSubmit = useCallback(
    async (data, { reset }) => {
      try {
        const bbankConnectData: IBbankConnectData = {};
        const bbankConnectDependents: IBbankConnectDependent[] = [];

        await bbankConnectValidation(data);

        Object.entries(data).forEach(([index, value]) => {
          if (index.search('bbankConnect-') >= 0) {
            const parsedIndex = index.replace('bbankConnect-', '');

            bbankConnectData[parsedIndex] = value as string;
          }
        });

        dependents.forEach((_, index) => {
          const bbankConnectDependent = {
            birthday: data[`dependents-birthday`][index],
            cpf: data[`dependents-cpf`][index],
            maritalStatus: data[`dependents-maritalStatus`][index],
            motherName: data[`dependents-motherName`][index],
            name: data[`dependents-name`][index],
            nationalHealthCard: data[`dependents-nationalHealthCard`][index],
            relationship: data[`dependents-relationship`][index],
            sex: data[`dependents-sex`][index],
          };

          bbankConnectDependents.push(bbankConnectDependent);
        });

        bbankConnectData.paymentValue = bbankConnectPriceObject.price;

        const { data: bbankConnect } = await api.post('/telemedicine', {
          indication: user.id,
          ...bbankConnectData,
        });

        if (dependents.length !== 0) {
          await api.post('/telemedicine-dependents/many', {
            bbankConnectId: bbankConnect.id,
            data: bbankConnectDependents,
          });
        }

        reset();
        setDependents([]);
        addToast({
          title: 'Sucesso!',
          type: 'success',
          message:
            'Sua solicitação foi recebida, aguarde para mais informações!',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro.',
            });
          }
        }
      }
    },
    [dependents, addToast, user.id, bbankConnectPriceObject.price],
  );

  const clientSelectOptions = useMemo(() => {
    return clients && getClientsSelectOptions(clients);
  }, [clients]);

  return (
    <>
      {pageIsLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Bbank Connect', 'Telemedicina', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo telemedicina</h1>

                <button type="button" onClick={handleAddDependents}>
                  Adicionar dependentes
                </button>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                  <FormRow separator>
                    <h1>Escolha um cliente</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Cliente</label>
                      <Select
                        name="bbankConnect-userId"
                        options={clientSelectOptions}
                        placeholder="Escolha seu cliente"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados para o telemedicina</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Registro geral (RG)</label>
                      <Input name="bbankConnect-generalRegistry" />
                    </InputGroup>

                    <InputGroup>
                      <label>Data de nascimento</label>
                      <InputMask
                        mask="99/99/9999"
                        name="bbankConnect-birthday"
                        noUnmask
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Sexo</label>
                      <Input name="bbankConnect-sex" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Estado civil</label>
                      <Select
                        name="bbankConnect-maritalStatus"
                        options={maritalStatusOptions}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome da mãe</label>
                      <Input name="bbankConnect-motherName" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Preço a ser pago</label>
                      <Input
                        name="bbankConnect-paymentValue"
                        readOnly
                        defaultValue={bbankConnectPriceObject.priceFormatted}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Forma de pagamento</label>
                      <Select
                        name="bbankConnect-paymentForm"
                        options={paymentFormOptions}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Parcelas</label>
                      <Select
                        name="bbankConnect-paymentPortions"
                        options={portionsOptions}
                      />
                    </InputGroup>
                  </FormRow>

                  <DependentsContainer>
                    {dependents.map(item => item)}
                  </DependentsContainer>

                  <FormRow buttonWrapper>
                    <Button type="submit" styleType="success" icon={FiSave}>
                      Enviar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { NewFranchiseesTelemedicine };
