import { IOption } from '@utils/interfaces';

import { banks } from '@helpers/getBanks';

import { IClient, IIndication } from './interfaces';

export const getClientOptions = (clients: IClient[]): IOption[] => {
  const options = clients.map(client => ({
    value: client.id,
    label: client.name,
  }));

  return options;
};

export const getIndicationsOptions = (
  indications: IIndication[],
): IOption[] => {
  const options = indications.map(indication => ({
    value: indication.id,
    label: indication.name,
  }));

  return options;
};

export const banksOptions = banks.map(bank => {
  const bankName = bank.split(' - ')[1];

  return {
    value: bank,
    label: bankName,
  };
});
