import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns/esm';
import { TableColumn } from 'react-data-table-component';
import { FiEye } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';
import { DataTable } from '@components/elements/Datatable';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { Card, CardHeader, CardContent } from './styles';

type IColumns = Array<TableColumn>;

interface IState {
  id: string;
  cnpj: string;
  cpf: string;
  email: string;
  social_reason: string;
  user_name: string;
  average_of_revenues: string;
  created_at: string;
  status: string;
}

interface IResponse {
  id: string;
  cnpj: string;
  average_of_revenues: string;
  status: string;
  email: string;
  created_at: string;
  social_reason: string;
  owner: {
    document: string;
    email: string;
    name: string;
  };
}

interface ITableData {
  id: string;
  search: string;
  email: string;
  created_at: string;
  status: JSX.Element;
  actions: JSX.Element;
}

type IBadgeProps = 'success' | 'danger' | 'info' | 'warning';

const SellerAllCredits: FC = () => {
  const { push } = useHistory();
  const [credits, setCredits] = useState<IState[]>([]);

  const tableData = useMemo<ITableData[]>(() => {
    const data = credits.map<ITableData>(credit => {
      let badgeType: IBadgeProps = 'info';

      if (credit.status === 'pendente') {
        badgeType = 'warning';
      } else if (credit.status === 'negado') {
        badgeType = 'danger';
      } else if (credit.status === 'aprovado') {
        badgeType = 'success';
      }

      return {
        id: credit.id,
        search: credit.social_reason || credit.user_name,
        email: credit.email,
        average_of_revenues: credit.average_of_revenues,
        status: <Badge type={badgeType}>{credit.status}</Badge>,
        created_at: format(new Date(credit.created_at), 'dd/MM/yyyy'),
        actions: (
          <Button
            styleType="info"
            onClick={() => push(`/sellers/cred-cash/view/${credit.id}`)}
            icon={FiEye}
          />
        ),
      };
    });

    return data;
  }, [credits, push]);

  const tableColumns = useMemo<IColumns>(() => {
    return [
      {
        name: 'Nome/Razão social',
        selector: 'search',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Data da solicitação',
        selector: 'created_at',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  useEffect(() => {
    async function loadCredits() {
      const { data } = await api.get<IResponse[]>('/indicated-credits');

      setCredits(_ => {
        return data.map(item => {
          return {
            ...item,
            email: item.email === null ? item.owner.email : item.email,
            cnpj: item.cnpj,
            cpf: item.owner.document,
            user_name: item.owner.name,
            social_reason: item.social_reason,
          };
        });
      });
    }

    loadCredits();
  }, []);

  return (
    <>
      <Row>
        <URLPath paths={['Créditos', 'Todos']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Créditos feitos com a minha indicação</h1>

            {/* <Link to="/cred-cash/new">Novo</Link> */}
          </CardHeader>

          <CardContent>
            <DataTable
              columns={tableColumns}
              filterPlaceholder="Filtrar por nome/razão social"
              data={tableData}
              pagination
              striped
              subHeader
            />
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { SellerAllCredits };
