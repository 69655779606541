import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
