interface IData {
  name: string;
  value: string;
}

export function removeInputMask(
  /**
   * @param parentElement
   * If preset, it is used for getting inputs that are children of the specified
   * element. This element needs to have an unique id.
   */
  parentElement?: HTMLElement,
): Array<IData> {
  if (parentElement) {
    const inputsMasked = document.querySelectorAll(
      `#${parentElement.id} input.input-masked`,
    );

    const arrayOfChildren = Array.from(inputsMasked);

    const filteredChildren = arrayOfChildren.filter(child => {
      const classNames = child.className.split(' ');

      const findBySpecifiedClass = classNames.find(
        className => className === 'input-masked',
      );

      if (!findBySpecifiedClass) {
        return;
      }

      return child;
    }) as HTMLInputElement[];

    const parsedData = filteredChildren.map(element => {
      const newValue = element.value.replace(/[^0-9]/g, '');

      return { name: element.name, value: newValue };
    });

    return parsedData;
  }

  const maskedElements = document.getElementsByClassName(
    'input-masked',
  ) as HTMLCollectionOf<HTMLInputElement>;

  return Array.from(maskedElements).map(element => {
    const newValue = element.value.replace(/[^0-9]/g, '');

    return { name: element.name, value: newValue };
  });
}
