import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const validation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    content: Yup.string().required('Preencha este campo!'),
    title: Yup.string().required('Preencha este campo!'),
    description: Yup.string().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
