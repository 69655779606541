import { decode } from 'jsonwebtoken';
import { FC, useState, useCallback, useEffect, createContext } from 'react';
import { isAfter } from 'date-fns';

import bbankApi from '../services/bbankApi';
import {
  IAuthContext,
  IResponseType,
  IAuthState,
  IPayloadToken,
} from './interfaces/auth';
import { useToast } from '../hooks/toast';

export const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC = ({ children }) => {
  const { addToast } = useToast();
  const [data, setData] = useState<IAuthState>({} as IAuthState);
  const [loading, setLoading] = useState(true);
  const [completedData, setCompletedData] = useState(false);

  const signOut = useCallback(() => {
    localStorage.removeItem('@BbankCred:Auth');
    setData({} as IAuthState);
  }, []);

  useEffect(() => {
    async function login() {
      try {
        const storedData = localStorage.getItem('@BbankCred:Auth');

        if (!storedData) {
          setData({} as IAuthState);
          return;
        }

        const parsedStoredData = JSON.parse(storedData) as IAuthState & {
          expiration: number;
        };

        if (parsedStoredData.token) {
          const expirationDate = new Date(parsedStoredData.expiration * 1000);

          if (isAfter(new Date(), expirationDate)) {
            addToast({
              title: 'Sessão expirada!',
              type: 'info',
              message: 'Faça login novamente!',
            });
            signOut();
            return;
          }

          bbankApi.defaults.headers.authorization = `Bearer ${parsedStoredData.token}`;

          const { user } = parsedStoredData;

          const completed = Object.entries(user).every(([key, value]) => {
            if (key === 'nickname' || key === 'indication') {
              return true;
            }

            return value !== null;
          });

          setCompletedData(completed);

          setData(parsedStoredData);
        }
      } catch {
        addToast({
          title: 'Sessão expirada!',
          type: 'info',
          message: 'Faça login novamente!',
        });

        signOut();
      } finally {
        setLoading(false);
      }
    }

    const timer = setTimeout(() => {
      login();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [addToast, signOut]);

  const signIn = useCallback(
    async (
      document: string,
      password: string,
    ): Promise<{ ok: boolean; type?: 'inativo' | 'pendente' }> => {
      const response = await bbankApi.post<IResponseType>('/session', {
        document,
        password,
      });

      const { token, user } = response.data;

      if (user.status === 'pendente') {
        return {
          ok: false,
          type: 'pendente',
        };
      }

      if (user.status === 'inativo') {
        return {
          ok: false,
          type: 'inativo',
        };
      }

      const { role, exp } = decode(token) as IPayloadToken;

      const sessionData = {
        token,
        user: {
          id: user.id,
          email: user.email,
          name: user.name,
          zipcode: user.zipcode,
          state: user.state,
          city: user.city,
          address: user.address,
          phone: user.phone,
          document: user.document,
          token,
        },
      };

      bbankApi.defaults.headers.authorization = `Bearer ${sessionData.token}`;

      localStorage.setItem(
        '@BbankCred:Auth',
        JSON.stringify({
          token: sessionData.token,
          expiration: exp,
          user: {
            ...sessionData.user,
            role,
          },
        }),
      );

      setData({
        token: sessionData.token,
        user: {
          ...sessionData.user,
          role,
        },
      });

      const completed = Object.entries(user).every(([key, value]) => {
        if (key === 'nickname' || key === 'indication') {
          return true;
        }

        return value !== null;
      });

      setCompletedData(completed);

      return {
        ok: true,
      };
    },
    [],
  );

  const handleCompleteData = useCallback(() => {
    setCompletedData(true);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        user: data.user,
        loading,
        signOut,
        completedData,
        handleCompleteData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
