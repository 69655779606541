import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    status: Yup.string()
      .required('Preencha este campo!')
      .oneOf(['accepted', 'denied', 'pending'], 'Inválido!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
