import { FormHandles } from '@unform/core';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ValidationError } from 'yup';
import { FiSave } from 'react-icons/fi';

import { Button } from '@components/elements/Button';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { LoadingPage } from '@components/layouts/LoadingPage';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import { ILoadedUser, IUser, IFormData, IDepartment } from './interfaces';
import { getUsersOptions } from './selectOptions';
import { formValidation } from './validations';
import { URLPath, Card, CardHeader, CardContent } from './styles';

const CreateDepartment: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [users, setUsers] = useState<IUser[]>();

  useEffect(() => {
    async function getUsers() {
      const { data } = await api.get<ILoadedUser[]>('/users');

      const filteredUsers = data.filter(user => user.role === 'admin');

      setUsers(filteredUsers);
    }

    const timer = setTimeout(() => {
      getUsers();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const usersOptions = useMemo(() => {
    return users ? getUsersOptions(users) : [];
  }, [users]);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        const { data: department } = await api.post<IDepartment>(
          '/departments',
          {
            name: data.name,
          },
        );

        await api.post('/users-departments', {
          departmentId: department.id,
          userId: data.userId,
        });

        reset();

        addToast({
          title: 'Sucesso!',
          type: 'success',
          message: 'Departamento adicionado com sucesso!',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              message,
              type: 'error',
            });
          }

          if (status === 500) {
            addToast({
              title: 'Solicitação não processada!',
              message:
                'Um erro desconhecido aconteceu. Tente novamente mais tarde!',
              type: 'error',
            });
          }
        }
      }
    },
    [addToast],
  );

  return (
    <>
      {!users ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Departamentos</li>
              <li>{'>'}</li>
              <li>Novo</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Adicionar novo departamento</h1>

                {/* <Button
            styleType="info"
            icon={FiPlusCircle}
            onClick={addUserSelect}
          >
            Adicionar usuários
          </Button> */}
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                  <FormRow>
                    <InputGroup>
                      <label>Nome do departamento</label>
                      <Input name="name" upperCase={false} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Comece atribuindo um usuário!</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Selecione um usuário</label>
                      <Select name="userId" options={usersOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button styleType="success" icon={FiSave}>
                      Adicionar novo departamento
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { CreateDepartment };
