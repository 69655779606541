import styled, { css } from 'styled-components';

interface IBackground {
  visible: boolean;
}

interface IContainer {
  visible: boolean;
}

export const Background = styled.div<IBackground>`
  height: 100vh;
  width: 100vw;

  background-color: rgba(0, 0, 0, 0.8);

  position: fixed;
  top: 0;
  left: 0;

  z-index: 12;

  opacity: 0;
  visibility: hidden;

  transition: opacity 0.4s, visibility 0.4s;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

export const Container = styled.div<IContainer>`
  position: fixed;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -20%);

  background-color: #fff;

  border: 2px solid transparent;
  border-radius: 10px;

  max-width: 500px;
  width: 100%;

  padding: 15px 20px;

  z-index: 13;

  opacity: 0;
  visibility: hidden;

  transition: transform 0.4s, opacity 0.4s, visibility 0.4s;

  ${props =>
    props.visible &&
    css`
      transform: translate(-50%, -50%);

      opacity: 1;
      visibility: visible;
    `}
`;

export const Header = styled.header`
  border-bottom: 2px solid #ebedf3;
  padding-bottom: 6px;

  > h1 {
    font-size: 1.75rem;
    color: #1e1e1e;
  }
`;

export const Main = styled.main`
  padding: 20px 0;

  > form {
    > div:nth-last-child(1) {
      > button {
        width: 100%;

        justify-content: center;
      }
    }
  }
`;

export const Footer = styled.footer``;
