import { FormHandles } from '@unform/core';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { ValidationError } from 'yup';

import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Button } from '@components/elements/Button';

import { useAuth } from '@hooks/auth';
import useBank from '@hooks/bank';
import { useToast } from '@hooks/toast';

import { getValidationErrors } from '@helpers/getValidationErrors';

import { Container, Header, Main, Background } from './styles';
import { formValidation } from './validation';
import { IAuthModal } from './interfaces';

export const AuthModal: FC<IAuthModal> = ({ refresh = true, onAuth }) => {
  const formRef = useRef<FormHandles>(null);
  const { handleAuth, handleVerifyToken } = useBank();
  const { user } = useAuth();
  const { addToast } = useToast();

  const [isVisible, setIsVisible] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState(false);

  useEffect(() => {
    if (refresh !== undefined) {
      handleVerifyToken().then(verified => {
        setIsVisible(!verified);
      });
    }
  }, [handleVerifyToken, refresh]);

  const handleFormSubmit = useCallback(
    async data => {
      try {
        setLoadingRequest(true);
        formRef.current?.setErrors({});

        await formValidation(data);

        const { password } = data;

        await handleAuth(user.document, password);

        if (onAuth) await onAuth();

        setIsVisible(false);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        if (err.response?.data?.message) {
          addToast({
            title: 'Ops... Algo não ocorreu bem!',
            message: err.response?.data?.message,
            type: 'error',
          });
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [handleAuth, user, addToast, onAuth],
  );

  return (
    <>
      <Background visible={isVisible} />

      <Container visible={isVisible}>
        <Header>
          <h1>Comprove sua identidade!</h1>
        </Header>

        <Main>
          <Form onSubmit={handleFormSubmit} ref={formRef}>
            <FormRow>
              <InputGroup>
                <label htmlFor="password">Insira sua senha de acesso</label>
                <Input
                  name="password"
                  id="password"
                  placeholder="*****"
                  upperCase={false}
                  type="password"
                />
              </InputGroup>
            </FormRow>

            <FormRow buttonWrapper>
              <Button type="submit" styleType="info" loading={loadingRequest}>
                Verificar
              </Button>
            </FormRow>
          </Form>
        </Main>
      </Container>
    </>
  );
};
