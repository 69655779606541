import { useRouteMatch } from 'react-router-dom';
import { FC, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FiMessageCircle } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';
import { format } from 'date-fns';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Textarea } from '@components/elements/Form/Textarea';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Button } from '@components/elements/Button';
import { Badge } from '@components/elements/Badge';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { formValidation } from './validations';
import {
  IFormData,
  ITicket,
  IParams,
  IDepartment,
  IPlatform,
} from './interfaces';
import {
  statusOptions,
  getDepartmentsOptions,
  getPlatformOptions,
} from './selectOptions';
import { URLPath, Card, CardHeader, CardContent } from './styles';

const UpdateTickets: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();

  const [ticket, setTicket] = useState<ITicket>();
  const [departments, setDepartments] = useState<IDepartment[]>();
  const [platforms, setPlatforms] = useState<IPlatform[]>();

  useEffect(() => {
    async function loadTicket() {
      const { data } = await api.get(`/tickets/${params.ticketId}`, {
        params: {
          relations: ['owner'],
        },
      });

      const parsedData = parseObjectPropertiesToCamelCase<ITicket>(data);

      setTicket(parsedData);
    }

    async function loadDepartments() {
      const { data } = await api.get(`/platforms`);

      setDepartments(data);
    }

    async function loadPlatforms() {
      const { data } = await api.get(`/departments`);

      setPlatforms(data);
    }

    const timer = setTimeout(async () => {
      await loadDepartments();
      await loadPlatforms();
      loadTicket();
    }, 1500);

    return () => clearInterval(timer);
  }, [params.ticketId]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        await formValidation(data);

        const parsedData = data;

        delete parsedData.title;
        delete parsedData.content;

        const { data: ticketUpdated } = await api.patch(
          `/tickets/${params.ticketId}`,
          parsedData,
        );

        addToast({
          type: 'success',
          message: 'Ticket atualizado com sucesso!',
          title: 'Sucesso!',
        });

        const parsed = parseObjectPropertiesToCamelCase<ITicket>(ticketUpdated);

        setTicket(parsed);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              message,
              type: 'error',
            });
          }

          if (status === 500) {
            addToast({
              title: 'Solicitação não processada!',
              message:
                'Um erro desconhecido aconteceu. Tente novamente mais tarde!',
              type: 'error',
            });
          }
        }
      }
    },
    [addToast, params.ticketId],
  );

  const platformsOptions = useMemo(() => {
    if (!platforms) {
      return [];
    }

    return getPlatformOptions(platforms);
  }, [platforms]);

  const departmensOptions = useMemo(() => {
    if (!departments) {
      return [];
    }

    return getDepartmentsOptions(departments);
  }, [departments]);

  return (
    <>
      {!ticket ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Tickets</li>
              <li>{'>'}</li>
              <li>Detalhes</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Responder ticket</h1>

                <div>
                  <Badge type="info">
                    Solicitado por:&nbsp;
                    {ticket.owner.name}
                    &nbsp;em&nbsp;
                    {format(new Date(ticket.createdAt), 'dd/MM/yyyy')}
                  </Badge>
                </div>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={ticket}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Assunto</label>
                      <Input name="title" upperCase={false} readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Status</label>
                      <Select name="status" options={statusOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Departamento</label>
                      <Select
                        name="departmentId"
                        options={platformsOptions}
                        readonly={ticket.status === 'finalizado'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Plataforma</label>
                      <Select
                        name="platformId"
                        options={departmensOptions}
                        readonly={ticket.status === 'finalizado'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Conteúdo</label>
                      <Textarea name="content" rows={15} readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Resposta</label>
                      <Textarea
                        name="answer"
                        rows={15}
                        readOnly={ticket.status === 'finalizado'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      type="submit"
                      icon={FiMessageCircle}
                    >
                      Responder a este ticket
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { UpdateTickets };
