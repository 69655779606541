import { FC, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { Form } from '@unform/web';
import { FiDownload, FiRotateCw } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';
import { useRouteMatch } from 'react-router-dom';

import { Select } from '@components/elements/Form/Select';
import { FormRow } from '@components/elements/Form/FormRow';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { URLPath } from '@components/layouts/UrlPath';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Button } from '@components/elements/Button';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IParams, IFormData, IECommerce, IBadgeTypes } from './interfaces';
import { Card, CardHeader, CardContent } from './styles';
import { formValidation } from './validations';
import { statusOptions } from './selectOptions';

export const EditECommerce: FC = () => {
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();
  const formRef = useRef<FormHandles>(null);

  const [ecommerce, setECommerce] = useState<IECommerce>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getECommerce() {
      const { data } = await api.get(`/ecommerce/${params.ecommerceId}`, {
        params: {
          relations: ['ecommerce_files', 'indication', 'user'],
        },
      });

      const parsedData = parseObjectPropertiesToCamelCase<IECommerce>(data);

      setECommerce(parsedData);
    }

    const timer = setTimeout(() => getECommerce(), 1500);

    return () => clearInterval(timer);
  }, [params.ecommerceId]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);
        const parsedData = data;

        await formValidation(parsedData);

        const { data: updatedData } = await api.patch(
          `/ecommerce/${params.ecommerceId}`,
          {
            status: parsedData.status,
          },
        );

        const parsed = parseObjectPropertiesToCamelCase<IECommerce>(
          updatedData,
        );

        setECommerce(parsed);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, params.ecommerceId],
  );

  const badgeTypes = useMemo<IBadgeTypes>(() => {
    return {
      pending: {
        badgeType: 'warning',
        badgeText: 'Pendente',
      },
      active: {
        badgeType: 'success',
        badgeText: 'Ativo',
      },
      denied: {
        badgeType: 'danger',
        badgeText: 'Negado',
      },
      contract_pending: {
        badgeType: 'info',
        badgeText: 'Contrato pendente',
      },
    };
  }, []);

  const handleDownloadFile = useCallback((url: string) => {
    window.open(url);
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do arquivo',
        selector: 'search',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!ecommerce) {
      return [];
    }

    const data = ecommerce.ecommerceFiles.map(file => {
      return {
        id: file.id,
        search: file.title,
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiDownload}
              title="Fazer o download"
              styleType="success"
              onClick={() => handleDownloadFile(file.url_path)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [ecommerce, handleDownloadFile]);

  return (
    <>
      {!ecommerce ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['ECommerce', 'Detalhes']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Arquivos enviados</h1>
              </CardHeader>

              <CardContent>
                <DataTable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes de um cadastro</h1>

                <Badge type={badgeTypes[ecommerce.status].badgeType}>
                  {badgeTypes[ecommerce.status].badgeText}
                </Badge>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} initialData={ecommerce}>
                  <FormRow separator>
                    <h1>Indicação e usuário</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Indicação</label>
                      <Input name="indication.name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Usuário</label>
                      <Input name="user.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Status</label>
                      <Select name="status" options={statusOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Documento (CPF/CNPJ)</label>
                      <Input
                        name="document"
                        maxLength={14}
                        readOnly={ecommerce.status !== 'pending'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Limite de crédito</label>
                      <InputCurrency
                        name="creditLimit"
                        readOnly={ecommerce.status !== 'pending'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      type="submit"
                      styleType="success"
                      icon={FiRotateCw}
                      loading={loading}
                    >
                      Atualizar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
