import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import { FiEye } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { Badge } from '@components/elements/Badge';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import api from '@services/bbankApi';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { IConvenience, IConvenienceLoaded } from './interfaces';

const DownloadConvenienceFiles: FC = () => {
  const { push } = useHistory();

  const [conveniences, setConveniences] = useState<IConvenience[]>();

  useEffect(() => {
    async function loadMerchantsWithFiles() {
      const { data } = await api.get<IConvenienceLoaded[]>(
        '/bbank-pay/conveniences/files',
      );

      const parsedMerchants = data.map(item => {
        return {
          id: item.id,
          status: item.status,
          cpf: item.cpf,
          infinityId: item.infinity_id,
        };
      });

      setConveniences(parsedMerchants);
    }

    const timer = setTimeout(() => {
      loadMerchantsWithFiles();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'CPF da conveniência',
        selector: 'search',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Infinity ID',
        selector: 'infinityId',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleNavigationToFilesList = useCallback(
    (merchantId: string) => {
      push(`/conveniences/${merchantId}/files`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!conveniences) {
      return [];
    }

    const columns = conveniences.map(merchant => {
      let badgeType: 'danger' | 'success' | 'warning' = 'success';

      if (merchant.status === 'parado') {
        badgeType = 'danger';
      }

      if (merchant.status === 'pendente') {
        badgeType = 'warning';
      }

      return {
        id: merchant.id,
        search: merchant.cpf,
        infinityId: merchant.infinityId,
        status: <Badge type={badgeType}>{merchant.status}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              icon={FiEye}
              onClick={() => handleNavigationToFilesList(merchant.id)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return columns;
  }, [conveniences, handleNavigationToFilesList]);

  return (
    <>
      {!conveniences ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Download</li>
              <li>{'>'}</li>
              <li>Estabelecimentos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Estabelecimentos que possuem arquivos</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  filterPlaceholder="Filtrar por CNPJ..."
                  data={tableData}
                  columns={tableColumns}
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { DownloadConvenienceFiles };
