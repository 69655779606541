import { FC, useCallback, useEffect, useState, createContext } from 'react';
import {} from 'use-context-selector';
import { v4 } from 'uuid';
import { addSeconds } from 'date-fns/esm';
import { isAfter } from 'date-fns';

import { bankApi } from '@services/bankApi';

export interface IBankContext {
  handleAuth(document: string, password: string): Promise<void>;
  handleLogOut(): void;
  handleVerifyToken(): Promise<boolean>;
}

export const BankContext = createContext<IBankContext>({} as IBankContext);

export const BankProvider: FC = ({ children }) => {
  const [, setSessionId] = useState<string>();

  const getUniqueIdentifierFromStorage = useCallback(() => {
    let uniqueIdentifierFromStorage = localStorage.getItem(
      '===a8f5f167f44f4964e6c998dee827110c',
    ) as string | undefined;

    if (!uniqueIdentifierFromStorage) {
      uniqueIdentifierFromStorage = v4();
      localStorage.setItem(
        '===a8f5f167f44f4964e6c998dee827110c',
        uniqueIdentifierFromStorage,
      );
    }

    bankApi.defaults.headers.identifier = uniqueIdentifierFromStorage;

    return uniqueIdentifierFromStorage;
  }, []);

  const getSessionIdFromStorage = useCallback(() => {
    const sessionIdFromStorage = localStorage.getItem(
      '===a5e785ef2f22710a5050ee1deeb94c98',
    ) as string | undefined;

    if (!sessionIdFromStorage) {
      return;
    }

    localStorage.setItem(
      '===a5e785ef2f22710a5050ee1deeb94c98',
      sessionIdFromStorage,
    );

    bankApi.defaults.headers['session-id'] = sessionIdFromStorage;

    return sessionIdFromStorage;
  }, []);

  const getExpiresInFromStorage = useCallback(() => {
    const expiresInFromStorage = localStorage.getItem(
      '===12f8d9e6dae1802e875b3fa4d25a28d7',
    ) as string | undefined;

    if (!expiresInFromStorage) {
      return;
    }

    localStorage.setItem(
      '===12f8d9e6dae1802e875b3fa4d25a28d7',
      expiresInFromStorage,
    );

    return expiresInFromStorage;
  }, []);

  const getGeneratedIn = useCallback(() => {
    const generatedInFromStorage = localStorage.getItem(
      '===9f71125f5f528372c01ef733d1ded80f',
    ) as string | undefined;

    if (!generatedInFromStorage) {
      return;
    }

    localStorage.setItem(
      '===9f71125f5f528372c01ef733d1ded80f',
      generatedInFromStorage,
    );

    return generatedInFromStorage;
  }, []);

  useEffect(() => {
    const sessionIdFromStorage = getSessionIdFromStorage();
    getUniqueIdentifierFromStorage();

    setSessionId(sessionIdFromStorage);
  }, [getSessionIdFromStorage, getUniqueIdentifierFromStorage]);

  const handleAuth = useCallback(
    async (document: string, password: string) => {
      const uniqueIdentifier = getUniqueIdentifierFromStorage();

      const { data: response } = await bankApi.post('/session', {
        document,
        password,
        uniqueIdentifier,
      });

      localStorage.setItem(
        '===a5e785ef2f22710a5050ee1deeb94c98',
        response.sessionId,
      );
      localStorage.setItem(
        '===12f8d9e6dae1802e875b3fa4d25a28d7',
        response.expiresIn,
      );
      localStorage.setItem(
        '===9f71125f5f528372c01ef733d1ded80f',
        Date.now().toString(),
      );

      bankApi.defaults.headers['session-id'] = response.sessionId;

      setSessionId(response.sessionId);
    },
    [getUniqueIdentifierFromStorage],
  );

  const handleLogOut = useCallback(() => {
    localStorage.removeItem('===a8f5f167f44f4964e6c998dee827110c');
    localStorage.removeItem('===a5e785ef2f22710a5050ee1deeb94c98');
    localStorage.removeItem('===12f8d9e6dae1802e875b3fa4d25a28d7');
    localStorage.removeItem('===9f71125f5f528372c01ef733d1ded80f');

    setSessionId(undefined);

    getUniqueIdentifierFromStorage();
  }, [getUniqueIdentifierFromStorage]);

  const handleVerifyToken = useCallback(async () => {
    const expiresIn = getExpiresInFromStorage();
    const uniqueIdentifier = getUniqueIdentifierFromStorage();
    const sessionId = getSessionIdFromStorage();
    const generatedIn = getGeneratedIn();

    if (!expiresIn || !uniqueIdentifier || !sessionId || !generatedIn)
      return false;

    const expireDate = addSeconds(Number(generatedIn), Number(expiresIn));

    if (isAfter(new Date(), expireDate)) return false;

    return true;
  }, [
    getExpiresInFromStorage,
    getUniqueIdentifierFromStorage,
    getSessionIdFromStorage,
    getGeneratedIn,
  ]);

  return (
    <BankContext.Provider
      value={{
        handleAuth,
        handleLogOut,
        handleVerifyToken,
      }}
    >
      {children}
    </BankContext.Provider>
  );
};
