import { rolesTranslated, IRole as IRoleTypes } from '@utils/rolesTranslated';

import { states } from '@helpers/getAllStates';

interface IRole {
  id: string;
  role: IRoleTypes;
}

type IReturnType = Array<{ value: string; label: string }>;

export const statusOptions = [
  { value: 'ativo', label: 'Ativo' },
  { value: 'pendente', label: 'Pendente' },
  { value: 'inativo', label: 'Inativo' },
];

export const statesOption = Object.entries(states).map(item => ({
  value: item[0],
  label: item[1],
}));

export const personTypesOptons = [
  { value: 'PF', label: 'Pessoa fisica' },
  { value: 'PJ', label: 'Pessoa jurídica' },
];

export const loadRolesOptions = (roles: IRole[]): IReturnType => {
  return roles.map(role => ({
    value: role.role,
    label: rolesTranslated[role.role],
  }));
};
