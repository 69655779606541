import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';

import api from '@services/bbankApi';

import { URLPath, Card, CardHeader, CardContent } from './styles';

interface IUser {
  id: string;
  name: string;
  status: string;
}

interface IUserFile {
  user: IUser;
}

interface ITableData {
  search: string;
  status: JSX.Element;
  actions: JSX.Element;
}

const DownloadUserFiles: FC = () => {
  const { push } = useHistory();

  const [users, setUsers] = useState<IUser[]>([]);

  // Load credits with files from API
  useEffect(() => {
    async function loadFiles() {
      const parsedData: Array<IUser> = [];

      const { data: files } = await api.get<Array<IUserFile>>('/user-files');

      files.map(file => {
        const findFile = parsedData.find(find => find.id === file.user.id);

        if (findFile) {
          return;
        }

        return parsedData.push(file.user);
      });

      setUsers(parsedData);
    }

    loadFiles();
  }, []);

  // Table columns
  const tableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'Nome',
        selector: 'search',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  // Handle switch of pages
  const handleNavigationToFilesList = useCallback(
    (userId: string) => {
      push(`/user/${userId}/files`);
    },
    [push],
  );

  // Table data
  const tableData = useMemo<Array<ITableData>>(() => {
    return users.map(user => {
      let badgeType: 'warning' | 'success' | 'danger' | 'info';

      switch (user.status) {
        case 'pendente':
          badgeType = 'warning';
          break;

        default:
          badgeType = 'success';
          break;
      }

      return {
        search: user.name,
        status: <Badge type={badgeType}>{user.status}</Badge>,
        actions: (
          <Button
            styleType="success"
            onClick={() => handleNavigationToFilesList(user.id)}
          >
            Ver arquivos
          </Button>
        ),
      };
    });
  }, [users, handleNavigationToFilesList]);

  // Page
  return (
    <>
      <Row>
        <URLPath>
          <li>Download</li>
          <li>{'>'}</li>
          <li>Usuários</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Usuários que possuem arquivos</h1>
          </CardHeader>

          <CardContent>
            <DataTable
              filterPlaceholder="Filtar por nome"
              data={tableData}
              columns={tableColumns}
            />
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { DownloadUserFiles };
