import { useRouteMatch } from 'react-router-dom';
import { FC, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { FormHandles } from '@unform/core';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Textarea } from '@components/elements/Form/Textarea';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { ITicket, IParams, IDepartment, IPlatform } from './interfaces';
import {
  statusOptions,
  getDepartmentsOptions,
  getPlatformOptions,
} from './selectOptions';
import { Card, CardHeader, CardContent } from './styles';

const TicketDetails: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<IParams>();

  const [ticket, setTicket] = useState<ITicket>();
  const [departments, setDepartments] = useState<IDepartment[]>();
  const [platforms, setPlatforms] = useState<IPlatform[]>();

  useEffect(() => {
    async function loadTicket() {
      const { data } = await api.get(`/tickets/${params.ticketId}`, {
        params: {
          relations: ['owner'],
        },
      });

      const parsedData = parseObjectPropertiesToCamelCase<ITicket>(data);

      setTicket(parsedData);
    }

    async function loadDepartments() {
      const { data } = await api.get(`/platforms`);

      setDepartments(data);
    }

    async function loadPlatforms() {
      const { data } = await api.get(`/departments`);

      setPlatforms(data);
    }

    const timer = setTimeout(async () => {
      await loadDepartments();
      await loadPlatforms();
      loadTicket();
    }, 1500);

    return () => clearInterval(timer);
  }, [params.ticketId]);

  const handleFormSubmit = useCallback(async () => {
    // CODE
  }, []);

  const platformsOptions = useMemo(() => {
    if (!platforms) {
      return [];
    }

    return getPlatformOptions(platforms);
  }, [platforms]);

  const departmensOptions = useMemo(() => {
    if (!departments) {
      return [];
    }

    return getDepartmentsOptions(departments);
  }, [departments]);

  return (
    <>
      {!ticket ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Ticket', 'Detalhes']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes do ticket que enviei</h1>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={ticket}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Assunto</label>
                      <Input name="title" upperCase={false} readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Status</label>
                      <Select name="status" options={statusOptions} readonly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Departamento</label>
                      <Select
                        name="departmentId"
                        options={platformsOptions}
                        readonly
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Plataforma</label>
                      <Select
                        name="platformId"
                        options={departmensOptions}
                        readonly
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Conteúdo</label>
                      <Textarea name="content" rows={15} readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Resposta</label>
                      <Textarea name="answer" rows={15} readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { TicketDetails };
