import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import { FiEye, FiUsers } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { Badge } from '@components/elements/Badge';
import { DataTable } from '@components/elements/Datatable';
import { LoadingPage } from '@components/layouts/LoadingPage';

import api from '@services/bbankApi';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  TableButton,
  TableButtonContainer,
} from './styles';
import { IFranchisee } from './interfaces';

const ListFranchisees: FC = () => {
  const { push } = useHistory();

  const [franchisees, setFranchisees] = useState<IFranchisee[]>();

  useEffect(() => {
    async function loadFranchises() {
      const { data } = await api.get<IFranchisee[]>('/franchises');

      setFranchisees(data);
    }

    const timer = setTimeout(() => {
      loadFranchises();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handlePushToUserView = useCallback(
    (franchiseeId: string) => {
      push(`/users/view/${franchiseeId}`);
    },
    [push],
  );

  const handlePushToListProspectsPage = useCallback(
    (franchiseeId: string) => {
      push(`/franchisees/${franchiseeId}/prospects`);
    },
    [push],
  );

  const tableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'Nome',
        selector: 'search',
      },
      {
        name: 'Documentos',
        selector: 'document',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!franchisees) {
      return [];
    }

    return franchisees.map(({ document, name, id, status }) => {
      let badgeType: 'success' | 'danger' | 'info' | 'warning' = 'warning';

      if (status === 'ativo') {
        badgeType = 'success';
      }

      return {
        id,
        search: name,
        document,
        status: <Badge type={badgeType}>{status}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              onClick={() => handlePushToUserView(id)}
            >
              <FiEye size={20} />
            </TableButton>

            <TableButton
              styleType="success"
              onClick={() => handlePushToListProspectsPage(id)}
              title="Ver prospectos"
            >
              <FiUsers size={20} />
            </TableButton>
          </TableButtonContainer>
        ),
      };
    });
  }, [franchisees, handlePushToUserView, handlePushToListProspectsPage]);

  return (
    <>
      {!franchisees ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Franqueados</li>
              <li>{'>'}</li>
              <li>Todos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Franquias cadastradas</h1>
              </CardHeader>

              <CardContent>
                <DataTable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListFranchisees };
