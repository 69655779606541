import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiEdit2, FiTrash } from 'react-icons/fi';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import api from '@services/bbankApi';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { ICategory, ILoadedCategory } from './interfaces';

const ListMarketingCategories: FC = () => {
  const { push } = useHistory();

  const [categories, setCategories] = useState<ICategory[]>();

  useEffect(() => {
    async function loadCategories() {
      const { data: loadedCategories } = await api.get<ILoadedCategory[]>(
        '/marketing/categories',
      );

      const parsedCategories = loadedCategories.map(item => ({
        id: item.id,
        name: item.name,
        createdAt: item.created_at,
      }));

      setCategories(parsedCategories);
    }

    const timer = setTimeout(() => loadCategories(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleNavigationToEditPage = useCallback(
    (marketingCategoryId: string) => {
      push(`/marketing/categories/${marketingCategoryId}/edit`);
    },
    [push],
  );

  const handleDeleteCategories = useCallback(
    async (marketingCategoryId: string) => {
      await api.delete(`/marketing/categories/${marketingCategoryId}`);

      setCategories(oldState => {
        if (!oldState) {
          return [];
        }

        const updatedState = oldState.filter(
          item => item.id !== marketingCategoryId,
        );

        return updatedState;
      });
    },
    [],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome da categoria',
        selector: 'search',
      },
      {
        name: 'Data de criação',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!categories) {
      return [];
    }

    const data = categories.map(item => ({
      id: item.id,
      search: item.name,
      createdAt: format(new Date(item.createdAt), 'dd/MM/yyyy'),
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="warning"
            onClick={() => handleNavigationToEditPage(item.id)}
            title="Clique para ir até a página de edição!"
            icon={FiEdit2}
          />

          <TableButton
            styleType="danger"
            onDoubleClick={() => handleDeleteCategories(item.id)}
            title="Clique duas vezes para deletar!"
            icon={FiTrash}
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [categories, handleNavigationToEditPage, handleDeleteCategories]);

  return (
    <>
      {!categories ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Marketing</li>
              <li>{'>'}</li>
              <li>Categorias</li>
              <li>{'>'}</li>
              <li>Todas</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Lista de todas as categorias cadastradas</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por nome da categoria"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListMarketingCategories };
