import { FC } from 'react';
import { FiLayers } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { useMenuTypes } from '@hooks/menu';
import { useAside } from '@hooks/aside';

import { renderMenu } from '@helpers/renderMenu';

import { Container, AsideHeader, Background } from './styles';

const Aside: FC = () => {
  const { menuType } = useMenuTypes();
  const { animation, isVisible, handleAsideVisibility } = useAside();

  return (
    <>
      <Background isShown={isVisible} onClick={handleAsideVisibility} />
      <Container style={animation}>
        <AsideHeader>
          <FiLayers size={21} />

          <span>
            <Link to="/dashboard">Dashboard</Link>
          </span>
        </AsideHeader>

        {renderMenu(menuType)}
      </Container>
    </>
  );
};

export { Aside };
