import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    name: Yup.string().required('Preencha este campo!'),
    zipcode: Yup.string().required('Preencha este campo!'),
    address: Yup.string().required('Preencha este campo!'),
    city: Yup.string().required('Preencha este campo!'),
    state: Yup.string().required('Preencha este campo!'),
    birthdate: Yup.string()
      .required('Preencha este campo!')
      .length(10, 'Insira uma data válida!'),
    selfie: Yup.object().shape({}).nullable(),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
