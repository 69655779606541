import { v4 } from 'uuid';

import { NewProspect } from '@pages/private/sellers/Prospects/New';
import { BbankConnectResearch } from '@pages/private/sellers/BbankConnect/Research/New';
import { ListBbankConnectResearchs } from '@pages/private/sellers/BbankConnect/Research/List';
import { TelemedicineResearchDetails } from '@pages/private/sellers/BbankConnect/Research/Details';
import { SellerNewMerchants } from '@pages/private/sellers/BbankPay/Merchants/New';
import { SellerNewConveniences } from '@pages/private/sellers/BbankPay/Conveniences/New';
import { ListCategoriesForFiles } from '@pages/private/sellers/Marketing/Files/ListCategoriesForFiles';
import { ListFilesByCategoryId } from '@pages/private/sellers/Marketing/Files/ListFilesByCategoryId';
import { LinksPerSite } from '@pages/private/sellers/Links/Sites';
import { FinancingDetails } from '@pages/private/sellers/CredCash/Financing/Property/Details';
import { NewPropertyFinancing } from '@pages/private/sellers/CredCash/Financing/Property/New';
import { ListPropertyFinancingByIndicationId } from '@pages/private/sellers/CredCash/Financing/Property/List';
import { SellerCreatePFCredit } from '@pages/private/sellers/CredCash/Create/PF';
import { SellerCreatePJCredit } from '@pages/private/sellers/CredCash/Create/PJ';
import { SellerAllCredits } from '@pages/private/sellers/CredCash/All';
import { ListSellerPendingCredits } from '@pages/private/sellers/CredCash/ListPending';
import { SellerUpdateCredit } from '@pages/private/sellers/CredCash/Update';
import { SellerProspectDetails } from '@pages/private/sellers/Prospects/Details';
import { SellerListProspects } from '@pages/private/sellers/Prospects/List';
import { SellerConsignedProspectDetails } from '@pages/private/sellers/CredCash/Consigned/Prospects/Details';
import { SellerListConsignedProspect } from '@pages/private/sellers/CredCash/Consigned/Prospects/List';
import { SellerNewConsignedProspect } from '@pages/private/sellers/CredCash/Consigned/Prospects/New';
import { NewECommerce } from '@pages/private/sellers/ECommerce/New';
import { NewBillet } from '@pages/private/sellers/Bwallet/Billets/New';
import { BilletDetails } from '@pages/private/sellers/Bwallet/Billets/Details';
import { SellerBilletsEmitted } from '@pages/private/sellers/Bwallet/Billets/ListEmitted';
import { ListBilletsPayed } from '@pages/private/sellers/Bwallet/Billets/ListPayed';

import { Route } from './Route';

export const sellerRoutes = [
  <Route
    key={v4()}
    path="/sellers/prospects/new"
    grantAccess={['seller']}
    isPrivate
    component={NewProspect}
  />,
  <Route
    key={v4()}
    path="/sellers/bbank-connect/research/new"
    grantAccess={['seller']}
    isPrivate
    component={BbankConnectResearch}
  />,
  <Route
    key={v4()}
    path="/sellers/bbank-connect/research/list"
    component={ListBbankConnectResearchs}
    isPrivate
    grantAccess={['seller']}
  />,
  <Route
    key={v4()}
    path="/sellers/bbank-connect/research/:telemedicineScoreId/details"
    component={TelemedicineResearchDetails}
    isPrivate
    grantAccess={['seller']}
  />,
  <Route
    key={v4()}
    path="/sellers/bbank-pay/merchants/new"
    component={SellerNewMerchants}
    isPrivate
    grantAccess={['seller']}
  />,
  <Route
    key={v4()}
    path="/sellers/bbank-pay/conveniences/new"
    component={SellerNewConveniences}
    isPrivate
    grantAccess={['seller']}
  />,
  <Route
    key={v4()}
    path="/sellers/marketing/files/categories"
    isPrivate
    grantAccess={['seller']}
    component={ListCategoriesForFiles}
  />,
  <Route
    key={v4()}
    path="/sellers/marketing/categories/:marketingCategoryId/files"
    isPrivate
    grantAccess={['seller']}
    component={ListFilesByCategoryId}
  />,
  <Route
    key={v4()}
    path="/sellers/links/sites"
    isPrivate
    grantAccess={['seller']}
    component={LinksPerSite}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/financing/property/new"
    component={NewPropertyFinancing}
    isPrivate
    grantAccess={['seller']}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/financing/property/list"
    component={ListPropertyFinancingByIndicationId}
    isPrivate
    grantAccess={['seller']}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/financing/property/:financingId/details"
    component={FinancingDetails}
    isPrivate
    grantAccess={['seller']}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/new/pf"
    isPrivate
    grantAccess={['seller']}
    component={SellerCreatePFCredit}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/new/pj"
    isPrivate
    grantAccess={['seller']}
    component={SellerCreatePJCredit}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/all"
    isPrivate
    grantAccess={['seller']}
    component={SellerAllCredits}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/pending"
    isPrivate
    grantAccess={['seller']}
    component={ListSellerPendingCredits}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/view/:creditId"
    isPrivate
    grantAccess={['seller']}
    component={SellerUpdateCredit}
  />,
  <Route
    key={v4()}
    path="/sellers/prospects/list"
    isPrivate
    grantAccess={['seller']}
    component={SellerListProspects}
  />,
  <Route
    key={v4()}
    path="/sellers/prospects/:prospectId/details"
    isPrivate
    grantAccess={['seller']}
    component={SellerProspectDetails}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/consigned/prospects/list"
    isPrivate
    grantAccess={['seller']}
    component={SellerListConsignedProspect}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/consigned/prospects/:consignedId/details"
    isPrivate
    grantAccess={['seller']}
    component={SellerConsignedProspectDetails}
  />,
  <Route
    key={v4()}
    path="/sellers/cred-cash/consigned/prospects/new"
    isPrivate
    grantAccess={['seller']}
    component={SellerNewConsignedProspect}
  />,
  <Route
    key={v4()}
    path="/sellers/ecommerce/new"
    isPrivate
    grantAccess={['seller']}
    component={NewECommerce}
  />,
  <Route
    key={v4()}
    path="/sellers/bwallet/billets/new"
    isPrivate
    grantAccess={['seller']}
    component={NewBillet}
  />,
  <Route
    key={v4()}
    path="/sellers/bwallet/billets/:billetId/details"
    isPrivate
    grantAccess={['seller']}
    component={BilletDetails}
  />,
  <Route
    key={v4()}
    path="/sellers/bwallet/billets/payed"
    isPrivate
    grantAccess={['seller']}
    component={ListBilletsPayed}
  />,
  <Route
    key={v4()}
    path="/sellers/bwallet/billets/emitted"
    isPrivate
    grantAccess={['seller']}
    component={SellerBilletsEmitted}
  />,
];
