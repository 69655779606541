import { useContext } from 'react';
import { AnimatedValue } from 'react-spring';
import {} from 'use-context-selector';

import { AsideContext } from '../contexts/aside';

interface IAsideContext {
  // eslint-disable-next-line @typescript-eslint/ban-types
  animation: AnimatedValue<Pick<object, never>>;
  isVisible: boolean;
  handleAsideVisibility(): void;
}

function useAside(): IAsideContext {
  const { animation, handleAsideVisibility, isVisible } =
    useContext(AsideContext);

  return {
    handleAsideVisibility,
    animation,
    isVisible,
  };
}

export { useAside };
