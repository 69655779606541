import { FC, useEffect } from 'react';
import { Switch } from 'react-router-dom';

import { Aside } from '@components/layouts/Aside';
import { CardMenuContainer } from '@components/layouts/CardMenuContainer';
import { Footer } from '@components/layouts/Footer';
import { Header } from '@components/layouts/Header';
import { Main } from '@components/layouts/Main';

import { useAuth } from '@hooks/auth';

import { Route } from './Route';
import { adminRoutes } from './admin.routes';
import { clientRoutes } from './client.routes';
import { franchiseesRoutes } from './franchisee.routes';
import { sellerRoutes } from './seller.routes';
import { publicRoutes } from './public.routes';
import { sharedRoutes } from './shared.routes';
import { superAdminRoutes } from './superAdmin.routes';

const Routes: FC = () => {
  const { user } = useAuth();

  useEffect(() => {
    // CODE
  }, [user]);

  return (
    <Switch>
      {publicRoutes}

      {clientRoutes}

      {user && (
        <>
          <Header />

          <Aside />

          <Main>
            <CardMenuContainer />

            {sharedRoutes}
            {sellerRoutes}
            {adminRoutes}
            {franchiseesRoutes}
            {superAdminRoutes}
          </Main>

          <Footer />
        </>
      )}

      <Route path="*" />
    </Switch>
  );
};

export { Routes };
