import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

const NotFound: FC = () => {
  const { push } = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      push('/');
    }, 4000);

    return () => clearInterval(timer);
  }, [push]);

  return (
    <Container>
      <h1>Página não encontrada</h1>

      <p>Cheque a URL e tente novamente!</p>
    </Container>
  );
};

export { NotFound };
