interface IData {
  [key: string]: string;
}

export const removeEmptyFields = (data: IData): IData => {
  const values: IData = {};

  Object.entries(data).forEach(([name, value]) => {
    if (value === '') {
      return;
    }

    values[name] = value;
  });

  return values;
};
