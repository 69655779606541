import { FC, useEffect, useState, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Select } from '@components/elements/Form/Select';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { paymentFormOptions } from './selectOptions';
import { Card, CardHeader, CardContent } from './styles';
import { ITelemedicine, IParams, IBadgeTypes } from './interfaces';

const FranchiseesTelemedicineDetails: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [telemedicine, setTelemedicine] = useState<ITelemedicine>();

  useEffect(() => {
    async function loadTelemedicine() {
      const { data: telemedicineFromApi } = await api.get<ITelemedicine>(
        `/telemedicine/${params.telemedicineId}`,
      );

      setTelemedicine(telemedicineFromApi);
    }

    setTimeout(() => {
      loadTelemedicine();
    }, 1000);
  }, [params]);

  const badgeTypes = useMemo((): IBadgeTypes => {
    return {
      pendente: 'warning',
      aprovado: 'success',
      negado: 'danger',
      analise: 'info',
      'pre-aprovado': 'info',
    };
  }, []);

  return (
    <>
      {!telemedicine ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Bbank Connect', 'Telemedicina', 'Detalhes']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes</h1>

                <Badge type={badgeTypes[telemedicine.status]}>
                  {telemedicine.status}
                </Badge>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={() => {
                    /* CODE */
                  }}
                  initialData={telemedicine}
                >
                  <FormRow separator>
                    <h1>Dados do usuário</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome</label>
                      <Input name="user.name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Email</label>
                      <Input name="user.email" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Documento</label>
                      <Input name="user.document" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Código postal</label>
                      <Input name="user.zipcode" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Endereço</label>
                      <Input name="user.address" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Input name="user.state" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="user.city" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Celular</label>
                      <Input name="user.cellphone" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados do telemedicina solicitado</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Registro geral (RG)</label>
                      <Input name="general_registry" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Data de nascimento</label>
                      <Input
                        name="birthday"
                        defaultValue={format(
                          new Date(telemedicine.birthday),
                          'dd/MM/yyy',
                        )}
                        readOnly
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Sexo</label>
                      <Input name="sex" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Estado civil</label>
                      <Input name="marital_status" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome da mãe</label>
                      <Input name="mother_name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados de pagamento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Forma da pagamento</label>
                      <Select
                        name="payment_form"
                        readonly
                        options={paymentFormOptions}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor</label>
                      <InputCurrency name="payment_value" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Parcelas</label>
                      <Input name="payment_portions" readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { FranchiseesTelemedicineDetails };
