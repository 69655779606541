import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { OptionTypeBase } from 'react-select';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeInputMask } from '@helpers/removeInputMask';
import { toCamelCase } from '@helpers/toCamelCase';

import { statusOpions, statesOptions } from './selectOptions';
import { URLPath, Card, CardHeader, CardContent } from './styles';
import { IParams, IMerchant, IFormData } from './interfaces';
import { formValidation } from './validations';

const TopBankEditMerchant: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();

  const [merchant, setMerchant] = useState<IMerchant>();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    async function loadMerchant() {
      const { data } = await api.get(
        `/bbank-pay/merchants/${params.merchantId}`,
      );

      setMerchant(data);
      setPrice(data.payment_value);
    }

    const timer = setTimeout(() => {
      loadMerchant();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.merchantId]);

  const bbankPayPaymentValueObject = useMemo(() => {
    const bbankPayPaymentValue = price;

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    const parsedValue = numberFormat.format(bbankPayPaymentValue);

    return {
      parsedValue,
      bbankPayPaymentValue,
    };
  }, [price]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const formData: IFormData = {};

        Object.entries(data).forEach(([key, value]) => {
          const parsedKey = toCamelCase(key);

          formData[parsedKey] = value;
        });

        delete formData.client;
        delete formData.franchisee;

        const valuesWithoutMasks = removeInputMask();

        const parsedValuesWithoutMask = valuesWithoutMasks.map(
          ({ name, value }) => {
            const parsedName = toCamelCase(name);

            return { name: parsedName, value };
          },
        );

        const parsedFormData = parsedValuesWithoutMask.reduce(
          (acc, { name, value }) => {
            acc[name] = value;

            return acc;
          },
          formData,
        );

        parsedFormData.paymentValue =
          bbankPayPaymentValueObject.bbankPayPaymentValue;

        const { data: merchantUpdated } = await api.put(
          `/bbank-pay/merchants/${params.merchantId}`,
          parsedFormData,
        );

        addToast({
          title: 'Dados atualizados com sucesso!',
          type: 'success',
        });

        setMerchant(merchantUpdated);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [
      addToast,
      params.merchantId,
      bbankPayPaymentValueObject.bbankPayPaymentValue,
    ],
  );

  return (
    <>
      {!merchant ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>BbankPay</li>
              <li>{'>'}</li>
              <li>Estabelecimentos</li>
              <li>{'>'}</li>
              <li>Editar/Visualizar</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Estabelecimento</h1>

                {merchant.status === 'ativo' && (
                  <Badge type="success">Ativo</Badge>
                )}

                {merchant.status === 'parado' && (
                  <Badge type="danger">Parado</Badge>
                )}

                {merchant.status === 'pendente' && (
                  <Badge type="warning">Pendente</Badge>
                )}
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={merchant}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Status</label>
                      <Select
                        name="status"
                        options={statusOpions}
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Franqueado responsável</label>
                      <Input name="franchisee.name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Cliente do fraqueado responsável</label>
                      <Input name="client.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados da empresa</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Razão social</label>
                      <Input
                        name="social_reason"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>CNPJ</label>
                      <Input
                        name="cnpj"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Data de abertura</label>
                      <InputMask
                        mask="99/99/9999"
                        placeholder="dd/mm/aaaa"
                        name="opening_date"
                        defaultValue={format(
                          new Date(merchant.opening_date),
                          'dd/MM/yyyy',
                        )}
                        readOnly={merchant.status !== 'pendente'}
                        noUnmask
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Tipo de empresa</label>
                      <Input
                        name="company_type"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome Fantasia</label>
                      <Input
                        name="name_fantasy"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Ramo de atividade</label>
                      <Input
                        name="company_activity"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>CNAE</label>
                      <Input
                        name="cnae"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Inscrição Estadual</label>
                      <Input
                        name="state_registry"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Faturamento Mensal Cartões</label>
                      <Input
                        name="card_monthly_invoicing"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>E-mail</label>
                      <Input
                        name="company_email"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Funcionamento do estabelecimento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Segunda a sexta </label>
                      <Input
                        name="opening_hours_monday_until_friday"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Sábado (deixe vazio caso não exista)</label>
                      <Input
                        name="opening_hours_saturday"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Domingo (deixe vazio caso não exista)</label>
                      <Input
                        name="opening_hours_sunday"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CEP</label>
                      <Input
                        name="postal_code"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Endereço</label>
                      <Input
                        name="address"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Bairro</label>
                      <Input
                        name="address_neighborhood"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input
                        name="address_city"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Input
                        name="address_state"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Complemento (deixe vazio caso não exista)</label>
                      <Input
                        name="address_complement"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Número (deixe vazio caso não exista)</label>
                      <InputMask
                        mask="9999999999"
                        noUnmask
                        name="address_number"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Contato</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Telefone da empresa</label>
                      <Input
                        name="company_phone"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Ramal</label>
                      <Input
                        name="ramal"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Celular</label>
                      <Input
                        name="company_cellphone"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Outros Telefones</label>
                      <Input
                        name="alternative_phone"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Responsável pela assinatura</label>
                      <Input
                        name="membership_responsible"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Fone contato</label>
                      <Input
                        name="membership_responsible_cellphone"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>CPF</label>
                      <Input
                        name="membership_responsible_document"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Função</label>
                      <Input
                        name="membership_responsible_role"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Contato com Departamento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome</label>
                      <Input name="department_contact_name" />
                      {merchant.status !== 'pendente'}
                    </InputGroup>
                    <InputGroup>
                      <label>E-mail</label>
                      <Input
                        name="department_contact_email"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                    <InputGroup>
                      <label>Telefone</label>
                      <Input
                        name="department_contact_phone"
                        readOnly={merchant.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Domicilio Bancario</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Banco</label>
                      <Input name="bankInfo[0].bank" />
                    </InputGroup>
                    <InputGroup>
                      <label>Número banco</label>
                      <Input name="bankInfo[0].bankCode" />
                    </InputGroup>
                    <InputGroup>
                      <label>Agência</label>
                      <Input name="bankInfo[0].agency" />
                    </InputGroup>
                    <InputGroup>
                      <label>Conta Corrente</label>
                      <Input name="bankInfo[0].account" />
                    </InputGroup>
                    <InputGroup>
                      <label>Pagamento na conta TopBank</label>
                      <Input name="bankInfo[0].payment_in_top_bank_account" />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      type="submit"
                      styleType="info"
                      icon={FiSave}
                      loading={loadingRequest}
                    >
                      Salvar alterações
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { TopBankEditMerchant };
