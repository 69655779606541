import {
  FC,
  useState,
  useCallback,
  ChangeEvent,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import { OptionTypeBase } from 'react-select';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputFile } from '@components/elements/Form/InputFile';
import { Button } from '@components/elements/Button';
import { InputMask } from '@components/elements/Form/InputMask';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';
import { IZipcodeResponse } from '@services/interfaces';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeInputMask } from '@helpers/removeInputMask';
import { getClientErrors } from '@helpers/getClientErrors';

import {
  Card,
  CardHeader,
  CardContent,
  CashBackPercentageFormRow,
  ChooseAFile,
} from './styles';
import {
  getFranchiseeClientsOptions,
  statesOptions,
  paymentFormOptions,
  businessMethodOptions,
  sellingTypeOptions,
  portionsOptions,
} from './selectOptions';
import { IFormData, IClient, IFile, IParsedFormData } from './interfaces';
import { formValidation } from './validations';

const NewMerchant: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const rgInputFileFormRow = useRef<HTMLDivElement>(null);
  const cnhInputGroupRef = useRef<HTMLDivElement>(null);
  const { addToast } = useToast();
  const { user } = useAuth();

  const [value, setValue] = useState(0);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [clients, setClients] = useState<IClient[]>();
  const [files, setFiles] = useState<IFile[]>([]);
  const [formRowShown, setFormRowShown] = useState(false);

  useEffect(() => {
    async function loadClients() {
      const { data } = await api.get('/users-indicated');

      setClients(data);
    }

    const timer = setTimeout(async () => {
      loadClients();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleSellingTypeSelectChange = useCallback(
    (option: OptionTypeBase | null) => {
      if (!option) {
        return;
      }

      if (option.value === 'sells_with_cashback') {
        setFormRowShown(true);
      } else {
        setFormRowShown(false);
      }
    },
    [],
  );

  const handlInputFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, key: string, title: string) => {
      const inputFileRef = formRef.current?.getFieldRef(e.target.name);

      if (!e.target.files || e.target.files.length === 0) {
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      const file = e.target.files[0];

      const fileExtension = file.name.split('.').reverse()[0];
      const fileSize = file.size * 0.000001;

      if (fileExtension !== 'pdf' && fileExtension !== 'png') {
        addToast({
          title: 'Arquivo inválido!',
          message: 'São aceitos apenas arquivos PDF ou PNG',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      if (fileSize > 2.1) {
        addToast({
          title: 'Arquivo grande demais!',
          message: 'São aceitos apenas arquivos com 2MB ou menos',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      setFiles(oldState => {
        const findDuplicated = oldState.findIndex(item => item.key === key);

        if (findDuplicated >= 0) {
          oldState.splice(findDuplicated, 1);
        }

        return [...oldState, { file, key, title }];
      });
    },
    [addToast],
  );

  const bbankPayPaymentValue = useMemo(() => {
    const paymentValue = value;

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    return {
      formattedValue: numberFormat.format(paymentValue),
      value: paymentValue,
    };
  }, [value]);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      const fieldsToDelete = [
        'fileOne',
        'fileTwo',
        'fileThree',
        'fileFour',
        'fileFive',
      ];

      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        if (
          rgInputFileFormRow.current?.style.display !== 'none' &&
          files.length !== 4
        ) {
          addToast({
            title: 'Insira os arquivos solicitados!',
            type: 'error',
            message:
              'Todos os arquivos são obrigatórios (4). Insira todos para poder continuar!',
          });
          return;
        }

        if (
          rgInputFileFormRow.current?.style.display === 'none' &&
          files.length !== 3
        ) {
          addToast({
            title: 'Insira os arquivos solicitados!',
            type: 'error',
            message:
              'Todos os arquivos são obrigatórios (3). Insira todos para poder continuar!',
          });
          return;
        }

        const parsedMaskedData = removeInputMask();

        const formData: IParsedFormData = {
          ...data,
        };

        delete formData.files;

        parsedMaskedData.forEach(item => {
          formData[item.name] = item.value;
        });

        formData.franchiseeId = user.id;
        formData.paymentValue = bbankPayPaymentValue.value;

        fieldsToDelete.forEach(fieldToDelete => {
          delete formData[fieldToDelete];
        });

        const { data: merchant } = await api.post(
          '/bbank-pay/merchants',
          formData,
        );

        const formFiles = new FormData();

        files.forEach(({ file, title }) => {
          formFiles.append('files', file);
          formFiles.append('titles', title);
        });

        formFiles.append('merchantId', merchant.id);

        await api.post('/bbank-pay/merchant-files', formFiles);

        addToast({
          title: 'Estabelecimento cadastrado!',
          type: 'success',
        });

        reset();
        setFiles([]);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, files, user.id, bbankPayPaymentValue.value],
  );

  const franchiseeClientsOptions = useMemo(() => {
    return clients ? getFranchiseeClientsOptions(clients) : [];
  }, [clients]);

  const handleFindZipcode = useCallback(
    async (zipcode: string) => {
      try {
        const { data } = await viaCepApi.get<IZipcodeResponse>(
          `/${zipcode}/json`,
        );
        const zipcodeInputRef = formRef.current?.getFieldRef('zipcode');
        const cityInputRef = formRef.current?.getFieldRef('city');
        const addressInputRef = formRef.current?.getFieldRef('address');
        const neighborhoodInputRef = formRef.current?.getFieldRef(
          'neighborhood',
        );

        if (data.erro === true) {
          zipcodeInputRef.value = '';
          cityInputRef.value = '';
          addressInputRef.value = '';
          neighborhoodInputRef.value = '';
          return;
        }

        const { cep, localidade, logradouro, bairro } = data;

        zipcodeInputRef.value = cep;
        cityInputRef.value = localidade;
        addressInputRef.value = logradouro;
        neighborhoodInputRef.value = bairro;

        const stateInputRef = formRef.current?.getFieldRef('state');

        stateInputRef.focus();
      } catch (err: any) {
        if (err.response) {
          addToast({
            title: 'Algo não está certo!',
            type: 'error',
            message: 'O CEP informado não foi digitado corretamente!',
          });
        }
      }
    },
    [addToast],
  );

  const handleChangeOfPrice = useCallback((option: OptionTypeBase | null) => {
    if (!option) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const ref = formRef.current?.getFieldRef('paymentValue');

    switch (option.value) {
      case 'accession':
        setValue(198);
        ref.value = numberFormat(198);
        break;

      case 'sells_a930':
        setValue(676);
        ref.value = numberFormat(676);
        break;

      default:
        setValue(556);
        ref.value = numberFormat(556);
        break;
    }
  }, []);

  const handleChooseAFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!rgInputFileFormRow.current || !cnhInputGroupRef.current) {
      return;
    }

    const { value: targetValue } = e.target;

    if (targetValue === 'rgCpfFile') {
      rgInputFileFormRow.current.style.display = 'flex';
      cnhInputGroupRef.current.style.display = 'none';
    } else {
      rgInputFileFormRow.current.style.display = 'none';
      cnhInputGroupRef.current.style.display = 'flex';
    }
  }, []);

  return (
    <>
      {!clients ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['BbankPay', 'Estabelecimento', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo estabelecimento</h1>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                  <FormRow>
                    <InputGroup>
                      <label>Escolha um de seus clientes</label>
                      <Select
                        name="clientId"
                        options={franchiseeClientsOptions}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados da empresa</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Razão social</label>
                      <Input name="socialReason" />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome fantasia</label>
                      <Input name="nameFantasy" />
                    </InputGroup>

                    <InputGroup>
                      <label>CNPJ</label>
                      <InputMask
                        mask="99.999.999/9999-99"
                        placeholder="00.000.000/0000-00"
                        name="cnpj"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data de abertura</label>
                      <InputMask
                        mask="99/99/9999"
                        placeholder="dd/mm/aaaa"
                        name="openingDate"
                        noUnmask
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Telefone (com DDD)</label>
                      <InputMask
                        mask="(99) 9999-9999"
                        placeholder="(00) 0000-0000"
                        name="phone"
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Celular (com DDD)</label>
                      <InputMask
                        mask="(99) 9 9999-9999"
                        placeholder="(00) 0 0000-0000"
                        name="cellphone"
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Número do SN</label>
                      <Input
                        name="infinityId"
                        placeholder="acc51515"
                        upperCase={false}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados do responsável</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome do responsável</label>
                      <Input name="responsibleName" />
                    </InputGroup>

                    <InputGroup>
                      <label>Email do responsável</label>
                      <Input name="responsibleEmail" upperCase={false} />
                    </InputGroup>

                    <InputGroup>
                      <label>CPF do responsável</label>
                      <InputMask
                        mask="999.999.999-99"
                        placeholder="000.000.000-00"
                        name="responsibleCpf"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data de nascimento do responsável</label>
                      <InputMask
                        mask="99/99/9999"
                        placeholder="dd/mm/aaaa"
                        name="responsibleBirthday"
                        noUnmask
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Celular do responsável (com DDD)</label>
                      <InputMask
                        mask="(99) 9 9999-9999"
                        placeholder="(00) 0 0000-0000"
                        name="responsibleCellphone"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CEP</label>
                      <InputMask
                        mask="99999-999"
                        placeholder="00000-000"
                        name="zipcode"
                        onBlur={e => handleFindZipcode(e.target.value)}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Endereço</label>
                      <Input name="address" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Bairro</label>
                      <Input name="neighborhood" />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="city" />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Select name="state" options={statesOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Complemento (deixe vazio caso não exista)</label>
                      <Input name="complement" />
                    </InputGroup>

                    <InputGroup>
                      <label>Número (deixe vazio caso não exista)</label>
                      <InputMask
                        mask="9999999999"
                        noUnmask
                        name="addressNumber"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Contrato</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Modelo de negócio</label>
                      <Select
                        name="businessMethod"
                        options={businessMethodOptions}
                        onChange={handleChangeOfPrice}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Tipo de venda</label>
                      <Select
                        name="sellingType"
                        options={sellingTypeOptions}
                        onChange={handleSellingTypeSelectChange}
                      />
                    </InputGroup>
                  </FormRow>

                  <CashBackPercentageFormRow isShown={formRowShown}>
                    <InputGroup>
                      <label>Porcentagem de cashback</label>
                      <Input
                        name="cashbackPercentage"
                        placeholder="O percentual não pode ser menor que 10 e nem maior que 99"
                      />
                    </InputGroup>
                  </CashBackPercentageFormRow>

                  <FormRow separator>
                    <h1>Método de pagamento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Preço a ser pago</label>
                      <Input
                        name="paymentValue"
                        readOnly
                        defaultValue={bbankPayPaymentValue.formattedValue}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Forma de pagamento</label>
                      <Select name="paymentForm" options={paymentFormOptions} />
                    </InputGroup>

                    <InputGroup>
                      <label>Parcelas</label>
                      <Select
                        name="paymentPortions"
                        options={portionsOptions}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Arquivos</h1>

                    <ChooseAFile>
                      <div>
                        <label htmlFor="cnhFile">CNH</label>
                        <input
                          name="chooseAFile"
                          type="radio"
                          id="cnhFile"
                          defaultValue="cnhFile"
                          onChange={handleChooseAFile}
                          defaultChecked
                        />
                      </div>
                      <div>
                        <label htmlFor="rgCpfFile">RG/CPF</label>
                        <input
                          name="chooseAFile"
                          type="radio"
                          id="rgCpfFile"
                          defaultValue="rgCpfFile"
                          onChange={handleChooseAFile}
                        />
                      </div>
                    </ChooseAFile>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Comprovante de residência</label>
                      <InputFile
                        name="fileOne"
                        onChange={e =>
                          handlInputFileChange(
                            e,
                            'aWqt',
                            'Comprovante de residência',
                          )
                        }
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Contrato social</label>
                      <InputFile
                        name="fileTwo"
                        onChange={e =>
                          handlInputFileChange(e, 'AOlv', 'Contrato')
                        }
                      />
                    </InputGroup>

                    <InputGroup ref={cnhInputGroupRef}>
                      <label>CNH</label>
                      <InputFile
                        name="fileThree"
                        onChange={e =>
                          handlInputFileChange(e, 'nyhZ', 'RG ou CNH')
                        }
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow ref={rgInputFileFormRow} style={{ display: 'none' }}>
                    <InputGroup>
                      <label>RG (frente)</label>
                      <InputFile
                        name="fileFour"
                        onChange={e =>
                          handlInputFileChange(e, 'OPiuI', 'RG (frente)')
                        }
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>RG (verso)</label>
                      <InputFile
                        name="fileFive"
                        onChange={e =>
                          handlInputFileChange(e, 'PnuT', 'RG (verso)')
                        }
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      type="submit"
                      styleType="info"
                      icon={FiCheckCircle}
                      loading={loadingRequest}
                    >
                      Enviar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { NewMerchant };
