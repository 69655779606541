import { states } from '@helpers/getAllStates';

interface IOption {
  value: string | number;
  label: string;
}

export const paymentFormOptions: IOption[] = [
  { value: 'credit_card', label: 'Cartão de crédito' },
  { value: 'debit', label: 'Débito' },
  { value: 'billet', label: 'Boleto' },
];

export const sellingTypeOptions: IOption[] = [
  { value: 'sells_with_cashback', label: 'Vendas com CashBack' },
  { value: 'sells_without_cashback', label: 'Vendas sem CashBack' },
];

const getStatesOptions = (): IOption[] => {
  const options = Object.entries(states).map(([code, state]) => {
    return {
      value: code,
      label: state,
    };
  });

  return options;
};

export const statesOptions = getStatesOptions();

export const businessMethodOptions = [
  { value: 'accession', label: 'Adesão' },
  { value: 'sells_a930', label: 'Vendas - A930' },
  { value: 'sells_a50', label: 'Vendas - A50' },
];

export const paymentPortionsOptions = Array.from(Array(12).keys()).map(
  value => ({
    value: value + 1,
    label: String(value + 1),
  }),
);
