import styled, { css } from 'styled-components';

interface IInputGroupProps {
  textarea?: number;
  checkbox?: number;
}

export const Container = styled.div<IInputGroupProps>`
  display: flex;
  flex-direction: column;

  flex: 1;

  > div {
    flex: 1;
    max-width: 100%;
  }

  > label {
    color: #3f4254;
    margin-bottom: 5px;
    margin-left: 5px;
    font-weight: 600;

    > span {
      color: #c53030;
      font-size: 14px;
      margin-left: 4px;
    }
  }

  ${props =>
    props.textarea
      ? css`
          height: auto;
          position: relative;

          > div {
            position: absolute;
            top: 35px;
            right: 10px;
          }
        `
      : css`
          height: 70px;
        `}

  ${props =>
    props.checkbox &&
    css`
      align-items: flex-start;
      justify-content: center;

      height: auto;

      > div {
        flex: none;

        & + div {
          margin-top: 8px;
        }
      }
    `}
`;
