import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { FiDownload, FiEye, FiMoreHorizontal } from 'react-icons/fi';
import exportFromJSON from 'export-from-json';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { Badge } from '@components/elements/Badge';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Dropdown } from '@components/elements/Dropdown';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { maskHandler } from '@helpers/maskHandler';

import { IStatusParsedObject, ICredit, ITableData } from './interfaces';
import { Card, CardHeader, CardContent } from './styles';

const ListCreditsByStatus: FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const { search } = useLocation();

  const query = search.replace('?status=', '');

  const [credits, setCredits] = useState<Array<ICredit>>();

  useEffect(() => {
    setCredits(undefined);

    async function loadCredits() {
      const { data } = await api.get('/credits/status', {
        params: { status: query },
      });

      setCredits(data.credits);
    }

    const timer = setTimeout(() => loadCredits(), 1500);

    return () => clearInterval(timer);
  }, [query, addToast]);

  const statusParsedObject = useMemo<IStatusParsedObject>(() => {
    return {
      pendente: {
        text: 'Pendente',
        badgeType: 'warning',
      },
      aprovado: {
        text: 'Aprovado',
        badgeType: 'success',
      },
      'pre-aprovado': {
        text: 'Pre aprovado',
        badgeType: 'info',
      },
      analise: {
        text: 'Em análise',
        badgeType: 'info',
      },
      negado: {
        text: 'Negado',
        badgeType: 'danger',
      },
      'contrato-pendente': {
        text: 'Contrato pendente',
        badgeType: 'info',
      },
    };
  }, []);

  const tableData = useMemo<ITableData[]>(() => {
    if (!credits) {
      return [];
    }

    const data = credits.map<ITableData>(credit => {
      const link =
        credit.cnpj === null
          ? `/cred-cash/pf/${credit.id}`
          : `/cred-cash/pj/${credit.id}`;

      return {
        id: credit.id,
        search: credit.social_reason || credit.user_name,
        email: credit.email,
        average_of_revenues: credit.average_of_revenues,
        status: (
          <Badge type={statusParsedObject[credit.status].badgeType}>
            {statusParsedObject[credit.status].text}
          </Badge>
        ),
        created_at: format(new Date(credit.created_at), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              onClick={() => push(link)}
              icon={FiEye}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [credits, push, statusParsedObject]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Referente à',
        selector: 'search',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Data da solicitação',
        selector: 'created_at',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleExportData = useCallback(
    (type: 'PF' | 'PJ') => {
      if (!credits || credits.length === 0) {
        return;
      }

      const filteredCredits = credits.filter(credit => credit.type === type);
      const numberFormatter = new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format;

      const creditsListLength = filteredCredits.length;

      const workSheetCells = Array.from(Array(creditsListLength).keys()).map(
        item => {
          const credit = filteredCredits[item];

          let workSheetCell = {};

          if (credit.type === 'PJ') {
            workSheetCell = {
              'Nome fantasia': credit.name_fantasy,
              'Razão social': credit.social_reason,
              CNPJ: maskHandler({ mask: 'cnpj', payload: credit.cnpj }),
              Email: credit.email,
              Telefone: credit.phone
                ? maskHandler({
                    mask: 'phone',
                    payload: credit.phone,
                  })
                : 'Não informado',
              'Faturamento médio': numberFormatter(
                Number(credit.average_of_revenues),
              ),
              'Valor do crédito': numberFormatter(credit.amount),
              'Nome do cliente': credit.owner.name,
              'Nome do franqueado': credit.franchisee.name,
              bank_code: credit.bank_code,
              agency: credit.agency,
              account: credit.account,
              zipcode: maskHandler({ mask: 'cep', payload: credit.zipcode }),
              state: credit.state,
              city: credit.city,
              address: credit.address,
              neighborhood: credit.neighborhood,
              complement: credit.complement,
              responsible_name: credit.responsible_name,
              responsible_cpf: maskHandler({
                mask: 'cpf',
                payload: credit.responsible_cpf,
              }),
              responsible_cellphone: maskHandler({
                mask: 'cellphone',
                payload: credit.responsible_cellphone,
              }),
              services: credit.services,
              modality: credit.modality,
              contract_percentage: credit.contract_percentage
                ? `${credit.contract_percentage}%`
                : 'Não informado',
              product: credit.product,
              employees_number: credit.employees_number
                ? credit.employees_number
                : 'Não informado',
              Status: credit.status,
              'Solicitado em': format(
                new Date(credit.created_at),
                'dd/MM/yyyy',
              ),
            };
          } else {
            workSheetCell = {
              'Faturamento médio': credit.average_of_revenues,
              'Valor do crédito': numberFormatter(credit.amount),
              'Cod do banco': credit.bank_code,
              'Agência bancária': credit.agency,
              'Conta bancária': credit.account,
              Telefone: credit.phone
                ? maskHandler({
                    mask: 'phone',
                    payload: credit.phone,
                  })
                : 'Não informado',
              Modalidade: credit.modality,
              Produto: credit.product,
              'Nome do cliente': credit.owner.name,
              'Nome do franqueado': credit.franchisee.name,
              Status: credit.status,
              'Solicitado em': format(
                new Date(credit.created_at),
                'dd/MM/yyyy',
              ),
            };
          }

          return workSheetCell;
        },
      );

      exportFromJSON({
        data: workSheetCells,
        exportType: 'xls',
        fileName: `cred_cash_${type}`,
      });
    },
    [credits],
  );

  return (
    <>
      {!credits ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={[
                'Cred Cash',
                'Solicitações',
                statusParsedObject[query].text,
              ]}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>
                  Crédito&nbsp;
                  {statusParsedObject[query].text}
                </h1>

                <Dropdown
                  styles={{ togglerBackground: '#c8c6c0', textColor: '#fff' }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button
                      type="button"
                      onClick={() => handleExportData('PF')}
                    >
                      <FiDownload />
                      Exportar dados PF
                    </button>
                  </li>

                  <li>
                    <button
                      type="button"
                      onClick={() => handleExportData('PJ')}
                    >
                      <FiDownload />
                      Exportar dados PJ
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>
              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por CPF/CNPJ"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListCreditsByStatus };
