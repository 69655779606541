import { FC, Fragment } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { v4 } from 'uuid';

import { IUrlPath } from './interfaces';
import { Container } from './styles';

export const URLPath: FC<IUrlPath> = ({ paths }) => {
  return (
    <Container>
      {paths.map((path, index) => {
        const finalIndex = paths.length - 1;

        if (index === finalIndex) {
          return <li key={v4()}>{path}</li>;
        }

        return (
          <Fragment key={v4()}>
            <li>{path}</li>
            <li>
              <FiChevronRight size={22} color="#b5b5c3" />
            </li>
          </Fragment>
        );
      })}
    </Container>
  );
};
