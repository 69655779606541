import * as Yup from 'yup';

export const creditValidationShape = async (
  data: Record<string, string>,
): Promise<void> => {
  const shape = Yup.object().shape({
    credit: Yup.object().shape({
      average_of_revenues: Yup.string().required('Preencha este campo!'),
      indication: Yup.string().required('Preencha este campo!'),
      modality: Yup.string().required('Preencha este campo!'),
      phone: Yup.string().required('Preencha este campo!'),
      user_id: Yup.string().required('Preencha este campo!'),
      product: Yup.string()
        .required('Preencha este campo!')
        .oneOf(['credluz', 'vehicle', 'property'], 'Opção inválida!'),
      amount: Yup.number()
        .typeError('Insira um número válido!')
        .required('Preencha este campo!'),
      contract_percentage: Yup.number()
        .transform((va, ov) => (ov === '' ? undefined : va))
        .typeError('Insira um número!')
        .required('Preencha este campo!')
        .min(3, 'O mínimo é 3%')
        .max(7, 'O máximo é 7%'),
    }),
  });

  await shape.validate({ credit: data }, { abortEarly: false });
};

export const vehicleGuaranteeValidationShape = async (
  data: Record<string, string>,
): Promise<void> => {
  const shape = Yup.object().shape({
    vehicle: Yup.object().shape({
      // clientName: Yup.string().required('Preencha este campo!'),
      // cpf: Yup.string().required('Preencha este campo!'),
      // generalRegistry: Yup.string().required('Preencha este campo!'),
      // birthday: Yup.string().required('Preencha este campo!'),
      // birthCity: Yup.string().required('Preencha este campo!'),
      // birthState: Yup.string().required('Preencha este campo!'),
      // maritalStatus: Yup.string().required('Preencha este campo!'),
      // motherName: Yup.string().required('Preencha este campo!'),
      // email: Yup.string()
      //   .email('Insira um endereço de email válido')
      //   .required('Preencha este campo!'),
      // cellphone: Yup.string().required('Preencha este campo!'),
      // postalCode: Yup.string().required('Preencha este campo!'),
      // address: Yup.string().required('Preencha este campo!'),
      // city: Yup.string().required('Preencha este campo!'),
      // neighborhood: Yup.string().required('Preencha este campo!'),
      // state: Yup.string().required('Preencha este campo!'),
      // residentTime: Yup.string().required('Preencha este campo!'),
      // companyDocument: Yup.string().required('Preencha este campo!'),
      // companyName: Yup.string().required('Preencha este campo!'),
      // companyPhone: Yup.string().required('Preencha este campo!'),
      // companyAddress: Yup.string().required('Preencha este campo!'),
      // complement: Yup.string().required('Preencha este campo!'),
      // profession: Yup.string().required('Preencha este campo!'),
      // income: Yup.number()
      //   .typeError('Insira um número válido')
      //   .required('Preencha este campo!'),
      // bank: Yup.string().required('Preencha este campo!'),
      // agency: Yup.string().required('Preencha este campo!'),
      // account: Yup.string().required('Preencha este campo!'),
      // vehicleIsNew: Yup.string().required('Preencha este campo!'),
      // vehiclePlate: Yup.string().required('Preencha este campo!'),
      // vehicleBrand: Yup.string().required('Preencha este campo!'),
      // vehicleModel: Yup.string().required('Preencha este campo!'),
      // vehicleModelYear: Yup.number()
      //   .typeError('Insira um número válido')
      //   .required('Preencha este campo!'),
      // vehicleValue: Yup.number()
      //   .typeError('Insira um número válido')
      //   .required('Preencha este campo!'),
      // financingValue: Yup.number()
      //   .typeError('Insira um número válido')
      //   .required('Preencha este campo!'),
      // firstValueToPay: Yup.number()
      //   .typeError('Insira um número válido')
      //   .required('Preencha este campo!'),
      // portions: Yup.string().required('Preencha este campo!'),
    }),
  });

  await shape.validate({ vehicle: data }, { abortEarly: false });
};

export const propertyGuaranteeValidation = async (
  data: Record<string, string>,
): Promise<void> => {
  const shape = Yup.object().shape({
    property: Yup.object().shape({
      account: Yup.string().when({
        is: (_: string) => data.ownProperty === '0',
        then: Yup.string().required('Preencha este campo!'),
        otherwise: Yup.string(),
      }),
      agency: Yup.string().when({
        is: (_: string) => data.ownProperty === '0',
        then: Yup.string().required('Preencha este campo!'),
        otherwise: Yup.string(),
      }),
      bank: Yup.string().when({
        is: (_: string) => data.ownProperty === '0',
        then: Yup.string().required('Preencha este campo!'),
        otherwise: Yup.string(),
      }),
      cpfCnpj: Yup.string().when({
        is: (_: string) => data.ownProperty === '0',
        then: Yup.string().required('Preencha este campo!'),
        otherwise: Yup.string(),
      }),
      city: Yup.string().required('Preencha este campo!'),
      condominium: Yup.string().required('Preencha este campo!'),
      financingPeriod: Yup.string().required('Preencha este campo!'),
      guaranteeValue: Yup.string().required('Preencha este campo!'),
      intendedCredit: Yup.number()
        .typeError('Insira um número válido!')
        .required('Preencha este campo!'),
      neighborhood: Yup.string().required('Preencha este campo!'),
      number: Yup.number()
        .typeError('Insira um número válido!')
        .required('Preencha este campo!'),
      ownProperty: Yup.string().required('Preencha este campo!'),
      propertyType: Yup.string().required('Preencha este campo!'),
      publicPlace: Yup.string().required('Preencha este campo!'),
      relationship: Yup.string().when({
        is: (_: string) => data.ownProperty === '0',
        then: Yup.string().required('Preencha este campo!'),
        otherwise: Yup.string(),
      }),
      state: Yup.string().required('Preencha este campo!'),
      thirdPartyCpf: Yup.string().when({
        is: (_: string) => data.ownProperty === '0',
        then: Yup.string().required('Preencha este campo!'),
        otherwise: Yup.string(),
      }),
      thirdPartyName: Yup.string().when({
        is: (_: string) => data.ownProperty === '0',
        then: Yup.string().required('Preencha este campo!'),
        otherwise: Yup.string(),
      }),
      tower: Yup.string().required('Preencha este campo!'),
      unity: Yup.string().required('Preencha este campo!'),
      vacancy: Yup.string().required('Preencha este campo!'),
      zipcode: Yup.string().required('Preencha este campo!'),
      generalRegistry: Yup.string().required('Preencha este campo!'),
    }),
  });

  await shape.validate({ property: data }, { abortEarly: false });
};

export const credluzValidationShape = async (
  data: Record<string, string>,
): Promise<void> => {
  const shape = Yup.object().shape({
    credluz: Yup.object().shape({
      rg: Yup.string().required('Preencha este campo!'),
      issuingBody: Yup.string().required('Preencha este campo!'),
      birthday: Yup.string().required('Preencha este campo!'),
      maritalStatus: Yup.string().required('Preencha este campo!'),
      schooling: Yup.string().required('Preencha este campo!'),
      fatherName: Yup.string().required('Preencha este campo!'),
      motherName: Yup.string().required('Preencha este campo!'),
      sex: Yup.string().required('Preencha este campo!'),
      reference: Yup.string().required('Preencha este campo!'),
      referencePhone: Yup.string().required('Preencha este campo!'),
      number: Yup.string().required('Preencha este campo!'),
      neighborhood: Yup.string().required('Preencha este campo!'),
      complement: Yup.string().required('Preencha este campo!'),
      secondReference: Yup.string().required('Preencha este campo!'),
      secondReferencePhone: Yup.string().required('Preencha este campo!'),
      consumerUnit: Yup.string().required('Preencha este campo!'),
    }),
  });

  await shape.validate({ credluz: data }, { abortEarly: false });
};
