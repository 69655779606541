import styled from 'styled-components';

interface ICardContent {
  scoreStatus: 'danger' | 'warning' | 'success';
}

const cardContentColors = {
  success: '#10ada6',
  danger: '#f64e60',
  warning: '#ffa800',
};

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div<ICardContent>`
  padding: 30px;

  > h1 {
    font-size: 23px;
    color: ${props => cardContentColors[props.scoreStatus]};
    text-align: center;
  }

  > p {
    text-align: center;
    font-size: 18px;

    color: ${props => cardContentColors[props.scoreStatus]};

    max-width: 650px;

    margin: 0 auto;

    & + p {
      margin-top: 20px;
    }

    &:nth-child(2) {
      text-transform: lowercase;

      margin-top: 10px;
    }
  }
`;
