import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const validationShape = Yup.object().shape({
    operationValue: Yup.number()
      .transform((va, ov) => {
        if (ov === '') {
          return undefined;
        }

        return va;
      })
      .required('Preencha este campo!')
      .typeError('Insira um número válido!'),
    Banco: Yup.string().required('Preencha este campo!'),
    'Tipo de conta': Yup.string().required('Preencha este campo!'),
    'Nome destino': Yup.string().required('Preencha este campo!'),
    Agencia: Yup.string().required('Preencha este campo!'),
    Conta: Yup.string().required('Preencha este campo!'),
    'Descrição da transferencia': Yup.string().required('Preencha este campo!'),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
