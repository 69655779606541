import styled, { css } from 'styled-components';

interface ITableButton {
  styleType: 'warning' | 'danger' | 'info' | 'success';
  loading?: number;
}

const buttonTypes = {
  success: css`
    color: #408401;
    background-color: #cbffc0;

    &:hover {
      color: #fff;
      background-color: #408401;
    }
  `,

  danger: css`
    color: #f64e60;
    background-color: #ffe2e5;

    &:hover {
      color: #fff;
      background-color: #f64e60;
    }
  `,

  info: css`
    color: #8950fc;
    background-color: #eee5ff;

    &:hover {
      color: #fff;
      background-color: #8950fc;
    }
  `,

  warning: css`
    color: #ffa800;
    background-color: #fff4de;

    &:hover {
      color: #fff;
      background-color: #ffa800;
    }
  `,
};

export const URLPath = styled.ul`
  display: flex;
  align-items: center;

  > li {
    font-size: 1.25rem;
    color: #b5b5c3;
    list-style: none;

    font-weight: 600;

    a {
      color: inherit;
      text-decoration: inherit;

      transition: color 0.2s;

      &:hover {
        color: #762772;
      }
    }

    & + li {
      margin-left: 15px;
    }

    &:nth-child(1) {
      font-size: 28px;

      color: #181c32;
    }
  }
`;

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const UserSelectsContainer = styled.div`
  margin: 20px 0;
`;

export const SelectContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  gap: 15px;

  > div {
    flex: 1;

    max-width: 100%;
  }
`;

export const TableButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  width: 100px;
`;

export const TableButton = styled.button<ITableButton>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  transition: background-color 0.2s, color 0.2s;

  ${props => buttonTypes[props.styleType]}

  ${props =>
    props.loading &&
    css`
      background-color: #ccc;
      color: #aaa;

      cursor: not-allowed;
    `}
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
