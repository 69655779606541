import { FC, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Container } from './styles';

const Unauthorized: FC = () => {
  const { goBack } = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      goBack();
    }, 4000);

    return () => clearInterval(timer);
  }, [goBack]);

  return (
    <Container>
      <h1>Não autorizado</h1>

      <p>Você não tem permissão para acessar essa página!</p>
    </Container>
  );
};

export { Unauthorized };
