import { IOption } from '@utils/interfaces';

import { IDepartment, IPlatform } from './interfaces';

export const getDepartmentsOptions = (
  departments: IDepartment[],
): IOption[] => {
  const options = departments.map(department => {
    return {
      value: department.id,
      label: department.name,
    };
  });

  return options;
};

export const getPlatformOptions = (platforms: IPlatform[]): IOption[] => {
  const options = platforms.map(platform => {
    return {
      value: platform.id,
      label: platform.name,
    };
  });

  return options;
};

export const statusOptions = [
  { value: 'em_aberto', label: 'Em aberto' },
  { value: 'em_andamento', label: 'Em andamento' },
  { value: 'finalizado', label: 'Finalizado' },
];
