import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import DataTable, { TableColumn } from 'react-data-table-component';
import { FiPlusCircle, FiTrash } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import { useToast } from '@hooks/toast';

import bbankApi from '@services/bbankApi';

import { URLPath, Card, CardContent, CardHeader } from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IColumns = Array<TableColumn<Record<string, any>>>;

interface IFaq {
  id: string;
  title: string;
  description: string;
}

interface IDataTableData {
  id: string;
  title: string;
  description: string;
  actions: JSX.Element;
}

const AllFaqCategories: FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [faqCategories, setFaqCategories] = useState<Array<IFaq>>([]);

  const handleDeleteFaqCategory = useCallback(
    async (id: string) => {
      try {
        await bbankApi.delete(`/faq-categories/${id}`);

        setFaqCategories(oldState => {
          const newState = oldState.filter(item => item.id !== id);

          return newState;
        });

        addToast({
          title: 'Categoria deletada com sucesso!',
          type: 'info',
        });
      } catch {
        addToast({
          title: 'Algum erro aconteceu',
          type: 'error',
          message: 'Tente novamente mais tarde!',
        });
      }
    },
    [addToast],
  );

  const dataTableColumns = useMemo<IColumns>(() => {
    return [
      {
        name: 'Titulo',
        selector: 'title',
      },
      {
        name: 'Descrição',
        selector: 'description',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const dataTableData = useMemo<IDataTableData[]>(() => {
    const data = faqCategories.map<IDataTableData>(item => {
      return {
        id: item.id,
        title: item.title,
        description: item.description,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              key={`${item.id}7`}
              onDoubleClick={() => handleDeleteFaqCategory(item.id)}
              icon={FiTrash}
              title="Clique duas vezes para deletar!"
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [faqCategories, handleDeleteFaqCategory]);

  useEffect(() => {
    async function loadFaqs() {
      const { data } = await bbankApi.get<Array<IFaq>>('/faq-categories');

      setFaqCategories(oldState => {
        const faqs = data.map(item => {
          return {
            id: item.id,
            title: item.title,
            description: item.description,
          };
        });

        return [...oldState, ...faqs];
      });
    }

    loadFaqs();
  }, []);

  return (
    <>
      <Row>
        <URLPath>
          <li>
            <strong>FAQ</strong>
          </li>
          <li>&gt;</li>
          <li>Categorias</li>
          <li>&gt;</li>
          <li>
            <Link to="/faq/all">Todos</Link>
          </li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Listagem</h1>

            <Button
              styleType="success"
              icon={FiPlusCircle}
              loading={false}
              onClick={() => push('/faq-categories/new')}
            >
              Adicionar
            </Button>
          </CardHeader>

          <CardContent>
            <DataTable
              columns={dataTableColumns}
              data={dataTableData}
              pagination
              striped
            />
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { AllFaqCategories };
