import { IOption } from '@utils/interfaces';

export const statusOptions: IOption[] = [
  { label: 'Aceito', value: 'accepted' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Negado', value: 'denied' },
];

export const yesOrNoQuestions = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
];
