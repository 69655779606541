import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IConsignedCompany } from './interfaces';
import { Card, CardHeader, CardContent } from './styles';

export const ListConsignedCompanies: FC = () => {
  const { push } = useHistory();

  const [companies, setCompanies] = useState<IConsignedCompany[]>();

  useEffect(() => {
    async function loadCompanies() {
      const { data } = await api.get<any[]>('/consigned/companies');

      const parsedData = data.map(item => {
        return parseObjectPropertiesToCamelCase<IConsignedCompany>(item);
      });

      setCompanies(parsedData);
    }

    const timer = setTimeout(() => loadCompanies(), 1000);

    return () => clearInterval(timer);
  }, []);

  const handleGoToDetailsPage = useCallback(
    (consignedCompanyId: string) => {
      push(`/cred-cash/consigned/companies/${consignedCompanyId}/details`);
    },
    [push],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome da empresa',
        selector: 'search',
      },
      {
        name: 'CNPJ da empresa',
        selector: 'companyDocument',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!companies) {
      return [];
    }

    const data = companies.map(company => ({
      id: company.id,
      search: company.companyName,
      companyDocument: company.companyDocument,
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="success"
            icon={FiEye}
            onClick={() => handleGoToDetailsPage(company.companyDocument)}
            title="Ver detalhes"
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [companies, handleGoToDetailsPage]);

  return (
    <>
      {!companies ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Cred Cash', 'Consignado', 'Empresas']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Lista de empresas cadastradas</h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
