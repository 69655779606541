import { FormHandles } from '@unform/core';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ValidationError } from 'yup';
import { useRouteMatch } from 'react-router-dom';
import { FiRefreshCw } from 'react-icons/fi';

import { URLPath } from '@components/layouts/UrlPath';
import { Row } from '@components/layouts/Grid/Row';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Button } from '@components/elements/Button';
import { Badge } from '@components/elements/Badge';
import { Select } from '@components/elements/Form/Select';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import {
  IIndication,
  ICreditPerson,
  IFormData,
  IParams,
  IBadgeTypes,
} from './interfaces';
import { formValidation } from './validations';
import { Card, CardHeader, CardContent } from './styles';
import { statusOptions, yesOrNoQuestions } from './selectOptions';

export const CreditPersonDetails: FC = () => {
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();
  const formRef = useRef<FormHandles>(null);

  const [creditPerson, setCreditPerson] = useState<ICreditPerson>();
  const [indication, setIndication] = useState<IIndication>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadInformation() {
      const { data: creditPersonInfo } = await api.get(
        `/bot/credits/person/${params.creditPersonId}`,
      );

      const parsedCreditPersonInfo = parseObjectPropertiesToCamelCase<ICreditPerson>(
        creditPersonInfo,
      );

      const { data: indicationInfo } = await api.get(
        `/users/${parsedCreditPersonInfo.indicationId}`,
      );

      const parsedIndicationInfo = parseObjectPropertiesToCamelCase<IIndication>(
        indicationInfo,
      );

      setIndication(parsedIndicationInfo);
      setCreditPerson(parsedCreditPersonInfo);
    }

    const timer = setTimeout(() => loadInformation(), 1500);

    return () => clearInterval(timer);
  }, [params.creditPersonId]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const { data: updated } = await api.patch(
          `/bot/credits/person/${params.creditPersonId}`,
          {
            status: data.status,
          },
        );

        const updatedParsed = parseObjectPropertiesToCamelCase<ICreditPerson>(
          updated,
        );

        setCreditPerson(updatedParsed);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, params.creditPersonId],
  );

  const badgeTypes = useMemo<IBadgeTypes>(() => {
    return {
      pending: {
        badgeType: 'warning',
        badgeText: 'Pendente',
      },
      accepted: {
        badgeType: 'success',
        badgeText: 'Aceito',
      },
      denied: {
        badgeType: 'danger',
        badgeText: 'Negado',
      },
    };
  }, []);

  return (
    <>
      {!creditPerson ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={['Pesquisas do bot', 'Pessoa física', 'Detalhes']}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes da pesquisa selecionada</h1>

                <Badge type={badgeTypes[creditPerson.status].badgeType}>
                  {badgeTypes[creditPerson.status].badgeText}
                </Badge>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={{ ...creditPerson, indication }}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Status</label>
                      <Select name="status" options={statusOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Indicação</label>
                      <Input name="indication.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor do crédito</label>
                      <InputCurrency name="amount" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Período de pagamento</label>
                      <Input name="paymentPeriod" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Documento (CPF)</label>
                      <InputMask
                        mask="999.999.999-99"
                        name="document"
                        readOnly
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Atividade de trabalho</label>
                      <Input name="workActivity" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Renda mensal</label>
                      <InputCurrency name="monthlyIncome" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Código postal (CEP)</label>
                      <InputMask mask="99999-999" name="zipcode" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>E-mail</label>
                      <Input name="email" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Celular</label>
                      <InputMask
                        mask="(99) 99999-9999"
                        name="cellphone"
                        readOnly
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Banco na qual se relaciona</label>
                      <Input name="bank" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Propósito do crédito</label>
                      <Input name="creditPurpose" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Possui cartão de crédito</label>
                      <Select
                        name="creditCard"
                        readonly
                        options={yesOrNoQuestions}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Possui conta de luz em seu nome</label>
                      <Select
                        name="electricityBillInYourName"
                        readonly
                        options={yesOrNoQuestions}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Garantia necessária</label>
                      <Input name="guaranteeNeeded" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      icon={FiRefreshCw}
                      loading={loading}
                    >
                      Alterar status
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
