import { IOption } from '@utils/interfaces';

import { IInvestmentPercentage, IClient } from './interfaces';

export const getInvestmentPercentageOptions = (
  data: IInvestmentPercentage[],
): IOption[] => {
  const options = data.map(percentage => ({
    value: percentage.id,
    label: `${percentage.name} - ${new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(percentage.value)}`,
  }));

  return options;
};

export const getClientsOptions = (data: IClient[]): IOption[] => {
  const options = data.map(client => ({
    value: client.id,
    label: client.name,
  }));

  return options;
};

export const periodOptions: IOption[] = [
  { label: '12 meses', value: 12 },
  { label: '24 meses', value: 24 },
  { label: '36 meses', value: 36 },
  { label: '48 meses', value: 48 },
  { label: '60 meses', value: 60 },
];
