import { useHistory, useRouteMatch } from 'react-router-dom';
import { useEffect, ChangeEvent, useCallback, useState, FC } from 'react';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { v4 } from 'uuid';

import { Button } from '@components/elements/Button';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputFile } from '@components/elements/Form/InputFile';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Form } from '@components/elements/Form';
import { Alert } from '@components/alerts/Alert';
import { URLPath } from '@components/layouts/UrlPath';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';

import { Card, CardHeader, CardContent, InputFilesContainer } from './styles';

interface IParams {
  creditId: string;
}

interface ITarget {
  files?: FileList;
}

interface IFile {
  id: string;
  file: File;
}

interface IElement extends HTMLElement {
  value?: string;
}

const FranchiseesCredCashFiles: FC = () => {
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();
  const { push } = useHistory();

  const [inputFiles, setInputFiles] = useState<JSX.Element[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);
  const [pageIsLoading, setPageIsLoading] = useState(true);

  useEffect(() => {
    async function loadCredit() {
      const { data } = await api.get(`/credits/${params.creditId}`);

      if (data.credit.status !== 'pendente') {
        addToast({
          title: 'Não autorizado!',
          type: 'error',
          message: 'Você não pode mais enviar arquivos para essa solicitação!',
        });
        return;
      }

      setPageIsLoading(false);
    }

    setTimeout(() => {
      loadCredit();
    }, 1000);
  }, [params.creditId, addToast]);

  const handleInputFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, fileId: string) => {
      const target = e.target as ITarget;

      if (!target.files) {
        return;
      }

      const fileExtension = target.files[0].name.split('.').reverse()[0];
      const size = target.files[0].size * 0.000001;

      if (
        fileExtension !== 'pdf' &&
        fileExtension !== 'jpeg' &&
        fileExtension !== 'jpg'
      ) {
        addToast({
          title: 'Documento inválido!',
          type: 'error',
          message: 'Só são aceitos documentos em PDF/JPEG.',
        });
        throw new Error();
      }

      if (size > 2.8) {
        addToast({
          title: 'Documento muito grande!',
          type: 'error',
          message: 'Só são aceitos documentos com 2.5MB ou menos.',
        });
        throw new Error();
      }

      const file = {
        id: fileId,
        file: target.files[0],
      };

      setFiles(oldState => {
        const findDuplicatedFile = oldState.find(item => item.id === fileId);

        if (findDuplicatedFile) {
          return [...oldState];
        }

        return [...oldState, file];
      });
    },
    [addToast],
  );

  const handleRemoveInputFile = useCallback((key: string) => {
    setInputFiles(oldState => {
      const updatedState = oldState.filter(item => item.key !== key);

      return updatedState;
    });
    setFiles(oldState => {
      const updatedState = oldState.filter(item => item.id !== key);

      return updatedState;
    });
  }, []);

  const getInputFile = useCallback(() => {
    const inputFileKey = v4();

    return (
      <FormRow key={inputFileKey}>
        <InputFile
          name="files"
          onChange={e => handleInputFileChange(e, inputFileKey)}
        />

        <Input name="title[]" placeholder="Dê um nome a este arquivo" />

        <Button
          styleType="danger"
          icon={FiMinusCircle}
          onClick={() => handleRemoveInputFile(inputFileKey)}
        />
      </FormRow>
    );
  }, [handleRemoveInputFile, handleInputFileChange]);

  const handleAddInputFiles = useCallback(() => {
    const inputFile = getInputFile();

    setInputFiles([...inputFiles, inputFile]);
  }, [getInputFile, inputFiles]);

  const handleUploadFiles = useCallback(async () => {
    try {
      const fileTitles = document.getElementsByName(
        'title[]',
      ) as NodeListOf<HTMLElement>;

      const titles = Array.from(fileTitles)
        .map(item => {
          const element = item as IElement;

          return element.value || '';
        })
        .filter(item => item !== '');

      if (titles.length !== files.length) {
        addToast({
          title: 'Alguma coisa não está certa!',
          type: 'error',
          message: 'Verifique se os seus arquivos contém nomes ou vice-versa.',
        });
        return;
      }

      const formData = new FormData();

      files.forEach((file, index) => {
        formData.append('file', file.file);
        formData.append(`title[${index}]`, titles[index]);
      });

      formData.append('creditId', params.creditId);

      await api.post('/credit-files', formData);

      addToast({
        title: 'Arquivos enviados com sucesso!',
        type: 'success',
      });

      setTimeout(() => {
        push('/franchisees/uploads/cred-cash/');
      }, 500);
    } catch (err: any) {
      if (err.response) {
        const { message, status } = getClientErrors(err.response);

        if (status === 400 || status === 403 || status === 404) {
          addToast({
            title: 'Solicitação não processada!',
            type: 'error',
            message,
          });
        }

        if (status === 500) {
          addToast({
            title: 'Erro interno do servidor!',
            type: 'error',
            message:
              'Um erro desconhecido aconteceu! Contate o administrador do sistema e reporte o erro.',
          });
        }
      }
    }
  }, [files, addToast, params.creditId, push]);

  return (
    <>
      {pageIsLoading ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <Alert type="info">
              Os documentos só serão aceitos em formato PDF/JPEG e que estejam
              em boa resolução. Tamanho máximo:&nbsp;
              <strong>2.5 MB</strong>
            </Alert>
            <Alert type="danger">
              Documentos com rasura, manchados, embassados, ou cortados nas
              laterais serão&nbsp;
              <strong>invalidados</strong>
            </Alert>
          </Row>

          <Row>
            <URLPath paths={['Uploads', 'CredCash', 'Novo arquivo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo arquivo</h1>

                <Button
                  styleType="info"
                  icon={FiPlusCircle}
                  disabled={files.length === 0}
                  onClick={handleAddInputFiles}
                >
                  Adicionar arquivo...
                </Button>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleUploadFiles}>
                  <FormRow>
                    <InputFile
                      name="files"
                      onChange={e =>
                        handleInputFileChange(e, 'first-file-cannot-be-deleted')
                      }
                    />

                    <Input
                      name="title[]"
                      multiple
                      placeholder="Dê um nome a este arquivo"
                    />
                  </FormRow>

                  <InputFilesContainer>
                    {inputFiles.map(item => item)}
                  </InputFilesContainer>

                  <FormRow buttonWrapper style={{ marginTop: 20 }}>
                    <Button type="submit" styleType="success">
                      Enviar arquivos
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { FranchiseesCredCashFiles };
