import { FC } from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

const Footer: FC = () => (
  <Container>
    <div>
      <span>
        {new Date().getFullYear()}
        &copy;
      </span>

      <span>Bbanking</span>
    </div>

    <div>
      <ul>
        <li>
          <Link to="/dashboard">Sobre</Link>
        </li>
        <li>
          <Link to="/dashboard">Time</Link>
        </li>
        <li>
          <Link to="/dashboard">Contato</Link>
        </li>
      </ul>
    </div>
  </Container>
);

export { Footer };
