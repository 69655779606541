import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;

  & + & {
    margin-top: 20px;
  }

  @media screen and (max-width: 1140px) {
    > div {
      & + div {
        margin-top: 8px;
      }
    }
  }
`;
