import { FC, HTMLAttributes, useMemo } from 'react';
import { FiAlertCircle, FiInfo, FiCheckCircle } from 'react-icons/fi';

import { Container } from './styles';

interface ITooltip extends HTMLAttributes<HTMLDivElement> {
  type: 'warning' | 'success' | 'error' | 'info';
}

const Tooltip: FC<ITooltip> = ({ type, children, ...rest }) => {
  const icon = useMemo(() => {
    switch (type) {
      case 'error':
        return <FiAlertCircle />;

      case 'success':
        return <FiCheckCircle />;

      default:
        return <FiInfo />;
    }
  }, [type]);

  return (
    <Container type={type} {...rest}>
      {icon}
      <span>{children}</span>
    </Container>
  );
};

export { Tooltip };
