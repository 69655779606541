import { banks } from '@helpers/getBanks';
import { states } from '@helpers/getAllStates';

interface IUser {
  name: string;
  id: string;
}

type IReturnType = Array<{ value: string; label: string }>;

export const loadUsers = (users: IUser[]): IReturnType => {
  return users.map(user => ({
    value: user.id,
    label: user.name,
  }));
};

export const loadBanks = (): IReturnType => {
  return banks.map(bank => {
    const bankSpliced = bank.split(' - ');

    return {
      value: bankSpliced[0],
      label: bankSpliced[1],
    };
  });
};

export const loadStates = (): IReturnType => {
  return Object.entries(states).map(item => {
    const initials = item[0];
    const stateName = item[1];

    return {
      value: initials,
      label: stateName,
    };
  });
};

export const creditProducts = [
  { value: 'sem_garantia', label: 'Sem garantia' },
  { value: 'com_garantia', label: 'Com garantia' },
];

export const maritalStatusOptions = [
  { value: 'casado', label: 'Casado' },
  { value: 'solteiro', label: 'Solteiro' },
];

export const propertyType = [
  { value: 'comercial', label: 'Comercial' },
  { value: 'residencial', label: 'Residencial' },
  { value: 'misto', label: 'Misto' },
];

export const ownPropertyOptions = [
  { value: '1', label: 'Sim' },
  { value: '0', label: 'Não' },
];

export const birthStateOptions = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];

export const vehicleIsNewType = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

export const portionsType = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
];
