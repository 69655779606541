import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;

  width: 100%;

  list-style: none;

  margin-bottom: 20px;

  gap: 0.7rem;

  z-index: 2;

  .active {
    background-color: #c8c6c0;

    > a {
      color: white;
      > svg {
        stroke: white;
        color: white;
      }
    }

    &:hover {
      background-color: #c8c6c0;

      > a {
        color: white;
        > svg {
          stroke: white;
          color: white;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }
`;

export const Row = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;

  & + & {
    margin-top: 20px;
  }

  @media (max-width: 1140px) {
    > div {
      & + div {
        margin-top: 8px;
      }
    }
  }
`;
