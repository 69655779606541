import styled, { css } from 'styled-components';

interface IContainerProps {
  isFosed: boolean;
  isFilled: boolean;
  hasErrors: boolean;
  readonly: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  align-items: center;

  background-color: #f3f6f9;

  height: 45px;
  max-width: 300px;
  width: 100%;

  border: 2px solid transparent;
  border-radius: 10px;

  padding: 0 10px;

  transition: background-color 0.2s;

  transition: border-color 0.2s;

  ${props =>
    props.readonly &&
    css`
      pointer-events: none;
      touch-action: none;
      background-color: #e6e9ec;
    `}

  & + & {
    margin-top: 8px;
  }

  > svg {
    color: #ccc;

    margin-right: 16px;

    transition: stroke 0.2s;
  }

  > div.css-2b097c-container,
  > div.react-select--is-disabled {
    flex: 1;

    > div.react-select__control {
      background-color: transparent;

      height: 41px;

      border: 0;

      box-shadow: none;

      > * {
        font-size: 16px;
      }
    }
  }

  ${props =>
    props.hasErrors &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFosed &&
    css`
      background-color: #fff;

      border-color: #c8c6c0;

      > svg {
        stroke: #c8c6c0;
      }
    `}

  ${props =>
    props.isFilled &&
    css`
      > svg {
        stroke: #c8c6c0;
      }
    `};
`;
