import { v4 } from 'uuid';

import { AllClientCredits } from '@pages/private/clients/Credits/All';
import { ClientCreditDetails } from '@pages/private/clients/Credits/Details';
import { ClientNewTelemedicine } from '@pages/private/clients/BbankConnect/Telemedicine/New';
import { ListMerchants } from '@pages/private/clients/BbankPay/Merchants/List';
import { EditMerchant } from '@pages/private/clients/BbankPay/Merchants/Edit';
import { ListConveniences } from '@pages/private/clients/BbankPay/Conveniences/List';
import { EditConveniences } from '@pages/private/clients/BbankPay/Conveniences/Edit';
import { NewConveniences } from '@pages/private/clients/BbankPay/Conveniences/New';
import { ConvenienceTransactions } from '@pages/private/clients/BbankPay/Conveniences/Transactions';
import { MerchantTransactions } from '@pages/private/clients/BbankPay/Merchants/Transactions';
import { NewPropertyFinancing } from '@pages/private/clients/Credits/Financing/Property/New';
import { FinancingDetails } from '@pages/private/clients/Credits/Financing/Property/Details';
import { ListPropertyFinancingByUserId } from '@pages/private/clients/Credits/Financing/Property/List';

import { Route } from './Route';

const clientRoutes = [
  <Route
    key={v4()}
    path="/client/bbank-connect/telemedicine"
    component={ClientNewTelemedicine}
    grantAccess={['client']}
    isPrivate
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/credits"
    component={AllClientCredits}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/credit/:id/details"
    component={ClientCreditDetails}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/bbank-pay/merchants/all"
    component={ListMerchants}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/bbank-pay/merchants/view/:merchantId"
    component={EditMerchant}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/bbank-pay/merchants/:merchantId/transactions"
    component={MerchantTransactions}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/cred-cash/financing/property/request"
    component={NewPropertyFinancing}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/cred-cash/financing/property/list"
    component={ListPropertyFinancingByUserId}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/cred-cash/financing/property/:financingId/details"
    component={FinancingDetails}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/bbank-pay/conveniences/list"
    component={ListConveniences}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/bbank-pay/conveniences/new"
    component={NewConveniences}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/bbank-pay/conveniences/:convenienceId/details"
    component={EditConveniences}
  />,
  <Route
    key={v4()}
    isPrivate
    grantAccess={['client']}
    path="/client/bbank-pay/conveniences/:convenienceId/transactions"
    component={ConvenienceTransactions}
  />,
];

export { clientRoutes };
