import styled, { css } from 'styled-components';

interface ITableButton {
  styleType: 'warning' | 'danger' | 'info' | 'success';
}

const buttonTypes = {
  success: css`
    color: #408401;
    background-color: #cbffc0;

    &:hover {
      color: #fff;
      background-color: #408401;
    }
  `,

  danger: css`
    color: #f64e60;
    background-color: #ffe2e5;

    &:hover {
      color: #fff;
      background-color: #f64e60;
    }
  `,

  info: css`
    color: #8950fc;
    background-color: #eee5ff;

    &:hover {
      color: #fff;
      background-color: #8950fc;
    }
  `,

  warning: css`
    color: #ffa800;
    background-color: #fff4de;

    &:hover {
      color: #fff;
      background-color: #ffa800;
    }
  `,
};

export const URLPath = styled.ul`
  display: flex;
  align-items: center;

  > li {
    font-size: 1.25rem;
    color: #b5b5c3;
    list-style: none;

    font-weight: 600;

    a {
      color: inherit;
      text-decoration: inherit;

      transition: color 0.2s;

      &:hover {
        color: #762772;
      }
    }

    & + li {
      margin-left: 15px;
    }

    &:nth-child(1) {
      font-size: 28px;

      color: #181c32;
    }
  }
`;

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }
`;

export const CardContent = styled.div`
  padding: 30px;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 30px;
`;

export const Prospect = styled.div`
  max-width: calc(100% / 3 - 30px);
  width: 100%;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 15px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 10%);

  border: 1px solid #ebedf3;
  border-radius: 10px;

  transition: transform 0.4s;

  /* &:hover {
    transform: scale(1.01);
  } */

  > h1 {
    font-size: 20px;
  }

  > div {
    margin: 12px 6px 6px;

    span {
      display: block;

      font-size: 16px;

      & + span {
        margin-top: 3px;
      }
    }
  }

  > span {
    font-size: 18px;

    color: #b5b5c3;
  }
`;

export const CustomButton = styled.button<ITableButton>`
  position: absolute;
  top: 5px;
  right: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  transition: background-color 0.2s, color 0.2s;

  ${props => buttonTypes[props.styleType]}
`;
