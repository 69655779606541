import * as Yup from 'yup';

interface IFormData {
  [key: string]: string;
}

export const formForPjTypesValidation = async (
  data: IFormData,
): Promise<void> => {
  const validationShape = Yup.object().shape({
    credit: Yup.object().shape({
      cnpj: Yup.string().required('Preencha este campo!'),
      name_fantasy: Yup.string().required('Preencha este campo!'),
      social_reason: Yup.string().required('Preencha este campo!'),
      email: Yup.string().required('Preencha este campo!'),
      phone: Yup.string().required('Preencha este campo!'),
      average_of_revenues: Yup.string().required('Preencha este campo!'),
      zipcode: Yup.string().required('Preencha este campo!'),
      address: Yup.string().required('Preencha este campo!'),
      complement: Yup.string().required('Preencha este campo!'),
      neighborhood: Yup.string().required('Preencha este campo!'),
      city: Yup.string().required('Preencha este campo!'),
      state: Yup.string().required('Preencha este campo!'),
      responsible_name: Yup.string().required('Preencha este campo!'),
      responsible_cpf: Yup.string().required('Preencha este campo!'),
      responsible_cellphone: Yup.string().required('Preencha este campo!'),
    }),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};

export const formForPfTypesValidation = async (
  data: IFormData,
): Promise<void> => {
  const validationShape = Yup.object().shape({
    credit: Yup.object().shape({}),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
