import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const validationShape = Yup.object().shape({
    maritalStatus: Yup.string()
      .required('Preencha este campo!')
      .oneOf(['casado', 'solteiro', 'divorciado', 'viuvo'], 'Valor inválido!'),
    weddingDate: Yup.string()
      .required('Preencha este campo!')
      .min(10, 'Insira uma data válida!')
      .max(10, 'Insira uma data válida!'),
    generalRegistry: Yup.string().required('Preencha este campo!'),
    generalRegistryExpeditionDate: Yup.string()
      .required('Preencha este campo!')
      .min(10, 'Insira uma data válida!')
      .max(10, 'Insira uma data válida!'),
    naturality: Yup.string().required('Preencha este campo!'),
    dispatchingAgency: Yup.string().required('Preencha este campo!'),
    motherName: Yup.string().required('Preencha este campo!'),
    grossIncome: Yup.number()
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    profession: Yup.string().required('Preencha este campo!'),
    companyName: Yup.string().required('Preencha este campo!'),
    companyDocument: Yup.string().required('Preencha este campo!'),
    admissionDate: Yup.string()
      .required('Preencha este campo!')
      .min(10, 'Insira uma data válida!')
      .max(10, 'Insira uma data válida!'),
    companyPhoneNumber: Yup.string().required('Preencha este campo!'),
    companyEmailAddress: Yup.string()
      .required('Preencha este campo!')
      .email('Insira um email válido!'),
    bank: Yup.string().required('Preencha este campo!'),
    userId: Yup.string().uuid().required('Preencha este campo!'),
    indicationId: Yup.string().uuid().required('Preencha este campo!'),
    propertyValue: Yup.number()
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    financingValue: Yup.number()
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    propertyZipcode: Yup.string().required('Preencha este campo!'),
    propertyState: Yup.string().required('Preencha este campo!'),
    propertyAddress: Yup.string().required('Preencha este campo!'),
    propertyAddressNumber: Yup.number()
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    propertyCity: Yup.string().required('Preencha este campo!'),
    deadline: Yup.number()
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
