import { FC, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import format from 'date-fns/format';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { Card, CardHeader, CardContent } from './styles';
import { IParams, IProspect } from './interfaces';

const SellerProspectDetails: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [prospect, setProspect] = useState<IProspect>();

  useEffect(() => {
    async function loadProspect() {
      const { data } = await api.get(`/prospects/${params.prospectId}`);

      setProspect({
        id: data.id,
        indication: data.indication,
        name: data.name,
        createdAt: data.created_at,
        ...data,
      });
    }

    const timer = setTimeout(() => {
      loadProspect();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.prospectId]);

  return (
    <>
      {!prospect ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Prospectos', prospect.name]} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes do prospecto selecionado</h1>

                <Badge type="info">
                  Propecto criado em:&nbsp;
                  {format(new Date(prospect.createdAt), 'dd/MM/yyyy')}
                </Badge>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={() => {
                    /* CODE */
                  }}
                  initialData={prospect}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Indicação</label>
                      <Input name="indication.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome</label>
                      <Input name="name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Email</label>
                      <Input name="email" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Telefone</label>
                      <Input name="phone" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Input name="state" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Produto</label>
                      <Input name="product" readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { SellerProspectDetails };
