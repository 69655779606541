import {} from 'use-context-selector';

import { useContext } from 'react';

import { IToastProps } from '@components/alerts/Toast';

import { ToastContext } from '../contexts/toast';

interface IToastContext {
  toasts: IToastProps[];
  addToast(data: Omit<IToastProps, 'id'>): void;
  removeToast(id: string): void;
}

export function useToast(): IToastContext {
  const { addToast, removeToast, toasts } = useContext(ToastContext);

  return {
    addToast,
    removeToast,
    toasts,
  };
}
