import { shade } from 'polished';
import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > button,
  > a {
    padding: 5px 10px;

    background-color: #762772;
    color: white;

    font-size: 16px;

    transition: background-color 0.2s;

    &:hover {
      background-color: ${shade(0.25, '#762772')};
    }
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const DependentsContainer = styled.div`
  margin: 20px 0;
`;

export const RemoveDependentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  color: #f64e60;

  background-color: #ffe2e5;

  border-radius: 10px;

  height: 35px;

  padding: 0 11px;

  transition: background-color 0.2s, color 0.2s;

  &:hover {
    background-color: #f64e60;

    color: #ffe2e5;
  }

  > svg {
    margin-right: 8px;
  }
`;
