import { FC } from 'react';

import { Container } from './styles';

interface IBadgeProps {
  type: 'success' | 'danger' | 'info' | 'warning';
}

const Badge: FC<IBadgeProps> = ({ type, children }) => (
  <Container type={type}>{children}</Container>
);

export { Badge };
