import {} from 'use-context-selector';
import { useContext } from 'react';

import { BankContext, IBankContext } from '../contexts/bank';

export default function useBank(): IBankContext {
  const { handleAuth, handleLogOut, handleVerifyToken } =
    useContext(BankContext);

  return {
    handleAuth,
    handleLogOut,
    handleVerifyToken,
  };
}
