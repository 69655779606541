import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { OptionTypeBase } from 'react-select';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import { FiCheckCircle } from 'react-icons/fi';

import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';
import { InputMask } from '@components/elements/Form/InputMask';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Alert } from '@components/alerts/Alert';
import { URLPath } from '@components/layouts/UrlPath';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';

import { IOption, IZipcode } from '@utils/interfaces';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { removeInputMask } from '@helpers/removeInputMask';

import {
  Card,
  CardContent,
  CardHeader,
  PropertiesForm,
  VehiclesForm,
  CreditsForm,
} from './styles';
import {
  creditModalities,
  banksOptions,
  getClientsOptions,
  statesOptions,
  maritalStatusOptions,
  propertyType,
  ownPropertyOptions,
  getIndicationsOptions,
} from './selectOptions';
import {
  creditFormValidation,
  propertyGuaranteeValidation,
  vehicleGuaranteeValidation,
} from './validations';
import { IClient, IFormData, IIndication } from './interfaces';

const CreatePJCredit: FC = () => {
  const creditFormRef = useRef<FormHandles>(null);
  const propertiesFormRef = useRef<FormHandles>(null);
  const vehiclesFormRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [clients, setClients] = useState<IClient[]>();
  const [indications, setIndications] = useState<IIndication[]>();
  const [productOptions, setProductsOptions] = useState<IOption[]>([]);
  const [creditId, setCreditId] = useState<string>();

  useEffect(() => {
    async function loadIndications() {
      const { data: franchisees } = await api.get('/franchisees');
      const { data: sellers } = await api.get('/users/sellers');

      setIndications([...franchisees, ...sellers]);
    }

    const timer = setTimeout(() => loadIndications(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleElementVisibility = useCallback((element: HTMLElement) => {
    const { style } = element;
    const { visibility } = window.getComputedStyle(element);

    if (visibility === 'visible') {
      style.visibility = 'hidden';
      style.opacity = '0';

      setTimeout(() => {
        style.display = 'none';
      }, 399);
    } else {
      style.display = 'block';

      setTimeout(() => {
        style.visibility = 'visible';
        style.opacity = '1';
      }, 50);
    }
  }, []);

  const handleCreditFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        creditFormRef.current?.setErrors({});

        await creditFormValidation(data);

        const creditForm = document.getElementById(
          'creditForm',
        ) as HTMLFormElement;

        const valuesWithoutMask = removeInputMask(creditForm);

        const parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, data);

        parsedData.status = 'pendente';
        parsedData.type = 'PJ';

        const { data: responseData } = await api.post('/credits', parsedData);

        const { modality, product } = data;

        reset();

        if (modality === 'com_garantia') {
          const vehiclesForm = document.getElementById(
            'vehiclesForm',
          ) as HTMLElement;
          const propertiesForm = document.getElementById(
            'propertiesForm',
          ) as HTMLElement;

          setCreditId(responseData.id);

          handleElementVisibility(creditForm);

          const handleNextStep = (element: HTMLElement) => {
            handleElementVisibility(element);
          };

          if (product === 'vehicle') {
            handleNextStep(vehiclesForm);
          } else {
            handleNextStep(propertiesForm);
          }

          addToast({
            title: 'Continue!',
            type: 'info',
            message:
              'Preenncha os próximos dados para completar a sua solicitação!',
          });

          return;
        }

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'Os dados foram computados com sucesso!',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const validationErrors = getValidationErrors(err);

          creditFormRef.current?.setErrors(validationErrors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'info',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [handleElementVisibility, addToast],
  );

  const handleVehiclesFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        const vehiclesForm = document.getElementById(
          'vehiclesForm',
        ) as HTMLElement;
        const creditForm = document.getElementById(
          'creditForm',
        ) as HTMLFormElement;

        vehiclesFormRef.current?.setErrors({});

        await vehicleGuaranteeValidation(data);

        const valuesWithoutMask = removeInputMask(vehiclesForm);

        const parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, data);

        parsedData.creditId = creditId as string;

        await api.post('/credit-guarantees/vehicles', parsedData);

        handleElementVisibility(vehiclesForm);
        handleElementVisibility(creditForm);

        addToast({
          title: 'Muito bom!',
          message: 'Os dados enviados foram computados com sucesso!',
          type: 'success',
        });
        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          vehiclesFormRef.current?.setErrors(errors);

          console.log(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [addToast, creditId, handleElementVisibility],
  );

  const handlePropertiesFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        propertiesFormRef.current?.setErrors({});

        const creditForm = document.getElementById(
          'creditForm',
        ) as HTMLFormElement;
        const propertiesForm = document.getElementById(
          'propertiesForm',
        ) as HTMLElement;

        await propertyGuaranteeValidation(data);

        const valuesWithoutMask = removeInputMask(propertiesForm);

        const parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, data);

        parsedData.creditId = creditId as string;

        await api.post('/credit-guarantees/properties', parsedData);

        handleElementVisibility(propertiesForm);
        handleElementVisibility(creditForm);

        addToast({
          title: 'Muito bom!',
          message: 'Os dados enviados foram computados com sucesso!',
          type: 'success',
        });
        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          propertiesFormRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [addToast, creditId, handleElementVisibility],
  );

  const indicationOptions = useMemo(() => {
    if (!indications) {
      return [];
    }

    return getIndicationsOptions(indications);
  }, [indications]);

  const clientOptions = useMemo(() => {
    if (!clients) {
      return [];
    }

    return getClientsOptions(clients);
  }, [clients]);

  const handleProductsOptions = useCallback((option: OptionTypeBase | null) => {
    if (!option) {
      return;
    }

    const employeesNumberContainer = document.getElementById(
      'employeesNumberContainer',
    ) as HTMLElement;

    const { value } = option;

    if (value === 'com_garantia') {
      employeesNumberContainer.style.display = 'none';

      setProductsOptions([
        { value: 'vehicle', label: 'Veículo' },
        { value: 'property', label: 'Imóvel' },
      ]);
    } else {
      employeesNumberContainer.style.display = 'flex';

      setProductsOptions([
        { value: 'gyro', label: 'Capital de giro' },
        { value: 'pic_machines', label: 'PIC máquinas' },
      ]);
    }
  }, []);

  const handleFindZipcode = useCallback(
    async (event: ChangeEvent<HTMLInputElement>, ref: FormHandles | null) => {
      if (!ref) {
        return;
      }

      const { target } = event;

      try {
        const { data } = await viaCepApi.get<IZipcode>(`/${target.value}/json`);

        const { bairro, uf, localidade, complemento, logradouro } = data;

        const addressInputRef = ref.getFieldRef('address');
        const stateInputRef = ref.getFieldRef('state');
        const cityInputRef = ref.getFieldRef('city');
        const complementInputRef = ref.getFieldRef('complement');
        const neighborhoodInputRef = ref.getFieldRef('neighborhood');

        const stateValue = statesOptions.find(state => state.value === uf);

        if (addressInputRef) {
          addressInputRef.value = logradouro;
        }

        if (cityInputRef) {
          cityInputRef.value = localidade;
        }

        if (complementInputRef) {
          complementInputRef.value = complemento;
          complementInputRef.focus();
        }

        if (neighborhoodInputRef) {
          neighborhoodInputRef.value = bairro;
        }

        if (stateInputRef) {
          stateInputRef.select.setValue(stateValue);
        }
      } catch {
        // CODE
      }
    },
    [],
  );

  const handleLoadClients = useCallback(
    async (option: OptionTypeBase | null) => {
      if (!option) {
        return;
      }

      const { value } = option;

      try {
        const { data } = await api.get(`/users-indicated/${value}`);

        setClients(data);
      } catch {
        // CODE
      }
    },
    [],
  );

  return (
    <>
      {!indications ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <Alert type="info">
              No caso de créditos com garantia, após o término do formulário
              abaixo, aguarde um instante para preencher o próximo (formulário),
              que servirá como os dados da garantia selecionada.
            </Alert>
          </Row>

          <Row>
            <URLPath paths={['Cred Cash', 'Novo cadastro']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Preencha os campos a seguir</h1>
              </CardHeader>

              <CardContent>
                <CreditsForm
                  id="creditForm"
                  onSubmit={handleCreditFormSubmit}
                  ref={creditFormRef}
                >
                  <FormRow separator>
                    <h1>Cliente e indicação</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Selecione um indicador</label>
                      <Select
                        name="indication"
                        options={indicationOptions}
                        onChange={handleLoadClients}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Selecione um cliente</label>
                      <Select name="user_id" options={clientOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados da empresa</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CNPJ</label>
                      <InputMask name="cnpj" mask="99.999.999/9999-99" />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome fantasia</label>
                      <Input name="name_fantasy" />
                    </InputGroup>

                    <InputGroup>
                      <label>Razão social</label>
                      <Input name="social_reason" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Email</label>
                      <Input name="email" upperCase={false} />
                    </InputGroup>

                    <InputGroup>
                      <label>Telefone</label>
                      <InputMask name="phone" mask="(99) 9999-9999" />
                    </InputGroup>

                    <InputGroup>
                      <label>Faturamento médio</label>
                      <InputCurrency name="average_of_revenues" />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Localização</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CEP</label>
                      <InputMask
                        name="zipcode"
                        mask="99999999"
                        onBlur={e =>
                          handleFindZipcode(e, creditFormRef.current)
                        }
                        noUnmask
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Select name="state" options={statesOptions} />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="city" maxLength={50} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Endereço</label>
                      <Input name="address" />
                    </InputGroup>

                    <InputGroup>
                      <label>Bairro</label>
                      <Input name="neighborhood" />
                    </InputGroup>

                    <InputGroup>
                      <label>Complemento</label>
                      <Input name="complement" />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados do responsável</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome do responsável</label>
                      <Input name="responsible_name" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CPF do responsável</label>
                      <InputMask name="responsible_cpf" mask="999.999.999-99" />
                    </InputGroup>

                    <InputGroup>
                      <label>Celular do responsável</label>
                      <InputMask
                        name="responsible_cellphone"
                        mask="(99) 99999-9999"
                      />
                    </InputGroup>
                  </FormRow>

                  {/* <FormRow separator>
                  <h1>Serviços</h1>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Selecione uma opção</label>
                    <Select name="services" options={servicesOptions} />
                  </InputGroup>
                </FormRow>

                <FormRow separator>
                  <h1>Forma de pagamento</h1>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Valor a ser pago</label>
                    <Input
                      name="payment_value"
                      readOnly
                      // defaultValue={credCashPriceObject.priceFormatted}
                    />
                  </InputGroup>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Forma de pagamento</label>
                    <Select
                      name="payment_form"
                      options={paymentFormOptions}
                      // onChange={handleChangeOfPaymentForm}
                    />
                  </InputGroup>

                  <InputGroup>
                    <label>Parcelas</label>
                    <Select
                      name="payment_portions"
                      options={paymentPortionsOptions}
                      // readonly={paymentPortionsReadOnly}
                    />
                  </InputGroup>
                </FormRow> */}

                  <FormRow separator>
                    <h1>Informações adicionais</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Tipo de crédito</label>
                      <Select
                        name="modality"
                        options={creditModalities}
                        onChange={handleProductsOptions}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor do crédito</label>
                      <InputCurrency name="amount" />
                    </InputGroup>

                    <InputGroup>
                      <label>Porcentagem SPREAD</label>
                      <Input name="contract_percentage" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Produto</label>
                      <Select name="product" options={productOptions} />
                    </InputGroup>

                    <InputGroup
                      id="employeesNumberContainer"
                      style={{ display: 'none' }}
                    >
                      <label>Número de funcionários</label>
                      <Input name="employees_number" />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button styleType="success" icon={FiCheckCircle}>
                      Enviar informações
                    </Button>
                  </FormRow>
                </CreditsForm>

                <VehiclesForm
                  id="vehiclesForm"
                  onSubmit={handleVehiclesFormSubmit}
                  ref={vehiclesFormRef}
                >
                  <FormRow separator>
                    <h1>Garantia de veículos</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data de nascimento</label>
                      <InputMask
                        mask="99/99/9999"
                        placeholder="dd/mm/aaaa"
                        name="birthday"
                        noUnmask
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Celular</label>
                      <InputMask name="cellphone" mask="(99) 99999-9999" />
                    </InputGroup>

                    <InputGroup>
                      <label>Telefone</label>
                      <InputMask name="phone" mask="(99) 9999-9999" />
                    </InputGroup>

                    <InputGroup>
                      <label>Telefone comercial</label>
                      <InputMask name="commercialPhone" mask="(99) 9999-9999" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Faturamento</label>
                      <InputCurrency name="income" />
                    </InputGroup>

                    <InputGroup>
                      <label>Registro geral (RG)</label>
                      <Input name="generalRegistry" />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado civil</label>
                      <Select
                        name="maritalStatus"
                        options={maritalStatusOptions}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome do cliente</label>
                      <Input name="clientName" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Empresa</label>
                      <Input name="company" />
                    </InputGroup>

                    <InputGroup>
                      <label>Data de admissão</label>
                      <InputMask
                        mask="99/99/9999"
                        placeholder="dd/mm/aaaa"
                        name="admissionDate"
                        noUnmask
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Função na empresa</label>
                      <Input name="employeeCompanyRole" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Tempo de conta bancária (em anos)</label>
                      <Input type="number" name="bankAccountAge" />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome da mãe</label>
                      <Input name="motherName" />
                    </InputGroup>

                    <InputGroup>
                      <label>Marca do veículo</label>
                      <Input name="vehicleBrand" />
                    </InputGroup>

                    <InputGroup>
                      <label>CPF do responsável</label>
                      <InputMask name="cpf" mask="999.999.999-99" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nacionalidade</label>
                      <Input name="nationality" />
                    </InputGroup>

                    <InputGroup>
                      <label>Profissão</label>
                      <Input name="profession" />
                    </InputGroup>

                    <InputGroup>
                      <label>Referência</label>
                      <Input name="thirdParty" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Modelo do veículo</label>
                      <Input name="vehicleModel" />
                    </InputGroup>

                    <InputGroup>
                      <label>Ano (fabricação e modelo)</label>
                      <Input name="vehicleFabricationYear" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Versão do veículo</label>
                      <Input name="vehicleVersion" />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor do veículo</label>
                      <InputCurrency name="vehicleValue" />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor do empréstimo</label>
                      <InputCurrency name="loanAmount" />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button styleType="success" icon={FiCheckCircle}>
                      Enviar informações adicionais
                    </Button>
                  </FormRow>
                </VehiclesForm>

                <PropertiesForm
                  id="propertiesForm"
                  onSubmit={handlePropertiesFormSubmit}
                  ref={propertiesFormRef}
                >
                  <FormRow separator>
                    <h1>Dados da garantia de imóveis</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Tipo do imóvel</label>
                      <Select name="propertyType" options={propertyType} />
                    </InputGroup>

                    <InputGroup>
                      <label>Logradouro</label>
                      <Input name="publicPlace" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Número</label>
                      <Input type="number" name="number" />
                    </InputGroup>

                    <InputGroup>
                      <label>Condomínio</label>
                      <Input name="condominium" />
                    </InputGroup>

                    <InputGroup>
                      <label>Unidade</label>
                      <Input name="unity" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Torre</label>
                      <Input name="tower" />
                    </InputGroup>

                    <InputGroup>
                      <label>Quantidade de vagas</label>
                      <Input name="vacancy" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CEP</label>
                      <InputMask
                        name="zipcode"
                        mask="99999-999"
                        onBlur={e =>
                          handleFindZipcode(e, creditFormRef.current)
                        }
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Bairro</label>
                      <Input name="neighborhood" />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="city" />
                    </InputGroup>

                    <InputGroup>
                      <label>Selecione um estado</label>
                      <Select name="state" options={statesOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Período de financiamento</label>
                      <Input name="financingPeriod" />
                    </InputGroup>

                    <InputGroup>
                      <label>Imóvel próprio?</label>
                      <Select name="ownProperty" options={ownPropertyOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Registro geral (RG)</label>
                      <Input name="generalRegistry" maxLength={50} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor da garantia</label>
                      <InputCurrency name="guaranteeValue" />
                    </InputGroup>

                    <InputGroup>
                      <label>Crédito pretendido</label>
                      <InputCurrency name="intendedCredit" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Relacionamento com o terceiro</label>
                      <Input name="relationship" />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome do terceiro</label>
                      <Input name="thirdPartyName" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Banco</label>
                      <Select name="bank" options={banksOptions} />
                    </InputGroup>

                    <InputGroup>
                      <label>CPF do terceiro</label>
                      <InputMask name="thirdPartyCpf" mask="999.999.999-99" />
                    </InputGroup>

                    <InputGroup>
                      <label>CPF/CNPJ</label>
                      <Input name="cpfCnpj" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Agência</label>
                      <Input name="agency" maxLength={4} />
                    </InputGroup>

                    <InputGroup>
                      <label>Conta</label>
                      <Input name="account" />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button styleType="success" icon={FiCheckCircle}>
                      Enviar informações adicionais
                    </Button>
                  </FormRow>
                </PropertiesForm>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { CreatePJCredit };
