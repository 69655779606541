import {
  FC,
  useCallback,
  useState,
  useRef,
  InputHTMLAttributes,
  useEffect,
} from 'react';
import { FiCheck } from 'react-icons/fi';
import { useField } from '@unform/core';

import { Container } from './styles';

type IInputCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
};

interface IRegisterFieldRef {
  value: string;
  checked: boolean;
}

const Checkbox: FC<IInputCheckboxProps> = ({ name, ...rest }) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { registerField, fieldName, error } = useField(name);

  const [isChecked, setIsChecked] = useState(!!rest.defaultChecked);

  useEffect(() => {
    setIsChecked(!!rest.defaultChecked);
  }, [rest.defaultChecked]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkboxRef.current,
      getValue: (ref: IRegisterFieldRef) => {
        if (!ref) {
          return '';
        }

        if (ref.checked) {
          return ref.value;
        }

        return '';
      },
      setValue: (ref: IRegisterFieldRef) => {
        if (!ref) {
          return '';
        }

        if (ref.checked) {
          return ref.value;
        }

        return '';
      },
      clearValue: (ref: IRegisterFieldRef) => {
        if (!ref) {
          return;
        }

        if (ref.checked) {
          // eslint-disable-next-line no-param-reassign
          ref.value = '';
          // eslint-disable-next-line no-param-reassign
          ref.checked = false;
          setIsChecked(false);
        }
      },
    });
  }, [registerField, fieldName]);

  const handleChecked = useCallback(() => {
    if (!checkboxRef.current) {
      return;
    }

    setIsChecked(!!checkboxRef.current.checked);
  }, []);

  return (
    <Container
      isChecked={isChecked}
      onClick={handleChecked}
      hasErrors={!!error}
    >
      <input name={name} type="checkbox" ref={checkboxRef} {...rest} />
      <FiCheck size={20} />
    </Container>
  );
};

export { Checkbox };
