import { useField } from '@unform/core';
import {
  useEffect,
  useState,
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useCallback,
  useRef,
} from 'react';
import { FiUpload } from 'react-icons/fi';

import { Container, Tooltip } from './styles';

type IInputFile = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
};

interface ITarget extends EventTarget {
  files?: FileList;
}

const InputFile: FC<IInputFile> = ({ placeholder, name, onChange }) => {
  const [isFilled, setIsFilled] = useState(false);
  const [filePlaceholder, setFilePlaceholder] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const { registerField, fieldName, error } = useField(name);

  useEffect(() => {
    setFilePlaceholder(placeholder || 'Nenhum arquivo selecionado!');

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]',
      clearValue: (ref: HTMLInputElement) => {
        // eslint-disable-next-line no-param-reassign
        ref.value = '';

        setFilePlaceholder('Nenhum arquivo selecionado!');
        setIsFilled(false);
      },
    });
  }, [placeholder, registerField, fieldName]);

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      try {
        const target = e.target as ITarget;

        if (onChange) {
          onChange(e);
        }

        if (target.files && target.files.length > 0) {
          setIsFilled(true);
          setFilePlaceholder(target.files[0].name);
        } else {
          setIsFilled(false);
          setFilePlaceholder('Nenhum arquivo selecionado!');
        }
      } catch {
        console.warn('An error ocurred on trying to insert a file!');

        if (inputRef.current) {
          setIsFilled(false);
          setFilePlaceholder('Nenhum arquivo selecionado!');
          inputRef.current.files = null;
          inputRef.current.value = '';
        }
      }
    },
    [onChange],
  );

  return (
    <>
      <Container isFilled={isFilled} isErrored={!!error}>
        <FiUpload size={20} />
        <input
          ref={inputRef}
          name={name}
          type="file"
          onChange={handleOnChange}
        />
        <span>{filePlaceholder}</span>
        {!!error && <Tooltip type="error">{error}</Tooltip>}
      </Container>
    </>
  );
};

export { InputFile };
