import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Button } from '@components/elements/Button';

import api from '@services/bbankApi';

import { Card, CardHeader, CardContent, Investment } from './styles';
import { IPercentage, IAdditionalInfo } from './interfaces';

const ListInvestmentsPercentages: FC = () => {
  const { push } = useHistory();

  const [percentages, setPercentages] = useState<IPercentage[]>();

  useEffect(() => {
    async function loadPercentages() {
      const { data } = await api.get('/investments/percentages');

      setPercentages(data);
    }

    const timer = setTimeout(() => {
      loadPercentages();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  const renderAdditionalInfo = useCallback(
    (additionalInfo: IAdditionalInfo[]) => {
      return additionalInfo.map(item => (
        <div>
          <span>{item.relation}</span>

          <strong>{item.value}</strong>
        </div>
      ));
    },
    [],
  );

  return (
    <>
      {!percentages ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={['Bbank Invest', 'Percentuais de rendimento', 'Todos']}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Percentuais de rendimento</h1>
              </CardHeader>

              <CardContent>
                {percentages.map(percentage => (
                  <Investment key={percentage.id}>
                    <header>
                      <h1>Plano de investimento</h1>

                      <strong>{percentage.name}</strong>

                      <p>Características:</p>
                    </header>

                    <main>
                      {renderAdditionalInfo(percentage.additional_info)}
                    </main>

                    <footer>
                      <Button
                        type="submit"
                        styleType="info"
                        onClick={() => {
                          push(
                            `/shared/bbank-invest/investments/new?percentageId=${percentage.id}`,
                          );
                        }}
                      >
                        Eu quero!
                      </Button>
                    </footer>
                  </Investment>
                ))}
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListInvestmentsPercentages };
