import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const consignedCompaniesFormValidation = async (
  data: IFormData,
): Promise<void> => {
  let shape = Yup.object().shape({
    companyDocument: Yup.string().required('Preencha este campo!'),
  });

  if (typeof data.companyName !== 'undefined') {
    shape = Yup.object().shape({
      companyDocument: Yup.string().required('Preencha este campo!'),
      companyName: Yup.string().required('Preencha este campo!'),
      indicationId: Yup.string()
        .uuid('Inválido!')
        .required('Preencha este campo!'),
    });
  }

  await shape.validate(data, {
    abortEarly: false,
  });
};

export const consignedFormValidation = async (
  data: IFormData,
): Promise<void> => {
  const shape = Yup.object().shape({
    bankWithCode: Yup.string().required('Preencha este campo!'),
    bankAgency: Yup.string().required('Preencha este campo!'),
    bankAccount: Yup.string().required('Preencha este campo!'),
    provenIcome: Yup.number()
      .transform((va, ov) => {
        return ov === '' ? undefined : va;
      })
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    phone: Yup.string().required('Preencha este campo!'),
    creditValue: Yup.number()
      .transform((va, ov) => {
        return ov === '' ? undefined : va;
      })
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    indicationId: Yup.string()
      .uuid('Insira um valor válido!')
      .required('Preencha este campo!'),
    clientId: Yup.string()
      .uuid('Insira um valor válido!')
      .required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
