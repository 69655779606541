import { FormHandles } from '@unform/core';
import { useRef, useCallback, FC, useState } from 'react';
import { FiMinusCircle, FiPlusCircle, FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { v4 } from 'uuid';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { URLPath } from '@components/layouts/UrlPath';
import { Input } from '@components/elements/Form/Input';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { Card, CardContent, CardHeader } from './styles';
import { periodOptions } from './selectOptions';
import { formValidation } from './validations';
import { IFormData, IItem, IItems } from './interfaces';

const NewInvestmentPercentage: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [items, setItems] = useState<IItem[]>([{ id: v4() }]);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        const itemsFromData = data.items as IItems;

        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const formData = data;

        delete formData.items;

        const {
          data: { id: investmentPercentageId },
        } = await api.post('/investments/percentages', formData);

        await api.post(`/investments/additional-info`, {
          investmentPercentageId,
          items: itemsFromData,
        });

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'As informações enviadas foram salvas com sucesso.',
        });
        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast],
  );

  const handleAddItems = useCallback(() => {
    setItems(oldState => {
      return [...oldState, { id: v4() }];
    });
  }, []);

  const handleRemoveItems = useCallback((id: string) => {
    setItems(oldState => {
      const updatedState = oldState.filter(item => item.id !== id);

      return updatedState;
    });
  }, []);

  return (
    <>
      <Row>
        <URLPath
          paths={['Bbank Invest', 'Percentuais de investimento', 'Novo']}
        />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Preencha o formulário abaixo</h1>

            <Button
              styleType="info"
              icon={FiPlusCircle}
              onClick={handleAddItems}
              disabled={items.length === 10}
            >
              Adicionar característica
            </Button>
          </CardHeader>

          <CardContent>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <FormRow>
                <InputGroup>
                  <label>Dê um nome a este percentual de rendimento!</label>
                  <Input name="name" upperCase={false} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Recorrência</label>
                  <InputCurrency
                    name="income"
                    decimalsLimit={2}
                    maxLength={10}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Período</label>
                  <Select name="period" options={periodOptions} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Valor</label>
                  <InputCurrency
                    name="value"
                    decimalsLimit={2}
                    maxLength={10}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Percentual</label>
                  <InputMask mask="9.99" name="percentage" noUnmask />
                </InputGroup>
              </FormRow>

              <FormRow separator>
                <h1>Características</h1>
              </FormRow>

              {items.map((item, index) => {
                return (
                  <FormRow key={item.id} style={{ alignItems: 'flex-end' }}>
                    <InputGroup>
                      <label>Sobre...</label>
                      <Input
                        name={`items[${index}].relation`}
                        placeholder="Valor do investimento..."
                        upperCase={false}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Recebe...</label>
                      <Input
                        name={`items[${index}].value`}
                        placeholder="R$ 100,00 por mês"
                        upperCase={false}
                      />
                    </InputGroup>

                    {index !== 0 && (
                      <Button
                        styleType="danger"
                        icon={FiMinusCircle}
                        onClick={() => handleRemoveItems(item.id)}
                      />
                    )}
                  </FormRow>
                );
              })}

              <FormRow buttonWrapper>
                <Button
                  styleType="success"
                  icon={FiSave}
                  loading={loadingRequest}
                >
                  Salvar informações
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewInvestmentPercentage };
