import {
  FC,
  FocusEvent,
  TextareaHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { Tooltip } from '@components/elements/Tooltip';

import { Container } from './styles';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
}

const Textarea: FC<ITextAreaProps> = ({
  children,
  name,
  disabled,
  onFocus,
  onBlur,
  ...rest
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFosused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { registerField, fieldName, error, defaultValue } = useField(name);

  const handleOnFocus = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      setIsFosused(true);

      if (onFocus) {
        onFocus(e);
      }
    },
    [onFocus],
  );

  const handleOnBlur = useCallback(
    (e: FocusEvent<HTMLTextAreaElement>) => {
      if (onBlur) {
        onBlur(e);
      }

      setIsFilled(!!textAreaRef.current?.value);
      setIsFosused(false);
    },
    [onBlur],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [registerField, fieldName]);

  return (
    <>
      <Container
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        isFilled={isFilled}
        isFocused={isFocused}
        hasErrors={!!error}
        ref={textAreaRef}
        isDisabled={disabled}
        defaultValue={defaultValue}
        {...rest}
      >
        {children}
      </Container>
      {!!error && <Tooltip type="error">{error}</Tooltip>}
    </>
  );
};

export { Textarea };
