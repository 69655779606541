import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiMinusCircle, FiPlusCircle, FiSave } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { v4 } from 'uuid';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputFile } from '@components/elements/Form/InputFile';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { IFile, IFormData, IParams } from './interfaces';

const AddMarketingFiles: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();

  const [files, setFiles] = useState<IFile[]>([]);
  const [fileFormRows, setFileFormRows] = useState<JSX.Element[]>([]);

  const handleFileInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, fileKey?: string) => {
      if (!e.target.files) {
        addToast({
          title: 'Ação inválida!',
          message: 'Selecione um arquivo!',
          type: 'error',
        });
        e.target.value = '';
        throw new Error();
      }

      const size = e.target.files[0].size * 0.000001;

      if (size > 5) {
        addToast({
          title: 'Arquivo muito grande!',
          message: 'O arquivo deve conter até no máximo 5MB!',
          type: 'error',
        });
        e.target.value = '';
        throw new Error();
      }

      const file = {
        key: fileKey || '',
        file: e.target.files[0],
      };

      setFiles(oldState => [...oldState, file]);
    },
    [addToast],
  );

  const handleDeleteFileFormRow = useCallback(
    (formRowKey: string, fileKey: string) => {
      setFileFormRows(oldState => {
        const updatedState = oldState.filter(state => state.key !== formRowKey);

        return updatedState;
      });
      setFiles(oldState => {
        const updatedState = oldState.filter(state => state.key !== fileKey);

        return updatedState;
      });
    },
    [],
  );

  const getFormRowInputFile = useCallback(() => {
    const formRowKey = v4();
    const fileKey = v4();

    return (
      <FormRow key={formRowKey}>
        <InputGroup>
          <label>Selecione um arquivo</label>
          <InputFile
            name={`file[${fileFormRows.length + 1}]`}
            onChange={e => handleFileInputChange(e, fileKey)}
          />
        </InputGroup>

        <InputGroup>
          <label>Uma breve descrição do arquivo</label>
          <Input
            name={`description[${fileFormRows.length + 1}]`}
            maxLength={255}
            upperCase={false}
          />
        </InputGroup>

        <Button
          styleType="danger"
          style={{ alignSelf: 'flex-end' }}
          onClick={() => handleDeleteFileFormRow(formRowKey, fileKey)}
          icon={FiMinusCircle}
        />
      </FormRow>
    );
  }, [handleDeleteFileFormRow, handleFileInputChange, fileFormRows]);

  const handleAddFileFormRows = useCallback(() => {
    const fileFormRow = getFormRowInputFile();

    setFileFormRows(oldState => {
      const updatedState = [...oldState, fileFormRow];

      return updatedState;
    });
  }, [getFormRowInputFile]);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      const parsedDescriptions = data.description.filter(item => item !== null);

      if (parsedDescriptions.length !== files.length) {
        addToast({
          title: 'Alguma coisa não está certa!',
          type: 'error',
          message:
            'Verifique se os seus arquivos possuem uma descrição ou vice-versa!',
        });
        return;
      }

      const formData = new FormData();

      files.forEach((file, index) => {
        const { file: formFile } = file;

        formData.append('files', formFile);
        formData.append(`description[${index}]`, parsedDescriptions[index]);
      });

      formData.append('marketingCategoryId', params.marketingCategoryId);

      await api.post(`/marketing/files`, formData);

      setFiles([]);
      reset();
      addToast({
        title: 'Sucesso!',
        type: 'success',
        message: 'Seus arquivos foram enviados e guardados com sucesso!',
      });
    },
    [files, addToast, params.marketingCategoryId],
  );

  return (
    <>
      <Row>
        <URLPath>
          <li>Marketing</li>
          <li>{'>'}</li>
          <li>Arquivos</li>
          <li>{'>'}</li>
          <li>Categoria</li>
          <li>{'>'}</li>
          <li>Novo</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Novo arquivo</h1>

            <Button
              styleType="info"
              icon={FiPlusCircle}
              onClick={handleAddFileFormRows}
            >
              Adicionar novo arquivo
            </Button>
          </CardHeader>

          <CardContent>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <FormRow>
                <InputGroup>
                  <label>Selecione um arquivo</label>
                  <InputFile name="file[0]" onChange={handleFileInputChange} />
                </InputGroup>

                <InputGroup>
                  <label>Uma breve descrição do arquivo</label>
                  <Input
                    name="description[0]"
                    maxLength={255}
                    upperCase={false}
                  />
                </InputGroup>
              </FormRow>

              {fileFormRows.map(item => item)}

              <FormRow buttonWrapper>
                <Button type="submit" styleType="info" icon={FiSave}>
                  Salvar arquivos
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { AddMarketingFiles };
