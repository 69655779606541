import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { FiDownload, FiMoreHorizontal, FiPlusCircle } from 'react-icons/fi';
import exportFromJSON from 'export-from-json';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Dropdown } from '@components/elements/Dropdown';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { Card, CardHeader, CardContent } from './styles';
import { IInvestment } from './interfaces';

const ListInvestments: FC = () => {
  const { push } = useHistory();
  const { user } = useAuth();

  const [investments, setInvestments] = useState<IInvestment[]>();

  useEffect(() => {
    async function loadInvestments() {
      const { data } = await api.get<any[]>('/investments/client', {
        params: {
          relations: ['investment_percentage'],
        },
      });

      const parsedData = data.map(item => {
        return parseObjectPropertiesToCamelCase<IInvestment>(item);
      });

      setInvestments(parsedData);
    }

    const timer = setTimeout(() => loadInvestments(), 1500);

    return () => clearInterval(timer);
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do cliente',
        selector: 'search',
      },
      {
        name: 'Duração',
        selector: 'duration',
      },
      {
        name: 'Percentual',
        selector: 'percentage',
      },
      {
        name: 'Valor',
        selector: 'value',
      },
      {
        name: 'Data início',
        selector: 'dateInit',
      },
      {
        name: 'Data fim',
        selector: 'dateEnd',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!investments) {
      return [];
    }

    const data = investments.map(investment => ({
      id: investment.id,
      search: user.name,
      duration: `${investment.investmentPercentage.period} meses`,
      percentage: `${investment.investmentPercentage.percentage}%`,
      value: new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        style: 'currency',
      }).format(investment.investmentPercentage.value),
      dateInit: format(new Date(investment.dateInit), 'dd/MM/yyyy'),
      dateEnd: format(new Date(investment.dateEnd), 'dd/MM/yyyy'),
    }));

    return data;
  }, [investments, user]);

  const handlePushToAddPage = useCallback(() => {
    push('/shared/bbank-invest/investments/new');
  }, [push]);

  const handleExportData = useCallback(() => {
    if (!investments) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const investmentsLength = investments.length;

    const worksheetData = Array.from(Array(investmentsLength).keys()).map(
      item => {
        const investment = investments[item];

        const worksheetLines = {
          Recorrência: numberFormat(investment.investmentPercentage.income),
          Porcentagem: `${investment.investmentPercentage.percentage}%`,
          Período: `${investment.investmentPercentage.period} meses`,
          Valor: numberFormat(investment.investmentPercentage.value),
          'Data de início': format(new Date(investment.dateInit), 'dd/MM/yyyy'),
          'Data final': format(new Date(investment.dateEnd), 'dd/MM/yyyy'),
          'Nome do cliente': investment.client.name,
        };

        return worksheetLines;
      },
    );

    exportFromJSON({
      data: worksheetData,
      exportType: 'xls',
      fileName: 'dados_investimentos',
    });
  }, [investments]);

  return (
    <>
      {!investments ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Bbank Invest', 'Investimentos', 'Todos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Ver todos os investimentos feitos</h1>

                <Dropdown
                  styles={{ togglerBackground: '#c8c6c0', textColor: '#fff' }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button type="button" onClick={handlePushToAddPage}>
                      <FiPlusCircle />
                      Novo investimento
                    </button>
                  </li>

                  <li>
                    <button type="button" onClick={handleExportData}>
                      <FiDownload />
                      Exportar dados
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListInvestments };
