import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    prospectId: Yup.string()
      .uuid('Opção inválida!')
      .required('Preencha este campo!'),
    questionFive: Yup.number()
      .typeError('Opção inválida!')
      .required('Preencha este campo!'),
    questionFour: Yup.number()
      .typeError('Opção inválida!')
      .required('Preencha este campo!'),
    questionOne: Yup.number()
      .typeError('Opção inválida!')
      .required('Preencha este campo!'),
    questionSix: Yup.number()
      .typeError('Opção inválida!')
      .required('Preencha este campo!'),
    questionTen: Yup.number()
      .typeError('Opção inválida!')
      .required('Preencha este campo!'),
    questionTwo: Yup.number()
      .typeError('Opção inválida!')
      .required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
