import { useRouteMatch } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import { FiClock } from 'react-icons/fi';
import { format } from 'date-fns';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { Carousel } from '@components/layouts/Carousel';

import api from '@services/bbankApi';

import { INews, IParams } from './interfaces';
import { Container, Content, NewsContent } from './styles';

export const ViewNews: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [news, setNews] = useState<INews>();

  useEffect(() => {
    async function loadNews() {
      const { data } = await api.get(`/news/${params.newsId}`);

      setNews(data);
    }

    const timer = setTimeout(() => loadNews(), 1500);

    return () => clearInterval(timer);
  }, [params.newsId]);

  return (
    <>
      {!news ? (
        <LoadingPage />
      ) : (
        <>
          <Container>
            <Content>
              <div>
                <Carousel imagesUrls={news.image_urls} />
              </div>

              <h1>{news.title}</h1>

              <h2>{news.description}</h2>

              <div>
                <FiClock size={18} color="#ccc" />

                <span>{format(new Date(news.created_at), 'dd/MM/yyyy')}</span>
              </div>

              <NewsContent>
                <p>{news.content}</p>
              </NewsContent>
            </Content>
          </Container>
        </>
      )}
    </>
  );
};
