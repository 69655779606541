import styled, { keyframes } from 'styled-components';

import backgroundLogin from '@assets/img/login-background.jpg';
import contentLogin from '@assets/img/content-login-background.jpg';

const fromLeft = keyframes`
  0% {
    transform: translateX(300px);
  }

  100% {
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;

  overflow: hidden;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 700px;
  width: 100%;

  height: 100vh;

  background-image: url(${contentLogin});
  background-position: center;
  background-repeat: no-repeat;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    max-width: 500px;
    width: 100%;
    height: 80%;

    animation: 1s ${fromLeft};

    form {
      max-width: 400px;
      width: 100%;

      > h1 {
        font-size: 27px;
        color: #7e8299;

        text-align: center;

        margin-bottom: 24px;
      }

      > div {
        height: 60px;

        max-width: 400px;
        width: 100%;

        background-color: white;

        svg {
          width: 26px;
          height: 26px;
        }

        input {
          background-color: white;
        }
      }

      button {
        height: 60px;
        width: 100%;

        background-color: #c8c6c0;
        color: white;

        margin-top: 16px;

        font-size: 18px;
      }

      > a {
        font-size: 1.3rem;
        color: #7e8299;
        margin-top: 24px;
        text-decoration: inherit;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: color 0.2s;

        &:hover {
          color: #c8c6c0;
        }

        svg {
          margin-right: 16px;
        }
      }
    }

    > ul {
      display: flex;
      align-items: center;
      justify-content: center;

      color: #abacb8;

      list-style: none;

      font-size: 1.3rem;

      li:nth-child(even) {
        padding: 0 5px;
      }
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  justify-self: flex-end;
`;

export const Background = styled.div`
  flex: 1;

  background-image: url(${backgroundLogin});
  background-position: 70% center;
  background-size: cover;
  background-repeat: no-repeat;
`;
