import { FC } from 'react';

import {
  Card as CardComponent,
  CardContent as CardContentComponent,
  CardHeader as CardHeaderComponent,
} from './styles';
import { ICard, ICardContent, ICardHeader } from './interfaces';

export const Card: FC<ICard> = ({ children }) => {
  return <CardComponent>{children}</CardComponent>;
};

export const CardHeader: FC<ICardHeader> = ({ children }) => {
  return <CardHeaderComponent>{children}</CardHeaderComponent>;
};

export const CardContent: FC<ICardContent> = ({ children }) => {
  return <CardContentComponent>{children}</CardContentComponent>;
};
