import { states } from '@helpers/getAllStates';
import { banks } from '@helpers/getBanks';

interface IOption {
  value: string;
  label: string;
}

interface IFranchisee {
  id: string;
  name: string;
}

interface IClient {
  id: string;
  name: string;
}

export const statusOpions: IOption[] = [
  { label: 'Ativo', value: 'active' },
  { label: 'Pendente', value: 'pending' },
  { label: 'Parado', value: 'stopped' },
];

export const PaymentOnAccount = [
  { label: 'Sim', value: 'true' },
  { label: 'Não', value: 'false' },
];

export const ResponsibleRole = [
  { value: 'administrator', label: 'Administrador' },
  { value: 'attorney', label: 'Procurador' },
];

export const TypeOfCompany = [
  { value: 'headquarters', label: 'Matriz' },
  { value: 'branch', label: 'Filial' },
];

export const getFranchiseesOptions = (data: IFranchisee[]): IOption[] => {
  const options = data.map(item => ({ value: item.id, label: item.name }));

  return options;
};

export const getFranchiseeClientsOptions = (data: IClient[]): IOption[] => {
  const options = data.map(item => ({ value: item.id, label: item.name }));

  return options;
};

const getStatesOptions = (): IOption[] => {
  const options = Object.entries(states).map(([code, state]) => {
    return {
      value: code,
      label: state,
    };
  });

  return options;
};

export const statesOptions = getStatesOptions();

export const banksOptions = banks.map(bank => {
  const bankName = bank.split(' - ')[1];

  return {
    value: bank,
    label: bankName,
  };
});
