import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const validationShape = Yup.object().shape({
    status: Yup.string()
      .oneOf(['ativo', 'pendente', 'parado'], 'Valor inválido!')
      .required('Preencha este campo!'),
    infinity_id: Yup.string(),
    complement: Yup.string(),
    address_number: Yup.string(),
    selling_type: Yup.string()
      .oneOf(
        ['sells_with_cashback', 'sells_without_cashback'],
        'Valor inválido!',
      )
      .required('Preencha este campo!'),
    payment_form: Yup.string()
      .oneOf(['credit_card', 'debit', 'billet'], 'Valor inválido!')
      .required('Preencha este campo!'),
    cashback_percentage: Yup.number()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .min(10, 'O minimo é 10')
      .max(99, 'O máximo é 99')
      .when({
        is: (_: string) => data.sellingType === 'sells_with_cashback',
        then: Yup.number().typeError('Insira um número válido').required(),
      }),
    payment_value: Yup.string().required('Preencha este campo!'),
    payment_portions: Yup.number()
      .typeError('Insira um número válido')
      .min(1, 'O mínimo é 1')
      .max(12, 'O máximo é 12')
      .required('Preencha este campo!'),
    business_method: Yup.string()
      .oneOf(['accession', 'sells_a930', 'sells_a50'])
      .required('Preencha este campo!'),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
