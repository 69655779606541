import { states } from '@helpers/getAllStates';
import { banks } from '@helpers/getBanks';

export const maritalStatusOptions = [
  { label: 'Casado', value: 'casado' },
  { label: 'Solteiro', value: 'solteiro' },
  { label: 'Divorciado', value: 'divorciado' },
  { label: 'Viuvo', value: 'viuvo' },
];

export const banksOptions = banks.map(bank => {
  const bankSplitted = bank.split(' - ');

  return {
    label: bankSplitted[1],
    value: bank,
  };
});

const getDeadlineOptions = () => {
  const arr112 = Array.from(Array(112).keys());
  let startCount = 48;

  const options = arr112.map(_ => {
    const option = { value: startCount, label: `${startCount} meses` };

    startCount += 1;

    return option;
  });

  options.push({ label: '160 meses', value: 160 });

  return options;
};

export const deadlineOptions = getDeadlineOptions();

export const statesOptions = Object.entries(states).map(([key, value]) => ({
  label: value,
  value: key,
}));
