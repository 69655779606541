import { v4 } from 'uuid';

import { FranchiseeAllCredits } from '@pages/private/franchisees/shared/CredCash/All';
import { FranchiseeCreatePFCredit } from '@pages/private/franchisees/shared/CredCash/Create/PF';
import { FranchiseeCreatePJCredit } from '@pages/private/franchisees/shared/CredCash/Create/PJ';
import { ListFranchiseePendingCredits } from '@pages/private/franchisees/shared/CredCash/ListPending';
import { NewFranchiseesTelemedicine } from '@pages/private/franchisees/shared/BbankConnect/Telemedicine/New';
import { NewClient } from '@pages/private/franchisees/business/Clients/New';
import { AllBusinessClients } from '@pages/private/franchisees/business/Clients/All';
import { EditBusinessClient } from '@pages/private/franchisees/business/Clients/Edit';
import { AllMasterClients } from '@pages/private/franchisees/master/Clients/All';
import { EditMasterClient } from '@pages/private/franchisees/master/Clients/Edit';
import { NewMasterClient } from '@pages/private/franchisees/master/Clients/New';
import { FranchiseesAllTelemedicines } from '@pages/private/franchisees/shared/BbankConnect/Telemedicine/All';
import { FranchiseesTelemedicineDetails } from '@pages/private/franchisees/shared/BbankConnect/Telemedicine/Details';
import { FranchiseesCredCashFiles } from '@pages/private/franchisees/shared/Upload/CredCash/Files';
import { CredCashUploadFiles } from '@pages/private/franchisees/shared/Upload/CredCash';
import { FranchiseUpdateCredit } from '@pages/private/franchisees/shared/CredCash/Update';
import { NewMerchant } from '@pages/private/franchisees/shared/BbankPay/Merchants/New';
import { ListMerchants } from '@pages/private/franchisees/shared/BbankPay/Merchants/List';
import { EditMerchant } from '@pages/private/franchisees/shared/BbankPay/Merchants/Edit';
import { MerchantTransactions } from '@pages/private/franchisees/shared/BbankPay/Merchants/Transactions';
import { LinksPerSite } from '@pages/private/franchisees/shared/Links/Sites';
import { ListCategoriesForFiles } from '@pages/private/franchisees/shared/Marketing/Files/ListCategoriesForFiles';
import { ListFilesByCategoryId } from '@pages/private/franchisees/shared/Marketing/Files/ListFilesByCategoryId';
import { ListSellers } from '@pages/private/franchisees/shared/Sellers/List';
import { ListSellerIndications } from '@pages/private/franchisees/shared/Sellers/Indications';
import { ListSellersForResearchs } from '@pages/private/franchisees/shared/BbankConnect/Researchs/Sellers';
import { ListResearchsBySeller } from '@pages/private/franchisees/shared/BbankConnect/Researchs/ListBySeller';
import { TelemedicineResearchDetails } from '@pages/private/franchisees/shared/BbankConnect/Researchs/Details';
import { NewConveniences } from '@pages/private/franchisees/shared/BbankPay/Conveniences/New';
import { ListConveniences } from '@pages/private/franchisees/shared/BbankPay/Conveniences/List';
import { EditConveniences } from '@pages/private/franchisees/shared/BbankPay/Conveniences/Edit';
import { ConvenienceTransactions } from '@pages/private/franchisees/shared/BbankPay/Conveniences/Transactions';
import { ListProspects } from '@pages/private/franchisees/shared/Prospects/List';
import { ProspectDetails } from '@pages/private/franchisees/shared/Prospects/Details';
import { CreateSellers } from '@pages/private/franchisees/shared/Sellers/New';
import { ListResearchsFromFranchisee } from '@pages/private/franchisees/shared/BbankConnect/Researchs/List';
import { ListConveniencesByIndicationId } from '@pages/private/franchisees/shared/BbankPay/Conveniences/ListByIndicationId';
import { ListMerchantsByIndicationId } from '@pages/private/franchisees/shared/BbankPay/Merchants/ListByIndicationId';
import { ListSellersToCheckIndications } from '@pages/private/franchisees/shared/BbankPay/Sellers';
import { NewPropertyFinancing } from '@pages/private/franchisees/shared/CredCash/Financing/Property/New';
import { ListPropertyFinancingByIndicationId } from '@pages/private/franchisees/shared/CredCash/Financing/Property/List';
import { FinancingDetails } from '@pages/private/franchisees/shared/CredCash/Financing/Property/Details';
import { NewConsignedProspect } from '@pages/private/franchisees/shared/CredCash/Consigned/Prospects/New';
import { ListConsignedProspect } from '@pages/private/franchisees/shared/CredCash/Consigned/Prospects/List';
import { ConsignedProspectDetails } from '@pages/private/franchisees/shared/CredCash/Consigned/Prospects/Details';
import { ConsignedDetails } from '@pages/private/franchisees/shared/CredCash/Consigned/Credits/Details';
import { ListConsigned } from '@pages/private/franchisees/shared/CredCash/Consigned/Credits/List';
import { NewConsigned } from '@pages/private/franchisees/shared/CredCash/Consigned/Credits/New';
import { ListProspectsByFranchiseIndication } from '@pages/private/franchisees/shared/Sellers/Prospects';
import { NewECommerce } from '@pages/private/franchisees/shared/ECommerce/New';
import { NewBillet } from '@pages/private/franchisees/shared/Bwallet/Billets/New';
import { ListBilletsPayed } from '@pages/private/franchisees/shared/Bwallet/Billets/ListPayed';
import { FranchiseBilletsEmitted } from '@pages/private/franchisees/shared/Bwallet/Billets/ListEmitted';
import { BilletDetails } from '@pages/private/franchisees/shared/Bwallet/Billets/Details';

import { Route } from './Route';

const franchiseesRoutes = [
  <Route
    key={v4()}
    path="/franchisees/cred-cash/new/pf"
    isPrivate
    grantAccess={['business', 'master']}
    component={FranchiseeCreatePFCredit}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/new/pj"
    isPrivate
    grantAccess={['business', 'master']}
    component={FranchiseeCreatePJCredit}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/all"
    isPrivate
    grantAccess={['business', 'master']}
    component={FranchiseeAllCredits}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/pending"
    isPrivate
    grantAccess={['business', 'master']}
    component={ListFranchiseePendingCredits}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/view/:creditId"
    isPrivate
    grantAccess={['business', 'master']}
    component={FranchiseUpdateCredit}
  />,

  <Route
    key={v4()}
    path="/franchisees/bbank-connect/telemedicine/new"
    isPrivate
    grantAccess={['business', 'master']}
    component={NewFranchiseesTelemedicine}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-connect/telemedicine/all"
    isPrivate
    grantAccess={['business', 'master']}
    component={FranchiseesAllTelemedicines}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-connect/telemedicine/details/:telemedicineId"
    isPrivate
    grantAccess={['business', 'master']}
    component={FranchiseesTelemedicineDetails}
  />,

  <Route
    key={v4()}
    path="/business/clients/:clientId/edit"
    isPrivate
    grantAccess={['business']}
    component={EditBusinessClient}
  />,

  <Route
    key={v4()}
    path="/franchisees/uploads/cred-cash/"
    isPrivate
    grantAccess={['business', 'master']}
    exact
    component={CredCashUploadFiles}
  />,

  <Route
    key={v4()}
    path="/franchisees/uploads/cred-cash/:creditId"
    isPrivate
    grantAccess={['business', 'master']}
    component={FranchiseesCredCashFiles}
  />,

  <Route
    key={v4()}
    path="/franchisees/bbank-connect/telemedicine/new"
    isPrivate
    grantAccess={['business', 'master']}
    component={NewFranchiseesTelemedicine}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-connect/telemedicine/all"
    isPrivate
    grantAccess={['business', 'master']}
    component={FranchiseesAllTelemedicines}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-connect/telemedicine/details/:telemedicineId"
    isPrivate
    grantAccess={['business', 'master']}
    component={FranchiseesTelemedicineDetails}
  />,
  <Route
    key={v4()}
    path="/business/clients/new"
    isPrivate
    grantAccess={['business']}
    component={NewClient}
  />,
  <Route
    key={v4()}
    path="/business/clients/all"
    isPrivate
    grantAccess={['business']}
    component={AllBusinessClients}
  />,
  <Route
    key={v4()}
    path="/master/clients/new"
    isPrivate
    grantAccess={['master']}
    component={NewMasterClient}
  />,
  <Route
    key={v4()}
    path="/master/clients/all"
    isPrivate
    grantAccess={['master']}
    component={AllMasterClients}
  />,
  <Route
    key={v4()}
    path="/master/clients/:clientId/edit"
    isPrivate
    grantAccess={['master']}
    component={EditMasterClient}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-pay/merchants/new"
    isPrivate
    grantAccess={['master', 'business']}
    component={NewMerchant}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-pay/merchants/list"
    isPrivate
    grantAccess={['master', 'business']}
    component={ListMerchants}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-pay/merchants/view/:merchantId"
    isPrivate
    grantAccess={['master', 'business']}
    component={EditMerchant}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-pay/merchants/:merchantId/transactions"
    isPrivate
    grantAccess={['master', 'business']}
    component={MerchantTransactions}
  />,
  <Route
    key={v4()}
    path="/franchisees/links/sites"
    isPrivate
    grantAccess={['master', 'business']}
    component={LinksPerSite}
  />,
  <Route
    key={v4()}
    path="/franchisees/marketing/files/categories"
    isPrivate
    grantAccess={['master', 'business']}
    component={ListCategoriesForFiles}
  />,
  <Route
    key={v4()}
    path="/franchisees/marketing/categories/:marketingCategoryId/files"
    isPrivate
    grantAccess={['master', 'business']}
    component={ListFilesByCategoryId}
  />,
  <Route
    key={v4()}
    path="/franchisees/sellers/all"
    component={ListSellers}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/sellers/:sellerId/indications/"
    component={ListSellerIndications}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-connect/researchs/sellers"
    component={ListSellersForResearchs}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-connect/sellers/:sellerId/researchs"
    component={ListResearchsBySeller}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-connect/researchs/:telemedicineScoreId"
    component={TelemedicineResearchDetails}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-pay/conveniences/new"
    component={NewConveniences}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-pay/conveniences/list"
    component={ListConveniences}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-pay/conveniences/view/:convenienceId"
    component={EditConveniences}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-pay/conveniences/:convenienceId/transactions"
    component={ConvenienceTransactions}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/prospects/list"
    component={ListProspects}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/prospects/:prospectId/details"
    component={ProspectDetails}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/sellers/new"
    component={CreateSellers}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/bbank-connect/researchs"
    component={ListResearchsFromFranchisee}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/frachisees/bbank-pay/merchants/indication/:indicationId/list"
    component={ListMerchantsByIndicationId}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/frachisees/bbank-pay/conveniences/indication/:indicationId/list"
    component={ListConveniencesByIndicationId}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/frachisees/bbank-pay/sellers"
    component={ListSellersToCheckIndications}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/financing/property/new"
    component={NewPropertyFinancing}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/financing/property/list"
    component={ListPropertyFinancingByIndicationId}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/financing/property/:financingId/details"
    component={FinancingDetails}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/consigned/prospects/new"
    component={NewConsignedProspect}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/consigned/prospects/list"
    component={ListConsignedProspect}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/consigned/prospects/:consignedId/details"
    component={ConsignedProspectDetails}
    isPrivate
    grantAccess={['master', 'business']}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/consigned/new"
    isPrivate
    grantAccess={['master', 'business']}
    component={NewConsigned}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/consigned/list"
    isPrivate
    grantAccess={['master', 'business']}
    component={ListConsigned}
  />,
  <Route
    key={v4()}
    path="/franchisees/cred-cash/consigned/:consignedId/details"
    isPrivate
    grantAccess={['master', 'business']}
    component={ConsignedDetails}
  />,
  <Route
    key={v4()}
    path="/franchisees/prospects/indications/:indicationId/list"
    isPrivate
    grantAccess={['master', 'business']}
    component={ListProspectsByFranchiseIndication}
  />,
  <Route
    key={v4()}
    path="/franchisees/ecommerce/new"
    isPrivate
    grantAccess={['master', 'business']}
    component={NewECommerce}
  />,
  <Route
    key={v4()}
    path="/franchisees/bwallet/billets/new"
    isPrivate
    grantAccess={['master', 'business']}
    component={NewBillet}
  />,
  <Route
    key={v4()}
    path="/franchisees/bwallet/billets/payed"
    isPrivate
    grantAccess={['master', 'business']}
    component={ListBilletsPayed}
  />,
  <Route
    key={v4()}
    path="/franchisees/bwallet/billets/emitted"
    isPrivate
    grantAccess={['master', 'business']}
    component={FranchiseBilletsEmitted}
  />,
  <Route
    key={v4()}
    path="/franchisees/bwallet/billets/:billetId/details"
    isPrivate
    grantAccess={['master', 'business']}
    component={BilletDetails}
  />,
];

export { franchiseesRoutes };
