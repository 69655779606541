import {
  FC,
  useCallback,
  useRef,
  useState,
  FocusEvent,
  KeyboardEvent,
} from 'react';
import { FormHandles } from '@unform/core';
import {
  FiFlag,
  FiHash,
  FiHome,
  FiLock,
  FiMail,
  FiMap,
  FiMapPin,
  FiPhone,
  FiSmartphone,
  FiTag,
  FiUser,
  FiSave,
  FiKey,
  FiLink2,
  FiGift,
} from 'react-icons/fi';
import * as Yup from 'yup';
import { OptionTypeBase } from 'react-select';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import viaCepApi from '@services/viaCepApi';
import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { removeEmptyFields } from '@helpers/removeEmptyFields';
import { removeInputMask } from '@helpers/removeInputMask';

import { Card, CardHeader, CardContent } from './styles';
import { personTypesOptons, statesOption } from './selectOptions';
import { formValidation } from './validations';

interface IInputProps extends HTMLElement {
  value?: string;
}

interface IFormData {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  zipcode: string;
  address: string;
  state: string;
  city: string;
  phone: string;
  cellphone: string;
  person_type: string;
  document: string;
  role: string;
  indication: string;
  birthdate: string;
}

interface IParsedData {
  [key: string]: string;
}

interface ITarget extends EventTarget {
  value: string;
}

type IDocumentState = '999.999.999-999' | '99.999.999/9999-99';

const NewMasterClient: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { user } = useAuth();
  const { addToast } = useToast();

  const [cpfCnpjMask, setCpfCnpjMask] = useState<IDocumentState>(
    '999.999.999-999',
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);

  const handleDocumentInputMask = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as ITarget;

      if (target.value.length >= 15) {
        setCpfCnpjMask('99.999.999/9999-99');
      } else {
        setCpfCnpjMask('999.999.999-999');
      }
    },
    [],
  );

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      const formData: IParsedData = {
        ...data,
      };

      setButtonLoading(true);

      try {
        formRef.current?.setErrors({});

        await formValidation(formData);

        const parsedValues = removeEmptyFields(formData);
        const parsedValuesFromInputMask = removeInputMask();

        parsedValuesFromInputMask.forEach(item => {
          parsedValues[item.name] = item.value;
        });

        parsedValues.indication = user.id;

        delete parsedValues.confirmPassword;

        await api.post('/users', parsedValues);

        addToast({
          title: 'Dados cadastrados com sucesso!',
          type: 'success',
        });

        reset();
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const { message } = getClientErrors(err.response);

        addToast({
          title: `Algum erro aconteceu!`,
          type: 'error',
          message,
        });
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast, user],
  );

  const handleFindZipcode = useCallback(
    async (e: FocusEvent<HTMLElement>) => {
      setButtonLoading(true);

      try {
        const addressInput = document.getElementById('address') as IInputProps;
        const cityInput = document.getElementById('city') as IInputProps;
        const passwordInput = document.getElementById(
          'password',
        ) as IInputProps;
        const { target } = e as { target: { value?: string } };

        const cep = target.value?.replace('-', '');

        const { data } = await viaCepApi.get(`${cep}/json`);

        if (data.erro) {
          addToast({
            title: 'CEP inválido!',
            type: 'info',
          });

          return;
        }

        addressInput.value = data.logradouro.toUpperCase();
        cityInput.value = data.localidade.toUpperCase();
        passwordInput.focus();

        addToast({
          title: 'Endereço preenchido!',
          type: 'success',
        });
      } catch (err) {
        addToast({
          title: 'CEP não encontrado!',
          type: 'info',
        });
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast],
  );

  const handleNicknameField = useCallback((event: OptionTypeBase | null) => {
    if (!event) {
      return;
    }

    setIsBusiness(event.value === 'business');
  }, []);

  return (
    <>
      <Row>
        <URLPath paths={['Usuários', 'Novo']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Novo</h1>
          </CardHeader>

          <CardContent>
            <Form ref={formRef} onSubmit={handleFormSubmit} noValidate>
              <FormRow>
                <Select
                  name="role"
                  placeholder="Cliente ou franqueado business?"
                  icon={FiKey}
                  options={[
                    { value: 'client', label: 'Cliente' },
                    { value: 'business', label: 'Business' },
                  ]}
                  onChange={handleNicknameField}
                />
              </FormRow>

              <FormRow separator>
                <h1>Dados</h1>
              </FormRow>

              <FormRow>
                <Input name="name" placeholder="Nome" icon={FiUser} />

                <Input
                  type="email"
                  name="email"
                  placeholder="Email"
                  icon={FiMail}
                />

                <InputMask
                  name="birthdate"
                  mask="99/99/9999"
                  placeholder="Data de aniversário"
                  icon={FiGift}
                  noUnmask
                />
              </FormRow>

              <FormRow>
                <InputMask
                  name="phone"
                  mask="(99) 9999-9999"
                  placeholder="Telefone"
                  icon={FiPhone}
                />

                <InputMask
                  name="cellphone"
                  mask="(99) 9 9999-9999"
                  placeholder="Celular"
                  icon={FiSmartphone}
                />

                {isBusiness && (
                  <Input
                    name="nickname"
                    placeholder="Apelido"
                    upperCase={false}
                    icon={FiLink2}
                  />
                )}
              </FormRow>

              <FormRow separator>
                <h1>Endereço</h1>
              </FormRow>

              <FormRow>
                <InputMask
                  name="zipcode"
                  placeholder="CEP"
                  mask="99999-999"
                  icon={FiMapPin}
                  onBlur={e => handleFindZipcode(e)}
                />

                <Input
                  name="address"
                  id="address"
                  placeholder="Endereço"
                  icon={FiHome}
                  maxLength={45}
                />

                <Input
                  name="city"
                  id="city"
                  placeholder="Cidade"
                  icon={FiMap}
                  maxLength={45}
                />

                <Select
                  name="state"
                  id="state"
                  placeholder="Estado"
                  icon={FiFlag}
                  options={statesOption}
                />
              </FormRow>

              <FormRow separator>
                <h1>Dados de acesso</h1>
              </FormRow>

              <FormRow>
                <Input
                  name="password"
                  type="password"
                  placeholder="Senha"
                  id="password"
                  icon={FiLock}
                />

                <Input
                  name="confirmPassword"
                  type="password"
                  placeholder="Confirmar senha"
                  icon={FiLock}
                />
              </FormRow>

              <FormRow>
                <Select
                  name="person_type"
                  placeholder="Tipo de documentação"
                  icon={FiTag}
                  id="person_type"
                  options={personTypesOptons}
                />

                <InputMask
                  name="document"
                  placeholder="CPF/CNPJ"
                  icon={FiHash}
                  mask={cpfCnpjMask}
                  onKeyUp={e => handleDocumentInputMask(e)}
                />
              </FormRow>

              <FormRow buttonWrapper>
                <Button
                  type="submit"
                  loading={buttonLoading}
                  styleType="success"
                  icon={FiSave}
                >
                  Enviar
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewMasterClient };
