import { FormHandles } from '@unform/core';
import {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
  ChangeEvent,
} from 'react';
import { ValidationError } from 'yup';
import { FiArrowRight, FiSave } from 'react-icons/fi';
import { OptionTypeBase } from 'react-select';

import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { Button } from '@components/elements/Button';
import { InputFile } from '@components/elements/Form/InputFile';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { removeInputMask } from '@helpers/removeInputMask';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { IFormData, IClient, IIndication, IFile } from './interfaces';
import {
  getClientOptions,
  getIndicationsOptions,
  banksOptions,
} from './selectOptions';
import {
  Card,
  CardContent,
  CardHeader,
  ConsignedForm,
  ConsignedCompaniesForm,
  HasArchivesFormRow,
} from './styles';
import {
  consignedFormValidation,
  consignedCompaniesFormValidation,
} from './validations';

export const NewConsigned: FC = () => {
  const consignedFormRef = useRef<FormHandles>(null);
  const consignedCompaniesFormRef = useRef<FormHandles>(null);
  const consignedCompanyNameField = useRef<HTMLDivElement>(null);

  const { addToast } = useToast();

  const [clients, setClients] = useState<IClient[]>();
  const [indications, setIndications] = useState<IIndication[]>();
  const [consignedCompanyId, setConsignedCompanyId] = useState<string>();
  const [files, setFiles] = useState<IFile[]>([]);
  const [creditWithoutFiles, setCreditWithoutFiles] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  useEffect(() => {
    async function loadIndications() {
      const { data: franchisees } = await api.get('/franchisees');
      const { data: sellers } = await api.get('/users/sellers');

      const parsedData = [...sellers, ...franchisees];

      setIndications(parsedData);
    }

    const timer = setTimeout(async () => {
      loadIndications();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  const handleClientsByIndications = useCallback(
    async (option: OptionTypeBase | null) => {
      if (!option) {
        return;
      }

      const { value: indicationId } = option;

      const { data } = await api.get(`/users-indicated/${indicationId}`);

      setClients(data);
    },
    [],
  );

  const handleElementVisibility = useCallback((element: HTMLElement) => {
    const { style } = element;
    const { visibility } = window.getComputedStyle(element);

    if (visibility === 'visible') {
      style.visibility = 'hidden';
      style.opacity = '0';

      setTimeout(() => {
        style.display = 'none';
      }, 399);
    } else {
      style.display = 'block';

      setTimeout(() => {
        style.visibility = 'visible';
        style.opacity = '1';
      }, 50);
    }
  }, []);

  const handleConsignedFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        consignedFormRef.current?.setErrors({});

        await consignedFormValidation(data);

        if (!creditWithoutFiles && files.length !== 3) {
          addToast({
            title: 'Ops... Algo está errado!',
            type: 'info',
            message: 'Insira todos os três arquivos antes de continuar!',
          });
          return;
        }

        const valuesWithoutMask = removeInputMask(
          document.getElementById('consignedForm') || undefined,
        );

        const parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, data);

        parsedData.consignedCompanyId = consignedCompanyId || '';

        delete parsedData.file;

        const fileData = new FormData();

        files.forEach(({ file, title }) => {
          fileData.append('titles', title);
          fileData.append('files', file);
        });

        setLoadingState(true);

        const { data: consigned } = await api.post('/consigned', parsedData);

        fileData.append('consignedId', consigned.id);

        if (!creditWithoutFiles) {
          await api.post('/consigned/files', fileData);
        }

        const consignedForm = document.getElementById(
          'consignedForm',
        ) as HTMLElement;
        const consignedCompaniesForm = document.getElementById(
          'consignedCompaniesForm',
        ) as HTMLElement;

        addToast({
          title: 'Muito bem!',
          type: 'success',
          message: 'Os dados enviados foram salvos com sucesso!',
        });
        reset();

        handleElementVisibility(consignedForm);
        handleElementVisibility(consignedCompaniesForm);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const validationErrors = getValidationErrors(err);

          consignedFormRef.current?.setErrors(validationErrors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'info',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingState(false);
      }
    },
    [
      addToast,
      consignedCompanyId,
      handleElementVisibility,
      creditWithoutFiles,
      files,
    ],
  );

  const handleConsignedCompaniesFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        let parsedData = data;

        consignedCompaniesFormRef.current?.setErrors({});

        if (consignedCompanyId) {
          delete parsedData.companyName;
        }

        await consignedCompaniesFormValidation(parsedData);

        const consignedCompaniesForm = document.getElementById(
          'consignedCompaniesForm',
        ) as HTMLElement;

        if (!consignedCompanyId) {
          const valuesWithoutMask = removeInputMask(consignedCompaniesForm);

          parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
            acc[name] = value;

            return acc;
          }, parsedData);

          setLoadingState(true);

          const { data: consignedCompany } = await api.post(
            '/consigned/companies',
            parsedData,
          );

          setConsignedCompanyId(consignedCompany.id);
        }

        const consignedForm = document.getElementById(
          'consignedForm',
        ) as HTMLElement;
        const {
          style: companyNameFieldStyles,
        } = consignedCompanyNameField.current as HTMLDivElement;

        companyNameFieldStyles.display = 'none';

        handleElementVisibility(consignedForm);
        handleElementVisibility(consignedCompaniesForm);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const validationErrors = getValidationErrors(err);

          consignedCompaniesFormRef.current?.setErrors(validationErrors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'info',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingState(false);
      }
    },
    [consignedCompanyId, addToast, handleElementVisibility],
  );

  const handleFindConsignedCompany = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (!value) {
        return;
      }

      const {
        style: companyNameFieldStyles,
      } = consignedCompanyNameField.current as HTMLDivElement;

      try {
        const { value: parsedValue } = removeInputMask()[0];

        const { data } = await api.get(`/consigned/companies/${parsedValue}`);

        companyNameFieldStyles.display = 'none';

        setConsignedCompanyId(data.id);
      } catch {
        companyNameFieldStyles.display = 'flex';

        addToast({
          title: 'Ops, a empresa não foi encontrada!',
          type: 'info',
          message:
            'Não encontramos uma empresa com este documento nos nossos registros! Por favor, faça o cadastro para continuar!',
        });
        setConsignedCompanyId(undefined);
      }
    },
    [addToast],
  );

  const handleCreditWithoutFiles = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;

      setCreditWithoutFiles(checked);
    },
    [],
  );

  const handleInputFileChange = useCallback(
    (fileId: string, title: string, event: ChangeEvent<HTMLInputElement>) => {
      const { files: targetFiles, name } = event.target;
      const inputFileRef = consignedFormRef.current?.getFieldRef(name);

      if (!targetFiles || targetFiles.length === 0) {
        inputFileRef.value = '';
        setFiles(oldState => {
          const updatedState = oldState.filter(state => state.id !== fileId);

          return updatedState;
        });
        return;
      }

      const file = targetFiles[0];

      const fileExtension = file.name.split('.').reverse()[0];

      if (fileExtension !== 'png' && fileExtension !== 'pdf') {
        inputFileRef.value = '';
        setFiles(oldState => {
          const updatedState = oldState.filter(state => state.id !== fileId);

          return updatedState;
        });
        return;
      }

      const fileSize = file.size * 0.000001;

      if (fileSize > 2.1) {
        inputFileRef.value = '';
        setFiles(oldState => {
          const updatedState = oldState.filter(state => state.id !== fileId);

          return updatedState;
        });
        return;
      }

      setFiles(oldState => {
        const updatedState = oldState.filter(state => state.id !== fileId);

        const newState = {
          file,
          id: fileId,
          title,
        };

        return [...updatedState, newState];
      });
    },
    [],
  );

  const clientOptions = useMemo(() => {
    if (!clients) {
      return [];
    }

    return getClientOptions(clients);
  }, [clients]);

  const indicationsOptions = useMemo(() => {
    if (!indications) {
      return [];
    }

    return getIndicationsOptions(indications);
  }, [indications]);

  return (
    <>
      {!indications ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Cred Cash', 'Consignado', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo consignado privado</h1>
              </CardHeader>

              <CardContent>
                <ConsignedCompaniesForm
                  onSubmit={handleConsignedCompaniesFormSubmit}
                  ref={consignedCompaniesFormRef}
                  id="consignedCompaniesForm"
                >
                  <FormRow separator>
                    <h1>
                      Verfique se a empresa do solicitante está disponível para
                      o crédito consignado
                    </h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Documento (CNPJ)</label>
                      <InputMask
                        name="companyDocument"
                        mask="99.999.999/9999-99"
                        onBlur={handleFindConsignedCompany}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow
                    ref={consignedCompanyNameField}
                    style={{ display: 'none' }}
                  >
                    <InputGroup>
                      <label>Nome da empresa</label>
                      <Input name="companyName" />
                    </InputGroup>

                    <InputGroup>
                      <label>Indicação</label>
                      <Select
                        name="indicationId"
                        options={indicationsOptions}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      icon={FiArrowRight}
                      loading={loadingState}
                    >
                      Avançar
                    </Button>
                  </FormRow>
                </ConsignedCompaniesForm>

                <ConsignedForm
                  onSubmit={handleConsignedFormSubmit}
                  ref={consignedFormRef}
                  id="consignedForm"
                >
                  <FormRow separator>
                    <h1>Cliente e indicador</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Selecione um indicador</label>
                      <Select
                        name="indicationId"
                        options={indicationsOptions}
                        onChange={handleClientsByIndications}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Selecione um cliente</label>
                      <Select name="clientId" options={clientOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados bancários</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Banco</label>
                      <Select name="bankWithCode" options={banksOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Agência</label>
                      <Input name="bankAgency" maxLength={30} />
                    </InputGroup>

                    <InputGroup>
                      <label>Conta</label>
                      <Input name="bankAccount" maxLength={255} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Contato</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Telefone</label>
                      <InputMask
                        name="phone"
                        mask="(99) 99999-9999"
                        placeholder="(00) 00000-0000"
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados para o crédito</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Renda comprovada</label>
                      <InputCurrency name="provenIcome" placeholder="R$" />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor do crédito</label>
                      <InputCurrency name="creditValue" placeholder="R$" />
                    </InputGroup>
                  </FormRow>

                  <HasArchivesFormRow separator>
                    <h1>Arquivos</h1>

                    <div>
                      <label htmlFor="hasArchives">Sem arquivos</label>
                      <input
                        type="checkbox"
                        id="hasArchives"
                        onChange={handleCreditWithoutFiles}
                      />
                    </div>
                  </HasArchivesFormRow>

                  {!creditWithoutFiles && (
                    <>
                      <FormRow>
                        <InputGroup>
                          <label>Contra cheque dos últimos 3 meses</label>
                          <InputFile
                            name="file"
                            onChange={event =>
                              handleInputFileChange(
                                's98T',
                                'Contra cheque dos últimos 3 meses',
                                event,
                              )
                            }
                          />
                        </InputGroup>
                      </FormRow>

                      <FormRow>
                        <InputGroup>
                          <label>CNH ou identidade (frente)</label>
                          <InputFile
                            name="file"
                            onChange={event =>
                              handleInputFileChange(
                                'xWtr',
                                'CNH ou identidade (frente)',
                                event,
                              )
                            }
                          />
                        </InputGroup>

                        <InputGroup>
                          <label>CNH ou identidade (verso)</label>
                          <InputFile
                            name="file"
                            onChange={event =>
                              handleInputFileChange(
                                'PoKI',
                                'CNH ou identidade (verso)',
                                event,
                              )
                            }
                          />
                        </InputGroup>
                      </FormRow>
                    </>
                  )}

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      icon={FiSave}
                      loading={loadingState}
                    >
                      Salvar novo crédito
                    </Button>
                  </FormRow>
                </ConsignedForm>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
