import { FC, useCallback, useState, useEffect, useMemo, useRef } from 'react';
import { OptionTypeBase } from 'react-select';
import { addMonths, format } from 'date-fns';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';
import { FiDollarSign } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Select } from '@components/elements/Form/Select';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';
import { LoadingPage } from '@components/layouts/LoadingPage';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { Card, CardContent, CardHeader } from './styles';
import { IInvestmentPercentage, IClient, IFormData } from './interfaces';
import { formValidation } from './validations';
import { getInvestmentPercentageOptions, periodOptions } from './selectOptions';

const NewInvestment: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();
  const { search } = useLocation();

  const [clients, setClients] = useState<IClient>();
  const [dateInit] = useState(new Date().toLocaleDateString());
  const [dateEnd, setDateEnd] = useState('');
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [percentage, setPercentage] = useState<IInvestmentPercentage>();
  const [investmentPercentages, setInvestmentsPercentages] = useState<
    IInvestmentPercentage[]
  >();

  useEffect(() => {
    const percentageIdFromQuery = search.replace('?percentageId=', '');

    async function loadClients() {
      const { data } = await api.get(`/users/me`);

      setClients(data);
    }

    async function getPercentage(id: string) {
      const { data } = await api.get(`/investments/percentages/${id}`, {
        params: {
          relations: ['investment'],
        },
      });

      setInvestmentsPercentages([data]);
      setPercentage(data);
      setDateEnd(format(addMonths(new Date(), data.period), 'dd/MM/yyyy'));
    }

    const timer = setTimeout(async () => {
      if (percentageIdFromQuery) {
        await getPercentage(percentageIdFromQuery);
      }

      loadClients();
    }, 1500);

    return () => clearInterval(timer);
  }, [search]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation({ ...data, clientId: user.id });

        const { investmentPercentageId } = data;

        const formData = {
          clientId: user.id,
          investmentPercentageId,
          dateInit,
          dateEnd,
        };

        await api.post('/investments', formData);

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'O investimento cadastrado foi computado com sucesso!',
        });

        formRef.current?.clearField('clientId');
        formRef.current?.clearField('dateEnd');
        formRef.current?.clearField('investmentPercentageId');
        formRef.current?.clearField('period');
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [dateEnd, dateInit, addToast, user.id],
  );

  // const clientOptions = useMemo(() => {
  //   if (!clients) {
  //     return [];
  //   }

  //   return getClientsOptions(clients);
  // }, [clients]);

  const investmentPercentageOptions = useMemo(() => {
    if (!investmentPercentages) {
      return [];
    }

    return getInvestmentPercentageOptions(investmentPercentages);
  }, [investmentPercentages]);

  const loadInvestmentPercentages = useCallback(
    async (option: OptionTypeBase | null) => {
      if (!option) {
        return;
      }

      const { value: periodNumber } = option as { value: number };

      const { data } = await api.get(`/investments/percentages/period`, {
        params: {
          periodNumber,
        },
      });

      const valueToDateEnd = format(
        addMonths(new Date(), periodNumber),
        'dd/MM/yyyy',
      );

      setInvestmentsPercentages(data);
      setDateEnd(valueToDateEnd);
      setPercentage(undefined);
    },
    [],
  );

  return (
    <>
      {!clients ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Bbank Invest', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Preencha abaixo para um novo investimento</h1>
              </CardHeader>

              <CardContent>
                <Form
                  ref={formRef}
                  initialData={clients}
                  onSubmit={handleFormSubmit}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Seu nome</label>
                      <Input name="name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Período</label>
                      <Select
                        name="period"
                        options={periodOptions}
                        onChange={loadInvestmentPercentages}
                        defaultValue={
                          percentage
                            ? {
                                label: `${percentage.period} meses`,
                                value: percentage.period,
                              }
                            : undefined
                        }
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor</label>
                      <Select
                        name="investmentPercentageId"
                        options={investmentPercentageOptions}
                        defaultValue={
                          percentage
                            ? {
                                value: percentage.id,
                                label: `${
                                  percentage.name
                                } - ${new Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(percentage.value)}`,
                              }
                            : undefined
                        }
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data inicial</label>
                      <Input name="dateInit" defaultValue={dateInit} readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Data final</label>
                      <Input name="dateEnd" defaultValue={dateEnd} readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      icon={FiDollarSign}
                      loading={loadingRequest}
                    >
                      Investir
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { NewInvestment };
