import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import {
  Card,
  CardHeader,
  CardContent,
  TableButtonContainer,
  TableButton,
} from './styles';
import {
  IScore,
  ISeller,
  IParams,
  IBadgeTypesPerScoreClassification,
} from './interfaces';

const ListResearchsBySeller: FC = () => {
  const { params } = useRouteMatch<IParams>();
  const { push } = useHistory();

  const [scores, setScores] = useState<IScore[]>();
  const [seller, setSeller] = useState<ISeller>();

  useEffect(() => {
    async function loadSeller() {
      const { data } = await api.get(`/users/${params.sellerId}`);

      setSeller(data);
    }

    async function loadScores() {
      const { data } = await api.get(
        `/telemedicine/sellers/${params.sellerId}/scores`,
        {
          params: {
            relations: ['prospect', 'seller'],
          },
        },
      );

      setScores(data);
    }

    const timer = setTimeout(async () => {
      await loadSeller();
      loadScores();
    }, 1500);

    return () => clearInterval(timer);
  }, [params.sellerId]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do prospecto',
        selector: 'search',
      },
      {
        name: 'Pontuação',
        selector: 'score',
      },
      {
        name: 'Classificação',
        selector: 'classification',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const badgeTypes = useMemo<IBadgeTypesPerScoreClassification>(() => {
    return {
      warm: {
        badgeType: 'warning',
        badgeText: 'Morno',
      },
      hot: {
        badgeType: 'success',
        badgeText: 'Quente',
      },
      cold: {
        badgeType: 'danger',
        badgeText: 'Frio',
      },
    };
  }, []);

  const handleNavigationToResearchDetails = useCallback(
    (telemedicineScoreId: string) => {
      push(`/franchisees/bbank-connect/researchs/${telemedicineScoreId}`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!scores) {
      return [];
    }

    const data = scores.map(score => ({
      id: score.id,
      search: score.prospect.name,
      score: <Badge type="info">{score.score}</Badge>,
      classification: (
        <Badge type={badgeTypes[score.classification].badgeType}>
          {badgeTypes[score.classification].badgeText}
        </Badge>
      ),
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="success"
            title="Ver informações completas"
            onClick={() => handleNavigationToResearchDetails(score.id)}
          >
            <FiEye size={22} />
          </TableButton>
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [scores, badgeTypes, handleNavigationToResearchDetails]);

  return (
    <>
      {!scores ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={[
                'Bbank Connect',
                'Vendedores',
                seller?.name || '',
                'Pesquisas de mercado',
              ]}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>
                  Pesquisas de mercado realizadas pelo vendedor selecionado
                </h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListResearchsBySeller };
