import { ChangeEvent, FC } from 'react';
import { FiX } from 'react-icons/fi';

import { TextField, ClearButton } from './styles';

interface IFilterInputProps {
  filterText: string;
  onFilter(e: ChangeEvent<HTMLInputElement>): void;
  onClear(): void;
  filterPlaceholder?: string;
}

const FilterInput: FC<IFilterInputProps> = ({
  filterText,
  onFilter,
  filterPlaceholder,
  onClear,
}) => {
  return (
    <>
      <TextField
        id="search"
        type="text"
        placeholder={filterPlaceholder || 'Filtrar por nome'}
        aria-label="Search Input"
        value={filterText}
        onChange={onFilter}
      />
      <ClearButton type="button" onClick={onClear}>
        <FiX size={20} />
      </ClearButton>
    </>
  );
};

export { FilterInput };
