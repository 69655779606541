import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    creditLimit: Yup.number()
      .typeError('Insira um número')
      .required('Preencha este campo!'),
    document: Yup.string().required('Preencha este campo!'),
    status: Yup.string()
      .oneOf(['active', 'pending', 'denied', 'contract_pending'], 'Inválido!')
      .required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
