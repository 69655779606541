export type IPages = { [key: number]: any };

export function createPagination(data: any[]): IPages {
  const pages: IPages = {};

  const dataQuantityPerPage = 10;
  const dataQuantity = data.length;

  const quantityOnFinalPage = dataQuantity % dataQuantityPerPage;

  const pagesQuantity =
    (dataQuantity - quantityOnFinalPage) / dataQuantityPerPage;

  Array.from(Array(pagesQuantity).keys()).forEach(item => {
    const itensPerPage = data.splice(0, dataQuantityPerPage);

    pages[item + 1] = itensPerPage;
  });

  if (quantityOnFinalPage) {
    const reversedData = data.reverse();

    const lastPage = reversedData.splice(0, quantityOnFinalPage);

    const pagesSize = Object.keys(pages).length;

    pages[pagesSize + 1] = lastPage;
  }

  return pages;
}
