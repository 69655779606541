import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    clientId: Yup.string().required('Selecione uma opção!'),
    relation: Yup.string().required('Selecione uma opção'),
    operationValue: Yup.string().required('Insira um valor'),
    operationDate: Yup.string().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
