import { FC } from 'react';
import Skeleton from '@yisheng90/react-loading';

import { Container } from './styles';

const LoadingPage: FC = () => (
  <Container>
    <Skeleton height={38} width="100%" />

    <br />

    <Skeleton height={200} width="100%" />

    <br />

    <Skeleton height={400} width="100%" />
  </Container>
);

export { LoadingPage };
