import { FC, useEffect, useState, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FiDollarSign, FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Aside } from '@components/layouts/Aside';
import { CardMenuContainer } from '@components/layouts/CardMenuContainer';
import { Footer } from '@components/layouts/Footer';
import { Header } from '@components/layouts/Header';
import { Main } from '@components/layouts/Main';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { IConvenience, IConvenienceFromApi } from './interfaces';
import { URLPath, Card, CardHeader, CardContent } from './styles';

const ListConveniences: FC = () => {
  const { user } = useAuth();
  const { push } = useHistory();

  const [conveniences, setConveniences] = useState<IConvenience[]>();

  useEffect(() => {
    async function loadConveniences() {
      const { data } = await api.get<IConvenienceFromApi[]>(
        `/bbank-pay/conveniences/clients/${user.id}`,
        {
          params: {
            relations: ['client'],
          },
        },
      );

      const state = data.map(convenience => {
        return {
          id: convenience.id,
          infinityId: convenience.infinity_id,
          cpf: convenience.cpf,
          client: convenience.client,
          status: convenience.status,
        };
      });

      setConveniences(state);
    }

    const timeout = setTimeout(() => {
      loadConveniences();
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [user.id]);

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'Documento da conveniência (CPF)',
        selector: 'search',
      },
      {
        name: 'Nome do cliente',
        selector: 'userName',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Infinity ID',
        selector: 'infinityId',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!conveniences) {
      return [];
    }

    const data = conveniences.map(convenience => {
      let badgeType: 'danger' | 'success' | 'warning' = 'success';

      if (convenience.status === 'parado') {
        badgeType = 'danger';
      }

      if (convenience.status === 'pendente') {
        badgeType = 'warning';
      }

      return {
        id: convenience.id,
        search: convenience.cpf,
        infinityId: convenience.infinityId,
        userName: convenience.client.name,
        status: <Badge type={badgeType}>{convenience.status}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              title="Visualizar"
              onClick={() =>
                push(`/client/bbank-pay/conveniences/${convenience.id}/details`)
              }
              icon={FiEye}
            />
            <TableButton
              styleType="info"
              title="Transações"
              onClick={() =>
                push(
                  `/client/bbank-pay/conveniences/${convenience.id}/transactions`,
                )
              }
              icon={FiDollarSign}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [conveniences, push]);

  return (
    <>
      <Header />

      <Aside />

      <Main>
        <CardMenuContainer />

        {!conveniences ? (
          <LoadingPage />
        ) : (
          <>
            <Row>
              <URLPath>
                <li>BbankPay</li>
                <li>{'>'}</li>
                <li>Estabelecimentos</li>
                <li>{'>'}</li>
                <li>Todos</li>
              </URLPath>
            </Row>

            <Row>
              <Card>
                <CardHeader>
                  <h1>Estabelecimentos</h1>
                </CardHeader>

                <CardContent>
                  <DataTable
                    columns={tableColumns}
                    data={tableData}
                    filterPlaceholder="Filtrar por CPF..."
                  />
                </CardContent>
              </Card>
            </Row>
          </>
        )}
      </Main>

      <Footer />
    </>
  );
};

export { ListConveniences };
