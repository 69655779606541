import axios from 'axios';
import { differenceInHours, format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiClock, FiSearch } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Badge } from '@components/elements/Badge';
import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';
import { Modal } from '@components/elements/Modal';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import avatarBoy009 from '@assets/avatars/009-boy-4.svg';
import avatarBoy008 from '@assets/avatars/008-boy-3.svg';
import avatarBoy007 from '@assets/avatars/007-boy-2.svg';
import avatarBoy004 from '@assets/avatars/004-boy-1.svg';
import avatarGirl from '@assets/avatars/002-girl.svg';

import { IIndication, INews, ITermsOfUse } from './interfaces';
import { News, NewsImageWrapper, NewsInfo } from './styles';

const Dashboard: FC = () => {
  // const simulationResultRef = useRef<HTMLParagraphElement>(null);

  const { user } = useAuth();

  const [indications, setIndications] = useState<IIndication[]>([]);
  const [termsOfUse, setTermsOfUse] = useState<ITermsOfUse>();
  const [news, setNews] = useState<INews[]>();
  const [searchNews, setSearchNews] = useState<INews[]>([]);
  const [searchNewsString, setSearchNewsString] = useState('');

  useEffect(() => {
    async function loadIndications() {
      const { data } = await api.get('/users-indicated');
      const parsedData: IIndication[] = [];

      const arrayToControlIndicationsQuantity = Array.from(Array(5).keys());

      arrayToControlIndicationsQuantity.forEach((_, index) => {
        if (data[index] !== undefined) {
          const createdAtDate = new Date(data[index].created_at);

          if (differenceInHours(new Date(), createdAtDate) <= 24) {
            parsedData.push(data[index]);
          }
        }
      });

      setIndications(parsedData);
    }

    async function loadNews() {
      const { data: newsFromApi } = await api.get('/news');
      const reversedNewsArray = newsFromApi.reverse();

      // Set the array quantity to control how much news are going to be displayed
      // Four news are going to be displayed
      const arrayToControlNewsQuantity = Array.from(Array(4).keys());

      const parsedData = arrayToControlNewsQuantity
        .map((_, index) => {
          if (reversedNewsArray[index] === undefined) {
            return;
          }

          return reversedNewsArray[index];
        })
        .filter(item => item !== undefined) as INews[];

      setNews(parsedData);
      setSearchNews(parsedData);
    }

    const timer = setTimeout(async () => {
      await loadIndications();
      loadNews();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    async function getTermsOfUse() {
      const { data } = await api.get('/terms-of-use');

      setTermsOfUse(data);
    }

    const timer = setTimeout(() => getTermsOfUse(), 200);

    return () => clearInterval(timer);
  }, []);

  const handleMarkTermsOfUseAsConcluded = useCallback(async () => {
    const {
      data: { ip },
    } = await axios.get('https://api.ipify.org?format=json');

    const { data: terms } = await api.post('/terms-of-use', {
      ipAddress: ip,
    });

    setTermsOfUse(terms);
  }, []);

  const photos = useMemo(() => {
    return [avatarGirl, avatarBoy004, avatarBoy007, avatarBoy008, avatarBoy009];
  }, []);

  const randomPhotos = useMemo(() => {
    const getPhotos = photos.map(_ => {
      return photos[Math.floor(Math.random() * 5)];
    });

    return getPhotos;
  }, [photos]);

  const handleSearchNews = useCallback(() => {
    if (!news) {
      return;
    }

    if (searchNewsString === '') {
      setSearchNews(news);
      return;
    }

    const updatedSearchNews = news.filter(
      item =>
        item.title.toLowerCase().search(searchNewsString.toLowerCase()) >= 0 ||
        item.description.toLowerCase().search(searchNewsString.toLowerCase()) >=
          0,
    );

    setSearchNews(updatedSearchNews);
  }, [searchNewsString, news]);

  // const handlePriceSelectChange = useCallback(
  //   (value: OptionTypeBase | null) => {
  //     if (!value) {
  //       return;
  //     }

  //     const optionValue = value.value as number;

  //     const portions = credluzSimulationPortionsByPrice[optionValue];

  //     const portionOptions = Object.entries(portions).map(
  //       ([portionNumber, portionPrice]) => {
  //         return {
  //           value: portionPrice.toString(),
  //           label: portionNumber,
  //         };
  //       },
  //     );

  //     setPortionsOptions(portionOptions);
  //   },
  //   [],
  // );

  // const handleSimulation = useCallback((data: ISimulationData) => {
  //   if (!simulationResultRef.current) {
  //     return;
  //   }

  //   const numberFormat = new Intl.NumberFormat('pt-BR', {
  //     style: 'currency',
  //     currency: 'brl',
  //   }).format;

  //   const price = Number(data.price);
  //   const portion = Number(data.portion);

  //   const htmlText =
  //     `` +
  //     `A simulação com o preço de&nbsp;` +
  //     `<strong>${numberFormat(price)}</strong>` +
  //     `&nbsp;com 12 parcelas recebe um resultado de:&nbsp;` +
  //     `<strong>${numberFormat(portion)}</strong>`;

  //   simulationResultRef.current.innerHTML = htmlText;
  // }, []);

  return (
    <>
      {!news ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <h1>Quadro de notícias</h1>

            <Form onSubmit={handleSearchNews}>
              <Input
                name="search"
                icon={FiSearch}
                placeholder="Filtrar notícias"
                onChange={e => setSearchNewsString(e.target.value)}
                onKeyUp={handleSearchNews}
                upperCase={false}
              />
            </Form>
          </Row>

          <Row style={{ gap: '32px', justifyContent: 'normal' }}>
            {news.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flex: 1,
                  borderBottomStyle: 'solid',
                  borderBottomWidth: 2,
                  borderBottomColor: '#ebedf3',
                  padding: 10,
                }}
              >
                <Badge type="info">Não há notícias recentes</Badge>
              </div>
            )}

            {news.length !== 0 &&
              searchNews.map(item => (
                <News key={item.id}>
                  <NewsImageWrapper>
                    <img src={item.image_urls[0]} alt={item.title} />
                  </NewsImageWrapper>

                  <NewsInfo>
                    <Link to={`/shared/news/${item.id}`}>{item.title}</Link>

                    <p>{item.description}</p>

                    <span>
                      <FiClock size={18} />
                      Postado em:&nbsp;
                      {format(new Date(item.created_at), 'dd/MM/yyyy')}
                    </span>
                  </NewsInfo>
                </News>
              ))}
          </Row>

          <Row>
            {/* <CardContainer>
              <CardHeader>
                <ImageSlider />
              </CardHeader>
            </CardContainer> */}

            {/* {user.role !== 'client' && (
              <UsersListContainer>
                <UsersListHeader>
                  <h1>Novas indicações nas últimas 24h</h1>
                </UsersListHeader>

                <UsersList>
                  {indications.length === 0 ? (
                    <BadgeComponent type="info">
                      Não há indicações recentes
                    </BadgeComponent>
                  ) : (
                    indications.map((item, index) => (
                      <UserListItem key={item.id}>
                        <div>
                          <img src={randomPhotos[index]} alt={item.name} />
                        </div>

                        <div>
                          <Link to="/">{item.name}</Link>
                          <span>{item.email}</span>
                        </div>
                      </UserListItem>
                    ))
                  )}
                </UsersList>
              </UsersListContainer>
            )} */}
          </Row>

          {/* <Row>
            <SimulationContainer>
              <SimulationHeader>
                <h1>Simulação do CredLuz</h1>
              </SimulationHeader>

              <SimulationContent>
                <p ref={simulationResultRef} />

                <Form onSubmit={handleSimulation}>
                  <FormRow>
                    <Select
                      name="price"
                      options={credluzSimulationPriceValues}
                      placeholder="Selecione um valor"
                      onChange={handlePriceSelectChange}
                    />
                  </FormRow>

                  <FormRow>
                    <Select
                      name="portion"
                      options={portionsOptions}
                      placeholder="Selecione as parcelas"
                    />
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="info"
                      type="submit"
                      icon={FiCheckCircle}
                      style={{ backgroundColor: '#8950fc', color: '#fff' }}
                    >
                      Simular
                    </Button>
                  </FormRow>
                </Form>
              </SimulationContent>
            </SimulationContainer>
          </Row> */}

          <Row>
            {!termsOfUse && (
              <Modal
                headerText="Termos de uso"
                visible
                hideButton
                onOkClick={handleMarkTermsOfUseAsConcluded}
              >
                <p>
                  Termos de uso Termos e Condições de Uso do Site e Aplicativo
                  BBANKCRED!
                </p>
                <p>
                  Olá! Seja bem-vindo(a) aos nossos Termos e Condições de Uso
                  (“Termos de Uso”). Eles são bastante importantes e afetam
                  diretamente seus direitos no relacionamento com ao Bbankcred,
                  assim como nosso Aviso Externo de Privacidade (disponível em
                  https://www.Bbankcred.com.br) e os outros documentos
                  mencionados nestes termos, por isso, leia todos atentamente.
                  Esperamos que você esteja sentado confortavelmente e prestando
                  bastante atenção. Os termos iniciados em letra maiúscula
                  quando não estiverem definidos têm o significado a eles
                  atribuído no Capítulo 1 “Das Definições”. Vamos lá! É muito
                  importante que você leia os Termos de Uso por completo. Caso
                  você não concorde com eles, você não deve utilizar os
                  serviços. Mas aqui vão os principais pontos. RESUMO: Todo o
                  seu relacionamento e interação com o Bbankcred, na qualidade
                  de Usuário, serão realizados de forma online. Todas as
                  informações dos Usuários são sigilosas e confidenciais. Todos
                  os Serviços oferecidos, bem como os limites de transações, são
                  baseados nas informações que você, Usuário, nos envia ou nos
                  permite consultar, então lembre-se sempre de passar os dados
                  corretos e mantê-los atualizados para uma melhor interação com
                  o Bbankcred. O Bbankcred poderá cobrar tarifas para a
                  realização dos Serviços, sendo que a relação das tarifas
                  cobradas estará disponível no Aplicativo e no Site para
                  consulta. Não se preocupe! Você será informado antes de haver
                  majoração de tarifas para serviços que utiliza. Para utilizar
                  as Soluções do Bbankcred o Usuário cria uma senha pessoal e
                  sigilosa. O Usuário não deve divulgar essa senha para
                  terceiros e é o único responsável pelo uso não autorizado da
                  senha em caso de divulgação. O Usuário pode ter acesso também
                  a serviços de terceiros. Nesse caso, o Usuário deve ter o
                  cuidado de analisar os Termos e Condições de Uso desses
                  terceiros. O Bbankcred não é responsável por esses serviços
                  prestados por terceiros que não sejam Representantes de
                  Soluções financeiras junto ao Bbankcred, Prestadores de
                  Serviços ou Franqueados. O Bbankcred e Instituições Parceiras
                  trabalham juntas para oferecer a melhor solução em conta
                  digital e crédito. O Bbankcred declara que durante todo o
                  prazo de vigência destes Termos de Uso e da prestação dos
                  serviços relacionados ao PIX, mantém a sua regular adesão a
                  tal arranjo de pagamentos instantâneos como participante
                  indireto, nos termos da regulamentação aplicável. O Bbankcred
                  declara que durante todo o prazo de vigência destes Termos de
                  Uso e da prestação dos serviços relacionados ao Open Banking,
                  mantém sua regular adesão ao ecossistema como participante
                  obrigatória, na função de detentora de conta, nos termos da
                  regulamentação aplicável. Você poderá entrar em contato
                  conosco a qualquer momento por meio da central de atendimento
                  ou pelo e-mail disponível no Site e no Aplicativo
                  administrativo@Bbankcred.com.br. Note que este é apenas um
                  resumo dos principais pontos dos Termos de Uso e a leitura
                  dele não dispensa a leitura completa dos Termos de Uso. Vamos
                  ler agora os Termos na íntegra? INTRODUÇÃO Agradecemos por
                  usar nossos produtos e serviços (“Serviços”). Os Serviços
                  serão fornecidos pelo CREDCASH intermediações financeiras
                  LTDA, sociedade limitada por cotas de ações com sede na Região
                  Administrativa de Taguatinga em Brasília - DF, no QC 02 LOTES
                  14/15 SALA 820 ED. ACCIOLY (“BBANKCRED”) e, eventualmente, em
                  conjunto com Instituição(ões) Parceira(s). Ao se inscrever ou
                  usar, de qualquer forma, nossos Serviços, você manifesta sua
                  concordância com estes Termos de Uso. Por isso, leia-os com
                  atenção! Seu relacionamento conosco é regulado por esses
                  Termos de Uso e pelo nosso Aviso Externo de Privacidade e,
                  caso queira analisá-los, a versão integral de cada um deles
                  estará disponível em nosso Site ou Aplicativo. AO UTILIZAR
                  NOSSOS SERVIÇOS, VOCÊ DECLARA E GARANTE QUE LEU, COMPREENDEU E
                  ESTÁ DE ACORDO COM O CONTEÚDO DESTES TERMOS DE USO E
                  COMPROMETE-SE A CUMPRI-LOS DE FORMA INTEGRAL. CASO VOCÊ NÃO
                  CONCORDE COM QUALQUER TÓPICO DISPOSTO NESTES TERMOS DE USO (OU
                  NÃO POSSA CUMPRIR COM TAIS TÓPICOS POR QUALQUER MOTIVO),
                  ENTÃO, INFELIZMENTE, VOCÊ NÃO PODERÁ USAR OS SERVIÇOS
                  OFERECIDOS PELO BBANKCRED OU ACESSAR NENHUM CONTEÚDO. Para
                  usar os Serviços ou acessar o Conteúdo, você precisa: (I) ter
                  18 (dezoito) anos ou mais, porém, o Bbankcred se reserva o
                  direito de autorizar ou cadastrar clientes menores de 18 anos,
                  mediante aprovação especial, realizada a único e exclusivo
                  critério do Bbankcred sempre que sejam atendidos os requisitos
                  legais aplicáveis; (II) ter um RG, RNM (caso o cliente seja
                  estrangeiro residente legalmente no Brasil) ou CNH em mãos ou
                  qualquer outro documento de identidade aceito pelo Bbankcred
                  em sua página de cadastro; (III) ter a capacidade de celebrar
                  um contrato vinculativo conosco e não ser impedido de fazê-lo
                  segundo as leis aplicáveis; (IV) ser residente no Brasil, onde
                  os Serviços estão disponíveis; (V) ter um smartphone com
                  sistema operacional iOS ou Android, nas versões mínimas
                  suportadas pelo Bbankcred, com câmera frontal e com acesso à
                  internet banda larga (3G, 4G ou 5G) ou outro dispositivo,
                  sistema operacional ou conexão que venha a ser aceito pelo
                  Bbankcred; e (VI) cumprir com eventuais requisitos adicionais
                  exigidos para determinados Serviços, conforme especificados
                  nestes Termos de Uso, no Aplicativo e/ou no Site. Você também
                  assegura ao Bbankcred que quaisquer informações de cadastro
                  que nos enviar são verdadeiras, precisas e completas e, caso
                  não sejam ou tornem-se desatualizadas ou incorretas,
                  compromete-se a corrigi-las imediatamente no seu cadastro.
                  Havendo qualquer identificação pelo Bbankcred de informações
                  incompletas, falsas, em desacordo com leis ou regulamentações
                  aplicáveis ou que não possam ser confirmadas, ao Bbankcred se
                  resguarda o direito de encerrar a relação comercial
                  imediatamente em cumprimento de normas legais e/ou
                  regulatórias. 1. DAS DEFINIÇÕES 1.1. Para facilitar seu
                  entendimento e evitar repetições, os termos constantes nestes
                  Termos de Uso, sempre que usados com a primeira letra em
                  maiúsculo, terão o significado estabelecido abaixo, seja no
                  plural ou no singular: (I) Anunciantes: instituições
                  financeiras, seguradoras, corretoras de seguros, entre outros
                  que venham a publicar anúncios por meio dos ambientes
                  administrados pelo Bbankcred. (II) ACEITAR OU ACEITE:
                  SIGNIFICA O ATO DE O USUÁRIO CLICAR NA CAIXA “LI E ACEITO OS
                  TERMOS DE USO” DISPOSTA NO SITE OU NO APLICATIVO. TAL ATO
                  IMPLICA CONSENTIMENTO PRÉVIO, LIVRE, EXPRESSO, INFORMADO E
                  INEQUÍVOCO DO USUÁRIO EM RELAÇÃO A TODO O CONTEÚDO DOS TERMOS
                  DE USO ABRANGENDO, ESPECIALMENTE, A SOLICITAÇÃO E A CONCESSÃO
                  DE AUTORIZAÇÃO PARA A CONSTITUIÇÃO E ARMAZENAMENTO DE BASE DE
                  DADOS COMPOSTA POR DADOS PESSOAIS, ENTRE OUTRAS INFORMAÇÕES.
                  (III) Aplicativo: se refere aos aplicativos do Bbankcred,
                  adaptados e desenvolvidos para operação em telefone celular,
                  ou qualquer outro dispositivo móvel que venha ser suportado
                  pelo Bbankcred, com câmera frontal e com acesso à internet
                  banda larga (3G, 4G ou 5G) ou outra conexão aceita pelo
                  Bbankcred e desde que tenha o sistema operacional Android e
                  iOS, nas versões mínimas suportadas pelo Bbankcred. (IV)
                  Aplicativos de Terceiros: tem o significado que lhe é
                  atribuído na Cláusula 12.1. (V) Browser: programa usado para
                  ver páginas disponíveis na internet, que interpreta as
                  informações do website indicado, exibindo na tela do
                  computador textos, sons e imagens. (VI) Cadastro: tem o
                  significado que lhe é atribuído na Cláusula 5.2. (VII)
                  Cancelamento de conta: A adesão à Conta Bbankcred é válida por
                  tempo indeterminado, podendo ser cancelada por iniciativa do
                  Usuário ou do Bbankcred, mediante comunicação por escrito com
                  antecedência de 30 (trinta) dias. O cancelamento de conta
                  equivale ao seu encerramento e, ao ter a conta cancelada, o
                  Usuário fica impedido de abrir uma nova conta com o mesmo CPF
                  cadastrado anteriormente. (VIII) Cartão Físico: significa o
                  instrumento de pagamento pré-pago em formato físico, utilizado
                  na função débito e emitido pelo Bbankcred para uso pessoal e
                  intransferível dos Usuários. O Cartão Físico pode ter a função
                  pós-paga habilitada, a critério do Bbankcred, desde que o
                  Usuário seja aprovado na análise de crédito. (IX) Cartão
                  Virtual: significa o instrumento de pagamento em formato
                  virtual para compras realizadas na internet, emitido pelo
                  Bbankcred, utilizado na função crédito, mas com o valor sendo
                  debitado da Conta Bbankcred de forma automática. O Cartão
                  Virtual pode ter a função pós-paga habilitada, a critério do
                  Bbankcred, desde que o Usuário seja aprovado na análise de
                  crédito. (X) Cartões BBANKCRED: significa o Cartão Virtual e O
                  Cartão Físico que, após a ativação de sua Conta, poderá ser
                  emitido pelo Bbankcred e/ou você poderá solicitar a emissão
                  dos Cartões Bbankcred quando da abertura da Conta, mediante
                  depósito mínimo. (XI) CDB: significa o Certificado de Depósito
                  Bancário. (XII) Chargeback: significa o procedimento de
                  contestação de débito por meio do qual um Usuário pagador não
                  reconhece junto à administradora ou ao Bbankcred uma despesa
                  efetuada com os Cartões Bbankcred, a fim de invalidar um
                  pagamento realizado. (XIII) Chave PIX: informação relacionada
                  ou cadastrada pelo Usuário, que inicialmente será apenas chave
                  aleatória. Posteriormente serão liberadas as opções: número do
                  telefone celular, o CPF, o CNPJ, endereço de e-mail,
                  informações que possibilitam a identificação do Usuário e da
                  Conta Bbankcred. A Chave PIX visa facilitar o processo de
                  iniciação de transações de pagamento pelos Usuário que
                  realizam pagamentos via PIX e mitigar o risco de fraude em
                  transações no âmbito desse arranjo de pagamento. Todas as
                  Chaves PIX ficam armazenadas em plataforma do Banco Central e
                  são protegidas de acordo com a regulação de sigilo bancário e
                  de proteção de dados aplicável. (XIV) Conta Bbankcred:
                  significa a conta de pagamento que o Usuário mantém junto ao
                  Bbankcred, destinada à execução de transações de pagamento em
                  moeda eletrônica realizadas com base em fundos denominados em
                  reais previamente aportados, cuja movimentação só pode ocorrer
                  por meio do Aplicativo ou do Site. A Conta Bbankcred poderá
                  também ser usada para realização e recebimento de
                  transferências ou de pagamentos no âmbito do PIX, de forma
                  ilimitada, observada a regulamentação aplicável. Da mesma
                  forma, poderá ser parte do processo de Open Banking conforme
                  as regulações do Banco Central do Brasil. (XV) Conteúdo:
                  significa toda e qualquer informação disponibilizada pelo ou
                  por meio do Site ou do Aplicativo, tais como textos, dados,
                  software, imagens, vídeos, áudios, recursos interativos etc.,
                  incluindo-se os códigos fontes empregados para exibição desses
                  conteúdos, como aqueles em linguagem HTML, CSS, PHP, entre
                  outros. (XVI) Correspondente Bancário: empresa contratada por
                  instituições financeiras e demais instituições autorizadas
                  pelo Banco Central para a prestação de serviços de atendimento
                  aos clientes e usuários dessas instituições, conforme
                  Resolução nº 4935/2021 do Conselho Monetário Nacional. (XVII)
                  Conteúdo de Usuário: tem o significado que lhe é atribuído na
                  Cláusula 9.1. (XVIII) Dados Cadastrais: são as informações
                  necessárias para concluir o seu cadastro. No caso do
                  Bbankcred, pedimos os seguintes dados: CPF, Nome Completo,
                  Gênero, Data de Nascimento, Nome da Mãe, E-mail, Telefone
                  Celular, Renda, PEP (Pessoa Exposta Politicamente) e
                  Profissão. (XIX) Dados Pessoais: significa todo e qualquer
                  dado disponibilizado pelo Usuário que, de alguma forma
                  possibilitem sua identificação, como, por exemplo, nome,
                  endereço, CPF, número de telefone, ou endereço de e-mail, ou
                  quaisquer outras informações, conforme regulamentações
                  aplicáveis. As práticas descritas no Aviso Externo de
                  Privacidade só se aplicam ao tratamento dos seus dados
                  pessoais no Brasil e estão sujeitas às leis locais aplicáveis,
                  com destaque para a Lei Federal nº 13.709/2018 (Lei Geral de
                  Proteção de Dados Pessoais, ou "LGPD"). (XX) Disponibilidade:
                  capacidade de acesso à informação gerada ou adquirida para
                  qualquer finalidade, no momento em que for necessário. (XXI)
                  Dispositivos móveis: aparelhos que possuem tecnologia para
                  acesso à rede de telecomunicações, devidamente habilitados
                  junto a uma operadora de serviços de telecomunicação. (XXII)
                  Extrato Inteligente: extrato elaborado pelo Bbankcred que
                  organiza e categoriza os dados e despesas do Usuário de acordo
                  com o perfil de cada transação realizada. (XXIII) Instituição
                  Parceira: conforme definido no Anexo I. (XXIV) Limite: tem o
                  significado que lhe é atribuído na Cláusula 6.1. (XXV) Login:
                  endereço de e-mail e CPF, fornecidos pelo Usuário no ato de
                  cadastro no Site e/ou no Aplicativo. (XXVI) Malware e/ou
                  Práticas Nocivas: significa o software malicioso e/ou a
                  prática de atividades nocivas a qualquer pessoa ou entidade.
                  São exemplos de Malwares os worms, cavalo de Troia e vírus.
                  São exemplos de práticas nocivas e indesejadas os exploits,
                  spamming, flooding, spoofing, crashing e root kits. (XXVII)
                  Nome Social: designação pela qual a pessoa travesti ou
                  transgênera se identifica e é socialmente reconhecida, em
                  contraste com o nome oficialmente registrado, que não reflete
                  sua identidade de gênero. (XXVIII) Open Banking: modelo
                  financeiro que viabiliza o compartilhamento de dados e
                  serviços, como transações financeiras, entre instituições
                  financeiras e demais instituições autorizadas a funcionar pelo
                  Banco Central. (XXIX) Operadora de Serviços de
                  Telecomunicação: empresas prestadoras de serviços de
                  telecomunicações devidamente habilitadas junto à ANATEL -
                  Agência Nacional de Telecomunicações para prestação do serviço
                  de telecomunicação. (XXX) PIX: significa o arranjo de
                  pagamentos instituído pelo Banco Central do Brasil que
                  disciplina a prestação de serviços de pagamento relacionados a
                  transações de pagamentos instantâneas e a própria transação de
                  pagamento instantânea no âmbito do arranjo. Tal solução de
                  pagamento instantânea do Banco Central do Brasil possibilita a
                  realização de transferência e de pagamentos pelos e para os
                  Usuários, detentores de Conta Bbankcred. (XXXI) Aviso Externo
                  de Privacidade: significa o documento que informa como são
                  tratados seus dados pessoais pelo Bbankcred que pode ser
                  encontrado no seguinte link [https://www.Bbankcred.com.br].
                  (XXXII) Provedores: tem o significado que lhe é atribuído na
                  Cláusula 22.1. (XXXIII) Prestadores de Serviço Terceiros: tem
                  o significado que lhe é atribuído na Cláusula 4.3. (XXXIV)
                  Saques: tem o significado que lhe é atribuído na Cláusula
                  5.11. (XXXV) SCR: tem o significado que lhe é atribuído na
                  Cláusula 17.3. (XXXVI) Senha: meio de validação da identidade
                  do Usuário para fins de acesso aos recursos ou serviços de
                  processamento da informação dentro de um sistema ou ambiente
                  eletrônico, representado por um conjunto de caracteres, de
                  conhecimento único do Usuário. (XXXVII) Soluções Financeiras:
                  significa todos os produtos e serviços oferecidos pelo
                  Bbankcred, que estão indicados e descritos na Cláusula 5.
                  (XXXVIII) Site: significa os endereços eletrônicos
                  [https://www.Bbankcred.com.br] ou qualquer outro que vier a
                  substituí-lo. (XXXIX) SMS: Short Message Service - serviço de
                  envio e recebimento de mensagens de texto por meio do aparelho
                  celular. (XL) Software: significa o conjunto de soluções
                  tecnológicas do Bbankcred (de sua propriedade ou licenciadas
                  por terceiros para uso do Bbankcred), para executar os
                  Serviços realizados pelo Bbankcred. (XLI) Usuário: significa a
                  Pessoa Física, com plena capacidade de contratar, que acessa o
                  Site e/ou o Aplicativo e realiza o seu cadastro pessoal de
                  modo a usufruir das funcionalidades oferecidas pelo Bbankcred,
                  aceitando, nessa oportunidade, incondicionalmente, os
                  presentes Termos de Uso e tendo sido informado sobre a forma
                  como tratamos seus dados pessoais conforme está indicado no
                  Aviso Externo de Privacidade. (XLII) Vírus: programa capaz de
                  infectar outros programas e arquivos de um computador ou
                  dispositivo móvel, expondo os seus Usuários a diversos riscos.
                  2. CONDIÇÕES DE ACESSO 2.1. Para utilização das plataformas é
                  necessário ter 18 anos completos. No entanto, o Bbankcred se
                  resguarda ao direito de, a seu critério, cadastrar Usuários
                  menores de idade, mediante o consentimento do responsável para
                  abertura da conta. 2.2. Para ter acesso a algumas seções ou
                  funcionalidades dos nossos Serviços pode ser necessário
                  fornecer seus dados pessoais para a realização de cadastro
                  ("Dados Cadastrais"). 2.3. Você garante que todos os Dados
                  Cadastrais fornecidos são verdadeiros, precisos, completos e
                  estão atualizados. É importante manter seus dados atualizados
                  para que seu acesso à plataforma seja mantido. Caso
                  informações não verdadeiras ou desatualizadas incluídas pelo
                  Usuário causem prejuízos, será de sua única e exclusiva
                  responsabilidade. Caso você altere qualquer um dos seus dados
                  cadastrais deverá atualizá-los através do aplicativo ou
                  escrevendo um e-mail para juridico@Bbankcred.com.br 2.4. Caso
                  o Bbankcred verifique a existência de Dados Cadastrais
                  incorretos e/ou inverídicos, e não seja possível
                  regularizá-los, ou, ainda, caso o Usuário se negue a fornecer
                  eventuais esclarecimentos e/ou dados pessoais adicionais, se
                  solicitados, o Bbankcred se reserva ao direito de, sem
                  qualquer aviso prévio, cancelar senhas e dispositivos de
                  segurança e bloquear o acesso a seus Serviços, respeitadas as
                  condições da legislação aplicável e sem prejuízo de outras
                  medidas que entender aplicáveis. 2.5. O Usuário declara ser o
                  único responsável pela guarda e sigilo do seu nome de usuário
                  (login) e senhas cadastrados nas plataformas, que são de
                  caráter pessoal e intransferível. Dessa forma, o Usuário
                  assume a responsabilidade de nunca compartilhar seus dados de
                  acesso com outras pessoas, pois qualquer prejuízo sofrido por
                  essa divulgação será de sua exclusiva responsabilidade. 2.6. É
                  vedada a utilização das plataformas: (i) visando causar
                  qualquer dano ou prejuízo ao Bbankcred ou a terceiros; (ii)
                  para intencionalmente sobrecarregar, inutilizar ou prejudicar
                  o seu regular funcionamento; (iii) executar, publicar ou
                  disseminar vírus, spyware ou outros programas de computador
                  maliciosos, que possam causar danos; (iv) para publicação de
                  conteúdos que infrinjam direitos de autor, segredos de negócio
                  ou quaisquer outros direitos de terceiros, ou ainda que tenham
                  natureza discriminatória, ofensiva ou ilícita, e/ou (v) para
                  quaisquer fins ilícitos, como lavagem de dinheiro,
                  financiamento ao terrorismo e outros. 2.7. É reservado ao
                  Bbankcred o direito de editar ou mesmo excluir qualquer
                  conteúdo publicado por Usuários que não estejam em
                  conformidade com a legislação aplicável, regulamentos, Termos
                  de Uso, o Aviso Externo de Privacidade e as melhores práticas
                  comumente aceitas no mercado brasileiro. 2.8. Sem prejuízo de
                  outras medidas cabíveis, o Bbankcred poderá, suspender o
                  acesso às plataformas, temporária ou definitivamente, se: (i)
                  o Usuário descumprir as disposições constantes nestes Termos
                  de Uso; (ii) existirem suspeitas de prática de atos
                  fraudulentos ou ilegais; (iii) caso não seja possível a
                  verificação da identidade do Usuário, ou se os dados e
                  informações fornecidos estiverem incorretos, e/ou (iv) caso
                  seja verificada irregularidade de natureza grave nas
                  informações prestadas. 3. TERMOS E CONDIÇÕES DE USO
                  ESPECÍFICOS 3.1. Além desses Termos de Uso e o nosso Aviso
                  Externo de Privacidade, alguns Sites e Aplicativos podem ter
                  serviços e funcionalidades específicos e termos e condições
                  próprios ou adicionais para a sua utilização. Nesse caso, os
                  termos adicionais estarão disponíveis em referidos Sites e
                  Aplicativos e serão aplicáveis se você usar tais serviços e
                  funcionalidades. 4. DA ADESÃO 4.1. Para obtenção da totalidade
                  dos benefícios proporcionados pela Conta e Aplicativo
                  Bbankcred, como, por exemplo, o acesso a todos os Serviços
                  oferecidos conforme descrição na Cláusula 5, o Usuário deverá
                  efetuar o seu cadastro no Site e/ou no Aplicativo e fornecer
                  ao Bbankcred seus Dados Pessoais. Para a realização do
                  cadastro, é obrigatória a leitura, compreensão e Aceite dos
                  presentes Termos de Uso e pelo Usuário. O ACEITE DOS TERMOS DE
                  USO IMPLICA O RECONHECIMENTO, PELO USUÁRIO, DE QUE ELE LEU,
                  ENTENDEU E ACEITOU INCONDICIONALMENTE TODAS AS DISPOSIÇÕES
                  CONSTANTES DESSE DOCUMENTO. CASO O USUÁRIO TENHA QUALQUER
                  DÚVIDA SOBRE ESTES TERMOS DE USO E, O BBANKCRED SOLICITA QUE O
                  USUÁRIO ENTRE EM CONTATO ANTES DE ACEITAR E ESTAR SUJEITO ÀS
                  SUAS REGRAS. 4.2. A partir do momento em que o Usuário aceitar
                  os Termos de Uso, as disposições neles constantes serão
                  aplicáveis e irão regular plenamente a relação entre o
                  Bbankcred e o Usuário. Dessa forma, é recomendável eventual
                  impressão de uma cópia dos referidos documentos para futura
                  referência. 4.3. Por meio destes Termos de Uso e conforme e
                  informado no Aviso Externo de Privacidade, o Usuário concorda
                  e autoriza as empresas terceiras prestadoras de serviços do
                  Bbankcred (“Prestadores de Serviço, Representantes de Soluções
                  Financeiras e Franqueados”, em especial as listadas em Anexo
                  I) a tratarem (por exemplo: coletarem e armazenarem, assim
                  como qualquer uma das outras atividades permitidas pela lei)
                  imagens pessoais do Usuário e os Dados Pessoais solicitados no
                  Cadastro e/ou na atualização do Cadastro, inclusive aquelas na
                  modalidade selfie, a fim de que seja possível prestar os
                  serviços contratados pelo Usuário, bem como obter e/ou
                  fornecer score biométrico e/ou score cadastral. POR MEIO
                  DESTES TERMOS DE USO, O USUÁRIO DECLARA ESTAR CIENTE E
                  CONCORDAR QUE SEUS DADOS PESSOAIS FIQUEM ARMAZENADOS EM BASE
                  DE DADOS DO BBANKCRED E/OU DOS PRESTADORES DE SERVIÇO
                  TERCEIROS PARA OS FINS DE (I) VALIDAÇÃO DOS DADOS BIOMÉTRICOS,
                  PARA OPERAÇÕES DE CRÉDITO E OUTRAS OPERAÇÕES; (II) PROMOÇÃO DE
                  MAIOR SEGURANÇA NO USO DOS DADOS PESSOAIS, VISANDO A PREVENÇÃO
                  DE FRAUDES PELO USO INDEVIDO DESSES; (III) UTILIZAÇÃO DOS
                  DADOS PESSOAIS COMO PROVA LEGAL EM EVENTUAIS AÇÕES JUDICIAIS,
                  ADMINISTRATIVAS E/OU ARBITRAIS; (IV) CUMPRIMENTO DE ORDEM
                  JUDICIAL, DE AUTORIDADE ADMINISTRATIVA E/OU DE JUIZ ARBITRAL;
                  E (V) DESENVOLVIMENTO, MANUTENÇÃO E APERFEIÇOAMENTO DOS
                  RECURSOS E FUNCIONALIDADES DOS SERVIÇOS E SISTEMAS DOS
                  PRESTADORES DE SERVIÇO TERCEIROS. 4.4. O BBANKCRED PODERÁ
                  REQUERER SEU CONSENTIMENTO ESPECÍFICO, CASO O INDICADO NESSE
                  DOCUMENTO NÃO SEJA SUFICIENTE, RESULTE NECESSÁRIO DE
                  CONFORMIDADE COM ALGUMA NORMA LEGAL E/OU REGULATÓRIA, E/OU NÃO
                  SEJA POSSÍVEL APLICAR ALGUMA OUTRA HIPÓTESE LEGAL DE
                  TRATAMENTO DE DADOS PESSOAIS EM CONFORMIDADE COM A LEI, QUANDO
                  OPORTUNO, PARA O COMPARTILHAMENTO DE DADOS COM FINALIDADES
                  ESPECÍFICAS PARA A INSTITUIÇÃO PARCEIRA OU OUTROS PRESTADORES
                  DE SERVIÇOS TERCEIROS, OU AINDA COMPARTILHAMENTO DE DADOS E/OU
                  INICIAÇÃO DE TRANSAÇÃO PAGAMENTO ENTRE AS EMPRESAS DO GRUPO
                  BBBANKCRED 4.5. Além do compartilhamento de dados com
                  Prestadores de Serviço Terceiros, Franqueados, Representantes
                  de Soluções Financeiras e as Instituições Parceiras, o
                  Bbankcred poderá compartilhar seus dados com outras empresas
                  do grupo para o atendimento de finalidades lícitas e legítimas
                  em concordância com o indicado no Aviso Externo de Privacidade
                  e tendo como limite as disposições da LGPD e qualquer outra
                  norma legal e/ou regulatória aplicável. 4.6. O Bbankcred, no
                  papel de correspondente bancário, por atuar sob as diretrizes
                  da Instituição Parceira, poderá compartilhar seus dados para
                  essa finalidade. Isso abrange a execução de qualquer um dos
                  produtos e/ou serviços contratados pelo Usuário e que sejam
                  decorrentes do uso da conta e/ou cartões do Bbankcred. 4.7.
                  Ainda, em conformidade com o disposto no Aviso Externo de
                  Privacidade, caso o Usuário não deseje que os Prestadores de
                  Serviço Terceiros coletem e/ou armazenem as suas imagens
                  pessoais e/ou os seus Dados Pessoais, o Usuário deverá
                  cancelar o seu Cadastro no Bbankcred e expressamente solicitar
                  a exclusão de sua imagem e dos seus Dados Pessoais,
                  ressalvadas as hipóteses de guarda obrigatória de registros
                  previstas na lei aplicável ou da guarda necessária para
                  exercício regular de direito por parte do Bbankcred. 5.
                  CURTINDO O BBANKCRED 5.1. O Bbankcred é um é um banco digital,
                  gratuito e completo, que atua como líder na revolução da
                  indústria bancária brasileira, oferecendo um ecossistema
                  compartilhado com uma proposta de valor disruptiva. Oferece um
                  novo conceito de banco - ofertando um portifólio completo de
                  serviços e produtos financeiros, com cobrança de tarifas PAY
                  PER USER, para todos os tipos de clientes, independente da
                  condição econômica, social e idade. Possuímos um modelo de
                  negócio único, reunindo as melhores características da
                  indústria bancária tradicional e das empresas de tecnologia da
                  área financeira. 5.2. Antes de mais nada, além de ter mais de
                  18 (dezoito) anos, salvo as exceções indicadas nesses termos,
                  e ter um telefone móvel com sistema e rede compatíveis, você
                  irá precisar realizar um cadastro para poder começar a
                  utilizar o Aplicativo, momento em que o Bbankcred solicitará o
                  seu nome completo e o seu número de inscrição no CPF
                  (“Cadastro”) –e demais informações cadastrais a critério do
                  Bbankcred e conforme regulamentação aplicável. As informações
                  solicitadas no Cadastro deverão ser completas, preenchendo-se
                  todos os espaços obrigatórios com informações exatas, precisas
                  e verdadeiras, sendo o Usuário o único responsável pelos dados
                  fornecidos, obrigando-se, ainda, a atualizá-los anualmente ou
                  sempre que solicitado pelo Bbankcred e/ou o Banco Central do
                  Brasil. O Usuário se responsabiliza por qualquer resultado ou
                  prejuízo decorrente da falsidade das suas informações pessoais
                  ou da sua não atualização. 5.2.1. No Computador é possível
                  realizar o pré-cadastro (informar nome, sobrenome, CPF,
                  celular, data de nascimento, e-mail e dar o aceite nesses
                  Termos de Uso). O Cadastro e a utilização dos serviços só
                  poderão ser realizados por meio do Aplicativo. 5.2.2. O
                  Bbankcred não terá obrigação de policiar ou fiscalizar as
                  informações e os Dados Pessoais fornecidos pelos Usuários, à
                  parte de suas obrigações regulamentares, mas poderá, a seu
                  exclusivo critério, bloquear ou cancelar as contas cadastradas
                  por meio de informações inverídicas ou excessivas. 5.2.3. Caso
                  o Bbankcred detecte a abertura ou a manutenção de Conta
                  Bbankcred a partir de informações falsas, incompletas,
                  equivocadas, errôneas, enganosas, ou, ainda, que não permita
                  identificar a identidade do Usuário, o Bbankcred poderá
                  encerrar a conta imediatamente ou solicitar esclarecimentos e
                  documentação adicional que julgue necessária para a devida
                  comprovação das informações prestadas e para a validação do
                  Cadastro, podendo, inclusive, recusar-se a validar qualquer
                  Cadastro, a seu exclusivo critério. 5.2.4. O Usuário que não
                  atualizar seu Cadastro ou não enviar os esclarecimentos
                  adicionais e documentações sempre que for solicitado pelo
                  Bbankcred, estará sujeito à (I) suspensão dos Serviços, até a
                  regularização das inconsistências cadastrais que motivaram a
                  suspensão; (II) suspensão com posterior cancelamento da sua
                  Conta Bbankcred, caso em que o Usuário deverá solicitar
                  imediatamente a retirada de eventual saldo em dinheiro
                  indicado em sua Conta Bbankcred para outra conta bancária de
                  sua titularidade; e/ou (III) responsabilização nas esferas
                  cível, administrativa e criminal na forma prevista em lei.
                  5.2.5. Somente será permitida a vinculação de um Cadastro a um
                  CPF, um telefone e um e-mail, não podendo haver duplicidade de
                  dados. É vedada a criação de mais de um Cadastro por Usuário.
                  Em caso de multiplicidade de Cadastros elaborados por um só
                  Usuário, o Bbankcred se reserva o direito de a seu exclusivo
                  critério e sem necessidade de prévia anuência do Usuário ou
                  comunicação ao Usuário, inabilitar todos os cadastros
                  existentes e impedir eventuais cadastros futuros vinculados a
                  estes. 5.2.6. O Cadastro inicial do Usuário permanecerá válido
                  enquanto o seu saldo estiver limitado a R$ xxxxxx (xxxx
                  reais). O Bbankcred poderá solicitar algumas informações
                  adicionais caso (I) o seu saldo mantido na Conta Bbankcred ou
                  o somatório dos aportes que você efetuar em cada mês superar o
                  valor de R$ xxxxx (xxxxx reais); ou (II) a natureza jurídica
                  do Serviço exija informações adicionais. 5.3. O Bbankcred
                  reconhece a identidade de gênero de pessoas travestis e
                  transexuais ou transgêneros. Nesse sentido, o Bbankcred
                  realizará a alteração do nome do Usuário registrado no
                  Cadastro para o Nome Social indicado, quando solicitado pelo
                  Usuário por meio dos canais oficiais de comunicação com o
                  Bbankcred. Para realizar tal alteração, o Usuário deverá
                  fornecer ao Bbankcred documento com CPF no qual conste o
                  referido Nome Social, nos termos da Instrução Normativa RFB Nº
                  1718, de 18 de julho de 2017. Destaca-se que o Usuário é o
                  único responsável pela solicitação da alteração do nome do
                  Usuário do Cadastro e pelo uso do Nome Social. 5.4. Após a
                  conclusão do Cadastro, a sua Conta Bbankcred, a ser
                  movimentada mediante débitos e créditos resultantes do uso dos
                  Serviços, estará automaticamente criada para fins de órgãos
                  reguladores. A partir da criação de sua Conta Bbankcred a
                  utilização pelo Usuário poderá depender da validação dos dados
                  pelo Bbankcred e de um saldo mínimo, informado pelo Bbankcred
                  no Site ou no Aplicativo. Após a ativação de sua Conta, o
                  Bbankcred poderá realizar a emissão do seu cartão físico
                  Bbankcred e/ou você poderá solicitar a emissão dos Cartões
                  Bbankcred e começar a desfrutar de tudo o que podemos lhe
                  oferecer. O aporte de recursos de sua Conta Bbankcred poderá
                  ser feito através de DOC, TED, pagamento de boleto bancário ou
                  transferência, incluindo as transferências realizadas via PIX,
                  como for mais fácil para você! 5.5. O Usuário concorda em
                  manter sob sua guarda e sigilo suas senhas (tanto a senha de
                  acesso ao Aplicativo, quanto a senha individual de cada um dos
                  Cartões Bbankcred), de forma que o Bbankcred não será, em
                  nenhuma hipótese, responsável por quaisquer prejuízos causados
                  ao Usuário ou a quaisquer terceiros pela divulgação e
                  utilização indevida das referidas senhas. O Usuário ainda se
                  compromete a notificar imediatamente ao Bbankcred, por meio
                  idôneo e fidedigno, sobre qualquer uso não autorizado na sua
                  Conta Bbankcred, assim como do acesso ou de tentativas de
                  acesso por terceiros não autorizados. O Bbankcred reserva-se
                  no direito de bloquear a sua senha caso sejam verificadas
                  movimentações atípicas em sua Conta Bbankcred, conforme
                  processo de monitoramento de prevenção de fraudes e outras
                  atividades ilícitas. 5.6. Em caso de perda, roubo, extravio ou
                  uso indevido dos Cartões Bbankcred na forma física, você
                  deverá nos informar o mais rápido possível para que possamos
                  cancelar imediatamente o respectivo Cartão Bbankcred, mediante
                  comunicação à central de atendimento pelos canais de
                  atendimento eletrônicos do Bbankcred e/ou aplicativo para
                  celular. 5.7. O Cartão Físico é desenvolvido utilizando-se
                  tecnologia de chip e/ou contactless e será enviado ao endereço
                  que você indicar, devendo ser desbloqueado seguindo instruções
                  contidas no Site ou no Aplicativo para começar a utilizar os
                  Serviços. 5.8. É expressamente vedada a utilização do Cartão,
                  em qualquer função contratada, em operações não permitidas por
                  lei, tais como, mas não limitada, a jogos de azar no
                  território brasileiro. Constatado o uso irregular do Cartão, o
                  Bbankcred poderá proceder ao seu imediato cancelamento
                  mediante aviso ao titular a respeito. 5.9. Constatado o uso
                  irregular, inadequado, ou suspeito do Cartão, em qualquer
                  função contratada, o Bbankcred, a seu exclusivo critério,
                  poderá promover a suspensão imediata do uso do Cartão pelo
                  prazo que entender cabível, ou o seu cancelamento, sem
                  prejuízo da aplicação das demais sanções legais e contratuais
                  cabíveis. 5.10. O Bbankcred reserva o direito não efetuar
                  transações que identifique estar em desacordo com os seus
                  Termos de Uso. 5.11. Eis aqui algumas informações úteis sobre
                  todas as formas por meio das quais você poderá curtir e
                  desfrutar de todos os Serviços ofertados pelo Bbankcred e
                  pelas Instituições Parceiras, se aplicável. Para facilitar
                  nossas vidas, descrevemos abaixo brevemente as principais
                  características dos nossos Serviços para que você possa
                  desfrutá-los da melhor forma possível, todos podendo ser
                  realizados com apenas poucos comandos diretamente no
                  Aplicativo e/ou Site: (I) Transferência entre Contas
                  Bbankcred: este produto permitirá a transferência de recursos
                  de um Usuário para outro Usuário, ambos clientes do Bbankcred,
                  incluindo via PIX. Há um limite diário e/ou mensal de
                  transferências e de valores de transferências que pode ser
                  checado no Aplicativo e que deve ser acompanhado pelo Usuário.
                  (II) Transferências para contas em outras instituições
                  financeiras via TED: este produto contempla a transferência de
                  recursos de um Usuário para um terceiro (Usuário ou não)
                  titular de conta corrente junto à instituição financeira de
                  sua escolha, desde que respeitados os horários limites
                  estabelecidos pela Instituição Parceira. Há um limite diário
                  e/ou mensal para transferências que pode ser checado no
                  Aplicativo e que deve ser acompanhado pelo Usuário. (III)
                  Transferências via PIX: O Bbankcred é participante indireto do
                  PIX, logo suas transações são liquidadas por intermédio de um
                  participante direto, Instituição Parceira do Bbankcred. Este
                  produto contempla a transferência de recursos via arranjo de
                  pagamentos instantâneos, na qual o Usuário pode atuar como
                  pagador ou recebedor dos recursos transferidos via o arranjo
                  de pagamentos instantâneos do Banco Central do Brasil, estando
                  disponível 24 (vinte e quatro) horas por dia, todos os dias do
                  ano, incluindo finais de semana e feriados. Esse serviço está
                  sujeito à confirmação, autenticação e autorização da transação
                  por parte da instituição junto da qual o recebedor possui uma
                  conta transacional. Há um limite diário e/ou mensal para
                  transferências que pode ser checado no Aplicativo e que deve
                  ser acompanhado pelo Usuário. (IV) Pagamento de boletos e de
                  contas de consumo: este produto contempla pagamento de
                  boletos, ou qualquer outra conta de consumo que o Usuário
                  possua, incluindo, mas não se limitando a, recarga de
                  celulares, guias, contribuições estatais e/ou federais. Há um
                  limite diário e/ou mensal para pagamento de boletos e contas
                  de consumo que pode ser checado no Aplicativo e no Site e que
                  deve ser acompanhado pelo Usuário. (V) Saque: este produto
                  contempla a retirada de recursos existentes na Conta Bbankcred
                  do Usuário em um caixa eletrônico compatível e disponível para
                  a bandeira dos Cartões Bbankcred; trata-se de débito direto na
                  Conta Bbankcred. Para maiores informações sobre esse serviço,
                  por favor, veja a Cláusula 5.11 abaixo. Há um limite diário
                  e/ou mensal para Saques/valores que pode ser checado no
                  Aplicativo e no Site e que deve ser acompanhado pelo Usuário.
                  (VI) Recebimento de transferências: este produto contempla o
                  recebimento de valores oriundos de outras contas, tanto do
                  próprio Usuário, quanto de conta de terceiros. O Usuário ou
                  terceiro que desejar realizar a transferência deverá indicar
                  os dados solicitados, como Cadastro de Pessoa Física ou
                  Cadastro Nacional de Pessoa Jurídica e os dados referentes à
                  conta do Usuário, e o Bbankcred será responsável pela ordem de
                  crédito na conta indicada do Usuário. Ainda, o recebimento de
                  transferências poderá ser realizado também por meio do PIX,
                  mediante disponibilização pelo Usuário Recebedor, conforme
                  aplicável (i) de sua Chave PIX; ou (ii) de seu QR Code
                  (estático ou dinâmico); ou (iii) de tecnologias que permitam a
                  troca de informações por aproximação, como a tecnologia
                  near-field communication (NFC), caso disponibilizadas, a
                  critério do Bbankcred, desde que o Bbankcred confirme,
                  autentique e autorize o recebimento. (VII) Aplicação em CDB da
                  Instituição Parceira e Floating: este produto contempla a
                  possibilidade de utilizar os recursos disponíveis em conta
                  para realizar um investimento em CDB emitido por Instituição
                  Parceira. Os CDB serão resgatados pelo Bbankcred, conforme
                  necessidade de cancelamento ou bloqueio de conta do CLIENTE,
                  bem como para ressarcimento de dívidas do Usuário com o
                  Bbankcred. (VIII) Cartões Bbankcred: são utilizados para
                  realizar transações de pagamento através do Cartão Físico e
                  Cartão Virtual, no Brasil ou no exterior, de acordo com as
                  regras definidas pelo instituidor do arranjo de pagamento dos
                  Cartões Bbankcred. (IX) Cashback: comissionamento feito pelo
                  Bbankcred ou por empresas parceiras por benefícios oferecidos
                  através do Software; (X) Folhas Salariais: prestação de
                  serviços a empresas para a distribuição de folha salarial a
                  funcionário por meio do Software; (XI) Emissão de boleto:
                  prestação de serviços de emissão de boletos de pagamento,
                  podendo ser boleto de cobrança, boleto de proposta ou boleto
                  de depósito. Há um limite diário e/ou mensal para emissão de
                  boletos/valores que pode ser checado no Aplicativo e no Site e
                  que deve ser acompanhado pelo Usuário. (XII) Parceiros:
                  empresas que poderão fazer oferta de produtos/serviços por
                  meio do Aplicativo, não relacionados ou vinculados ao
                  Bbankcred. (XIII) Produtos de crédito: oferta de produtos de
                  crédito à base de clientes (i.e., CDC, consignado ou rotativo
                  por meio de Cartão Pós-Pago) a serem contratados junto à
                  Instituição Parceira e mediante aprovação prévia. (XIV) Minhas
                  conquistas e Bbankcred +: programa de fidelidade e engajamento
                  do Bbankcred que reconhece o desenvolvimento financeiro do
                  Usuário e sua relação com o Bbankcred, através de recompensas
                  digitais ou benefícios; (XV) Pagamentos no WhatsApp: o
                  Bbankcred disponibiliza ao Usuário, por meio do aplicativo
                  WhatsApp, a Funcionalidade de pagamentos no WhatsApp, que se
                  trata de serviço de transferência de valores dentro do
                  aplicativo, disponível 24h (vinte e quatro horas) por dia, 7
                  (sete) dias por semana. O serviço de pagamentos no WhatsApp é
                  oferecido pelo Facebook Pay e processado pelo Facebook
                  Pagamentos e um provedor de serviços de pagamentos local. Há
                  um limite diário e/ou mensal para esses pagamentos que pode
                  ser checado no Aplicativo e que deve ser acompanhado pelo
                  Usuário. (XVI) Iniciação de Pagamentos: o Bbankcred, como
                  detentora de conta de pagamento, permitirá que, quando você
                  inicie uma transação de pagamento em outra instituição, possa
                  acessar a sua Conta Bbankcred dentro da jornada desta
                  transação para realizar pagamentos ou transferências. (XVII)
                  Compartilhamento de dados cadastrais e transacionais: o
                  Bbankcred, por meio de instituição parceira e/ou diretamente,
                  permite que você compartilhe conosco, caso queira, dados de
                  cadastro e transações junto a outras instituições, para que
                  possamos melhorar a oferta de produtos serviços a você.
                  (XVIII) Pagamento de Empréstimo Pessoal com Débito em Conta:
                  todas as quantias devidas em função dos empréstimos
                  contratados pelo Usuário que opte pelo pagamento em Débito
                  Automático serão pagas mediante débito na Conta-Corrente, nos
                  respectivos vencimentos. Para essa forma de pagamento, é
                  necessário planejamento e cuidado, para assim garantir que
                  terá valores em conta no dia do desconto e você mantenha a
                  dívida em dia. 5.12. No caso dos produtos de crédito indicados
                  no item 3.11. (XII) acima, note que as estimativas que lhe
                  forem apresentadas são baseadas exclusivamente nos dados que
                  você nos forneceu e, caso tenha consentido, obtidos por meio
                  de parceria e/ou diretamente pelo Bbankcred em outras
                  instituições com as quais você mantenha relacionamento. Assim,
                  você concorda que todas as decisões que você tomar em relação
                  aos produtos de crédito, serão de sua exclusiva
                  responsabilidade. 5.13. Na tentativa de sempre oferecer o que
                  há de melhor para você ao utilizar nossos Serviços, estamos
                  sempre nos aprimorando e em constante desenvolvimento de novos
                  produtos. Fique tranquilo que você será sempre o primeiro a
                  saber quando realizarmos o lançamento de novos Serviços ou
                  alterarmos qualquer informação a respeito deles, inclusive
                  taxas! 5.14.Saques 5.14.1. Retirada de recursos (“Saque”) é o
                  serviço de retirada de dinheiro com o seu Cartão Físico, no
                  país ou no exterior, nos terminais habilitados. 5.14.2. O
                  limite para o Saque está sujeito à avaliação do saldo e do
                  limite de Saques do Usuário pelo Bbankcred no momento de sua
                  realização. 5.14.3. O valor total do Saque e respectivos
                  impostos deverão ser pagos pelo Usuário, sendo que o Bbankcred
                  fará o débito diretamente da Conta Bbankcred do Usuário no
                  momento da realização do Saque. 5.14.4. Para a realização de
                  Saques no exterior, além dos impostos, poderá́ haver a cobrança
                  de tarifas pela empresa responsável pelo terminal utilizado.
                  Essa tarifa não é comandada e nem está sob o controle ou
                  gestão do Bbankcred ou da Instituição Parceira.
                  5.15.Utilização Internacional 5.15.1. O Usuário poderá
                  utilizar o seu Cartão Bbankcred no exterior para compras ou
                  Saques. Na realização de transações internacionais, em
                  estabelecimentos no exterior ou em sites de compras
                  internacionais, o dólar norte-americano será convertido para
                  moeda corrente nacional e debitado da Conta Bbankcred. 5.15.2.
                  O valor das operações realizadas em estabelecimentos no
                  exterior ou em sites de compras internacionais em moeda
                  distinta do dólar norte-americano, primeiramente será́
                  convertido pela “bandeira do seu cartão” em dólar
                  norte-americano, conforme os sistemas e critérios utilizados.
                  5.15.3. Caso haja variação cambial entre o momento do débito
                  da Conta Bbankcred do Usuário e a data da liquidação
                  financeira da transação, o valor relativo a essa diferença
                  será creditado ou debitado, conforme o caso, direto na Conta.
                  5.15.4. A taxa de câmbio do dólar norte-americano utilizada
                  pela “bandeira do cartão” será́ compatível com a taxa média de
                  mercado para operações de varejo com Pessoas Físicas, podendo,
                  em determinados dias, ser superior ou inferior à taxa média
                  divulgada pelo Banco Central do Brasil. Caso haja variação
                  cambial e não haja saldo disponível na Conta do Usuário na
                  data da liquidação financeira, o valor remanescente referente
                  à variação cambial, bem como os acréscimos legais, será
                  debitado automaticamente do saldo do Usuário tão logo seja
                  disponibilizado o valor na Conta do Usuário. 5.15.5. Os
                  impostos decorrentes da remessa de moeda ao exterior
                  necessária para pagamento das operações realizadas com o seu
                  Cartão Bbankcred serão de sua responsabilidade e, portanto,
                  serão repassados pelo Bbankcred a você por meio de débito
                  direto na sua Conta Bbankcred. Caso haja restrição que impeça
                  momentaneamente a remessa de moeda ao exterior, você será́
                  responsável pelo valor da eventual variação cambial da
                  remessa, quando autorizada. 5.15.6. Além dos impostos
                  decorrentes da remessa de moeda ao exterior, o Usuário declara
                  que está de acordo com a cobrança pelo Bbankcred de uma tarifa
                  para cobrir os custos da transação internacional. Para maiores
                  informações, consulte o Site ou Aplicativo. 5.15.7. A
                  utilização do Cartão Bbankcred em outro país não será
                  autorizada em estabelecimentos não permitidos pela legislação
                  brasileira. Além disso, a utilização do seu Cartão Bbankcred
                  no exterior implica a sua responsabilidade de observar as
                  determinações legais em vigor, em especial, as normas do Banco
                  Central do Brasil sobre o mercado de câmbio. 5.15.8. VOCÊ, NA
                  QUALIDADE DE USUÁRIO, DECLARA CIÊNCIA E CONCORDA QUE (I) O
                  BBANKCRED É OBRIGADO A FORNECER AO BANCO CENTRAL DO BRASIL
                  INFORMAÇÕES SOBRE TODAS AS TRANSAÇÕES REALIZADAS COM O CARTÃO
                  BBANKCRED NO EXTERIOR; E (II) O BANCO CENTRAL DO BRASIL PODERÁ́
                  COMUNICAR EVENTUAIS INDÍCIOS DE IRREGULARIDADES À RECEITA
                  FEDERAL DO BRASIL OU OUTRO ÓRGÃO PÚBLICO COMPETENTE. 5.15.9.
                  Constatado o uso irregular, inadequado ou suspeito do Cartão
                  Bbankcred no exterior, o Bbankcred a seu exclusivo critério,
                  poderá́ promover a suspensão imediata do uso do Cartão
                  Bbankcred ou o seu cancelamento, sem prejuízo da aplicação das
                  demais sanções legais e contratuais cabíveis. 5.15.10. A
                  utilização do Cartão Bbankcred no exterior restringe-se a
                  Saques, pagamentos de gastos com viagem e manutenção pessoal
                  do Usuário e compra de bens e/ou serviços nos limites da
                  legislação aplicável e de acordo com estes Termos de Uso. Ao
                  aceitar estes Termos de Uso e utilizar os Serviços, você, na
                  qualidade de Usuário, declara que não utilizará Cartão
                  Bbankcred para a compra de bens e/ou serviços que possam
                  caracterizar investimento no exterior ou importação
                  brasileira. 5.16.Transferências e Pagamentos via PIX 5.16.1. A
                  realização ou o recebimento de transferências via PIX ocorrerá
                  no próprio Aplicativo, não sendo necessário que o Usuário
                  baixe um novo aplicativo para usar tal funcionalidade. 5.16.2.
                  Para receber transferências via PIX, o Usuário poderá
                  cadastrar sua(s) Chave(s), de forma a vinculá-la(s) à sua
                  Conta Bbankcred, de acordo com o indicado no Aplicativo e/ou
                  Poderão ser armazenados os seguintes dados da (i) Pessoa
                  Física: CPF, telefone celular, e-mail ou aleatória. Cada Chave
                  PIX poderá ser vinculada a uma única Conta Bbankcred. 5.16.3.
                  O cadastro da Chave PIX é opcional e não é necessário para
                  realizar ou receber uma transferência via PIX, mas é
                  ferramenta que facilita essa operação. É fundamental que o
                  cadastro seja atualizado, toda vez que as informações que
                  constam nele identificam o Usuário e sua Conta Bbankcred. O
                  procedimento de cadastramento de Chave(s) PIX se dá por meio
                  do envio das informações solicitadas pelo Bbankcred ao
                  Usuário, para realização do respectivo registro, conforme
                  indicado abaixo. 5.16.4. A todo momento, o Bbankcred
                  disponibilizará as seguintes funcionalidades associadas às
                  Chaves PIX: (i) registro: permite a vinculação de uma Chave
                  PIX a uma Conta Bbankcred; (ii) exclusão: permite a remoção do
                  vínculo existente entre uma Chave PIX a uma Bbankcred; (iii)
                  portabilidade: permite a transferência do vínculo de
                  determinada Chave PIX da Conta Bbankcred original para uma
                  nova conta de mesma titularidade, mantida na instituição que
                  reivindicar a portabilidade; e (iv) reivindicação de posse:
                  permite a transferência do vínculo de determinada Chave PIX da
                  Conta Bbankcred original para uma nova conta, de titularidade
                  diferente, mantida na instituição reivindicadora. 5.16.5. O
                  Bbankcred solicitará a exclusão da sua Chave PIX, de forma
                  automática, sem prévio aviso, nos seguintes casos de: (i)
                  encerramento da Conta Bbankcred e/ou rescisão destes Termos de
                  Uso; (ii) suspeita, tentativa ou efetivação de uso fraudulento
                  da Chave PIX; (iii) identificação da necessidade de ajuste
                  após processo de verificação de sincronismo de Chaves PIX; ou
                  (iv) inatividade de uso da Chave PIX, caracterizada pelo não
                  recebimento de ordens de liquidação por mais de 12 (doze)
                  meses. 5.16.6. Para quaisquer transações via PIX, os padrões
                  de iniciação e efetivação seguirão os parâmetros estabelecidos
                  no Aplicativo, no Site, nestes Termos e na regulação
                  aplicável. 5.16.7. Ainda, além das regras previstas neste
                  Termos de Uso, o Usuário também poderá estar sujeito a
                  regulação aplicável aos usuários finais das transações via
                  PIX, conforme definido pelo Banco Central do Brasil. 5.16.8. O
                  Usuário se compromete a guardar sua Chave PIX com segurança e
                  responsabilidade, compartilhando-a de forma cuidadosa e
                  diligente. O Usuário se compromete a: (i) fazer o
                  cadastramento das Chaves PIX nos canais oficiais do Bbankcred;
                  (ii) nunca fornecer sua Chave PIX em ligações ou mensagens que
                  receber para cadastramento, atualização, confirmação da Chave
                  PIX, ou para qualquer outra finalidade; (iii) não permitir o
                  acesso remoto aos seus dispositivos para habilitação do PIX.
                  5.16.9. Será aplicado o Mecanismo Especial de Devolução, nos
                  termos estabelecidos pelo Banco Central do Brasil, para os
                  casos em que se verifique fundada suspeita do uso do arranjo
                  para a prática de fraude e naqueles em que se verifique falha
                  operacional no sistema de tecnologia da informação de qualquer
                  dos participantes envolvidos na transação. 5.16.10. A
                  solicitação de devolução pode ser realizada pelo Bbankcred,
                  por iniciativa própria, ou a pedido do usuário. 5.16.10.1.
                  Ficarão condicionadas à abertura e à conclusão, com a
                  aceitação do participante prestador de serviço de pagamento do
                  usuário pagador/recebedor, do procedimento de notificação de
                  infração relativo à transação a ser devolvida, sempre que
                  houver fundada suspeita do uso do arranjo para a prática de
                  fraude. O participante que receber a notificação de infração
                  deverá analisá-la e decidir por aceitá-la ou rejeitá-la.
                  5.16.10.2. AO ACEITAR O PRESENTE TERMO DE USO O USUÁRIO
                  AUTORIZA EXPRESSAMENTE AS DEVOLUÇÕES REALIZADAS NO ÂMBITO DO
                  MECANISMO ESPECIAL DE DEVOLUÇÃO E, INCLUSIVE, A POSSIBILIDADE
                  DE BLOQUEIO DOS RECURSOS MANTIDOS NA CONTA TRANSACIONAL, EM
                  UMA OU MAIS PARCELAS, ATÉ O ATINGIMENTO DO VALOR TOTAL DA
                  TRANSAÇÃO. 5.16.10.3. Implica o bloqueio imediato na conta
                  transacional do usuário os valores cuja devolução é
                  solicitada, ou, sendo menor, do valor correspondente ao saldo
                  nela disponível, exclusivamente em casos decorrentes de
                  fundada suspeita de fraude. Nesse sentido, é permitida a
                  realização de múltiplos bloqueios parciais na conta
                  transacional do usuário recebedor, até que se alcance o valor
                  total da transação objeto da solicitação de devolução.
                  5.16.10.4. O usuário recebedor dos recursos cuja devolução é
                  pleiteada será prontamente comunicado sobre a efetivação (i)
                  do bloqueio de recursos em sua conta transacional; e (ii) da
                  concretização de uma devolução realizada. O usuário recebedor
                  pode solicitar, no prazo de 30 (trinta) dias contado da
                  comunicação. 5.17.Pagamentos no WhatsApp 5.17.1. A
                  disponibilidade do serviço dependerá dos serviços prestados
                  pela Operadora de Serviços de Telecomunicação ao Usuário, bem
                  como da compatibilidade de seu dispositivo móvel com os
                  Serviços aqui relacionados, isentando o Bbankcred de eventuais
                  falhas decorrentes dos serviços não prestados. 5.17.2. Ao
                  recorrer à funcionalidade, o Usuário tem ciência da
                  necessidade de executar a transferência dos dados pessoais e
                  outras informações financeiras relativas à transação entre o
                  Bbankcred e o WhatsApp para o adequado cumprimento da função
                  e, consequentemente, tem o direito de decidir até qual momento
                  isso poderá ocorrer. 5.17.3. O Bbankcred se exime de qualquer
                  responsabilidade pelas perdas e danos, de toda natureza, que
                  possam ser atribuídos à presença de vírus ou de outros
                  elementos incidentes no aparelho do Usuário, bem como
                  quaisquer danos oriundos do uso inadequado do mecanismo de
                  pagamentos no WhatsApp pelo Usuário ou decorrente de qualquer
                  falha de desempenho, erro, omissão, interrupção, defeito ou
                  demora na operação ou transmissão, vírus digital ou falha nas
                  redes de telecomunicações. 5.17.4. O Usuário é responsável por
                  comunicar ao Bbankcred, imediatamente, no caso de perda,
                  extravio, furto ou roubo de seu aparelho celular caso tenha
                  cadastrado seu Cartão Bbankcred na funcionalidade de
                  pagamentos no WhatsApp, para que seu Cartão possa ser
                  bloqueado. 5.17.5. O serviço de Pagamentos deve ser utilizado
                  apenas para fins legais, autorizados e aceitáveis. 5.17.6. Se
                  você enviar um pagamento para um Usuário não registrado no
                  serviço de pagamentos no WhatsApp, o Processador de Pagamentos
                  do WhatsApp poderá deduzir ou reter o pagamento até que o
                  destinatário esteja registrado e conclua todas as etapas
                  necessárias para receber os fundos ou até que o pagamento
                  expire. 5.17.7. O Bbankcred não se responsabiliza por
                  quaisquer bens ou serviços adquiridos utilizando o serviço de
                  pagamentos no WhatsApp, incluindo reclamações, danos, perdas,
                  obrigações, estornos ou disputas associadas a transações.
                  5.17.8. Ao enviar um pagamento pelo serviço de pagamentos no
                  WhatsApp, ele se torna definitivo. 5.17.9. Em caso de dúvidas
                  sobre a forma como tratamos os dados pessoais, o Usuário pode
                  entrar em contato com nossa Encarregada pelo Tratamento de
                  Dados Pessoais através do e-mail juridico@Bbankcred.com.br. 6.
                  LIMITE DOS CARTÕES BBANKCRED 6.1. Você poderá utilizar o seu
                  Cartão Bbankcred até o valor do seu limite disponível
                  (“Limite”). 6.2. O Limite será comprometido pelo valor total
                  de: (I) gastos e despesas decorrentes do uso do Cartão
                  Bbankcred; (II) pré-autorizações de operações com o Cartão
                  Bbankcred; (III) juros, impostos e demais despesas de acordo
                  com estes Termos de Uso; e (IV) outros pagamentos devidos ao
                  Bbankcred ou à Instituição Parceira nos termos destes Termos
                  de Uso. 6.3. O seu Limite estará disponível para consulta no
                  Aplicativo ou no Site, na central de atendimento, sendo que,
                  na modalidade Crédito, poderá ser reduzido, a critério do
                  Bbankcred, a qualquer momento, sem a necessidade de
                  comunicação prévia, em caso de deterioração do perfil de risco
                  de crédito e uma vez que a informação está disponível em seu
                  aplicativo. Para aumento, vamos solicitar sua concordância por
                  meio do App. O uso do Cartão Bbankcred, a indicação no
                  Aplicativo ou Site de alteração do valor do Limite, implicará
                  sua concordância com o novo Limite, se majorado e, caso
                  reduzido, será comunicado ao Usuário com, no mínimo, 30 dias
                  de antecedência, sem necessidade de anuência do Usuário. 6.4.
                  Você deve acompanhar sempre o seu Limite. O Bbankcred negará a
                  utilização do seu Cartão Bbankcred caso não haja Limite
                  disponível para determinada transação. 6.5. O Limite Elástico
                  é uma função do cartão de crédito que permite que suas
                  transações sejam aprovadas mesmo depois de ter seu limite de
                  crédito atingido. Nessa função, a cada tentativa de compra
                  será feita uma análise pontual da transação realizada, podendo
                  ser aprovada ou não. Para ativar ou desativar esta função,
                  basta acessar a aba Crédito Limite. Uma vez dado o aceite, o
                  limite elástico permanecerá ativo, independentemente de
                  eventuais aumentos de limite. Para desabilitar a
                  funcionalidade será necessário que o Usuário faça a
                  desabilitação no próprio aplicativo. 7. DA COMUNICAÇÃO COM O
                  BBANKCRED 7.1. Para qualquer assunto relacionado às condições
                  destes Termos de Uso bem como ao
                  Site/Aplicativo/Conteúdo/Software, o Usuário deverá entrar em
                  contato com o Bbankcred por meio de (I) mensagem endereçada ao
                  e-mail atendmento@Bbankcred.om.br (II) chat no próprio
                  Aplicativo ou no Site; (III) páginas do Bbankcred em redes
                  sociais; ou (IV) pelo telefone 61 3971 2999. Caso tenha alguma
                  dúvida sobre o Aviso Externo de Privacidade ou deseje
                  esclarecer qualquer informação sobre a forma como o Bbankcred
                  trata seus dados pessoais para executar qualquer uma das
                  atividades descritas nestes Termos de Uso, você pode entrar em
                  contato com nossa Encarregada pelo Tratamento de Dados
                  Pessoais / Data Protection Officer (DPO) Ingrid Tietro
                  Nascimento de Sousa pelo e-mail juridico@Bbankcred.com.br 7.2.
                  Ao concordar com os presentes Termos e Condições de Uso, o
                  Usuário reconhece desde já que o Bbankcred poderá contatá-lo
                  por qualquer meio, inclusive e-mail, telefone, SMS, push,
                  aplicativo WhatsApp e correspondência, para o envio de
                  comunicações sobre a sua Conta. 7.3. Ainda, para referência e
                  garantia de transparência e segurança no relacionamento com o
                  Usuário, o Bbankcred poderá manter em arquivo toda ou parte da
                  comunicação trocada entre o suporte do Bbankcred e o Usuário.
                  Será um prazer conhecê-lo e conversar sobre nossos Serviços!
                  8. DA UTILIZAÇÃO DO SOFTWARE 8.1. O Bbankcred, por meio destes
                  Termos de Uso, concede ao Usuário uma licença pessoal,
                  limitada, temporária, mundial, revogável, não exclusiva e
                  intransferível de uso do Software, durante o período de
                  manutenção da Conta Bbankcred, sem cobrança de remuneração de
                  qualquer natureza, sendo certo que o Usuário não poderá
                  utilizar e nem permitir o uso do Software para qualquer outra
                  finalidade não expressamente prevista nestes Termos de Uso.
                  Desta forma, não será permitido ao Usuário, sem qualquer
                  limitação, copiar, modificar, distribuir, vender, alugar,
                  compartilhar ou, ainda, ceder, a qualquer título, os serviços
                  disponibilizados pelo Bbankcred e viabilizados pelo Software
                  e/ou qualquer dos benefícios e utilidades deles decorrentes. O
                  Usuário também não poderá modificar o Software de qualquer
                  maneira com a finalidade de obter acesso não autorizado às
                  suas funcionalidades. O Bbankcred se reserva todos os direitos
                  do Software que não estejam expressamente concedidos aqui.
                  8.2. No ato de seu cadastro no Site e/ou no Aplicativo, o
                  Usuário fornecerá ao Bbankcred, o e-mail de usuário e senha
                  que, em conjunto com o CPF, serão por ele utilizados para o
                  acesso à sua Conta Bbankcred. O Usuário não poderá acessar o
                  Site e o Aplicativo por qualquer meio que não sejam as
                  interfaces fornecidas para tanto pelo Bbankcred. 8.2.1. O
                  Usuário é responsável pela veracidade, validade e precisão dos
                  Dados Pessoais por ele fornecidos no seu Cadastro e no
                  cadastramento de Chaves PIX ou qualquer outra informação ou
                  funcionalidade para transferências via PIX, inclusive em
                  relação à indicação de endereço de e-mail válido de sua
                  titularidade, competindo-lhe, ainda, manter referido Cadastro,
                  informação ou funcionalidade sempre atualizado. Portanto,
                  quando da alteração de qualquer dos parâmetros inicialmente
                  informados, deverá o Usuário imediatamente atualizar o seu
                  Cadastro, as informações prestadas ou as funcionalidades
                  cadastradas, sob pena de ter sua Conta Bbankcred bloqueada ou
                  cancelada, independente de aviso prévio. 8.2.2. O Usuário
                  deverá criar e manusear sua Conta Bbankcred acessando o
                  Aplicativo através de dispositivo seguro e com sistemas
                  antivírus atualizados, não utilizando redes públicas de wifi,
                  em lan houses e cyber cafés, por exemplo. 8.2.3. A senha e o
                  Login criados pelo Usuário para acesso a sua Conta Bbankcred
                  são confidenciais e de responsabilidade exclusiva do Usuário,
                  que deverá entrar em contato com o Bbankcred, imediatamente,
                  na hipótese de comprometimento do seu sigilo. O Bbankcred não
                  recomenda o uso, pelo Usuário, da mesma senha para diversos
                  sites na internet/propósitos ou a inserção de Dados Pessoais
                  como parte da senha. Na criação de sua conta o Usuário deverá
                  observar, ainda, critérios que dificultem a identificação da
                  senha por terceiros. Para tanto, o Usuário deve evitar
                  sequências de número que remetam a telefones, datas de
                  aniversário e outras sequências numéricas ou alfabéticas que
                  possam ser facilmente vinculadas ao Usuário. 8.3. A utilização
                  do Software pelo Usuário possui caráter pessoal e
                  intransferível e está autorizada unicamente para fins lícitos
                  relacionados ao propósito a que o Site e o Aplicativo se
                  destinam, de acordo com estes Termos de Uso. A propriedade
                  intelectual do Software pertence, e continuará pertencendo,
                  exclusivamente ao Bbankcred. Em nenhuma hipótese, o Usuário
                  terá acesso ao código fonte do Software, já que a licença de
                  uso ora conferida abrange tão-somente o seu código objeto. 9.
                  CONTEÚDO FORNECIDO PELO USUÁRIO 9.1. O Bbankcred pode
                  ocasionalmente, ao seu exclusivo critério, permitir que o
                  Usuário submeta, transfira, publique ou de outra forma
                  disponibilize ao Bbankcred, através dos Serviços, conteúdo e
                  informação em formato de texto, áudio e/ou visual, incluindo
                  comentários e feedbacks relacionados aos Serviços, pedidos de
                  apoio e registos (“Conteúdo de Usuário”). Todo o Conteúdo de
                  Usuário mantém-se como sua propriedade. Para estes casos, os
                  conteúdos enviados e a identificação do seu perfil, se houver,
                  poderão ser visualizados por outros Usuários, atendendo sempre
                  às normas de sigilo bancário. Contudo, ao fornecer Conteúdo de
                  Usuário ao Bbankcred, o Usuário concede ao Bbankcred uma
                  licença mundial, perpétua, irrevogável, transferível, isenta
                  de direitos autorais (“royalties”), para utilizar, copiar,
                  modificar, criar obras derivadas, distribuir, incluir em
                  propagandas, material de marketing ou materiais correlatos
                  para a promoção do Bbankcred, da Instituição Parceira e/ou dos
                  Serviços, apresentar e executar publicamente, e de outra forma
                  explorar, sob qualquer modo, tal Conteúdo de Usuário, em todos
                  os formatos e canais de distribuição conhecidos ou futuramente
                  concebidos (incluindo em relação aos Serviços e à atividade do
                  Bbankcred e em sites e serviços de terceiros), sem aviso ou
                  consentimento prévio do Usuário, e sem a necessidade de
                  qualquer pagamento ao mesmo ou a qualquer outra pessoa ou
                  entidade. 9.2. O Usuário declara e garante que: (I) é o
                  exclusivo proprietário de todo o Conteúdo de Usuário ou detém
                  todos os direitos, licenças, consentimentos e autorizações
                  necessárias para conceder ao Bbankcred, a licença sobre o
                  Conteúdo de Usuário nos termos acima descritos; e (II) nem o
                  Conteúdo de Usuário nem a respectiva submissão, transferência,
                  publicação ou qualquer outra forma de divulgação de tal
                  Conteúdo de Usuário, nem a utilização de Conteúdo pelo
                  Bbankcred nos termos previstos no presente documento irão
                  resultar na infração, apropriação indevida ou violação da
                  propriedade intelectual ou dos direitos proprietários de um
                  terceiro, ou dos direitos de publicidade ou privacidade, ou na
                  violação de qualquer lei ou regulamento aplicável. 9.3. O
                  Usuário, ao Aceitar estes Termos de Uso e utilizar os
                  Serviços, concorda que não poderá submeter Conteúdo de Usuário
                  de caráter difamatório, calunioso, violento, obsceno,
                  pornográfico, ilegal ou de outra forma ofensivo, assim
                  determinado pelo Bbankcred a seu exclusivo critério, quer ou
                  não esse material se encontre protegido por lei. O Bbankcred
                  pode, mas não é obrigada a rever, controlar ou eliminar
                  Conteúdo de Usuário, ao seu exclusivo critério e em qualquer
                  momento e por qualquer motivo, sem pré-aviso ao Usuário. 9.4.
                  Pode também ser possível ao Usuário enviar conteúdo, como
                  fotos, documentos, comentários e outras mensagens para fins de
                  cadastro, atendimento, uso de serviços disponíveis nos Sites e
                  Aplicativos ou outras finalidades. Nesses casos, os conteúdos
                  enviados não ficarão disponíveis em áreas de conteúdo aberto
                  dos Sites e Aplicativos. 9.5. Lembramos que, em qualquer dos
                  casos, os conteúdos enviados serão de responsabilidade de quem
                  os enviou. 10. CONTRATAÇÃO POR MEIO ELETRÔNICO 10.1. O Usuário
                  aceita e reconhece que o meio eletrônico é um meio válido de
                  contratação, bem como todas as suas etapas, e que sua
                  identificação no momento da contratação é prova inquestionável
                  de sua concordância com esse formato de contratação. 11.
                  RESPONSABILIDADES 11.1. O Usuário é responsável: por todas as
                  suas ações ou omissões realizadas nos nossos Sites e
                  Aplicativos; pelos conteúdos que enviou e/ou transmitiu nos
                  Sites e Aplicativos; e pela reparação de danos causados ao
                  Bbankcred, terceiros ou outros Usuários, a partir do seu
                  acesso e uso dos nossos Sites e Aplicativos. 11.2. Desta
                  forma, o Bbankcred não se responsabiliza pelos itens citados
                  acima e tampouco por indisponibilidades e falhas técnicas do
                  sistema dos Sites e Aplicativos. 11.3. Considere também que
                  conteúdos enviados e/ou transmitidos por Usuários e/ou
                  terceiros não representam a opinião ou a visão do Bbankcred
                  13. LIMITAÇÕES DE USO E INTERFERÊNCIA 13.1. O Usuário não
                  poderá: (I) Utilizar o Site ou o Aplicativo, inclusive para
                  divulgar informações, de qualquer forma que possa implicar
                  violação de normas aplicáveis no Brasil, de direitos de
                  propriedade do Bbankcred ou da Instituição Parceira e/ou de
                  terceiros ou dos bons costumes, incluindo, sem limitação, a
                  violação de direitos intelectuais, autorais e de privacidade,
                  ou a produção e divulgação de conteúdo ilegal, imoral,
                  inapropriado ou ofensivo; (II) Copiar, ceder, sublicenciar,
                  vender, dar em locação ou em garantia, reproduzir, doar,
                  alienar de qualquer forma, transferir total ou parcialmente,
                  sob qualquer modalidade, gratuita ou onerosamente, provisória
                  ou permanentemente, o Software, assim como seus módulos,
                  partes, Conteúdos, manuais ou quaisquer informações a ele
                  relativas; (III) Empregar Malware e/ou Práticas Nocivas com o
                  intuito de utilizar indevidamente o Site, o Aplicativo e/ou o
                  Software para práticas indesejadas ao Bbankcred ou à
                  Instituição Parceira ou a terceiros, tais como exploits,
                  spamming, flooding, spoofing, crashing e root kits etc.; (IV)
                  Reproduzir, adaptar, modificar e/ou empregar, no todo ou em
                  parte, para qualquer fim, o Software ou qualquer Conteúdo do
                  Site ou do Aplicativo sem a autorização expressa do Bbankcred
                  ou da Instituição Parceira; (V) Publicar ou transmitir
                  qualquer arquivo que contenha vírus, worms, cavalos de Tróia
                  ou qualquer outro programa contaminante ou destrutivo, ou que,
                  de alguma forma, possa interferir no bom funcionamento do
                  Site, do Aplicativo ou do Software; (VI) Utilizar o Software
                  para finalidade diversa daquela para a qual foi
                  disponibilizada pelo Bbankcred ou pela Instituição Parceira;
                  (VII) Acessar, armazenar, divulgar, utilizar ou modificar
                  indevidamente os dados de outros Usuários; e (VIII) Utilizar o
                  Software, ou permitir seu uso, para benefício de terceiros.
                  13.2. É vedada a utilização de dispositivo, software ou outro
                  recurso que possa interferir nas atividades do Software e nas
                  operações do Site e do Aplicativo, bem como nas Contas
                  Bbankcred ou em seus bancos de dados. Qualquer intromissão,
                  tentativa de, ou atividade que viole ou contrarie as leis de
                  direito de propriedade intelectual e as proibições estipuladas
                  nestes Termos de Uso tornará o responsável passível de sofrer
                  os efeitos das ações legais pertinentes, bem como das sanções
                  aqui previstas, sendo ainda responsável por indenizar o
                  Bbankcred, as Instituições Parceiras e/ou seus Usuários por
                  eventuais danos causados. 14. CHARGEBACK (CONTESTAÇÃO DE
                  DÉBITO) E CANCELAMENTO DA TRANSAÇÃO 14.1. As transações
                  realizadas com os Cartões Bbankcred estão sujeitas às regras
                  de resolução de disputas e Chargeback instituídas pela
                  “bandeira do cartão”, pelo Bbankcred e/ou pela Instituição
                  Parceira - Adquirência, se aplicável. 14.2. Destaca-se que o
                  prazo máximo para o Usuário contestar uma compra é de 90
                  (noventa) dias corridos a partir da data da transação. 14.3. O
                  prazo para resposta ao pedido de Chargeback realizado pelo
                  Usuário é de até 120 (cento e vinte) dias corridos a partir da
                  abertura do Chargeback com a bandeira do cartão. 14.3.1 Em
                  caso de abertura de contestação de débito, o Usuário deverá
                  efetuar normalmente o pagamento das faturas. 14.4. As faturas
                  referentes aos gastos de Cartão de Crédito estão disponíveis
                  para o Usuário no próprio Aplicativo e poderão ser solicitadas
                  por meio da Central de Atendimento ou pelo e-mail disponível
                  no Site e no Aplicativo para envio digital. 14.5. O Usuário
                  está ciente e concorda que o Bbankcred não tem nenhuma
                  responsabilidade pela política e pelo prazo de estorno de
                  compras, que são dependentes única e exclusivamente do
                  previsto pelo estabelecimento comercial no qual o Usuário
                  tiver utilizado o seu Cartão Bbankcred. 14.6. Em relação às
                  transações a serem realizadas via PIX, o Usuário poderá
                  alterar o valor a ser pago/transferido ou cancelar a transação
                  apenas antes da confirmação do pagamento/transferência. Após a
                  confirmação, não haverá mais possibilidade de cancelamento da
                  transação, na medida em que esta ocorre em tempo real. Nesse
                  caso, a devolução dos valores transferidos via PIX só ocorrerá
                  mediante negociação com e iniciativa daquele que recebeu os
                  valores transferidos. Nesse sentido, o Bbankcred não se
                  responsabiliza pela devolução de quaisquer valores
                  transferidos erroneamente via PIX. 14.7. Em caso de
                  identificada fraude no âmbito do PIX, o Bbankcred terá a
                  faculdade ressarcir ou não o Usuário, a depender de análise do
                  caso concreto e conforme disposições do Banco Central do
                  Brasil. 15. TARIFAS 15.1. O Usuário declara-se ciente de que o
                  Bbankcred cobrará tarifas para realização dos Serviços,
                  mediante débito na sua Conta Bbankcred, sendo que tais tarifas
                  estarão disponíveis para consulta no Aplicativo e no Site.
                  15.2. O Usuário declara-se ciente de que o Bbankcred cobrará a
                  tarifa devida pela realização de um Serviço, mediante débito
                  na sua Conta Bbankcred, caso o referido Serviço seja
                  operacionalizado pelo Bbankcred, mas posteriormente cancelado
                  em decorrência de erro do Usuário. 15.3. Caso não haja saldo
                  suficiente na conta do Usuário na data do débito das tarifas,
                  tais valores serão cobrados tão logo haja dinheiro disponível
                  em conta. 15.4. Caso o Bbankcred venha a disponibilizar novos
                  serviços, poderá instituir remuneração pelos serviços que
                  vierem a ser solicitados pelo Usuário, conforme valores,
                  termos e condições que vierem a ser previamente informados, de
                  acordo com a regulamentação aplicável. 15.5. Adicionalmente,
                  em relação aos serviços prestados pelo Bbankcred no âmbito do
                  PIX, não haverá qualquer tipo de cobrança dos Usuários Pessoas
                  Físicas, salvo no caso de o Usuário estar recebendo valores em
                  sua Conta Bbankcred, via PIX, decorrentes da venda de produto
                  ou serviço (finalidade compra). 15.6. Ainda, o Bbankcred
                  reserva-se o direito de alterar as tarifas mencionadas na
                  cláusula acima a qualquer momento, desde que os Usuários sejam
                  devida e previamente avisados, de acordo com a regulamentação
                  aplicável, e tenham a opção de cancelar o Cadastro. 16.
                  PROCEDIMENTOS DE SEGURANÇA Medidas de Segurança 16.1. Ao
                  receber o seu Cartão Bbankcred, confira seus dados pessoais
                  nele contidos. Por medida de segurança, o Bbankcred enviará o
                  Cartão Físico sempre bloqueado para utilização. O desbloqueio
                  deve ser realizado pelo Aplicativo. 16.2. Você é responsável
                  pelo uso e guarda do seu Cartão Bbankcred, assim como
                  respectiva senha, quando fornecida.. 16.3. Como medida de
                  segurança, você deve: (I) guardar o seu Cartão Físico em local
                  seguro, nunca permitindo o uso por terceiros; (II) memorizar
                  sua senha e mantê-la em sigilo, não a informando para
                  terceiros; (III) nunca anotar ou guardar sua senha junto com
                  seu Cartão Físico; e (IV) nunca divulgar seu Cartão em locais
                  públicos, principalmente em mídias sociais. 16.4. Também como
                  medida de segurança, o Bbankcred poderá solicitar novos
                  documentos ou fotos para comprovação da sua identidade durante
                  o uso do aplicativo, caso no qual serão tratados em
                  conformidade com as normas estabelecidas na Lei Geral de
                  Proteção de Dados Pessoais e as resoluções e circulares do
                  Banco Central do Brasil quando cabível. 16.5. Ainda como
                  medida de segurança, o Bbankcred poderá bloquear o seu Cartão
                  Bbankcred preventivamente caso verifique operações fora do seu
                  padrão de uso, necessidade de envio de novos documentos ou
                  fotos para comprovação da sua identidade e renda, ou a
                  utilização indevida do Cartão que desrespeite qualquer
                  condição presente nestes Termos de Uso ou na legislação
                  vigente. VOCÊ PODE EVITAR ESSE TIPO DE BLOQUEIO COMPARTILHANDO
                  CORRETAMENTE OS DOCUMENTOS E DEMAIS DADOS PESSOAIS SOLICITADOS
                  DURANTE O PEDIDO DO CARTÃO BBANKCRED, AVISANDO A BBANKCRED
                  ANTES DE REALIZAR OPERAÇÕES FORA DO SEU PADRÃO, POR EXEMPLO,
                  EM VIAGENS AO EXTERIOR, BEM COMO SOBRE QUALQUER OCORRÊNCIA QUE
                  POSSA RESULTAR NA UTILIZAÇÃO DO CARTÃO BBANKCRED POR
                  TERCEIROS. 16.6. Durante a vigência da relação jurídica
                  existente entre as partes sempre que você for instado a
                  regularizar suas informações cadastrais ou relacionadas às
                  transferências via PIX, e não regularizar as informações e/ou
                  dados requeridos em até 48 (quarenta e oito) horas a contar da
                  solicitação, sua conta poderá ser bloqueada devendo o Usuário
                  solicitar imediatamente a retirada de eventual saldo em
                  dinheiro indicado em sua Conta para outra conta bancária ou de
                  pagamento de sua titularidade, para subsequente cancelamento.
                  16.7. Os Usuários que, durante o período de qualquer programa
                  de incentivo do Bbankcred, tiverem suas contas bloqueadas,
                  canceladas ou inativadas serão excluídos dos referidos
                  programas, não fazendo jus a eventual benefício oferecido aos
                  participantes do programa que cumprirem as regras
                  estabelecidas no regulamento do mesmo. Perda, Extravio e Roubo
                  ou Fraude no Cartão Bbankcred 16.8. Em observância ao seu
                  dever de boa-fé e cooperação mútua, no caso de perda,
                  extravio, furto, roubo ou fraude do seu Cartão Físico, você
                  deverá sempre comunicar imediatamente o fato à central de
                  atendimento pelos canais eletrônicos do Bbankcred, inclusive
                  aplicativo para celular, para que o Bbankcred possa efetuar o
                  imediato bloqueio e cancelamento do seu Cartão Físico. 16.9.
                  EM CASO DE DESCUMPRIMENTO DO SEU DEVER DE COMUNICAÇÃO PREVISTO
                  ACIMA, VOCÊ SERÁ RESPONSÁVEL PELAS OPERAÇÕES REALIZADAS POR
                  TERCEIROS. 16.10. Você deverá ainda confirmar por escrito a
                  comunicação feita ao Bbankcred, acompanhada de um boletim de
                  ocorrência policial, quando assim lhe for solicitado. Caso se
                  comprove que você agiu de má-fé, fraudando o Cartão Bbankcred
                  sob sua responsabilidade, você será o único responsável por
                  quaisquer despesas incorridas com o uso do Cartão Bbankcred
                  mesmo após a comunicação, sem prejuízo das sanções legais na
                  esfera cível e criminal. Padrões de Segurança 16.11. Fiquem
                  tranquilos! O Bbankcred e a Instituição Parceira, se
                  aplicável, oferecem serviços de qualidade e possuem
                  certificados digitais de garantia e segurança que respeitam as
                  regras regulatórias, legais e as boas práticas de segurança de
                  informação. 16.12. Além disso, o Bbankcred e a Instituição
                  Parceira, se aplicável, atendem todos os requisitos
                  estabelecidos pelo Banco Central do Brasil no que diz respeito
                  ao seu regular funcionamento, incluindo, mas não se limitando
                  às regras e mecanismos de proteção aos titulares de crédito
                  contra o Bbankcred e/ou contra a Instituição Parceira. Medidas
                  de Segurança PIX 16.13. Visando evitar que você, Usuário, caia
                  em fraudes que usem o PIX como isca, o Bbankcred recomenda que
                  você (i) não entre em sites ou instale no celular aplicativos
                  desconhecidos; (ii) saiba que não existem sites ou aplicativos
                  do Banco Central do Brasil ou do PIX criados exclusivamente
                  para cadastramento das Chaves PIX, nem para a realização das
                  transações PIX; (iii) o cadastramento das Chaves PIX é
                  realizado em ambiente logado no Aplicativo ou no Site, os
                  mesmos que já são utilizados para as demais transações
                  tratadas nestes Termos de Uso; (iv) o cadastramento das Chaves
                  PIX requer o consentimento do cliente e para cadastrar a chave
                  PIX é feita uma validação em duas etapas, conforme indicado
                  acima. 17. DADOS PESSOAIS E O AVISO EXTERNO DE PRIVACIDADE
                  17.1. Ao cadastrar-se no Site e/ou no Aplicativo, o Usuário
                  está ciente do Aviso Externo de Privacidade no qual está
                  descrita a forma como tratamos seus dados pessoais. 17.2. No
                  Aviso Externo de Privacidade estão descritas as atividades de
                  tratamento de dados pessoais a serem executadas pelo
                  Bbankcred, incluindo mas não se limitando ao compartilhamento
                  e transferência de dados pessoais com outras entidades do
                  Grupo Bbankcred e outras empresas parceiras e/ou terceiros em
                  geral. Dessa forma, os dados poderão ser compartilhados entre
                  outros com bureaus de créditos, empresas de publicidade,
                  agências de comunicação, entre outras com o objetivo de
                  atender finalidades específicas descritas no Aviso Externo de
                  Privacidade. Em qualquer caso, toda atividade de tratamento de
                  dados pessoais executada pelo Bbankcred atenderá às normas
                  estabelecidas na Lei Geral de Proteção de Dados Pessoais e
                  demais normas regulatórias setoriais emitidas pelo Banco
                  Central de Brasil e a legislação em vigor no Brasil. Da mesma
                  forma, serão incorporadas boas práticas de tratamento de dados
                  pessoais tendo como principal objetivo garantir o tratamento
                  certo, ético, íntegro e inteligente dos dados pessoais
                  respeitando os direitos e liberdades fundamentais dos
                  titulares de dados pessoais. 17.3. Ao abrir sua conta
                  Bbankcred o Usuário autoriza ao Bbankcred e a Instituição
                  Parceira, se aplicável, a verificarem os dados fornecidos no
                  cadastro e a obterem e fornecerem tais informações às empresas
                  de análises de crédito e de proteção ao crédito e ao Sistema
                  de Informações de Crédito (“SCR”) do Banco Central do Brasil,
                  e/ou outro sistema que em virtude de norma legal o substitua,
                  inclusive informações decorrentes de operações de crédito de
                  sua responsabilidade, para fins de avaliação do risco de
                  crédito e intercâmbio de informações com outras instituições
                  financeiras respeitando sempre os limites estabelecidos na
                  legislação e executando os melhores esforços para garantir o
                  tratamento lícito, ético e inteligente dos dados pessoais em
                  conformidade com as normas legais e/ou regulatórias a respeito
                  O Usuário declara estar ciente de que a consulta ao SCR
                  depende dessa prévia autorização e que o Usuário pode ter
                  acesso aos dados do SCR pelos meios colocados à sua disposição
                  pelo Banco Central do Brasil. Em caso de divergência nos dados
                  do SCR fornecidos pelo Bbankcred o Usuário poderá solicitar
                  correção, exclusão ou registro de anotação complementar,
                  mediante solicitação formal. O Usuário declara estar ciente de
                  que, ocorrendo a falta de pagamento de qualquer obrigação
                  assumida será promovido o registro junto aos órgãos de
                  proteção de crédito e ao SCR. Caso seja necessário, o
                  Bbankcred e terceiros por ela contratados para esse fim,
                  poderão executar cobranças e outras gestões administrativas e
                  financeiras em nome e representação do Bbankcred. 17.4. Com a
                  finalidade de garantir a proteção do crédito, o Usuário está
                  ciente que o Bbankcred e a Instituição Parceira, se aplicável,
                  poderão consultar seus dados relativos a histórico de crédito
                  – informações que englobam os dados financeiros e de
                  pagamentos relativos à operações de crédito e obrigações de
                  pagamento adimplidas ou em andamento – em bancos de dados de
                  Cadastro Positivo, nos termos da legislação em vigor. Em
                  qualquer caso, o Usuário poderá solicitar as informações e
                  esclarecimentos necessários para atender seus interesses e
                  resguardar seus direitos, assim como exercê-los em
                  conformidade com as normas legais e/ou regulatórias que
                  regulam o SCR e o cadastro positivo. 17.5. O Bbankcred como
                  instituição participante de Open Banking (Open Finance) poderá
                  compartilhar prévio consentimento do Usuário suas informações
                  financeiras com as outras entidades participantes seguindo as
                  regras estabelecidas nas normas regulatórias emitidas pelo
                  Banco Central do Brasil. Dessa forma, o Usuário poderá exercer
                  qualquer um dos direitos e atribuições nelas estabelecidos.
                  Com base no descrito acima, o Bbankcred implementará e
                  respeitará as normas relativas ao tratamento de dados pessoais
                  derivadas da Lei Geral de Proteção de Dados Pessoais e as
                  normas regulatórias emitidas pelo Banco Central do Brasil e
                  qualquer outra entidade regulatória, assim como qualquer outra
                  legislação em vigor aplicável. Dessa forma, o Bbankcred se
                  compromete a garantir a integridade, confidencialidade, e
                  disponibilidade dos dados pessoais sempre que sejam requeridos
                  pelo Titular dos dados pessoais e/ou seus representantes
                  legais e as autoridades competentes, tendo como única
                  limitação aqueles estabelecidas na legislação. Com a
                  finalidade de garantir a transparência e manter informados os
                  Usuários, o Bbankcred se compromete a manter atualizado o
                  Aviso Externo de Privacidade que contém toda a descrição da
                  forma como são tratados seus dados pessoais. Esse Aviso estará
                  disponível no site a aplicativo. Em caso de dúvidas a respeito
                  o Usuário poderá entrar em contato com nossa Encarregada pelo
                  Tratamento de Dados Pessoais / Data Protection Officer pelo
                  e-mail juridico@Bbankcred.com.br 18. PROPRIEDADE INTELECTUAL
                  18.1. O Usuário não adquire, por meio dos presentes Termos de
                  Uso, nenhum direito de propriedade intelectual ou outros
                  direitos exclusivos, incluindo patentes, desenhos, marcas,
                  direitos autorais ou direitos sobre informações confidenciais
                  ou segredos de negócio, sobre ou relacionados ao Software, ao
                  Aplicativo e/ou ao Site, bem como à base de dados composta por
                  dados de Usuários, os quais são de propriedade exclusivo do
                  Bbankcred, da Instituição Parceira ou de órgão regulador
                  (e.g., Banco Central do Brasil). 18.2. Sem prejuízo dos
                  direitos assegurados por lei, ao Bbankcred, à Instituição
                  Parceira e ao Banco Central do Brasil, caso o Usuário venha a
                  desenvolver um novo módulo ou produto que caracterize cópia,
                  de todo ou em parte, quer seja do dicionário de dados, quer
                  seja do programa, será considerado como parte do Software,
                  ficando, portanto, sua propriedade incorporada pelo Bbankcred,
                  pela Instituição Parceira ou pelo Banco Central do Brasil e
                  seu uso condicionado ao atendimento das regras definidas
                  nestes Termos de Uso. 18.3. Todo e qualquer Conteúdo
                  disponibilizado no Site e/ou no Aplicativo, como,
                  exemplificativamente, textos, gráficos, imagens, logos,
                  ícones, fotografias, conteúdo editorial, notificações,
                  análises de dados, indicadores financeiros, softwares e
                  qualquer outro material, bem como toda e qualquer base de
                  dados constituída pelo Bbankcred, pela Instituição Parceira ou
                  pelo Banco Central do Brasil pertencem exclusivamente ao
                  Bbankcred, à Instituição Parceira ou ao Banco Central do
                  Brasil e são protegidos pela lei brasileira, especialmente no
                  que se refere à propriedade intelectual e aos direitos
                  autorais. Na qualidade de exclusivo titular da propriedade
                  intelectual da citada base de dados, ao Bbankcred, a
                  Instituição Parceira ou o Banco Central do Brasil poderão
                  utilizá-la livremente para o fim específico de proporcionar
                  benefícios aos Usuários e criar soluções que, no entendimento
                  do Bbankcred, da Instituição Parceira ou do Banco Central do
                  Brasil, possam satisfazer as necessidades desses. 18.4. A
                  marca PIX é de titularidade exclusiva do Banco Central do
                  Brasil, que conferirá ao Bbankcred licença temporária, não
                  exclusiva e intransferível de uso, em suas formas nominativa e
                  de símbolo, de acordo com a regulação aplicável. 18.5. O
                  Bbankcred poderá, a seu exclusivo critério, a qualquer tempo e
                  sem necessidade de comunicação prévia ao Usuário: (I)
                  Encerrar, modificar ou suspender, total ou parcialmente, o
                  acesso do Usuário ao Site e/ou ao Aplicativo, quando referido
                  acesso ou Cadastro violar as condições estabelecidas nestes
                  Termos de Uso e (II) Excluir, total ou parcialmente, as
                  informações cadastradas pelo Usuário que não estejam em
                  consonância com as disposições destes Termos de Uso; e (III)
                  Acrescentar, excluir ou modificar o Conteúdo; (IV) Suspender a
                  aceitação do PIX pelo Usuário, em caso de reincidência de
                  infração relacionada ao uso da marca PIX, de recusa ou de
                  demora injustificada para a regularização do uso da marca PIX;
                  (V) Resolver unilateralmente o negócio jurídico relativo ao
                  PIX, em caso de grave infração, pelo Usuário, das regras de
                  uso da marca PIX, desde que devidamente comprovados os fatos;
                  e (VI) Cancelar a Chave PIX, no caso de inatividade de uso
                  desta ou da conta vinculada à Chave PIX, caracterizada pelo
                  não recebimento de ordens de liquidação por mais de 12 (doze)
                  meses. 18.6. As situações indicadas nos itens 14.5. (IV) e (V)
                  abaixo serão comunicadas ao Banco Central do Brasil, em até 7
                  (sete) dias. Poderá o Bbankcred ou a Instituição Parceira, a
                  seu exclusivo critério, suspender, modificar ou encerrar as
                  atividades do Site e/ou do Aplicativo, mediante comunicação
                  prévia ao Usuário, salvo nas hipóteses de ato de autoridade
                  competente, caso fortuito ou força maior. 18.8. O Bbankcred ou
                  a Instituição Parceira com base no seu legítimo interesse
                  poderão enviar para o seu endereço de e-mail mensagens ou
                  outras correspondências de caráter informativo, comercial e/ou
                  promocional, com informações do próprio Bbankcred ou da
                  Instituição Parceira ou de Anunciantes, deixando ao usuário a
                  possibilidade de exercer seus direitos e solicitar sua remoção
                  da lista de distribuição dessas informações. 18.9. O Usuário
                  AUTORIZA o BBANKCRED ou a Instituição Parceira a enviar para o
                  seu endereço de e-mail mensagens ou outras correspondências de
                  caráter informativo, comercial e/ou promocional, com
                  informações do próprio Bbankcred ou da Instituição Parceira ou
                  de Anunciantes, salvo expressa solicitação em contrário pelo
                  Usuário, nos termos da Política de Privacidade. 18.9. Todas as
                  informações constantes do site e serviços por ele
                  relacionados, como textos, imagens, marcas, entre outros,
                  pertencem ao Bbankcred e são protegidas por lei, a menos que
                  esteja indicado expressamente o contrário. O uso não
                  autorizado poderá gerar penalidades previstas em lei. 18.10.
                  Todos os direitos de nossas plataformas são protegidos por
                  direitos de propriedade intelectual e são reservados. O
                  conteúdo das plataformas não pode ser cedido, vendido ou
                  distribuído com fins lucrativos e é proibida qualquer
                  modificação não autorizada, imitação, aluguel, transmissão ou
                  radiodifusão. O mesmo se aplica às imagens e elementos de
                  identificação das marcas de propriedade do Bbankcred que não
                  podem ser utilizadas sem prévia e expressa autorização. 19.
                  GARANTIA E TERMO DE RESPONSABILIDADE 19.1. O BBANKCRED SE
                  EMPENHA PARA PRESTAR O MELHOR SERVIÇO E PROMOVER A MELHOR
                  EXPERIÊNCIA DE USO POSSÍVEL. NA MEDIDA MÁXIMA PERMITIDA PELA
                  LEI APLICÁVEL, O BBANKCRED OU INSTITUIÇÃO PARCEIRA NÃO FAZEM
                  QUALQUER DECLARAÇÃO E NÃO RENUNCIAM A QUAISQUER GARANTIAS OU
                  CONDIÇÕES DE QUALIDADE SATISFATÓRIA, COMERCIABILIDADE E
                  ADEQUAÇÃO PARA UMA DETERMINADA FINALIDADE, OU NÃO INFRAÇÃO DE
                  TERCEIROS. NEM O BBANKCRED NEM A INSTITUIÇÃO PARCEIRA GARANTEM
                  QUE OS SERVIÇOS DE TERCEIROS ESTEJAM LIVRES DE MALWARE OU
                  OUTROS COMPONENTES PREJUDICIAIS. AINDA, O BBANKCRED OU A
                  INSTITUIÇÃO PARCEIRA NÃO FAZEM DECLARAÇÕES NEM ASSEGURAM,
                  ENDOSSAM, GARANTEM OU ASSUMEM A RESPONSABILIDADE POR QUAISQUER
                  APLICATIVOS OU SERVIÇOS DE TERCEIROS (OU PELO CONTEÚDO
                  DESTES), PELO CONTEÚDO DOS USUÁRIOS OU POR QUALQUER OUTRO
                  PRODUTO OU SERVIÇO DIVULGADO OU OFERECIDO POR UM TERCEIRO NO
                  SITE OU APLICATIVO OU POR MEIO DOS SERVIÇOS BBANKCRED, OU
                  QUALQUER SITE COM HIPERLINKS, OU INTEGRADO EM QUALQUER BANNER
                  OU OUTRA PROPAGANDA. VOCÊ COMPREENDE E CONCORDA QUE NEM O
                  BBANKCRED NEM A INSTITUIÇÃO PARCEIRA SÃO RESPONSÁVEIS POR
                  NENHUMA TRANSAÇÃO ENTRE VOCÊ E TERCEIROS DE APLICATIVOS OU
                  PRODUTOS, OU SERVIÇOS DIVULGADOS EM OU POR MEIO DOS SERVIÇOS
                  BBANKCRED. ASSIM COMO OCORRE COM QUALQUER COMPRA DE PRODUTO OU
                  SERVIÇO ATRAVÉS DE QUALQUER MEIO OU EM QUALQUER AMBIENTE, VOCÊ
                  DEVE USAR SEU DISCERNIMENTO E TER CAUTELA ONDE FOR APROPRIADO.
                  19.2. O BBANKCRED ASSEGURA CUMPRIR E OBSERVAR O DISPOSTO NA
                  REGULAMENTAÇÃO VIGENTE QUE TRATA DO ECOSSISTEMA DE PAGAMENTOS
                  INSTANTÂNEOS BRASILEIRO, ENQUANTO PARTICIPANTE INDIRETA DO
                  PIX. 19.3. As limitações e exceções constantes na presente
                  cláusula não pretendem constituir uma limitação da
                  responsabilidade ou alterar os direitos do utilizador, na
                  qualidade de consumidor, que não podem ser excluídos por lei
                  aplicável. 20. LIMITAÇÃO DE RESPONSABILIDADE 20.1. VOCÊ
                  CONCORDA QUE POSSUI COMO SOLUÇÃO PARA QUAISQUER PROBLEMAS OU
                  INSATISFAÇÃO COM OS SERVIÇOS BBANKCRED A POSSIBILIDADE DE
                  DESINSTALAR QUALQUER SOFTWARE BBANKCRED E PARAR DE USAR OS
                  SERVIÇOS BBANKCRED. NEM O BBANKCRED OU A INSTITUIÇÃO PARCEIRA
                  SÃO RESPONSÁVEIS POR APLICATIVOS DE TERCEIROS OU PELO CONTEÚDO
                  DESTES, E EMBORA SEU RELACIONAMENTO COM TAIS APLICATIVOS DE
                  TERCEIROS POSSA SER REGIDO POR ACORDOS SEPARADOS COM ESSES
                  TERCEIROS, NA MEDIDA MÁXIMA PERMITIDA PELA LEI APLICÁVEL, SUA
                  SOLUÇÃO PARA QUAISQUER PROBLEMAS OU INSATISFAÇÃO COM
                  APLICATIVOS DE TERCEIROS OU O CONTEÚDO DESTES, É DESINSTALAR
                  E/OU PARAR DE USAR TAIS APLICATIVOS DE TERCEIROS. 20.2. NA
                  MEDIDA MÁXIMA PERMITIDA PELA LEI, EM NENHUMA CIRCUNSTÂNCIA O
                  BBANKCRED OU A INSTITUIÇÃO PARCEIRA, SEUS RESPONSÁVEIS,
                  ACIONISTAS, FUNCIONÁRIOS, AGENTES, DIRETORES, SUBSIDIÁRIAS,
                  AFILIADAS, SUCESSORES, CESSIONÁRIOS, FRANQUEADOS,
                  REPRESENTANTES DE SOLUÇÃO FINANCEIRA, FORNECEDORES OU
                  LICENCIADORES SERÃO RESPONSÁVEIS POR QUAISQUER DANOS
                  INDIRETOS, ESPECIAIS, INCIDENTAIS, PUNITIVOS, EXEMPLARES OU
                  CONSEQUENTES DECORRENTES, EM TODOS OS CASOS, DO USO OU DA
                  INCAPACIDADE DE USAR OS SERVIÇOS BBANKCRED, OS APLICATIVOS DE
                  TERCEIROS OU O CONTEÚDO DE APLICATIVOS DE TERCEIROS,
                  INDEPENDENTEMENTE DA TEORIA LEGAL, AINDA QUE O BBANKCRED OU A
                  INSTITUIÇÃO PARCEIRA TENHAM SIDO ADVERTIDOS SOBRE A
                  POSSIBILIDADE DESSES DANOS, E MESMO SE UMA SOLUÇÃO FALHAR EM
                  SUA FINALIDADE ESSENCIAL. 20.3. NADA NESTES TERMOS DE USO
                  ELIMINA OU LIMITA A RESPONSABILIDADE DO BBANKCRED OU DA
                  INSTITUIÇÃO PARCEIRA QUANTO À FRAUDE, FALSIDADE IDEOLÓGICA,
                  MORTE OU LESÃO CORPORAL CAUSADA POR AÇÕES DOLOSAS POR PARTE DO
                  BBANKCRED. EM NENHUMA HIPÓTESE O BBANKCRED SERÁ RESPONSÁVEL
                  POR DANOS CAUSADOS DECORRENTES DE CONDUTAS IMPRÓPRIAS
                  REALIZADAS PELOS USUÁRIOS. 20.4. AO ACEITAR ESTES TERMOS DE
                  USO, O USUÁRIO CONCORDA, AINDA, QUE NEM O BBANKCRED OU A
                  INSTITUIÇÃO PARCEIRA POSSUEM RESPONSABILIDADE POR QUAISQUER
                  DANOS E/OU PERDAS SOFRIDOS EM DECORRÊNCIA DE PRODUTOS E/OU
                  SERVIÇOS OFERTADOS POR TERCEIROS POR MEIO DO APLICATIVO.
                  AINDA, O USUÁRIO CONCORDA QUE NEM O BBANKCRED E NEM A
                  INSTITUIÇÃO PARCEIRA SERÃO RESPONSÁVEIS POR ERROS OU
                  INTERRUPÇÕES NO FORNECIMENTO DE INFORMAÇÕES POR SISTEMAS
                  INDEPENDENTES DE PRESTAÇÃO DE SERVIÇOS, COMO OS SISTEMAS DE
                  PAGAMENTO E OS SERVIDORES, OU POR SITES INTEGRADOS GERENCIADOS
                  POR TERCEIROS.. 20.5. AO ACEITAR ESTES TERMOS DE USO, O
                  USUÁRIO RECONHECE QUE TODO SISTEMA QUE DEPENDE DE REDE MUNDIAL
                  DE COMPUTADORES ESTÁ SUJEITO A FALHAS E INDISPONIBILIDADE.
                  ASSIM, NÃO HAVERÁ RESPONSABILIDADE DO BBANKCRED OU INSTITUIÇÃO
                  PARCEIRA EM CASO DE INDISPONIBILIDADE DO SISTEMA, PREJUÍZO OU
                  PERDA SOFRIDOS PELO USUÁRIO EM RAZÃO DE FALHAS EM SUA CONEXÃO
                  COM A INTERNET, COM O SEU PROVEDOR, NO SISTEMA OPERACIONAL OU
                  SERVIDOR UTILIZADOS PELO USUÁRIO, DECORRENTES DE CONDUTAS DE
                  TERCEIROS, OU EM CASO FORTUITO OU FORÇA MAIOR, SENDO QUE
                  NESSAS HIPÓTESES VOCÊ PODERÁ ENTRAR EM CONTATO COM O BBANKCRED
                  ATRAVÉS DOS CANAIS DE ATENDIMENTO. 21. LIQUIDAÇÃO COMPULSÓRIA
                  DE ATIVOS 21.1. Nos casos em que o cliente não honre seus
                  compromissos com o Bbankcred (“Default”) ou em caso de
                  encerramento de conta independente do motivo (“Encerramento de
                  Conta”), o BBANKCRED, a seu exclusivo critério, reserva-se o
                  direito de realizar a liquidação compulsória de ativos (ex:
                  CDBs) independente de comunicação. O CLIENTE, DESDE JÁ, FICA
                  CIENTE E CONCORDA QUE O BBANKCRED NÃO PODERÁ SER
                  RESPONSABILIZADA POR EVENTUAIS PERDAS DE RENTABILIDADE E/OU
                  PREJUÍZOS FINANCEIROS GERADOS A PARTIR DESTA LIQUIDAÇÃO
                  COMPULSÓRIA. 22. INDENIZAÇÃO 22.1. O Usuário concorda em
                  defender, indenizar e manter indene ao Bbankcred, a
                  Instituição Parceira e suas afiliadas, diretores, empregados e
                  agentes, de e contra quaisquer encargos, ações ou demandas,
                  incluindo, exemplificativamente, honorários advocatícios
                  razoáveis, resultantes: (I) da eventual utilização indevida do
                  Site, do Aplicativo, do Software e/ou do seu Conteúdo; (II) da
                  violação das condições ora pactuadas; (III) qualquer atividade
                  da qual o Usuário participe ou por meio dos Serviços; e (IV)
                  sua violação de qualquer lei ou dos direitos de um terceiro.
                  22.2. Em nenhum caso o Bbankcred ou a Instituição Parceira
                  serão responsáveis por danos pessoais ou qualquer prejuízo
                  incidental, direto, indireto ou consequente, lucros cessantes,
                  incluindo, sem limitação, prejuízos por lucros cessantes,
                  corrupção ou perda de dados, falha de transmissão ou recepção
                  de dados, não continuidade do negócio ou qualquer outro
                  prejuízo ou perda comercial, decorrentes ou relacionados à
                  prestação de serviços por terceiros. 23. DIREITOS DE TERCEIROS
                  23.1. O Usuário confirma e concorda que os proprietários do
                  Conteúdo e determinados distribuidores (como os provedores de
                  lojas de aplicativos, “Provedores”) são beneficiários de todos
                  os acordos firmados entre o Usuário, o Bbankcred e a
                  Instituição Parceira, e têm o direito de impor tais acordos
                  diretamente contra você. Além do que está previsto nesta
                  cláusula, os acordos não se destinam a conceder direitos a
                  ninguém, exceto ao Usuário, ao Bbankcred e à Instituição
                  Parceira, e em nenhuma circunstância tais acordos deverão
                  criar quaisquer direitos que beneficiem terceiros. Além disso,
                  os direitos de encerrar, rescindir ou concordar com qualquer
                  variação, renúncia ou liquidação dos acordos não estão
                  sujeitos ao consentimento de qualquer outra pessoa. 23.2.
                  Estes Termos de Uso têm validade somente entre você, na
                  qualidade de Usuário, o Bbankcred e a Instituição Parceira,
                  sem ter quaisquer vínculos com os Provedores, que não são
                  responsáveis pelos Serviços e/ou pelo Conteúdo deste. Os
                  Provedores não possuem qualquer obrigação de prestar serviços
                  de manutenção e de suporte com relação aos Serviços. Em caso
                  de falha dos Serviços em conformidade com qualquer garantia
                  aplicável, então o Usuário poderá informar os Provedores, que
                  reembolsarão você por qualquer preço de compra aplicável; e,
                  na medida máxima permitida pela lei aplicável, os Provedores
                  não têm qualquer outra obrigação de garantia com relação aos
                  Serviços. Os Provedores não são responsáveis por abordar
                  nenhuma reivindicação de sua parte ou de qualquer terceiro com
                  relação aos Serviços ou sua posse e/ou uso dos Serviços,
                  incluindo, entre outros: (I) reivindicações de
                  responsabilidade dos produtos; (II) qualquer reivindicação de
                  que os Serviços falham em conformidade com qualquer requisito
                  legal ou regulatório aplicável; e (III) reivindicações
                  surgidas em virtude da proteção ao consumidor ou legislação
                  semelhante. Os Provedores não são responsáveis pela
                  investigação, defesa, liquidação e quitação de qualquer
                  reivindicação de terceiros de que os Serviços e/ou sua posse e
                  uso do aplicativo infringem os direitos de propriedade
                  intelectual daquele terceiro. Você, na qualidade de Usuário,
                  concorda em cumprir quaisquer termos de terceiros aplicáveis
                  ao usar os Serviços. 24. VIGÊNCIA E RESCISÃO 24.1. Estes
                  Termos de Uso entram em vigor a partir do Aceite do Usuário e
                  permanecerão vigentes por prazo indeterminado, podendo ser
                  rescindidos unilateralmente por qualquer das partes a qualquer
                  tempo, sem qualquer ônus, mediante simples comunicação por
                  meio do próprio Site/Aplicativo ou de mensagem para o e-mail
                  indicado no Cadastro pessoal do Usuário que implicará o
                  encerramento de sua Conta Bbankcred. 24.2. O Usuário poderá, a
                  qualquer momento, solicitar a exclusão da sua Conta Bbankcred,
                  encerrando seu relacionamento com a Bbankcred e com a
                  Instituição Parceira, se aplicável, mediante solicitação
                  realizada diretamente por meio do Site ou outros canais de
                  comunicação disponíveis. Neste caso, os dados do Usuário serão
                  tratados de acordo com o disposto no Aviso Externo de
                  Privacidade. A Conta Bbankcred será definitivamente encerrada
                  dentro de prazo razoável, nos termos da regulamentação
                  aplicável. 24.3. O Bbankcred terá a faculdade, a qualquer
                  momento, mediante aviso prévio, de encerrar a sua Conta
                  Bbankcred, nos termos da regulamentação aplicável. 24.4.
                  Ainda, o Bbankcred poderá, a seu exclusivo critério, encerrar
                  a conta do Usuário, caso não haja movimentação e saldo no
                  prazo de 6 (seis) meses consecutivos. 25. MODIFICAÇÕES 25.1. O
                  Bbankcred ou a Instituição Parceira, se aplicável, poderão, a
                  qualquer tempo, alterar estes Termos de Uso, e condições
                  comerciais, a seu exclusivo critério. Quaisquer alterações
                  relevantes nestes Termos de Uso serão informadas por meio do
                  Site e do Aplicativo, entrando em vigor a partir da publicação
                  da alteração. Caso o Usuário não concorde com as alterações,
                  deve solicitar o encerramento da sua relação com o Bbankcred,
                  de acordo com a cláusula 23.2 destes Termos. 25.1.1. Durante o
                  período mencionado acima, o Usuário poderá escolher rescindir
                  a relação contratual com o Bbankcred e com a Instituição
                  Parceira, sem aplicação de quaisquer multas. A utilização dos
                  Serviços pelo Usuário após a comunicação da modificação
                  implica concordância tácita do Usuário em relação a todas as
                  alterações realizadas nos Termos de Uso. 25.2. Será sempre
                  indicada a data da última atualização realizada pelo Bbankcred
                  e/ou pela Instituição Parceira aos presentes Termos de Uso.
                  25.3. Ao aceitar estes Termos de Uso e utilizar os Serviços, o
                  Usuário reconhece e aceita que, assim que publicada a
                  alteração destes Termos de Uso no Site ou no Aplicativo, o uso
                  do Site e do Aplicativo passará a ser submetido aos Termos de
                  Uso. 26. DISPOSIÇÕES GERAIS 26.1. Estes Termos de Uso não
                  geram contrato de sociedade, mandato, franquia ou relação de
                  trabalho entre o Usuário e o Bbankcred, a Instituição
                  Parceira, os profissionais, parceiros e/ou Anunciantes destes.
                  26.2. Caso qualquer disposição destes Termos de Uso venha a
                  ser considerada ilegal, nula ou inexequível por qualquer
                  razão, as disposições restantes não serão afetadas,
                  permanecendo válidas e aplicáveis na máxima extensão possível.
                  26.3. Estes Termos de Uso constituem a totalidade do acordo
                  sobre as condições de uso do Site, do Aplicativo e do
                  Software. O Usuário declara ter ciência dos direitos e
                  obrigações decorrentes dos presentes Termos de Uso, tendo
                  lido, compreendido e aceito todos os termos e condições neles
                  inseridos. 26.4. Qualquer falha do Bbankcred ou da Instituição
                  Parceira para impor ou exercer qualquer direito, prerrogativa
                  ou faculdade assegurados por estes Termos de Uso ou pela
                  legislação, incluindo direitos conexos, não constitui renúncia
                  a esse direito, prerrogativa ou faculdade. 26.5. O Usuário se
                  obriga a prover sua conta com recursos suficientes e
                  imediatamente disponíveis para débitos relativos a operações
                  de crédito contratadas para liquidação por meio de débito em
                  conta corrente, na data da exigibilidade dos valores devidos.
                  Você autoriza o débito na Conta Bbankcred de valores em atraso
                  de produtos de crédito contratados junto ao Bbankcred. Você
                  autoriza o Bbankcred, por prazo indeterminado, a compensar
                  créditos e débitos referentes à correção de lançamentos
                  incorretos efetuados em sua Conta, decorrentes de erros ou
                  falhas operacionais, nos termos do Código Civil. 27. LEI
                  APLICÁVEL 27.1.
                </p>
              </Modal>
            )}
          </Row>
        </>
      )}
    </>
  );
};

export { Dashboard };
