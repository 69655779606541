interface IResponse {
  status: number;
  data: {
    message: string;
  };
}

interface IReturnType {
  message: string;
  status: number;
}

export function getClientErrors({ data, status }: IResponse): IReturnType {
  if (status === 500) {
    return {
      status,
      message:
        'Erro interno do servidor. Por favor, contate o administrador do sistema.',
    };
  }

  if (
    data.message === 'celebrate request validation failed' ||
    data.message === 'validationError'
  ) {
    return {
      status: 400,
      message:
        'Alguns valores não passaram na validação interna. Cheque seus dados e tente novamente, e se o erro persistir, contate o administrador do sistema!',
    };
  }

  return {
    message: data.message,
    status,
  };
}
