import { FormHandles } from '@unform/core';
import { useRef, useCallback, FC, useState, useEffect } from 'react';
import { FiMinusCircle, FiPlusCircle, FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { useRouteMatch } from 'react-router-dom';
import { v4 } from 'uuid';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';
import { Input } from '@components/elements/Form/Input';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { Card, CardContent, CardHeader } from './styles';
import { periodOptions } from './selectOptions';
import { formValidation } from './validations';
import {
  IFormData,
  IPercentage,
  IParams,
  IAdditionalInfo,
  IItem,
  IItems,
} from './interfaces';

const EditInvestmentPercentage: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [percentage, setPercentage] = useState<IPercentage>();
  const [items, setItems] = useState<IItem[]>([]);

  useEffect(() => {
    async function loadPercentage() {
      const { data } = await api.get(
        `/investments/percentages/${params.percentageId}`,
      );

      setPercentage(data);
    }

    const timer = setTimeout(() => loadPercentage(), 1500);

    return () => clearInterval(timer);
  }, [params.percentageId]);

  const handleDeleteAdditionalInfo = useCallback(async (id: string) => {
    await api.delete(`/investments/additional-info/${id}`);

    setPercentage(oldState => {
      if (!oldState) return undefined;

      const { additional_info } = oldState;

      const updatedAdditionalInfo = additional_info.filter(
        item => item._id !== id,
      );

      return { ...oldState, additional_info: updatedAdditionalInfo };
    });
  }, []);

  const renderAdditionalInfo = useCallback(
    (additionalInfo: IAdditionalInfo[]) => {
      return additionalInfo.map((item, index) => (
        <FormRow key={item._id} style={{ alignItems: 'flex-end' }}>
          <Input
            name={`additional_info[${index}].id`}
            type="hidden"
            value={item._id}
          />

          <InputGroup>
            <label>Sobre...</label>
            <Input
              name={`additional_info[${index}].relation`}
              placeholder="Valor do investimento..."
              upperCase={false}
            />
          </InputGroup>

          <InputGroup>
            <label>Recebe...</label>
            <Input
              name={`additional_info[${index}].value`}
              placeholder="R$ 100,00 por mês"
              upperCase={false}
            />
          </InputGroup>

          <Button
            type="button"
            styleType="danger"
            onDoubleClick={() => handleDeleteAdditionalInfo(item._id)}
            icon={FiMinusCircle}
            title="Clique duas vezes para deletar!"
            style={{
              width: 50,
              height: 50,
              padding: 0,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </FormRow>
      ));
    },
    [handleDeleteAdditionalInfo],
  );

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const additionalInfoToUpdate = (data.additional_info as IItems) || [];
        const additionalInfoToCreate = (data.items as IItems) || [];

        const formData = data;

        delete formData.items;
        delete formData.additional_info;

        const { data: updatedData } = await api.put(
          `/investments/percentages/${params.percentageId}`,
          formData,
        );

        if (additionalInfoToUpdate.length > 0) {
          await api.put(`/investments/additional-info`, additionalInfoToUpdate);
        }

        if (additionalInfoToCreate.length > 0) {
          const { data: response } = await api.post(
            `/investments/additional-info`,
            {
              investmentPercentageId: updatedData.id,
              items: additionalInfoToCreate,
            },
          );

          updatedData.additional_info.push(...response);

          setItems([]);
        }

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'As informações enviadas foram atualizadas com sucesso.',
        });

        setPercentage(updatedData);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, params.percentageId],
  );

  const handleAddItems = useCallback(() => {
    setItems(oldState => {
      return [...oldState, { id: v4() }];
    });
  }, []);

  const handleRemoveItems = useCallback((id: string) => {
    setItems(oldState => {
      const updatedState = oldState.filter(item => item.id !== id);

      return updatedState;
    });
  }, []);

  return (
    <>
      {!percentage ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={[
                'Bbank Invest',
                'Percentuais de investimento',
                'Editar percentual',
              ]}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Preencha o formulário abaixo</h1>

                <Button
                  styleType="info"
                  icon={FiPlusCircle}
                  onClick={handleAddItems}
                  disabled={
                    percentage.additional_info.length + items.length === 10
                  }
                >
                  Adicionar característica
                </Button>
              </CardHeader>

              <CardContent>
                <Form
                  ref={formRef}
                  onSubmit={handleFormSubmit}
                  initialData={percentage}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Dê um nome a este percentual de rendimento!</label>
                      <Input name="name" upperCase={false} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Recorrência</label>
                      <InputCurrency
                        name="income"
                        decimalsLimit={2}
                        maxLength={10}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Período</label>
                      <Select name="period" options={periodOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor</label>
                      <InputCurrency
                        name="value"
                        decimalsLimit={2}
                        maxLength={10}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Percentual</label>
                      <InputMask mask="9.99" name="percentage" noUnmask />
                    </InputGroup>
                  </FormRow>

                  {percentage.additional_info.length > 0 && (
                    <FormRow separator>
                      <h1>Características já cadastradas</h1>
                    </FormRow>
                  )}

                  {renderAdditionalInfo(percentage.additional_info)}

                  {items.length > 0 && (
                    <FormRow separator>
                      <h1>Novas características</h1>
                    </FormRow>
                  )}

                  {items.map((item, index) => {
                    return (
                      <FormRow key={item.id} style={{ alignItems: 'flex-end' }}>
                        <InputGroup>
                          <label>Sobre...</label>
                          <Input
                            name={`items[${index}].relation`}
                            placeholder="Valor do investimento..."
                            upperCase={false}
                          />
                        </InputGroup>

                        <InputGroup>
                          <label>Recebe...</label>
                          <Input
                            name={`items[${index}].value`}
                            placeholder="R$ 100,00 por mês"
                            upperCase={false}
                          />
                        </InputGroup>

                        <Button
                          styleType="danger"
                          icon={FiMinusCircle}
                          onClick={() => handleRemoveItems(item.id)}
                        />
                      </FormRow>
                    );
                  })}

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      icon={FiSave}
                      loading={loadingRequest}
                    >
                      Salvar informações
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { EditInvestmentPercentage };
