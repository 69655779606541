import { useRouteMatch } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';

import { FAQ } from '@components/layouts/FAQ';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { Card, CardHeader, CardContent } from './styles';
import { IParams, IFaq } from './interfaces';

const Faqs: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [faqs, setFaqs] = useState<IFaq[]>();

  useEffect(() => {
    async function loadQuestions() {
      const { data } = await api.get(`/faqs/category/${params.faqCategoryId}`);
      setFaqs(data);
    }

    const timer = setTimeout(() => {
      loadQuestions();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.faqCategoryId]);

  return (
    <>
      {!faqs ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Categorias', 'Perguntas frequentes']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Perguntas frequentes</h1>
              </CardHeader>

              <CardContent>
                {faqs.length === 0 ? (
                  <Badge type="info">
                    Nenhuma pergunta foi adicionada para essa categoria!
                  </Badge>
                ) : (
                  faqs.map(item => (
                    <FAQ
                      key={item.id}
                      title={item.title}
                      content={item.content}
                    />
                  ))
                )}
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { Faqs };
