import { states } from '@helpers/getAllStates';

interface IOption {
  value: string;
  label: string;
}

export const statesOptions = Object.entries(states).reduce<IOption[]>(
  (acc, [key, value]) => {
    const option = {
      label: value,
      value: key,
    };

    acc.push(option);

    return acc;
  },
  [],
);

export const personTypeOptions: IOption[] = [
  { label: 'Pessoa física', value: 'PF' },
  { label: 'Pessoa jurídica', value: 'PJ' },
];
