import { FC, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import format from 'date-fns/format';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { Select } from '@components/elements/Form/Select';

import api from '@services/bbankApi';

import { vehicleIsNewType } from '@pages/private/admin/CredCash/Create/PF/selectOptions';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { IParams, IProspect } from './interfaces';

const ProspectDetailsVehicles: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [prospect, setProspect] = useState<IProspect>();

  useEffect(() => {
    async function loadProspect() {
      const { data } = await api.get(
        `/credit-guarantees/vehicles/${params.prospectId}`,
        {
          params: {
            relations: ['indication'],
          },
        },
      );

      setProspect({
        id: data.id,
        indication: data.indication,
        name: data.name,
        createdAt: data.created_at,
        ...data,
      });
    }

    const timer = setTimeout(() => {
      loadProspect();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.prospectId]);

  function formatCurrency(value: any) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(value);
  }

  return (
    <>
      {!prospect ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Prospectos</li>
              <li>{'>'}</li>
              <li>{prospect.name}</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes do prospecto selecionado</h1>

                <Badge type="info">
                  Propecto criado em:&nbsp;
                  {format(new Date(prospect.createdAt), 'dd/MM/yyyy')}
                </Badge>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={() => {
                    /* CODE */
                  }}
                  initialData={prospect}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Indicação</label>
                      <Input name="indication.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Comentários do Administrador</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Status </label>

                      <Input name="status" />
                    </InputGroup>

                    <InputGroup>
                      <label>Speed </label>

                      <Input name="spread" />
                    </InputGroup>
                    <InputGroup>
                      <label>Comentarios </label>
                      <Input name="commentaries" />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados da Proposta</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Tipo de Pessoa</label>
                      <Input name="person_type" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>CNH</label>
                      <Input name="cpf" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Tipo de proposta</label>
                      <Input name="proposal_type" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados Pessoais</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome Completo</label>
                      <Input name="client_name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>CPF</label>
                      <Input name="cpf" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>RG</label>
                      <Input name="general_registry" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data de Nascimento</label>
                      <Input
                        name="birthday"
                        readOnly
                        defaultValue={format(
                          new Date(prospect.birthday),
                          'dd/MM/yyyy',
                        )}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade de Nascimento</label>
                      <Input name="birth_city" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Input name="birth_state" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Estado Civil</label>
                      <Input name="marital_status" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Nome da mãe</label>
                      <Input name="mother_name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>E-mail</label>
                      <Input name="email" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados Residenciais</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Telefone Celular</label>
                      <Input name="company_phone" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>CEP</label>
                      <Input name="postal_code" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Endereço</label>
                      <Input name="address" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="city" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Complemento</label>
                      <Input name="neighborhood" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Input name="state" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Tempo de residência</label>
                      <Input name="resident_time" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados profissionais</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CNPJ</label>
                      <Input name="company_document" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Empresa</label>
                      <Input name="company_phone" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Telefone</label>
                      <Input name="company_phone" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Endereço</label>
                      <Input name="company_address" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Complemento</label>
                      <Input name="complement" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="city" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Estado</label>
                      <Input name="state" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Profissão</label>
                      <Input name="profession" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Salário</label>
                      <Input
                        name="income"
                        readOnly
                        value={formatCurrency(prospect.income)}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Data de admissão</label>
                      <Input
                        name="created_at"
                        readOnly
                        defaultValue={format(
                          new Date(prospect.created_at),
                          'dd/MM/yyyy',
                        )}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados Bancários</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Banco</label>
                      <Input name="bank" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Agência</label>
                      <Input name="agency" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Conta</label>
                      <Input name="account" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados do veiculo</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>0KM</label>

                      <Select
                        name="vehicle_is_new"
                        options={vehicleIsNewType}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Placa do Veiculo</label>
                      <Input name="vehicle_plate" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Marca</label>
                      <Input name="vehicle_brand" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Modelo</label>
                      <Input name="vehicle_model" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Ano</label>
                      <Input name="vehicle_model_year" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor do Bem</label>
                      <Input
                        name="vehicle_value"
                        readOnly
                        id="vehicle_value"
                        value={formatCurrency(prospect.vehicle_value)}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor de Entrada</label>
                      <Input
                        name="financing_value"
                        readOnly
                        value={formatCurrency(prospect.financing_value)}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor Financiado</label>
                      <Input
                        name="first_value_to_pay"
                        readOnly
                        value={formatCurrency(prospect.first_value_to_pay)}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Prazo/Parcelas</label>
                      <Input name="portions" readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ProspectDetailsVehicles };
