import { ReactNode, useState, useCallback, useEffect, forwardRef } from 'react';
import { FiX } from 'react-icons/fi';
import { CSSProperties } from 'styled-components';

import { Container, Background, ButtonWrapper, Content } from './styled';

interface IEvent {
  visible: boolean;
}

interface IModalProps {
  buttonText?: string;
  headerText?: string;
  visible?: boolean;
  hideButton?: boolean;
  onOkClick?: (data: IEvent) => void;
  onDismiss?: (data: IEvent) => void;
  okButtonText?: string;
  closeButtonText?: string;
  hide?: {
    xButton: boolean;
    closeButton: boolean;
  };
  children?: ReactNode;
  style?: CSSProperties;
}

const Modal = forwardRef<HTMLDivElement, IModalProps>(
  (
    {
      buttonText,
      headerText,
      visible = false,
      hideButton,
      onOkClick,
      children,
      okButtonText,
      closeButtonText,
      hide,
      onDismiss,
      style,
    },
    ref,
  ) => {
    const [isVisible, setIsVisible] = useState(visible);

    useEffect(() => {
      setIsVisible(visible);
    }, [visible]);

    const handleModal = useCallback(() => {
      setIsVisible(!isVisible);

      if (onDismiss) {
        setTimeout(() => {
          onDismiss({ visible: !isVisible });
        }, 500);
      }
    }, [isVisible, onDismiss]);

    const handleOkButtonCallbackActions = useCallback(() => {
      setIsVisible(false);

      if (onOkClick) {
        onOkClick({ visible: false });
      }
    }, [onOkClick]);

    return (
      <>
        <Container ref={ref}>
          {!!hideButton === false && (
            <ButtonWrapper>
              <button type="button" onClick={handleModal}>
                {buttonText || 'Modal'}
              </button>
            </ButtonWrapper>
          )}

          <Content isVisible={isVisible} style={style}>
            <div>
              <h1>{headerText || 'Modal header'}</h1>

              {!hide?.xButton && <FiX size={20} onClick={handleModal} />}
            </div>

            <div>{children}</div>

            <div>
              {!hide?.closeButton && (
                <button type="button" onClick={handleModal}>
                  {closeButtonText || 'Fechar'}
                </button>
              )}
              <button type="button" onClick={handleOkButtonCallbackActions}>
                {okButtonText || 'OK!'}
              </button>
            </div>
          </Content>
        </Container>
        <Background isVisible={isVisible} />
      </>
    );
  },
);

export { Modal };
