import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    document: Yup.string().required('Preencha este campo!'),
    companyWebsite: Yup.string(),
    humanResourcesContactName: Yup.string().required('Preencha este campo!'),
    humanResourcesContactEmail: Yup.string()
      .required('Preencha este campo!')
      .email('Insira um email válido!'),
    humanResourcesPhone: Yup.string().required('Preencha este campo!'),
    sponsorsNumber: Yup.number().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
