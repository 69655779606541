import { FC, ComponentType, ButtonHTMLAttributes } from 'react';
import { IconBaseProps } from 'react-icons/lib';
import { TailSpin } from 'react-loader-spinner';

import { Container } from './styles';

interface ITableButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType: 'warning' | 'danger' | 'info' | 'success';
  icon: FC<IconBaseProps>;
  loading?: boolean;
}

export const TableButton: FC<ITableButton> = ({
  styleType,
  icon: Icon,
  children,
  loading,
  ...rest
}) => {
  return (
    <Container styleType={styleType} {...rest}>
      {loading ? (
        <TailSpin color="#fff" width={24} height={24} />
      ) : (
        <>
          <Icon size={22} />
          {children}
        </>
      )}
    </Container>
  );
};
