interface IFaqCategories {
  id: string;
  title: string;
}

interface IReturnType {
  value: string;
  label: string;
}

export const getFaqCategoriesOptions = (
  faqCategories: IFaqCategories[],
): IReturnType[] => {
  const options = faqCategories.map(item => {
    return {
      label: item.title,
      value: item.title,
    };
  });

  return options;
};
