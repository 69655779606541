import { useRouteMatch } from 'react-router-dom';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { FiSave } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Textarea } from '@components/elements/Form/Textarea';
import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';
import { LoadingPage } from '@components/layouts/LoadingPage';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { formValidation } from './validations';
import { ICategory, IFormData, IParams } from './interfaces';

const EditMarketingCategory: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { params } = useRouteMatch<IParams>();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [category, setCategory] = useState<ICategory>();

  useEffect(() => {
    async function loadCategory() {
      const { data } = await api.get(
        `/marketing/categories/${params.marketingCategoryId}`,
      );

      setCategory(data);
    }

    const timer = setTimeout(() => loadCategory(), 1500);

    return () => clearInterval(timer);
  }, [params.marketingCategoryId]);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        setButtonLoading(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        await api.put(
          `/marketing/categories/${params.marketingCategoryId}`,
          data,
        );

        addToast({
          type: 'success',
          title: 'Muito bom!',
          message: 'A categoria foi registrada com sucesso!',
        });

        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { status, message } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }
        }
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast, params.marketingCategoryId],
  );

  return (
    <>
      {!category ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Marketing</li>
              <li>{'>'}</li>
              <li>Categorias</li>
              <li>{'>'}</li>
              <li>Detalhes</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Nova categoria para marketing</h1>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={category}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Nome da categoria</label>
                      <Input name="name" upperCase={false} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>
                        Uma breve descrição para a categoria (opcional)
                      </label>
                      <Textarea name="description" rows={8} />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="info"
                      icon={FiSave}
                      loading={buttonLoading}
                    >
                      Salvar alterações
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { EditMarketingCategory };
