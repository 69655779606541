import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    name: Yup.string()
      .required('Preencha este campo!')
      .max(255, 'O máximo são 255 caracteres'),
    decription: Yup.string().max(255, 'O máximo são 255 caracteres'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
