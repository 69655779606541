import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import DataTable, { TableColumn } from 'react-data-table-component';
import { FiPlusCircle, FiTrash } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import { useToast } from '@hooks/toast';

import bbankApi from '@services/bbankApi';

import { URLPath, Card, CardContent, CardHeader } from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IColumns = Array<TableColumn<Record<string, any>>>;

interface IFaq {
  id: string;
  title: string;
  content: string;
  sub_category: string;
  faq_category: {
    title: string;
  };
  created_at: string;
  updated_at: string;
}

interface IFaqState {
  id: string;
  title: string;
  content: string;
  sub_category: string;
  category: string;
  created_at: string;
  updated_at: string;
}

interface IDataTableData {
  id: string;
  title: string;
  content: string;
  sub_category: string;
  category: string;
  created_at: string;
  updated_at: string;
  actions: JSX.Element;
}

const AllFaqs: FC = () => {
  const { addToast } = useToast();
  const { push } = useHistory();
  const [faqs, setFaqs] = useState<Array<IFaqState>>([]);

  const handleFaqDelete = useCallback(
    async (id: string) => {
      try {
        await bbankApi.delete(`/faqs/${id}`);

        setFaqs(oldState => {
          const newState = oldState.filter(item => item.id !== id);

          return newState;
        });

        addToast({
          title: 'FAQ deletada!',
          type: 'info',
        });
      } catch {
        addToast({
          title: 'Algum erro aconteceu',
          type: 'error',
          message: 'Tente novamente mais tarde',
        });
      }
    },
    [addToast],
  );

  const dataTableColumns = useMemo<IColumns>(() => {
    return [
      {
        name: 'Titulo',
        selector: 'title',
      },
      {
        name: 'Conteúdo',
        selector: 'content',
      },
      {
        name: 'Categoria',
        selector: 'category',
      },
      {
        name: 'Sub Categoria',
        selector: 'sub_category',
      },
      {
        name: 'Criado em',
        selector: 'created_at',
      },
      {
        name: 'Atualizado em',
        selector: 'updated_at',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const dataTableData = useMemo<IDataTableData[]>(() => {
    const data = faqs.map<IDataTableData>(faq => {
      return {
        id: faq.id,
        category: faq.category,
        content: faq.content,
        created_at: faq.created_at,
        sub_category: faq.sub_category,
        title: faq.title,
        updated_at: faq.updated_at,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              onDoubleClick={() => handleFaqDelete(faq.id)}
              key={`${faq.id}4`}
              icon={FiTrash}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [faqs, handleFaqDelete]);

  useEffect(() => {
    async function loadFaqs() {
      const { data } = await bbankApi.get<Array<IFaq>>('/faqs');

      setFaqs(oldState => {
        const newState = data.map(item => {
          const createdAt = format(new Date(item.created_at), 'dd/MM/yyyy');
          const updatedAt = format(new Date(item.updated_at), 'dd/MM/yyyy');

          return {
            id: item.id,
            title: item.title,
            content: item.content,
            sub_category: item.sub_category,
            category: item.faq_category.title,
            created_at: createdAt,
            updated_at: updatedAt,
          };
        });

        return [...oldState, ...newState];
      });
    }

    loadFaqs();
  }, []);

  return (
    <>
      <Row>
        <URLPath>
          <li>
            <strong>FAQ</strong>
          </li>
          <li>&gt;</li>
          <li>
            <Link to="/faq/all">Todos</Link>
          </li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Listagem</h1>

            <Button
              styleType="success"
              icon={FiPlusCircle}
              loading={false}
              onClick={() => push('/faq/new')}
            >
              Adicionar
            </Button>
          </CardHeader>

          <CardContent>
            <DataTable
              columns={dataTableColumns}
              data={dataTableData}
              pagination
              striped
            />
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { AllFaqs };
