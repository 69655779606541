import { FC, useMemo, useEffect, useState, useCallback } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { LoadingPage } from '@components/layouts/LoadingPage';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { Card, CardHeader, CardContent } from './styles';
import { IConsignedProspect } from './interfaces';

export const ListConsignedProspect: FC = () => {
  const { push } = useHistory();
  const { user } = useAuth();

  const [consignedProspects, setConsignedProspects] = useState<
    IConsignedProspect[]
  >();

  useEffect(() => {
    async function loadConsigned() {
      const { data } = await api.get(
        `/consigned/prospects/indications/${user.id}`,
      );

      const parsedData = data.map((item: IConsignedProspect) =>
        parseObjectPropertiesToCamelCase<IConsignedProspect>(item),
      );

      setConsignedProspects(parsedData);
    }

    const timer = setTimeout(() => loadConsigned(), 1500);

    return () => clearInterval(timer);
  }, [user]);

  const handleGoToDetailsPage = useCallback(
    (consignedProspectId: string) => {
      push(
        `/franchisees/cred-cash/consigned/prospects/${consignedProspectId}/details`,
      );
    },
    [push],
  );

  const tableColumms = useMemo(() => {
    return [
      {
        name: 'Documento',
        selector: 'search',
      },
      {
        name: 'Site da empresa',
        selector: 'companyWebsite',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!consignedProspects) {
      return [];
    }

    const data = consignedProspects.map(cons => ({
      id: cons.id,
      search: cons.document,
      companyWebsite: cons.companyWebsite || '-',
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="info"
            icon={FiEye}
            onClick={() => handleGoToDetailsPage(cons.id)}
            title="Ver detalhes"
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [consignedProspects, handleGoToDetailsPage]);

  return (
    <>
      {!consignedProspects ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={['Cred Cash', 'Consiginado privado', 'Solicitações']}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Lista de solicitações</h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumms} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
