import { FC, useCallback, useRef, useState } from 'react';
import {
  FiFlag,
  FiGift,
  FiHash,
  FiHome,
  FiLock,
  FiMail,
  FiMap,
  FiMapPin,
  FiPhone,
  FiSmartphone,
  FiUser,
  FiTag,
  FiSave,
  FiSmile,
} from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/Grid/Row';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { InputMask } from '@components/elements/Form/InputMask';
import { Button } from '@components/elements/Button';
import { URLPath } from '@components/layouts/UrlPath';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';

import { IZipcode } from '@utils/interfaces';

import { removeInputMask } from '@helpers/removeInputMask';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { Card, CardHeader, CardContent } from './styles';
import { personTypeOptions } from './selectOptions';
import { formValidation } from './validations';
import { IAddressInfo, IDocumentMask } from './interfaces';

const CreateSellers: FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [loadingRequest, setLoadingRequest] = useState(false);
  const [addressInfo, setAddressInfo] = useState<IAddressInfo>();
  const [documentMask, setDocumentMask] = useState<IDocumentMask>(
    '999.999.999-999',
  );

  const handleFormSubmit = useCallback(
    async (data, { reset }) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const valuesWithoutMask = removeInputMask();

        const formData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, data);

        delete formData.confirm_password;

        formData.indication = user.id;
        formData.status = 'ativo';
        formData.role = 'seller';

        await api.post('/users', formData);

        addToast({
          title: 'Muito bem!',
          type: 'success',
          message: 'Um novo vendedor foi adicionado com sucesso!',
        });

        reset();
        setAddressInfo(undefined);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 401 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Um erro desconhecido aconteceu, por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, user.id],
  );

  const handleFindZipcode = useCallback(
    async (zipcode: string) => {
      try {
        const { data } = await viaCepApi.get<IZipcode>(`/${zipcode}/json`);

        const stateFieldRef = formRef.current?.getFieldRef('state');
        const cityFieldRef = formRef.current?.getFieldRef('city');
        const addressFieldRef = formRef.current?.getFieldRef('address');
        const passwordFieldRef = formRef.current?.getFieldRef('password');

        stateFieldRef.value = data.uf;
        cityFieldRef.value = data.localidade;
        addressFieldRef.value = data.logradouro;
        passwordFieldRef.focus();

        setAddressInfo({
          address: data.logradouro,
          city: data.localidade,
          state: data.uf,
        });
      } catch {
        addToast({
          title: 'Código postal em formato inválido!',
          type: 'error',
        });
      }
    },
    [addToast],
  );

  const handleDocumentKeyUp = useCallback((value: string) => {
    setDocumentMask(
      value.length > 14 ? '99.999.999/9999-99' : '999.999.999-999',
    );
  }, []);

  return (
    <>
      <>
        <Row>
          <URLPath paths={['Vendedores', 'Novo']} />
        </Row>

        <Row>
          <Card>
            <CardHeader>
              <h1>Preencha o formulário abaixo</h1>
            </CardHeader>

            <CardContent>
              <Form onSubmit={handleFormSubmit} ref={formRef}>
                <FormRow separator>
                  <h1>Dados básicos</h1>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Nome do vendedor</label>
                    <Input name="name" icon={FiUser} />
                  </InputGroup>

                  <InputGroup>
                    <label>Email</label>
                    <Input
                      name="email"
                      icon={FiMail}
                      upperCase={false}
                      placeholder="name@exemple.com"
                    />
                  </InputGroup>

                  <InputGroup>
                    <label>Data de aniversário</label>
                    <InputMask
                      name="birthdate"
                      mask="99/99/9999"
                      icon={FiGift}
                      noUnmask
                      placeholder="00/00/0000"
                    />
                  </InputGroup>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Tipo de cadastro (PF/PJ)</label>
                    <Select
                      name="person_type"
                      options={personTypeOptions}
                      icon={FiTag}
                    />
                  </InputGroup>

                  <InputGroup>
                    <label>Documento (CPF/CNPJ)</label>
                    <InputMask
                      icon={FiHash}
                      name="document"
                      mask={documentMask}
                      onChange={e => handleDocumentKeyUp(e.target.value)}
                    />
                  </InputGroup>
                </FormRow>

                <FormRow separator>
                  <h1>Link</h1>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Apelido</label>
                    <Input name="nickname" icon={FiSmile} upperCase={false} />
                  </InputGroup>
                </FormRow>

                <FormRow separator>
                  <h1>Contato</h1>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Telefone</label>
                    <InputMask
                      name="phone"
                      mask="(99) 9999-9999"
                      placeholder="(00) 0000-0000"
                      icon={FiPhone}
                    />
                  </InputGroup>

                  <InputGroup>
                    <label>Celular</label>
                    <InputMask
                      name="cellphone"
                      mask="(99) 99999-9999"
                      placeholder="(00) 00000-0000"
                      icon={FiSmartphone}
                    />
                  </InputGroup>
                </FormRow>

                <FormRow separator>
                  <h1>Endereço</h1>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Código postal (CEP)</label>
                    <InputMask
                      name="zipcode"
                      mask="99999-999"
                      placeholder="00000-000"
                      icon={FiMapPin}
                      onBlur={e => handleFindZipcode(e.target.value)}
                    />
                  </InputGroup>

                  <InputGroup>
                    <label>Endereço</label>
                    <Input
                      name="address"
                      icon={FiHome}
                      defaultValue={addressInfo?.address}
                    />
                  </InputGroup>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Cidade</label>
                    <Input
                      name="city"
                      icon={FiMap}
                      defaultValue={addressInfo?.city}
                    />
                  </InputGroup>

                  <InputGroup>
                    <label>Estado</label>
                    <Input
                      name="state"
                      icon={FiFlag}
                      defaultValue={addressInfo?.state}
                    />
                  </InputGroup>
                </FormRow>

                <FormRow separator>
                  <h1>Senhas</h1>
                </FormRow>

                <FormRow>
                  <InputGroup>
                    <label>Senha</label>
                    <Input type="password" name="password" icon={FiLock} />
                  </InputGroup>

                  <InputGroup>
                    <label>Repetir senha</label>
                    <Input
                      type="password"
                      name="confirm_password"
                      icon={FiLock}
                    />
                  </InputGroup>
                </FormRow>

                <FormRow buttonWrapper>
                  <Button
                    styleType="success"
                    type="submit"
                    loading={loadingRequest}
                    icon={FiSave}
                  >
                    Cadastrar novo vendedor
                  </Button>
                </FormRow>
              </Form>
            </CardContent>
          </Card>
        </Row>
      </>
    </>
  );
};

export { CreateSellers };
