import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const consignedFormValidation = async (
  data: IFormData,
): Promise<void> => {
  const shape = Yup.object().shape({
    bank_with_code: Yup.string().required('Preencha este campo!'),
    bank_agency: Yup.string().required('Preencha este campo!'),
    bank_account: Yup.string().required('Preencha este campo!'),
    proven_icome: Yup.number()
      .transform((va, ov) => {
        return ov === '' ? undefined : va;
      })
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    phone: Yup.string().required('Preencha este campo!'),
    credit_value: Yup.number()
      .transform((va, ov) => {
        return ov === '' ? undefined : va;
      })
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
