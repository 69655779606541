import { FC, useCallback, useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { InputGroup } from '@components/elements/Form/InputGroup';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { Card, CardContent, CardHeader } from './styles';
import { IConsignedProspect, IParams } from './interfaces';

export const ConsignedProspectDetails: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [consigned, setConsigned] = useState<IConsignedProspect>();

  useEffect(() => {
    async function loadConsigned() {
      const { data } = await api.get(
        `/consigned/prospects/${params.consignedProspectId}`,
      );

      const parsedData = parseObjectPropertiesToCamelCase<IConsignedProspect>(
        data,
      );

      setConsigned(parsedData);
    }

    const timer = setTimeout(() => loadConsigned(), 1500);

    return () => clearInterval(timer);
  }, [params.consignedProspectId]);

  const handleFormSubmit = useCallback(async () => {
    // CODE
  }, []);

  return (
    <>
      {!consigned ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Cred Cash', 'Consignado', 'Detalhes']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Detalhes do consignado selecionado</h1>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} initialData={consigned}>
                  <FormRow separator>
                    <h1>Dados</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Documento</label>
                      <InputMask
                        mask="99.999.999/9999-99"
                        name="document"
                        readOnly
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Site da empresa</label>
                      <Input name="companyWebsite" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome do contato do RH</label>
                      <Input name="humanResourcesContactName" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Email do RH</label>
                      <Input
                        type="email"
                        name="humanResourcesContactEmail"
                        readOnly
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Telefone do contato do RH</label>
                      <InputMask
                        mask="(99) 99999-9999"
                        name="humanResourcesPhone"
                        readOnly
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Número de colaboradores</label>
                      <Input name="sponsorsNumber" readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
