import { FC, useCallback, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useSpring } from 'react-spring';

import { Container, Header, Content } from './styles';

interface IFaq {
  title: string;
  content: string;
}

const FAQ: FC<IFaq> = ({ title, content }) => {
  const [isDropped, setIsDropped] = useState(false);
  const { height } = useSpring({ height: isDropped ? 'auto' : 70 });

  const handleClick = useCallback(() => {
    setIsDropped(!isDropped);
  }, [isDropped]);

  return (
    <Container style={{ height }}>
      <Header onClick={handleClick}>
        <h1>{title}</h1>
        <FiChevronDown
          size={22}
          color="#1e1e1e"
          style={{
            transform: isDropped ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        />
      </Header>

      <Content>
        <p>{content}</p>
      </Content>
    </Container>
  );
};

export { FAQ };
