interface IOption {
  value: string | number;
  label: string;
}

export const statusOpions: IOption[] = [
  { label: 'Ativo', value: 'ativo' },
  { label: 'Pendente', value: 'pendente' },
  { label: 'Parado', value: 'parado' },
];

export const paymentFormOptions: IOption[] = [
  { value: 'credit_card', label: 'Cartão de crédito' },
  { value: 'debit', label: 'Débito' },
  { value: 'billet', label: 'Boleto' },
];

export const sellingTypeOptions: IOption[] = [
  { value: 'sells_with_cashback', label: 'Vendas com CashBack' },
  { value: 'sells_without_cashback', label: 'Vendas sem CashBack' },
];

export const businessMethodOptions = [
  { value: 'accession', label: 'Adesão' },
  { value: 'sells_a930', label: 'Vendas - A930' },
  { value: 'sells_a50', label: 'Vendas - A50' },
];

export const portionsOptions: IOption[] = Array.from(Array(12).keys()).map(
  value => ({
    value: value + 1,
    label: String(value + 1),
  }),
);
