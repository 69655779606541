import {
  FC,
  useCallback,
  useMemo,
  useState,
  Fragment,
  useRef,
  useEffect,
} from 'react';
import { FiMinusCircle, FiPlusCircle, FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';
import { v4 } from 'uuid';
import { OptionTypeBase } from 'react-select';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  DependentsContainer,
  RemoveDependentButton,
} from './styles';
import {
  maritalStatusOptions,
  getFranchiseesOptions,
  getFranchiseClientsOptions,
  paymentFormOptions,
  portionsOptions,
} from './selectOptions';
import {
  ITelemedicineDependent,
  ITelemedicineData,
  IFranchise,
  IClient,
} from './interfaces';
import { telemedicineValidation } from './validations';

const NewTelemedicine: FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [dependents, setDependents] = useState<JSX.Element[]>([]);
  const [franchisees, setFranchisees] = useState<IFranchise[]>([]);
  const [franchiseClients, setFranchiseClients] = useState<IClient[]>();
  const [loadingButton, setLoadingButton] = useState(false);
  const [price, setPrice] = useState(34.9 * 13);

  useEffect(() => {
    async function loadFranchisees() {
      const { data } = await api.get<IFranchise[]>('/franchises');

      setFranchisees(data);
    }

    loadFranchisees();
  }, []);

  const handleRemoveDependents = useCallback((dependentFormKey: string) => {
    setPrice(oldState => {
      if (oldState === 59.8 * 13) {
        return 34.9 * 13;
      }

      return Number(Number(oldState - 29.9 * 13).toFixed(1));
    });
    setDependents(oldState => {
      const updatedDependentsForm = oldState.filter(
        item => item.key !== dependentFormKey,
      );

      return updatedDependentsForm;
    });
  }, []);

  const getDependentsForm = useCallback(() => {
    const key = v4();

    return (
      <Fragment key={key}>
        <FormRow separator>
          <h1>
            Dependente&nbsp;
            {dependents.length + 1}
          </h1>

          <RemoveDependentButton
            onClick={() => handleRemoveDependents(key)}
            type="button"
          >
            <FiMinusCircle size={20} />
            Remover
          </RemoveDependentButton>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>CPF</label>
            <InputMask
              name={`dependents-cpf[${dependents.length}]`}
              mask="99999999999"
            />
          </InputGroup>

          <InputGroup>
            <label>Nome</label>
            <Input name={`dependents-name[${dependents.length}]`} />
          </InputGroup>

          <InputGroup>
            <label>Sexo</label>
            <Input name={`dependents-sex[${dependents.length}]`} />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Data de nascimento</label>
            <InputMask
              name={`dependents-birthday[${dependents.length}]`}
              mask="99/99/9999"
              noUnmask
            />
          </InputGroup>

          <InputGroup>
            <label>Estado civil</label>
            <Select
              name={`dependents-maritalStatus[${dependents.length}]`}
              options={maritalStatusOptions}
            />
          </InputGroup>

          <InputGroup>
            <label>Relação como dependente</label>
            <Input name={`dependents-relationship[${dependents.length}]`} />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Número do cartão nacional de saúde</label>
            <Input
              name={`dependents-nationalHealthCard[${dependents.length}]`}
            />
          </InputGroup>

          <InputGroup>
            <label>Nome da mãe</label>
            <Input name={`dependents-motherName[${dependents.length}]`} />
          </InputGroup>
        </FormRow>
      </Fragment>
    );
  }, [dependents, handleRemoveDependents]);

  const bbankConnectPriceObject = useMemo(() => {
    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const priceFormatted = numberFormat(price);

    const priceFieldRef = formRef.current?.getFieldRef(
      'telemedicine-paymentValue',
    );

    if (priceFieldRef) {
      priceFieldRef.value = priceFormatted;
    }

    return {
      priceFormatted,
      price: price.toFixed(2),
    };
  }, [price]);

  const franchiseesOptions = useMemo(() => {
    return franchisees.length > 0 ? getFranchiseesOptions(franchisees) : [];
  }, [franchisees]);

  const franchiseClientsOptions = useMemo(() => {
    return franchiseClients ? getFranchiseClientsOptions(franchiseClients) : [];
  }, [franchiseClients]);

  const handleAddDependents = useCallback(() => {
    const dependentsForm = getDependentsForm();

    setPrice(oldState => {
      if (oldState === 34.9 * 13) {
        return 29.9 * 2 * 13;
      }

      return Number(Number(oldState + 29.9 * 13).toFixed(1));
    });
    setDependents(oldState => {
      return [...oldState, dependentsForm];
    });
  }, [getDependentsForm]);

  const handleFormSubmit = useCallback(
    async (data, { reset }) => {
      try {
        const telemedicineData: ITelemedicineData = {};
        const telemedicineDependents: ITelemedicineDependent[] = [];

        await telemedicineValidation(data);

        Object.entries(data).forEach(([index, value]) => {
          if (index.search('telemedicine-') >= 0) {
            const parsedIndex = index.replace('telemedicine-', '');

            telemedicineData[parsedIndex] = value as string;
          }
        });

        dependents.forEach((_, index) => {
          const telemedicineDependent = {
            birthday: data[`dependents-birthday`][index],
            cpf: data[`dependents-cpf`][index],
            maritalStatus: data[`dependents-maritalStatus`][index],
            motherName: data[`dependents-motherName`][index],
            name: data[`dependents-name`][index],
            nationalHealthCard: data[`dependents-nationalHealthCard`][index],
            relationship: data[`dependents-relationship`][index],
            sex: data[`dependents-sex`][index],
          };

          telemedicineDependents.push(telemedicineDependent);
        });

        telemedicineData.paymentValue = bbankConnectPriceObject.price;

        const { data: telemedicine } = await api.post('/telemedicine', {
          ...telemedicineData,
        });

        if (dependents.length > 0) {
          await api.post('/telemedicine-dependents/many', {
            telemedicineId: telemedicine.id,
            data: telemedicineDependents,
          });
        }

        addToast({
          title: 'Solicitação feita com sucesso!',
          type: 'success',
          message: 'Aguarde, entraremos em contatos para maiores informações!',
        });

        reset();
        setDependents([]);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro.',
            });
          }
        }
      }
    },
    [dependents, addToast, bbankConnectPriceObject.price],
  );

  const handleFindFranchiseClients = useCallback(
    async (value: OptionTypeBase | null) => {
      if (!value) {
        return;
      }

      const indicationId = value.value;

      setLoadingButton(true);

      const { data: franchiseClientsFromApi } = await api.get<IClient[]>(
        `/users-indicated/${indicationId}`,
      );

      setFranchiseClients(franchiseClientsFromApi);
      setLoadingButton(false);
    },
    [],
  );

  return (
    <>
      <Row>
        <URLPath>
          <li>Bbank Connect</li>
          <li>{'>'}</li>
          <li>Telemedicina</li>
          <li>{'>'}</li>
          <li>Novo</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Novo telemedicina</h1>

            <Button
              styleType="info"
              icon={FiPlusCircle}
              type="button"
              onClick={handleAddDependents}
            >
              Adicionar dependentes
            </Button>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow separator>
                <h1>Indicação e cliente</h1>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Fraqueado (indicação)</label>
                  <Select
                    name="telemedicine-indication"
                    onChange={handleFindFranchiseClients}
                    options={franchiseesOptions}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Cliente do franqueado</label>
                  <Select
                    name="telemedicine-userId"
                    options={franchiseClientsOptions}
                  />
                </InputGroup>
              </FormRow>

              <FormRow separator>
                <h1>Dados para o telemedicina</h1>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Registro geral (RG)</label>
                  <Input name="telemedicine-generalRegistry" />
                </InputGroup>

                <InputGroup>
                  <label>Data de nascimento</label>
                  <InputMask
                    mask="99/99/9999"
                    name="telemedicine-birthday"
                    noUnmask
                  />
                </InputGroup>

                <InputGroup>
                  <label>Sexo</label>
                  <Input name="telemedicine-sex" />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Estado civil</label>
                  <Select
                    name="telemedicine-maritalStatus"
                    options={maritalStatusOptions}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Nome da mãe</label>
                  <Input name="telemedicine-motherName" />
                </InputGroup>
              </FormRow>

              <FormRow separator>
                <h1>Método de pagamento</h1>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Preço a ser pago</label>
                  <Input
                    name="telemedicine-paymentValue"
                    readOnly
                    defaultValue={bbankConnectPriceObject.priceFormatted}
                  />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Forma de pagamento</label>
                  <Select
                    name="telemedicine-paymentForm"
                    options={paymentFormOptions}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Parcelas</label>
                  <Select
                    name="telemedicine-paymentPortions"
                    options={portionsOptions}
                  />
                </InputGroup>
              </FormRow>

              <DependentsContainer>
                {dependents.map(item => item)}
              </DependentsContainer>

              <FormRow buttonWrapper>
                <Button
                  type="submit"
                  styleType="success"
                  icon={FiSave}
                  loading={loadingButton}
                >
                  Enviar
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewTelemedicine };
