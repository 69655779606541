import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const validationShape = Yup.object().shape({
    password: Yup.string().required('Insira sua senha de acesso!'),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
