import { FC, useCallback, useEffect, useState } from 'react';
import { FiHeart, FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { format } from 'date-fns';

import { Row } from '@components/layouts/Grid/Row';
import { Input } from '@components/elements/Form/Input';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import {
  Card,
  CardHeader,
  CardContent,
  Telemedicine,
  TelemedicineInfo,
} from './styles';

interface ITelemedicine {
  id: string;
  created_at: string;
  user: {
    name: string;
    document: string;
  };
}

const FranchiseesAllTelemedicines: FC = () => {
  const { push } = useHistory();

  const [telemedicines, setTelemedicines] = useState<ITelemedicine[]>([]);
  const [searchTelemedicines, setSearchTelemedicines] = useState<
    ITelemedicine[]
  >([]);
  const [searchString, setSearchString] = useState('');

  useEffect(() => {
    async function loadTelemedicines() {
      const { data: telemedicinesFromApi } = await api.get<ITelemedicine[]>(
        '/indicated-telemedicines',
      );

      setTelemedicines(telemedicinesFromApi);
      setSearchTelemedicines(telemedicinesFromApi);
    }

    loadTelemedicines();
  }, []);

  const handleSearch = useCallback(() => {
    if (searchString === '') {
      setSearchTelemedicines(telemedicines);
      return;
    }

    const updatedSearchTelemedicines = telemedicines.filter(
      item =>
        item.user.name.toLowerCase().search(searchString.toLowerCase()) >= 0 ||
        item.user.document.toLowerCase().search(searchString.toLowerCase()) >=
          0,
    );

    setSearchTelemedicines(updatedSearchTelemedicines);
  }, [searchString, telemedicines]);

  const handleNavigationToTelemedicineDetails = useCallback(
    (telemedicineId: string) => {
      push(`/franchisees/bbank-connect/telemedicine/details/${telemedicineId}`);
    },
    [push],
  );

  return (
    <>
      <Row>
        <URLPath paths={['Bbank Connect', 'Solicitações', 'Telemedicina']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Todas as solicitações</h1>

            <Form onSubmit={handleSearch}>
              <Input
                name="search"
                icon={FiSearch}
                placeholder="Filtrar..."
                onChange={e => setSearchString(e.target.value)}
                onKeyUp={handleSearch}
                upperCase={false}
              />
            </Form>
          </CardHeader>

          <CardContent>
            {telemedicines.length >= 1 &&
              searchTelemedicines.map(telemedicine => (
                <Telemedicine
                  key={telemedicine.id}
                  onClick={() =>
                    handleNavigationToTelemedicineDetails(telemedicine.id)
                  }
                >
                  <FiHeart size={60} fill="#fff" color="#fff" />

                  <TelemedicineInfo>
                    <h1>Solicitação do telemedicina</h1>

                    <div>
                      <span>
                        Recebida no dia:
                        <strong>
                          {format(
                            new Date(telemedicine.created_at),
                            'dd/MM/yyyy',
                          )}
                        </strong>
                      </span>

                      <span>
                        Feita por:
                        <strong>{telemedicine.user.name}</strong>
                      </span>

                      <span>
                        Documento:
                        <strong>{telemedicine.user.document}</strong>
                      </span>
                    </div>
                  </TelemedicineInfo>
                </Telemedicine>
              ))}
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { FranchiseesAllTelemedicines };
