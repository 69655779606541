import * as Yup from 'yup';

interface IData {
  [key: string]: string;
}

export const bbankConnectValidation = async (data: IData): Promise<void> => {
  const validationShape = Yup.object().shape({
    'bbankConnect-birthday': Yup.string().required(),
    'bbankConnect-complement': Yup.string().required(),
    'bbankConnect-generalRegistry': Yup.string().required(),
    'bbankConnect-maritalStatus': Yup.string().required(),
    'bbankConnect-motherName': Yup.string().required(),
    'bbankConnect-nationalHealthCard': Yup.string().required(),
    'bbankConnect-neighborhood': Yup.string().required(),
    'bbankConnect-sex': Yup.string().required(),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
