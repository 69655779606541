import { FC, useEffect, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiEye } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Badge } from '@components/elements/Badge';
import { Aside } from '@components/layouts/Aside';
import { CardMenuContainer } from '@components/layouts/CardMenuContainer';
import { Footer } from '@components/layouts/Footer';
import { Header } from '@components/layouts/Header';
import { Main } from '@components/layouts/Main';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { Card, URLPath, CardContent, CardHeader } from './styles';
import { IFinancing } from './interfaces';

export const ListPropertyFinancingByUserId: FC = () => {
  const { user } = useAuth();
  const { push } = useHistory();

  const [financing, setFinancing] = useState<IFinancing[]>();

  useEffect(() => {
    async function getFinancing() {
      const { data } = await api.get(`/financing/property/users`, {
        params: {
          relations: ['indication'],
        },
      });

      setFinancing(data);
    }

    const timer = setTimeout(() => getFinancing(), 1500);

    return () => clearInterval(timer);
  }, [user.id]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Franqueado responsável',
        selector: 'search',
      },
      {
        name: 'Registro geral (RG)',
        selector: 'generalRegistry',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handlePushToDetailsPage = useCallback(
    (financingId: string) => {
      push(`/client/cred-cash/financing/property/${financingId}/details`);
    },
    [push],
  );

  const parsedStatusText = useMemo(() => {
    return {
      analise: 'Em análise',
      negado: 'Negado',
      aprovado: 'Aprovado',
      pendente: 'Pendente',
      pre_aprovado: 'Pré aprovado',
    };
  }, []);

  const tableData = useMemo(() => {
    if (!financing) {
      return [];
    }

    const data = financing.map(item => {
      let badgeType: 'success' | 'danger' | 'warning' | undefined;

      switch (item.status) {
        case 'aprovado':
          badgeType = 'success';
          break;

        case 'negado':
          badgeType = 'danger';
          break;

        default:
          badgeType = 'warning';
          break;
      }

      return {
        id: item.id,
        search: item.indication.name,
        generalRegistry: item.general_registry,
        status: <Badge type={badgeType}>{parsedStatusText[item.status]}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiEye}
              styleType="success"
              onClick={() => handlePushToDetailsPage(item.id)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [financing, handlePushToDetailsPage, parsedStatusText]);

  return (
    <>
      <Header />

      <Aside />

      <Main>
        <CardMenuContainer />

        {!financing ? (
          <LoadingPage />
        ) : (
          <>
            <Row>
              <URLPath>
                <li>Cred Cash</li>
                <li>{'>'}</li>
                <li>Financiamentos</li>
                <li>{'>'}</li>
                <li>Imóveis</li>
                <li>{'>'}</li>
                <li>Financiamentos feitos por/para mim</li>
              </URLPath>
            </Row>

            <Row>
              <Card>
                <CardHeader>
                  <h1>Lista de investimentos</h1>
                </CardHeader>

                <CardContent>
                  <DataTable
                    columns={tableColumns}
                    data={tableData}
                    filterPlaceholder="Filtrar pelo nome do cliente"
                  />
                </CardContent>
              </Card>
            </Row>
          </>
        )}
      </Main>

      <Footer />
    </>
  );
};
