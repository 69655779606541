import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    departmentId: Yup.string()
      .uuid('Inválido!')
      .required('Preencha este campo!'),
    platformId: Yup.string().uuid('Inválido!').required('Preencha este campo!'),
    title: Yup.string().required('Preencha este campo!'),
    content: Yup.string().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
