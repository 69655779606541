import { IFranchise, IClient } from './interfaces';

interface IOption {
  label: string;
  value: string;
}

export const maritalStatusOptions = [
  { label: 'Solteiro', value: 'solteiro' },
  { label: 'Casado', value: 'casado' },
];

export const getFranchiseesOptions = (data: IFranchise[]): IOption[] => {
  const options = data.map(item => ({ value: item.id, label: item.name }));

  return options;
};

export const getFranchiseClientsOptions = (data: IClient[]): IOption[] => {
  const options = data.map(item => ({ value: item.id, label: item.name }));

  return options;
};

export const paymentFormOptions: IOption[] = [
  { value: 'credit_card', label: 'Cartão de crédito' },
  { value: 'billet', label: 'Boleto' },
  { value: 'debit', label: 'Débito em conta' },
];

const getPortions = (): IOption[] => {
  const arr = Array.from(Array(12).keys());

  return arr.map(item => ({
    value: String(item + 1),
    label: String(item + 1),
  }));
};

export const portionsOptions = getPortions();
