import styled, { css } from 'styled-components';
import { animated } from 'react-spring';

interface IBackgroundProps {
  isShown: boolean;
}

export const Container = styled(animated.aside)`
  padding: 15px 0;

  box-shadow: 0 1px 9px -3px rgb(0 0 0 / 75%);

  display: flex;
  flex-direction: column;

  width: 275px;
  height: 100vh;

  overflow: auto;

  position: fixed;
  top: 0;
  left: -280px;
  z-index: 17;

  background-color: white;
`;

export const AsideHeader = styled.div`
  display: flex;
  align-items: center;

  padding: 10px 25px;

  background-color: #f3f6f9;

  > span {
    display: flex;
    align-items: center;

    height: 100%;
    width: 100%;

    color: #3f4254;
    font-size: 1rem;

    a {
      color: inherit;
      text-decoration: inherit;

      height: 100%;
      width: 100%;
    }
  }

  > svg {
    color: #c8c6c0;
    margin-right: 1.15rem;
  }
`;

export const Background = styled.div<IBackgroundProps>`
  visibility: hidden;
  opacity: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.1);

  position: fixed;
  top: 0;

  z-index: 16;

  transition: visibility 0.1s, opacity 0.1s;

  ${props =>
    props.isShown &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`;
