import { useState, FC, useEffect, useMemo, useCallback } from 'react';
import { format } from 'date-fns';
import { FiMessageCircle } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { Badge } from '@components/elements/Badge';
import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import api from '@services/bbankApi';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { ILoadedTicket, ITicket } from './interfaces';

const ListTickets: FC = () => {
  const { push } = useHistory();

  const [tickets, setTickets] = useState<ITicket[]>();

  useEffect(() => {
    async function loadTickets() {
      const { data } = await api.get<ILoadedTicket[]>('/tickets');

      const parsedData = data.map(ticket => {
        return {
          id: ticket.id,
          title: ticket.title,
          status: ticket.status,
          createdAt: ticket.created_at,
        };
      });

      setTickets(parsedData);
    }

    const timer = setTimeout(() => loadTickets(), 1500);

    return () => clearInterval(timer);
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Assunto',
        selector: 'search',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ticket enviado em',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleNavigateToEditTicketsPage = useCallback(
    (ticketId: string) => {
      push(`/tickets/${ticketId}/answer`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!tickets) {
      return [];
    }

    const data = tickets.map(ticket => {
      let badgeType: 'warning' | 'success' | 'info' = 'warning';
      let badgeText = 'Em aberto';

      if (ticket.status === 'em_andamento') {
        badgeType = 'info';
        badgeText = 'Em andamento';
      }

      if (ticket.status === 'finalizado') {
        badgeType = 'success';
        badgeText = 'Finalizado';
      }

      return {
        id: ticket.id,
        search: ticket.title,
        status: <Badge type={badgeType}>{badgeText}</Badge>,
        createdAt: format(new Date(ticket.createdAt), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              onClick={() => handleNavigateToEditTicketsPage(ticket.id)}
              title="Responder"
              icon={FiMessageCircle}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [tickets, handleNavigateToEditTicketsPage]);

  return (
    <>
      {!tickets ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Tickets</li>
              <li>{'>'}</li>
              <li>Tickets solicitados</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Lista de tickets</h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListTickets };
