import { FC, useEffect, useState, useCallback } from 'react';
import { Form } from '@unform/web';
import { format } from 'date-fns';

import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { URLPath } from '@components/layouts/UrlPath';

import { credHubApi } from '@services/credHubApi';
import api from '@services/bbankApi';

import { credHubConfig } from '@utils/credHubConfig';

import { Card, CardContent, CardHeader } from './styles';
import { IScore, IUserDataFromBoaVista } from './interfaces';

const CheckBoaVistaScore: FC = () => {
  const [score, setScore] = useState<IScore>();
  const [
    userDataFromBoaVista,
    setUserDataFromBoaVista,
  ] = useState<IUserDataFromBoaVista>();

  useEffect(() => {
    async function getBoaVistaInformations() {
      const { data: userInfo } = await api.get('/users/me');

      const { data } = await credHubApi.get('/', {
        params: {
          q: "SELECT FROM 'SPCNet'.'ScoreBoaVista'",
          documento: userInfo.document,
          apiKey: credHubConfig.apiKey,
        },
      });

      setScore(data.score[0]);
      setUserDataFromBoaVista(data.cadastro[0]);
    }

    const timer = setTimeout(() => getBoaVistaInformations(), 1500);

    return () => clearInterval(timer);
  }, []);

  const getScoreStatus = useCallback((number: string | number):
    | 'danger'
    | 'success'
    | 'warning' => {
    const scoreNumber = typeof number === 'string' ? Number(number) : number;

    if (scoreNumber >= 0 && scoreNumber <= 333) {
      return 'danger';
    }

    if (scoreNumber >= 334 && scoreNumber <= 666) {
      return 'warning';
    }

    return 'success';
  }, []);

  const parseToDate = useCallback((dateString: string): Date => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    const date = new Date(Number(year), Number(month) - 1, Number(day));

    return date;
  }, []);

  parseToDate('20030710');

  return (
    <>
      {!score || !userDataFromBoaVista ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['E-commerce', 'Consultas', 'Score Boa Vista']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Seus resultados</h1>

                <Badge type={getScoreStatus(score.score)}>
                  Total:&nbsp;
                  {score.score}
                </Badge>
              </CardHeader>

              <CardContent scoreStatus={getScoreStatus(score.score)}>
                <h1>{score.status}</h1>

                <p>{score.texto}</p>

                <p>
                  Provável:&nbsp;
                  {score.provavel}
                </p>
              </CardContent>
            </Card>
          </Row>

          <Row>
            <Card>
              <CardContent scoreStatus={getScoreStatus(score.score)}>
                <Form
                  onSubmit={() => {
                    // CODE
                  }}
                  initialData={userDataFromBoaVista}
                >
                  <FormRow separator>
                    <h1>Seus dados do Boa Vista</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Seu nome:</label>
                      <Input name="nome" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Seu CPF:</label>
                      <Input name="cpf" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome de sua mãe:</label>
                      <Input name="nome_mae" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Sua data de nacimento:</label>
                      <Input
                        name="data_nascimento"
                        readOnly
                        defaultValue={format(
                          parseToDate(userDataFromBoaVista.data_nascimento),
                          'dd/MM/yyyy',
                        )}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Título de eleitor:</label>
                      <Input name="titulo_eleitor" readOnly />
                    </InputGroup>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { CheckBoaVistaScore };
