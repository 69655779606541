import styled, { css } from 'styled-components';

interface IContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isDisabled?: boolean;
  hasErrors: boolean;
}

export const Container = styled.textarea<IContainerProps>`
  flex: 1;

  border: 2px solid transparent;
  border-radius: 10px;

  background-color: #f3f6f9;

  padding: 10px;

  font-size: 1.15rem;
  font-family: 'Nunito', sans-serif;

  max-width: 100%;

  transition: border-color 0.2s, background-color 0.2s;

  & ~ div {
    background-color: #f3f6f9;
  }

  ${props =>
    props.isDisabled &&
    css`
      background-color: #f5f5f5;
    `}

  &[readonly] {
    background-color: #e6e9ec;
    &::placeholder {
      color: #a3a3a3;
    }

    &:focus {
      background-color: #e6e9ec;
    }
  }

  ${props =>
    props.hasErrors &&
    css`
      border-color: #c53030;
    `}

  ${props =>
    props.isFocused &&
    css`
      background-color: #fff;

      border-color: #c8c6c0;

      > svg {
        stroke: #c8c6c0;
      }

      & ~ div {
        background-color: #fff;
      }
    `}

  ${props =>
    props.isFilled &&
    css`
      > svg {
        stroke: #c8c6c0;
      }
    `};
`;
