import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const validationShape = Yup.object().shape({
    socialReason: Yup.string().required('Preencha este campo!'),
    status: Yup.string().required('Preencha este campo!'),
    franchiseeId: Yup.string().required('Preencha este campo!'),
    nameFantasy: Yup.string().required('Preencha este campo!'),
    cnpj: Yup.string().required('Preencha este campo!'),
    openingDate: Yup.string().required('Preencha este campo!'),
    companyType: Yup.string().required('Preencha este campo!'),
    companyActivity: Yup.string().required('Preencha este campo!'),
    cnae: Yup.string().required('Preencha este campo!'),
    stateRegistry: Yup.string().required('Preencha este campo!'),
    cardMonthlyInvoicing: Yup.string().required('Preencha este campo!'),
    companyEmail: Yup.string().required('Preencha este campo!'),
    openingHoursMondayUntilFriday: Yup.string().required(
      'Preencha este campo!',
    ),
    openingHoursSaturday: Yup.string(),
    openingHoursSunday: Yup.string(),

    postalCode: Yup.string().required('Preencha este campo!'),
    address: Yup.string().required('Preencha este campo!'),
    addressNeighborhood: Yup.string().required('Preencha este campo!'),
    addressCity: Yup.string().required('Preencha este campo!'),
    addressState: Yup.string().required('Preencha este campo!'),
    companyPhone: Yup.string().required('Preencha este campo!'),
    addressComplement: Yup.string(),
    addressNumber: Yup.string(),
    alternativePhone: Yup.string(),
    ramal: Yup.string(),
    companyCellphone: Yup.string().required('Preencha este campo!'),
    membershipResponsible: Yup.string().required('Preencha este campo!'),
    membershipResponsibleCellphone: Yup.string().required(
      'Preencha este campo!',
    ),
    membershipResponsibleDocument: Yup.string().required(
      'Preencha este campo!',
    ),
    membershipResponsibleRole: Yup.string().required('Preencha este campo!'),
    departmentContactName: Yup.string().required('Preencha este campo!'),
    departmentContactEmail: Yup.string().required('Preencha este campo!'),
    departmentContactPhone: Yup.string().required('Preencha este campo!'),
    clientId: Yup.string().required('Preencha este campo!'),
    bankInfo: Yup.array()
      .required('Preencha este campo!')
      .min(1, 'Insira ao menos uma informação')
      .of(
        Yup.object({
          bank: Yup.string().required('Preencha este campo!'),
          bankCode: Yup.string().required('Preencha este campo!'),
          account: Yup.string().required('Preencha este campo!'),
          agency: Yup.string().required('Preencha este campo!'),
          paymentInTopBankAccount: Yup.string()
            .required('Preencha este campo!')
            .oneOf(['true', 'false'], 'Opção invalida!'),
        }),
      ),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
