import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import {
  FiDownload,
  FiEye,
  FiMoreHorizontal,
  FiPlusCircle,
} from 'react-icons/fi';
import exportFromJSON from 'export-from-json';
import { format } from 'date-fns';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { Badge } from '@components/elements/Badge';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Dropdown } from '@components/elements/Dropdown';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { rolesTranslated } from '@utils/rolesTranslated';

import { maskHandler } from '@helpers/maskHandler';

import { Card, CardHeader, CardContent } from './styles';

interface IState {
  id: string;
  name: string;
  email: string;
  person_type: string;
  document: string;
  status: 'pendente' | 'ativo' | 'inativo';
  role: 'client' | 'seller' | 'business' | 'admin' | 'master';
  phone: string;
  cellphone: string;
  created_at: string;
  birthdate: string;
}

interface IDataTableData {
  id: string;
  search: string;
  email: string;
  person_type: string;
  document: string;
  role: JSX.Element;
  actions: JSX.Element;
}

const AllUsers: FC = () => {
  const { push } = useHistory();

  const [users, setUsers] = useState<Array<IState>>([]);
  const [loadingPage, setLoadingPage] = useState(true);

  const dataTableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'Nome',
        selector: 'search',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Tipo de cadastro',
        selector: 'person_type',
      },
      {
        name: 'Documentos',
        selector: 'document',
      },
      {
        name: 'Papel',
        selector: 'role',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handlePushToUserView = useCallback(
    (id: string) => {
      push(`/users/view/${id}`);
    },
    [push],
  );

  const statusBadgeColor = useMemo<{
    [key: string]: 'success' | 'warning' | 'danger';
  }>(() => {
    return {
      ativo: 'success',
      inativo: 'warning',
      pendente: 'danger',
    };
  }, []);

  const dataTableData = useMemo<Array<IDataTableData>>(() => {
    return users.map(item => {
      let badgeType: 'success' | 'danger' | 'info' | 'warning';

      switch (item.role) {
        case 'admin':
          badgeType = 'info';
          break;

        case 'business':
          badgeType = 'warning';
          break;

        case 'master':
          badgeType = 'warning';
          break;

        default:
          badgeType = 'success';
          break;
      }

      return {
        id: item.id,
        search: item.name,
        document: item.document,
        email: item.email,
        person_type: item.person_type,
        role: <Badge type={badgeType}>{rolesTranslated[item.role]}</Badge>,
        status: (
          <Badge type={statusBadgeColor[item.status]}>{item.status}</Badge>
        ),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              onClick={() => handlePushToUserView(item.id)}
              icon={FiEye}
            />
          </TableButtonContainer>
        ),
      };
    });
  }, [users, handlePushToUserView, statusBadgeColor]);

  useEffect(() => {
    async function loadUsers() {
      const { data } = await api.get<any>('/users');

      setUsers(data);
      setLoadingPage(false);
    }

    const timer = setTimeout(() => {
      loadUsers();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleExportData = useCallback(() => {
    if (!users) {
      return;
    }

    const usersLength = users.length;

    const worksheetData = Array.from(Array(usersLength).keys()).map(item => {
      const user = users[item];
      let parsedDocument = user.document;

      if (user.document.length === 11) {
        parsedDocument = maskHandler({ mask: 'cpf', payload: user.document });
      } else {
        parsedDocument = maskHandler({ mask: 'cnpj', payload: user.document });
      }

      const worksheetLines = {
        Nome: user.name,
        CPF: parsedDocument,
        'Tipo de documentaçao': user.person_type,
        Email: user.email,
        Telefone: maskHandler({ mask: 'phone', payload: user.phone }),
        Celular: maskHandler({ mask: 'cellphone', payload: user.cellphone }),
        'Data de aniversário': format(new Date(user.birthdate), 'dd/MM/yyyy'),
        'Data de ingresso': format(new Date(user.created_at), 'dd/MM/yyyy'),
      };

      return worksheetLines;
    });

    exportFromJSON({
      data: worksheetData,
      exportType: 'xls',
      fileName: 'dados_usuarios',
    });
  }, [users]);

  return (
    <>
      {loadingPage ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Usuários', 'Novo']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Listagem</h1>

                <Dropdown
                  styles={{ textColor: '#fff', togglerBackground: '#c8c6c0' }}
                  icon={FiMoreHorizontal}
                  isDisabled={users.length === 0}
                >
                  <li>
                    <button type="button" onClick={() => push('/users/new')}>
                      <FiPlusCircle />
                      Adicionar novo usuário
                    </button>
                  </li>
                  <li>
                    <button type="button" onClick={handleExportData}>
                      <FiDownload />
                      Exportar dados para Excel
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={dataTableColumns}
                  data={dataTableData}
                  filterPlaceholder="Filtrar por nome"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { AllUsers };
