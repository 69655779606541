import {} from 'use-context-selector';
import { useContext } from 'react';

import { AuthContext } from '../contexts/auth';

export interface IUser {
  id: string;
  email: string;
  name: string;
  token: string;
  document: string;
  role?: string;
}

interface IAuthContext {
  signIn(
    document: string,
    password: string,
  ): Promise<{ ok: boolean; type?: 'pendente' | 'inativo' }>;
  signOut(): void;
  handleCompleteData(): void;
  loading: boolean;
  user: IUser;
  completedData: boolean;
}

export function useAuth(): IAuthContext {
  const { loading, signIn, signOut, user, completedData, handleCompleteData } =
    useContext(AuthContext);

  return {
    loading,
    signIn,
    signOut,
    user,
    completedData,
    handleCompleteData,
  };
}
