import { IOption } from '@utils/interfaces';

import { banks } from '@helpers/getBanks';

export const banksOptions = banks.map(bank => {
  const bankName = bank.split(' - ')[1];

  return {
    value: bank,
    label: bankName,
  };
});

export const statusOptions: IOption[] = [
  { value: 'pending', label: 'Pendente' },
  { value: 'approved', label: 'Aprovado' },
  { value: 'pre_approved', label: 'Pré aprovado' },
  { value: 'in_analysis', label: 'Em análise' },
  { value: 'denied', label: 'Negado' },
  { value: 'contract_pending', label: 'Contrato pendente' },
];
