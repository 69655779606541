import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import {
  IUserDepartment,
  ILoadedUserDepartment,
  IPlatformDepartment,
} from './interfaces';
import { Card, CardHeader, CardContent, URLPath } from './styles';

const ListDepartmentsForTickets: FC = () => {
  const { user } = useAuth();
  const { push } = useHistory();

  const [userDepartments, setUserDepartments] = useState<IUserDepartment[]>();
  const [platformDepartments, setPlatformDepartments] = useState<
    IPlatformDepartment[]
  >();

  useEffect(() => {
    async function loadUserDepartments() {
      const { data } = await api.get<ILoadedUserDepartment[]>(
        `/users-departments/user/${user.id}`,
      );

      const parsedData = data.map(item => ({
        userId: item.user_id,
        departmentId: item.department_id,
        department: item.department,
      }));

      setUserDepartments(parsedData);
    }

    async function loadPlatformDepartments() {
      const { data } = await api.get(`/platform-departments`, {
        params: {
          relations: ['platform', 'department'],
        },
      });

      setPlatformDepartments(data);
    }

    const timer = setTimeout(() => loadUserDepartments(), 1500);
    const timer2 = setTimeout(() => loadPlatformDepartments(), 2000);

    return () => {
      clearInterval(timer);
      clearInterval(timer2);
    };
  }, [user.id]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do departamento',
        selector: 'search',
      },
      {
        name: 'Ver tickets relacionados com o departamento',
        selector: 'actions',
      },
    ];
  }, []);

  const handlePageNavigation = useCallback(
    (departmentId: string, platformId: string) => {
      push(`/tickets/departments/${departmentId}/list?id=${platformId}`);
    },
    [push],
  );

  const tableData = useMemo(() => {
    if (!userDepartments || !platformDepartments) {
      return [];
    }

    const filteredDepartments = platformDepartments.filter(item => {
      const findDepartment = userDepartments.some(
        i => i.departmentId === item.department.id,
      );

      return findDepartment;
    });

    const data = filteredDepartments.map(({ department, platform }) => {
      return {
        search: `${department.name} (${platform.name})`,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              onClick={() => handlePageNavigation(department.id, platform.id)}
              icon={FiEye}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [userDepartments, handlePageNavigation, platformDepartments]);

  return (
    <>
      {!userDepartments || !platformDepartments ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Tickets</li>
              <li>{'>'}</li>
              <li>Seus departamentos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Departamentos com a sua participação</h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListDepartmentsForTickets };
