import { FC, useCallback, useRef, useState } from 'react';
import { FiSave } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Textarea } from '@components/elements/Form/Textarea';
import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { formValidation } from './validations';
import { IFormData } from './interfaces';

const NewMarketingCategory: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        setButtonLoading(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        await api.post('/marketing/categories', data);

        addToast({
          type: 'success',
          title: 'Muito bom!',
          message: 'A categoria foi registrada com sucesso!',
        });

        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { status, message } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }
        }
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      <Row>
        <URLPath>
          <li>Marketing</li>
          <li>{'>'}</li>
          <li>Categorias</li>
          <li>{'>'}</li>
          <li>Novo</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Nova categoria para marketing</h1>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow>
                <InputGroup>
                  <label>Nome da categoria</label>
                  <Input name="name" upperCase={false} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup textarea>
                  <label>Uma breve descrição para a categoria (opcional)</label>
                  <Textarea name="description" rows={8} />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button styleType="info" icon={FiSave} loading={buttonLoading}>
                  Salvar nova categoria
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewMarketingCategory };
