import { FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FiDownload, FiTrash } from 'react-icons/fi';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Badge } from '@components/elements/Badge';

import api from '@services/bbankApi';

import { IFile, IParams } from './interfaces';
import { Card, CardHeader, CardContent, URLPath } from './styles';

export const ListFilesFromFinancing: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [files, setFiles] = useState<IFile[]>();
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    async function getFiles() {
      const { data } = await api.get(
        `/financing/property/files/financing/${params.financingId}`,
      );

      setFiles(data);
    }

    const timer = setTimeout(() => getFiles(), 1500);

    return () => clearInterval(timer);
  }, [params.financingId]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Arquivo',
        selector: 'search',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handleDownloadFile = useCallback((fileUrl: string) => {
    window.open(fileUrl, '_blank');
  }, []);

  const handleDeleteFiles = useCallback(async (fileId: string) => {
    setDeleting(true);

    await api.delete(`/financing/property/files/${fileId}`);

    setDeleting(false);
    setFiles(oldState => {
      if (!oldState) {
        return [];
      }

      const updatedState = oldState.filter(file => file.id !== fileId);

      return updatedState;
    });
  }, []);

  const tableData = useMemo(() => {
    if (!files) {
      return [];
    }

    const data = files.map((file, index) => ({
      id: file.id,
      search: `Arquivo ${index + 1}`,
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="success"
            title="Clique uma vez para baixar!"
            icon={FiDownload}
            onClick={() => handleDownloadFile(file.url)}
          />

          <TableButton
            styleType="danger"
            title="Clique duas vezes para deletar este arquivo!"
            icon={FiTrash}
            loading={deleting}
            onDoubleClick={() => handleDeleteFiles(file.id)}
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [files, deleting, handleDownloadFile, handleDeleteFiles]);

  return (
    <>
      {!files ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Cred Cash</li>
              <li>{'>'}</li>
              <li>Financiamentos</li>
              <li>{'>'}</li>
              <li>Imóveis</li>
              <li>{'>'}</li>
              <li>Arquivos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Arquivos feitos para o financiamento selecionado</h1>

                <Badge type={files.length === 0 ? 'danger' : 'success'}>
                  Arquivos encontrados:&nbsp;
                  {files.length}
                </Badge>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
