import styled from 'styled-components';

export const Container = styled.div``;

export const Content = styled.div`
  color: #1e1e1e;

  > div:nth-child(1) {
    margin-top: 50px;
    img {
      width: 100%;
      height: auto;

      border-radius: 10px;
    }
  }

  h1 {
    text-align: left;
    margin-top: 50px;
    font-size: 30px;
  }

  h2 {
    font-size: 22px;
    text-align: left;
    margin-top: 50px;

    font-weight: 600;

    color: #3f3f3f;

    opacity: 0.75;
  }

  > div:nth-child(4) {
    margin-top: 25px;

    font-size: 16px;

    display: flex;
    align-items: center;
    gap: 10px;

    color: #ccc;
  }
`;

export const NewsContent = styled.div`
  margin-top: 50px;

  p {
    font-size: 17px;
  }
`;
