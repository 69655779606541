import { v4 } from 'uuid';

import { PageInDevelopment } from '@pages/dev/PageInConstruction';
import { CreateUsersByIndication } from '@pages/public/CreateUserByIndication';
import { Login } from '@pages/public/Login';
import { ForgotPassword } from '@pages/public/ForgotPassword';
import { RecoverPassword } from '@pages/public/RecoverUserPassword';

import { Route } from './Route';

const publicRoutes = [
  <Route
    key={v4()}
    path="/indication/:id"
    freePass
    component={CreateUsersByIndication}
  />,
  <Route key={v4()} path="/" exact freePass component={Login} />,
  <Route key={v4()} path="/login" freePass component={Login} />,
  <Route
    key={v4()}
    path="/forgot-password"
    freePass
    component={ForgotPassword}
  />,
  <Route
    key={v4()}
    path="/recover-password/:token"
    freePass
    component={RecoverPassword}
  />,
  <Route
    key={v4()}
    freePass
    path="/page-in-development"
    component={PageInDevelopment}
  />,
];

export { publicRoutes };
