import { FC, useMemo } from 'react';
import {
  format,
  differenceInHours,
  differenceInMinutes,
  differenceInDays,
  differenceInMonths,
} from 'date-fns';

import { Container } from './styles';
import { INotification } from './interfaces';

export const Notification: FC<INotification> = ({
  content,
  period,
  title,
  read,
  onClick,
}) => {
  const formattedDate = useMemo(() => {
    const notificationPeriod = new Date(period);

    const hourDiffToNow = differenceInHours(new Date(), notificationPeriod);

    if (hourDiffToNow <= 0) {
      const diffInMinutesToNow = differenceInMinutes(
        new Date(),
        notificationPeriod,
      );

      return `há ${diffInMinutesToNow} minutos atrás`;
    }

    if (hourDiffToNow <= 24) {
      return `há ${hourDiffToNow} horas`;
    }

    const diffInDaysToNow = differenceInDays(new Date(), notificationPeriod);

    if (hourDiffToNow > 24 && diffInDaysToNow <= 30) {
      return `há ${diffInDaysToNow} ${
        diffInDaysToNow === 1 ? 'dia' : 'dias'
      } atrás`;
    }

    const diffInMonthsToNow = differenceInMonths(
      new Date(),
      notificationPeriod,
    );

    if (diffInMonthsToNow <= 12) {
      return `há ${diffInMonthsToNow} meses atrás`;
    }

    const defaultDate = format(notificationPeriod, 'dd/MM/yyyy');

    return `em ${defaultDate}`;
  }, [period]);

  return (
    <Container read={read || false} onClick={onClick}>
      <header>
        <strong>{title}</strong>
      </header>

      <main>
        <p>{content}</p>
      </main>

      <footer>
        <span>{formattedDate}</span>
      </footer>
    </Container>
  );
};
