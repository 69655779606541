import styled, { css } from 'styled-components';

interface IContainerProps {
  type: 'warning' | 'success' | 'error' | 'info';
}

const tooltipTypes = {
  success: css`
    color: #10ada6;
    background-color: #c9f7f5;
  `,

  error: css`
    color: #f64e60;
    background-color: #ffe2e5;
  `,

  info: css`
    color: #8950fc;
    background-color: #eee5ff;
  `,

  warning: css`
    color: #ffa800;
    background-color: #fff4de;
  `,

  colors: ({ type }: IContainerProps) => {
    switch (type) {
      case 'warning':
        return {
          backgroundColor: '#fff4de',
          color: '#ffa800',
        };

      case 'success':
        return {
          backgroundColor: '#c9f7f5',
          color: '#10ada6',
        };

      case 'error':
        return {
          backgroundColor: '#ffe2e5',
          color: '#f64e60',
        };

      default:
        return {
          backgroundColor: '#eee5ff',
          color: '#8950fc',
        };
    }
  },
};

export const Container = styled.div<IContainerProps>`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: help;

  &:hover {
    span {
      opacity: 1;
      visibility: visible;
    }
  }

  > svg {
    height: 20px;
    width: 20px;
    color: ${props => tooltipTypes.colors({ type: props.type }).color};
  }

  > span {
    display: block;

    text-align: center;

    width: 185px;

    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);

    padding: 8px 10px;

    font-size: 1.15rem;
    font-weight: 600;

    border-radius: 10px;

    ${props => tooltipTypes[props.type]}

    margin-bottom: 7px;

    opacity: 0;
    visibility: hidden;

    transition: opacity 0.5s, visibility 0.5s;

    &::before {
      content: '';

      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);

      border-width: 7px;
      border-style: solid;
      border-color: ${props => {
          const { backgroundColor } = tooltipTypes.colors({ type: props.type });

          return backgroundColor;
        }}
        transparent transparent transparent;
    }
  }
`;
