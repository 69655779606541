import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const creditFormValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    user_id: Yup.string().uuid('Inválido').required('Preencha este campo!'),
    indication: Yup.string().uuid('Inválido').required('Preencha este campo!'),
    cnpj: Yup.string().required('Preencha este campo!'),
    name_fantasy: Yup.string().required('Preencha este campo!'),
    social_reason: Yup.string().required('Preencha este campo!'),
    email: Yup.string().required('Preencha este campo!'),
    phone: Yup.string().required('Preencha este campo!'),
    average_of_revenues: Yup.string().required('Preencha este campo!'),
    zipcode: Yup.string().required('Preencha este campo!'),
    state: Yup.string().required('Preencha este campo!'),
    city: Yup.string().required('Preencha este campo!'),
    address: Yup.string().required('Preencha este campo!'),
    neighborhood: Yup.string().required('Preencha este campo!'),
    complement: Yup.string().required('Preencha este campo!'),
    responsible_name: Yup.string().required('Preencha este campo!'),
    responsible_cpf: Yup.string().required('Preencha este campo!'),
    responsible_cellphone: Yup.string().required('Preencha este campo!'),
    amount: Yup.number()
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    modality: Yup.string()
      .required('Preencha este campo!')
      .oneOf(['com_garantia', 'sem_garantia'], 'Inválido!'),
    product: Yup.string()
      .required('Preencha este campo!')
      .oneOf(['vehicle', 'property', 'gyro', 'pic_machines'], 'Inválido!'),
    employees_number: Yup.number().when({
      is: (_: string) => data.modality === 'sem_garantia',
      then: Yup.number()
        .transform((va, ov) => (ov === '' ? undefined : va))
        .typeError('Insira um número válido!')
        .required('Preencha este campo!'),
      otherwise: Yup.number()
        .transform((va, ov) => (ov === '' ? undefined : va))
        .typeError('Insira um número válido!'),
    }),
    contract_percentage: Yup.number()
      .transform((va, ov) => (ov === '' ? undefined : va))
      .typeError('Insira um número!')
      .required('Preencha este campo!')
      .min(3, 'O mínimo é 3%')
      .max(7, 'O máximo é 7%'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};

export const vehicleGuaranteeValidation = async (
  data: IFormData,
): Promise<void> => {
  const shape = Yup.object().shape({
    cpf: Yup.string().required('Preencha este campo!'),
    birthday: Yup.string().required('Preencha este campo!'),
    cellphone: Yup.string().required('Preencha este campo!'),
    phone: Yup.string().required('Preencha este campo!'),
    commercialPhone: Yup.string().required('Preencha este campo!'),
    income: Yup.number()
      .typeError('Insira um número válido')
      .required('Preencha este campo!'),
    clientName: Yup.string().required('Preencha este campo!'),
    maritalStatus: Yup.string().required('Preencha este campo!'),
    motherName: Yup.string().required('Preencha este campo!'),
    nationality: Yup.string().required('Preencha este campo!'),
    profession: Yup.string().required('Preencha este campo!'),
    thirdParty: Yup.string().required('Preencha este campo!'),
    generalRegistry: Yup.string().required('Preencha este campo!'),
    company: Yup.string().required('Preencha este campo!'),
    employeeCompanyRole: Yup.string().required('Preencha este campo!'),
    admissionDate: Yup.string().required('Preencha este campo!'),
    bankAccountAge: Yup.number()
      .typeError('Insira um número válido')
      .required('Preencha este campo!'),
    vehicleBrand: Yup.string().required('Preencha este campo!'),
    vehicleModel: Yup.string().required('Preencha este campo!'),
    vehicleFabricationYear: Yup.number()
      .typeError('Insira um número válido')
      .required('Preencha este campo!'),
    vehicleVersion: Yup.string().required('Preencha este campo!'),
    vehicleValue: Yup.number()
      .typeError('Insira um número válido')
      .required('Preencha este campo!'),
    loanAmount: Yup.number()
      .typeError('Insira um número válido')
      .required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};

export const propertyGuaranteeValidation = async (
  data: IFormData,
): Promise<void> => {
  const shape = Yup.object().shape({
    account: Yup.string().when({
      is: (_: string) => data.ownProperty === '0',
      then: Yup.string().required('Preencha este campo!'),
      otherwise: Yup.string(),
    }),
    agency: Yup.string().when({
      is: (_: string) => data.ownProperty === '0',
      then: Yup.string().required('Preencha este campo!'),
      otherwise: Yup.string(),
    }),
    bank: Yup.string().when({
      is: (_: string) => data.ownProperty === '0',
      then: Yup.string().required('Preencha este campo!'),
      otherwise: Yup.string(),
    }),
    cpfCnpj: Yup.string().when({
      is: (_: string) => data.ownProperty === '0',
      then: Yup.string().required('Preencha este campo!'),
      otherwise: Yup.string(),
    }),
    city: Yup.string().required('Preencha este campo!'),
    condominium: Yup.string().required('Preencha este campo!'),
    financingPeriod: Yup.string().required('Preencha este campo!'),
    guaranteeValue: Yup.string().required('Preencha este campo!'),
    intendedCredit: Yup.number()
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    neighborhood: Yup.string().required('Preencha este campo!'),
    number: Yup.number()
      .typeError('Insira um número válido!')
      .required('Preencha este campo!'),
    ownProperty: Yup.string().required('Preencha este campo!'),
    propertyType: Yup.string().required('Preencha este campo!'),
    publicPlace: Yup.string().required('Preencha este campo!'),
    relationship: Yup.string().when({
      is: (_: string) => data.ownProperty === '0',
      then: Yup.string().required('Preencha este campo!'),
      otherwise: Yup.string(),
    }),
    state: Yup.string().required('Preencha este campo!'),
    thirdPartyCpf: Yup.string().when({
      is: (_: string) => data.ownProperty === '0',
      then: Yup.string().required('Preencha este campo!'),
      otherwise: Yup.string(),
    }),
    thirdPartyName: Yup.string().when({
      is: (_: string) => data.ownProperty === '0',
      then: Yup.string().required('Preencha este campo!'),
      otherwise: Yup.string(),
    }),
    tower: Yup.string().required('Preencha este campo!'),
    unity: Yup.string().required('Preencha este campo!'),
    vacancy: Yup.string().required('Preencha este campo!'),
    zipcode: Yup.string().required('Preencha este campo!'),
    generalRegistry: Yup.string().required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
