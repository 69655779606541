import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html, body {
    overflow-x: hidden;
  }

  html, body, input {
    font-family: 'Nunito', sans-serif;
    font-size: 14px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 700;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0 1000px #f3f6f9 inset;
  }


  button {
    font-size: 1rem;
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    cursor: pointer;

    background: transparent;
    border: none;

    height: auto;
    width: auto;

    border-radius: 10px;

    > a {
      display: flex;
      align-items: center;
      justify-content: center;

      text-decoration: inherit;
      color: inherit;

      height: 100%;

      &:hover {
        text-decoration: inherit;
        color: inherit;
      }
    }
  }

  .apexcharts-tooltip {
    height: 68px;

    box-shadow: 0 0 50px 0 rgb(82 63 105 / 15%);
  }

  .apexcharts-tooltip.light {
    border: none;
    background-color: rgba(255, 255, 255, 0.96);

    border-radius: 0.75rem;

    .apexcharts-tooltip-title {
      font-weight: 700;

      background-color: #f3f6f9;

      border: none;
    }
  }

  @media (max-width: 768px) {
    html, body, input {
      font-size: 13px;
    }
  }
`;
