import styled, { css } from 'styled-components';

interface ICheckboxContainer {
  checked: boolean;
}

export const CheckboxContainer = styled.div<ICheckboxContainer>`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;

  position: relative;

  height: 30px;
  width: 30px;

  background-color: #ccc;

  border-radius: 50%;

  z-index: 999;

  cursor: pointer;

  > input {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    height: 30px;
    width: 30px;

    border-radius: 50%;
  }

  ${props =>
    props.checked &&
    css`
      background-color: #c8c6c0;
    `}
`;
