import { useEffect, FC, useState, useMemo, useCallback } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { Badge } from '@components/elements/Badge';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { URLPath } from '@components/layouts/UrlPath';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { Card, CardContent, CardHeader } from './styles';
import { ITelemedicineScore, IScoreBadgeType } from './interfaces';

const ListBbankConnectResearchs: FC = () => {
  const { push } = useHistory();
  const { user } = useAuth();

  const [telemedicineScores, setTelemedicineScores] = useState<
    ITelemedicineScore[]
  >();

  useEffect(() => {
    async function loadScores() {
      const { data } = await api.get(
        `/telemedicine/sellers/${user.id}/scores`,
        {
          params: {
            relations: ['prospect'],
          },
        },
      );

      setTelemedicineScores(data);
    }

    const timer = setTimeout(() => loadScores(), 1500);

    return () => clearInterval(timer);
  }, [user.id]);

  const handleNavigationToResearchDetails = useCallback(
    (telemedicineScoreId: string) => {
      push(`/sellers/bbank-connect/research/${telemedicineScoreId}/details`);
    },
    [push],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do prospecto',
        selector: 'search',
      },
      {
        name: 'Pontuação na pesquisa',
        selector: 'score',
      },
      {
        name: 'Classificação',
        selector: 'classification',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!telemedicineScores) {
      return [];
    }

    const data = telemedicineScores.map(score => {
      const scoreBadgeType: IScoreBadgeType = {
        warm: {
          badgeType: 'warning',
          badgeText: 'Morno',
        },
        hot: {
          badgeType: 'success',
          badgeText: 'Quente',
        },
        cold: {
          badgeType: 'danger',
          badgeText: 'Frio',
        },
      };

      return {
        id: score.id,
        search: score.prospect.name,
        score: <Badge type="info">{score.score}</Badge>,
        classification: (
          <Badge type={scoreBadgeType[score.classification].badgeType}>
            {scoreBadgeType[score.classification].badgeText}
          </Badge>
        ),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              title="Ver informações completas"
              onClick={() => handleNavigationToResearchDetails(score.id)}
              icon={FiEye}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [telemedicineScores, handleNavigationToResearchDetails]);

  return (
    <>
      {!telemedicineScores ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={['Bbank Connect', 'Pesquisas de Mercado', 'Todas']}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Listagem das pesquisas de marcado</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar pelo nome do vendedor"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListBbankConnectResearchs };
