import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiArchive } from 'react-icons/fi';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { Card, CardHeader, CardContent } from './styles';
import { ICategory, ILoadedCategory } from './interfaces';

const ListCategoriesForFiles: FC = () => {
  const { push } = useHistory();

  const [categories, setCategories] = useState<ICategory[]>();

  useEffect(() => {
    async function loadCategories() {
      const { data: loadedCategories } = await api.get<ILoadedCategory[]>(
        '/marketing/categories',
        {
          params: {
            files: true,
          },
        },
      );

      const parsedCategories = loadedCategories.map(item => ({
        id: item.id,
        name: item.name,
        createdAt: item.created_at,
        marketingFiles: item.marketing_files,
      }));

      setCategories(parsedCategories);
    }

    const timer = setTimeout(() => loadCategories(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleNavigationToFilesPage = useCallback(
    (marketingCategoryId: string) => {
      push(`/sellers/marketing/categories/${marketingCategoryId}/files`);
    },
    [push],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome da categoria',
        selector: 'search',
      },
      {
        name: 'Data de criação',
        selector: 'createdAt',
      },
      {
        name: 'Quantidade de arquivos',
        selector: 'filesNumber',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!categories) {
      return [];
    }

    const data = categories.map(item => ({
      id: item.id,
      search: item.name,
      createdAt: format(new Date(item.createdAt), 'dd/MM/yyyy'),
      filesNumber: (
        <Badge type={item.marketingFiles.length === 0 ? 'danger' : 'success'}>
          {item.marketingFiles.length}
        </Badge>
      ),
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="info"
            onClick={() => handleNavigationToFilesPage(item.id)}
            title="Clique para ir até a página de arquivos!"
            icon={FiArchive}
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [categories, handleNavigationToFilesPage]);

  return (
    <>
      {!categories ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Marketing', 'Categorias', 'Todas']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Lista de todas as categorias cadastradas</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por nome da categoria"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListCategoriesForFiles };
