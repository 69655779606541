import styled from 'styled-components';

export const Container = styled.main`
  padding-top: 120px;

  z-index: 2;
`;

export const Content = styled.div`
  max-width: 1340px;

  margin: 0 auto;
  padding: 0 25px;

  @media screen and (max-width: 1365px) {
    max-width: 1140px;
  }
`;
