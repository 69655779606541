import { banks } from '@helpers/getBanks';

export const banksOptions = banks.map(bank => {
  const bankName = bank.split(' - ')[1];

  return {
    value: bank,
    label: bankName,
  };
});

export const accountType = [
  { value: 'Pessoa jurídica', label: 'Pessoa jurídica' },
  { value: 'Pessoa física', label: 'Pessoa física' },
];
