import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { format } from 'date-fns';
import * as Yup from 'yup';
import { FiSave } from 'react-icons/fi';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { Textarea } from '@components/elements/Form/Textarea';
import { Button } from '@components/elements/Button';
import { InputMask } from '@components/elements/Form/InputMask';
import { InputCurrency } from '@components/elements/Form/InputCurrency';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';

import { URLPath, Card, CardHeader, CardContent, InputGroup } from './styles';
import { ICredit, IRouteParams } from './interface';
import {
  modalityOptions,
  statusOptions,
  productOptions,
  personTypeOptions,
} from './selectOptions';

type IFormData = ICredit;

const UpdatePFCredit: FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { addToast } = useToast();
  const { params } = useRouteMatch<IRouteParams>();
  const { id } = params;

  const [guaranteeType, setGuaranteeType] = useState<string>('');
  const [guarantee, setGuarantee] = useState<any>();
  const [credit, setCredit] = useState<ICredit>();
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      setButtonLoading(true);

      try {
        formRef.current?.setErrors({});

        const shape = Yup.object().shape({
          status: Yup.string().required('Preencha este campo!'),
          commentaries: Yup.string(),
          product: Yup.string().required('Preencha este campo!'),
          type: Yup.string().required('Preencha este campo!'),
          modality: Yup.string().required('Preencha este campo!'),
          average_of_revenues: Yup.string().required('Preencha este campo!'),
          phone: Yup.string().required('Preencha este campo!'),
        });

        await shape.validate(data, {
          abortEarly: false,
        });

        const parsedData = {
          ...data,
          product: guaranteeType,
        };

        const { data: updatedCredit } = await api.put<{ credit: ICredit }>(
          `/credits/${id}`,
          parsedData,
        );

        setCredit(updatedCredit.credit);

        addToast({
          title: 'Crédito atualizado com sucesso!',
          type: 'success',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          title: 'Algum erro aconteceu!',
          type: 'error',
          message: 'Cheque seus dados e tente novamente',
        });
      } finally {
        setButtonLoading(false);
      }
    },
    [addToast, id, guaranteeType],
  );

  useEffect(() => {
    async function loadCredit() {
      try {
        const { data } = await api.get(`/credits/${id}`, {
          params: {
            relations: ['franchisee'],
          },
        });

        if (data.guarantees) {
          setGuarantee(data.guarantees[0]);
        }

        setCredit(data.credit);
        setGuaranteeType(data.credit.product);
      } catch (err) {
        // CODE
      }
    }

    setTimeout(() => {
      loadCredit();
    }, 2000);
  }, [id, addToast]);

  const defaultValuesForGuaranteForm = useMemo(() => {
    if (!guarantee) {
      return;
    }

    if (guaranteeType === 'property') {
      return {
        ...guarantee,
        own_property: guarantee.own_property ? 'Sim' : 'Não',
      };
    }

    return guarantee;
  }, [guarantee, guaranteeType]);

  return (
    <>
      {!credit ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Crédito</li>
              <li>&gt;</li>
              <li>Detalhes</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Editar crédito</h1>
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={credit}
                >
                  <FormRow separator>
                    <h1>Indicador</h1>
                  </FormRow>

                  <FormRow>
                    <Input name="franchisee.name" disabled />
                  </FormRow>

                  <FormRow separator>
                    <h1>Status</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Status</label>
                      <Select name="status" options={statusOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados do cliente</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Nome</label>
                      <Input
                        name="name"
                        readOnly
                        defaultValue={credit.owner.name}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>CPF</label>
                      <Input
                        name="document"
                        readOnly
                        defaultValue={credit.owner.document}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Email</label>
                      <Input
                        type="email"
                        name="email"
                        readOnly
                        defaultValue={credit.owner.email}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Telefone</label>
                      <InputMask
                        mask="(99) 9999-9999"
                        name="phone"
                        readOnly
                        defaultValue={credit.owner.phone}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Faturamento</label>
                      <Input
                        name="average_of_revenues"
                        readOnly
                        defaultValue={credit.average_of_revenues}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço do cliente</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CEP</label>
                      <Input
                        name="zipcode"
                        readOnly
                        defaultValue={credit.owner.zipcode}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Endereço</label>
                      <Input
                        name="address"
                        readOnly
                        defaultValue={credit.owner.address}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Cidade</label>
                      <Input
                        name="city"
                        readOnly
                        defaultValue={credit.owner.city}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Estado</label>
                      <Input
                        name="state"
                        readOnly
                        defaultValue={credit.owner.state}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Dados dos crédito</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Modalidade</label>
                      <Select
                        name="modality"
                        readonly
                        options={modalityOptions}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Tipo (PF/PJ)</label>
                      <Select
                        name="type"
                        readonly
                        options={personTypeOptions}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Produto</label>
                      <Select
                        name="product"
                        readonly
                        options={productOptions}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Valor solicitado</label>
                      <Input name="amount" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Acompanhamento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea={Number(true)}>
                      <label>Comentários do administrador</label>
                      <Textarea name="commentaries" rows={20} />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper>
                    <Button
                      styleType="success"
                      loading={buttonLoading}
                      icon={FiSave}
                    >
                      Enviar
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>

          {guarantee && (
            <Row>
              <Card>
                <CardHeader>
                  <h1>
                    {guaranteeType === 'property' && 'Garantia de imóvel'}
                    {guaranteeType === 'vehicle' && 'Garantia de veículo'}
                  </h1>
                </CardHeader>

                <CardContent>
                  <Form
                    onSubmit={() => {
                      // CODE
                    }}
                    initialData={defaultValuesForGuaranteForm}
                  >
                    {guaranteeType === 'property' && (
                      <>
                        <FormRow>
                          <InputGroup>
                            <label>Registro geral (RG)</label>
                            <Input
                              name="general_registry"
                              readOnly
                              maxLength={50}
                            />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="property_type">
                              Tipo do imóvel
                            </label>
                            <Input name="property_type" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="public_place">Logradouro</label>
                            <Input name="public_place" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="number">Número</label>
                            <Input name="number" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="condominium">Condomínio</label>
                            <Input name="condominium" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="unity">Unidade</label>
                            <Input name="unity" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="tower">Torre</label>
                            <Input name="tower" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="vacancy">Quantidade de vagas</label>
                            <Input type="number" name="vacancy" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="neighborhood">Bairro</label>
                            <Input name="neighborhood" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="city">Cidade</label>
                            <Input name="city" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="state">Estado</label>
                            <Input name="state" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="zipcode">CEP</label>
                            <InputMask
                              mask="99999-999"
                              name="zipcode"
                              readOnly
                            />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="guarantee_value">
                              Valor da garantia
                            </label>
                            <Input name="guarantee_value" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="intended_credit">
                              Crédito pretendido
                            </label>
                            <Input name="intended_credit" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="financing_period">
                              Período de financiamento
                            </label>
                            <Input name="financing_period" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="own_property">Imóvel próprio</label>
                            <Input name="own_property" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="relationship">
                              Relacionamento com o terceiro
                            </label>
                            <Input name="relationship" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="third_party_name">
                              Nome do terceiro
                            </label>
                            <Input name="third_party_name" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="bank">Banco</label>
                            <Input name="bank" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="third_party_cpf">
                              CPF do terceiro
                            </label>
                            <Input name="third_party_cpf" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="cpf_cnpj">CPF/CNPJ</label>
                            <Input name="cpf_cnpj" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="agency">Agência</label>
                            <Input name="agency" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="account">Conta</label>
                            <Input name="account" readOnly />
                          </InputGroup>
                        </FormRow>
                      </>
                    )}

                    {guaranteeType === 'vehicle' && (
                      <>
                        <FormRow>
                          <InputGroup>
                            <label htmlFor="birthday">Data de nascimento</label>
                            <Input
                              name="birthday"
                              defaultValue={format(
                                new Date(defaultValuesForGuaranteForm.birthday),
                                'dd/MM/yyy',
                              )}
                              readOnly
                            />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="cellphone">Celular</label>
                            <InputMask
                              mask="(99) 9 9999-9999"
                              name="cellphone"
                              readOnly
                            />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="commercial_phone">
                              Telefone comercial
                            </label>
                            <InputMask
                              mask="(99) 9999-9999"
                              name="commercial_phone"
                              readOnly
                            />
                          </InputGroup>

                          <InputGroup>
                            <label>Valor do empréstimo</label>
                            <InputCurrency name="loan_amount" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="income">Renda comprovada</label>
                            <InputCurrency name="income" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>CPF</label>
                            <Input name="cpf" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>Registro geral (RG)</label>
                            <Input name="general_registry" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="client_name">Nome do cliente</label>
                            <Input name="client_name" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="marital_status">Estado civil</label>
                            <Input name="marital_status" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>Empresa</label>
                            <Input name="company" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>Função na empresa</label>
                            <Input name="employee_company_role" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>Data de admissão</label>
                            <Input
                              name="admission_date"
                              readOnly
                              defaultValue={format(
                                new Date(
                                  defaultValuesForGuaranteForm.admission_date,
                                ),
                                'dd/MM/yyy',
                              )}
                            />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label>Tempo de conta bancária (em anos)</label>
                            <Input name="bank_account_age" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="mother_name">Nome da mãe</label>
                            <Input name="mother_name" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>Telefone</label>
                            <Input name="phone" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>CPF do responsável</label>
                            <InputMask
                              mask="999.999.999-99"
                              name="cpf"
                              readOnly
                            />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label htmlFor="nacionality">Nacionalidade</label>
                            <Input name="nacionality" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="profession">Profissão</label>
                            <Input name="profession" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label htmlFor="third_party">
                              Referência pessoal
                            </label>
                            <Input name="third_party" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label>Modelo do veículo</label>
                            <Input name="vehicle_model" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>Ano de fabricação do veículo</label>
                            <Input name="vehicle_fabrication_year" readOnly />
                          </InputGroup>
                        </FormRow>

                        <FormRow>
                          <InputGroup>
                            <label>Marca do veículo</label>
                            <Input name="vehicle_brand" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>Versão do veículo</label>
                            <Input name="vehicle_version" readOnly />
                          </InputGroup>

                          <InputGroup>
                            <label>Valor do veículo</label>
                            <InputCurrency name="vehicle_value" readOnly />
                          </InputGroup>
                        </FormRow>
                      </>
                    )}

                    {!guaranteeType ||
                      (guaranteeType === 'credluz' && (
                        <>
                          <FormRow separator>
                            <h1>CredLuz</h1>
                          </FormRow>

                          <FormRow>
                            <InputGroup>
                              <label>Registro geral (RG)</label>

                              <Input
                                name="rg"
                                defaultValue={defaultValuesForGuaranteForm.rg}
                                readOnly
                              />
                            </InputGroup>

                            <InputGroup>
                              <label>Orgão expeditor (RG)</label>

                              <Input name="issuing_body" readOnly />
                            </InputGroup>

                            <InputGroup>
                              <label>Data de nascimento</label>

                              <Input
                                name="birthday"
                                readOnly
                                defaultValue={format(
                                  new Date(
                                    defaultValuesForGuaranteForm.birthday,
                                  ),
                                  'dd/MM/yyyy',
                                )}
                              />
                            </InputGroup>

                            <InputGroup>
                              <label>Estado civil</label>

                              <Input name="marital_status" required readOnly />
                            </InputGroup>
                          </FormRow>

                          <FormRow>
                            <InputGroup>
                              <label>Escolaridade</label>

                              <Input name="schooling" readOnly />
                            </InputGroup>

                            <InputGroup>
                              <label>Nome do pai</label>

                              <Input name="father_name" readOnly />
                            </InputGroup>

                            <InputGroup>
                              <label>Nome da mãe</label>

                              <Input name="mother_name" readOnly />
                            </InputGroup>

                            <InputGroup>
                              <label>Sexo</label>

                              <Input name="sex" readOnly />
                            </InputGroup>
                          </FormRow>

                          <FormRow>
                            <InputGroup>
                              <label>Referência</label>

                              <Input name="reference" readOnly />
                            </InputGroup>

                            <InputGroup>
                              <label>Telefone da referência</label>

                              <Input name="reference_phone" readOnly />
                            </InputGroup>
                          </FormRow>

                          <FormRow>
                            <InputGroup>
                              <label>Segunda referência</label>
                              <Input name="second_reference" readOnly />
                            </InputGroup>

                            <InputGroup>
                              <label>Telefone da segunda referência</label>
                              <InputMask
                                name="second_reference_phone"
                                mask="(99) 99999-9999"
                                readOnly
                              />
                            </InputGroup>
                          </FormRow>

                          <FormRow>
                            <InputGroup>
                              <label>Unidade consumidora</label>
                              <Input name="consumer_unit" readOnly />
                            </InputGroup>
                          </FormRow>

                          <FormRow separator>
                            <h1>Endereço</h1>
                          </FormRow>

                          <FormRow>
                            <InputGroup>
                              <label>Número</label>

                              <Input name="number" readOnly />
                            </InputGroup>

                            <InputGroup>
                              <label>Bairro</label>

                              <Input name="neighborhood" readOnly />
                            </InputGroup>

                            <InputGroup>
                              <label>Complemento</label>

                              <Input name="complement" readOnly />
                            </InputGroup>
                          </FormRow>
                        </>
                      ))}
                  </Form>
                </CardContent>
              </Card>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export { UpdatePFCredit };
