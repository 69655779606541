export function toCamelCase(string: string): string {
  const splittedString = string.split('_');
  const arrayByLength = Array.from(Array(splittedString.length).keys());

  const arrayWithParsedValues = arrayByLength.map((item, index) => {
    if (index === 0) {
      return splittedString[0];
    }

    const stringToBeParsed = splittedString[index];

    const capitularLetter = stringToBeParsed[0].toUpperCase();
    const stringRest = stringToBeParsed.substring(1);

    return `${capitularLetter}${stringRest}`;
  });

  return arrayWithParsedValues.join('');
}
