import styled, { css } from 'styled-components';

interface IInputGroupProps {
  textarea?: number;
}

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const InputGroup = styled.div<IInputGroupProps>`
  display: flex;
  flex-direction: column;

  flex: 1;

  ${props =>
    props.textarea
      ? css`
          height: auto;
        `
      : css`
          height: 72px;
        `}

  > div {
    flex: 1;
    max-width: 100%;
  }

  label {
    color: #3f4254;
    margin-bottom: 5px;
    margin-left: 5px;
    font-weight: 600;
  }

  &.textarea {
    height: auto;
  }
`;

export const VehicleGuarantee = styled.div`
  margin-top: 20px;
`;

export const PropertyGuarantee = styled.div`
  margin-top: 20px;
`;

export const CredLuzGuarantee = styled.div`
  margin-top: 20px;
`;
