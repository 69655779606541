import { FC, useState, useEffect, useMemo, useCallback } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import { IClient } from './interfaces';
import { Card, CardContent, CardHeader } from './styles';

export const ListClientsFromAnybody: FC = () => {
  const { push } = useHistory();

  const [clients, setClients] = useState<IClient[]>();

  useEffect(() => {
    async function loadClients() {
      const { data } = await api.get('/users-indicated');

      setClients(data);
    }

    const timer = setTimeout(() => loadClients(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleClientDetails = useCallback(
    (userId: string) => {
      push(`/shared/clients/${userId}/details`);
    },
    [push],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome',
        selector: 'search',
      },
      {
        name: 'Documentos',
        selector: 'document',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!clients) {
      return [];
    }

    const data = clients.map(client => ({
      id: client.id,
      search: client.name,
      document: client.document,
      email: client.email,
      actions: (
        <TableButtonContainer>
          <TableButton
            icon={FiEye}
            styleType="info"
            onClick={() => handleClientDetails(client.id)}
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [clients, handleClientDetails]);

  return (
    <>
      {!clients ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Clientes', 'Todos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Meus clientes</h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
