/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FC,
  ComponentType,
  useState,
  useCallback,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';

import { Tooltip } from '@components/elements/Tooltip';

import { Container } from './styles';

interface ISelectProps extends SelectProps<OptionTypeBase> {
  icon?: FC<IconBaseProps>;
  name: string;
  readonly?: boolean;
}

const Select: FC<ISelectProps> = ({
  name,
  icon: Icon,
  readonly = false,
  defaultValue: selectDefaultValue,
  ...rest
}) => {
  const selectRef = useRef<any>(null);
  const [isFocused, setIsFosused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const { error, registerField, fieldName, defaultValue } = useField(name);

  const handleOnFocus = useCallback(() => {
    setIsFosused(true);
  }, []);

  const customStyles = useMemo(() => {
    return {
      placeholder: (provided: any) => ({
        ...provided,
        color: '#ccc',
        fontFamily: 'Nunito',
        fontWeight: 600,
      }),
      indicatorsContainer: (provided: any) => ({
        ...provided,
        color: '#ccc',
      }),
      menu: (provided: any) => ({
        ...provided,
        backgroundColor: '#fff',
      }),
    };
  }, []);

  const handleOnBlur = useCallback(() => {
    const ref = selectRef.current as any;

    if (ref.state.value) {
      setIsFilled(!!ref.state.value.value);
    }

    setIsFosused(false);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },

      setValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value;
      },

      clearValue: (ref: any) => {
        if (rest.isMulti) {
          return;
        }

        if (ref.state.value === null) {
          return;
        }

        // eslint-disable-next-line no-param-reassign
        ref.state.value = null;

        setIsFilled(false);
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  useEffect(() => {
    setIsFilled(!!defaultValue);
  }, [defaultValue]);

  return (
    <Container
      readonly={readonly}
      isFosed={isFocused}
      isFilled={isFilled}
      hasErrors={!!error}
    >
      {Icon && <Icon size={20} />}
      <ReactSelect
        ref={selectRef}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        classNamePrefix="react-select"
        styles={customStyles}
        defaultValue={
          selectDefaultValue ||
          rest.options?.find(item => item.value === defaultValue)
        }
        isDisabled={readonly}
        theme={theme => ({
          ...theme,
          borderRadius: 0,
          colors: {
            primary25: '#d4a3d1',
          },
        })}
        placeholder=""
        {...rest}
      />
      {!!error && <Tooltip type="error">{error}</Tooltip>}
    </Container>
  );
};

export { Select };
