import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { URLPath } from '@components/layouts/UrlPath';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Badge } from '@components/elements/Badge';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import {
  IConsigned,
  IClient,
  IConsignedCompany,
  IStatusParsedObject,
} from './interfaces';
import { Card, CardContent, CardHeader } from './styles';

export const ListConsigned: FC = () => {
  const { push } = useHistory();
  const { user } = useAuth();

  const [consignedList, setConsignedList] = useState<IConsigned[]>();

  useEffect(() => {
    async function loadConsigned() {
      const { data } = await api.get<IConsigned[]>(
        `/consigned/indications/${user.id}`,
        {
          params: {
            relations: ['client', 'consigned_company'],
          },
        },
      );

      const parsedData = data.map(consigned => {
        const parsedConsigned = parseObjectPropertiesToCamelCase<IConsigned>(
          consigned,
        );
        const { consignedCompany, client } = parsedConsigned;

        const parsedClient = parseObjectPropertiesToCamelCase<IClient>(client);
        const parsedConsignedCompany = parseObjectPropertiesToCamelCase<IConsignedCompany>(
          consignedCompany,
        );

        parsedConsigned.client = parsedClient;
        parsedConsigned.consignedCompany = parsedConsignedCompany;

        return parsedConsigned;
      });

      setConsignedList(parsedData);
    }

    const timer = setTimeout(() => loadConsigned(), 1500);

    return () => clearInterval(timer);
  }, [user.id]);

  const statusParsedObject = useMemo<IStatusParsedObject>(() => {
    return {
      pending: {
        text: 'Pendente',
        badgeType: 'warning',
      },
      approved: {
        text: 'Aprovado',
        badgeType: 'success',
      },
      pre_approved: {
        text: 'Pre aprovado',
        badgeType: 'info',
      },
      in_analysis: {
        text: 'Em análise',
        badgeType: 'info',
      },
      denied: {
        text: 'Negado',
        badgeType: 'danger',
      },
      contract_pending: {
        text: 'Contrato pendente',
        badgeType: 'info',
      },
    };
  }, []);

  const handleGoToDetailsPage = useCallback(
    (consignedId: string) => {
      push(`/franchisees/cred-cash/consigned/${consignedId}/details`);
    },
    [push],
  );

  // const handleGoToFilesPage = useCallback(
  //   (consignedId: string) => {
  //     push(`/franchisees/cred-cash/consigned/${consignedId}/files`);
  //   },
  //   [push],
  // );

  const tableData = useMemo(() => {
    if (!consignedList) {
      return [];
    }

    const data = consignedList.map(consigned => ({
      id: consigned.id,
      companyName: consigned.consignedCompany.companyName,
      search: consigned.client.name,
      status: (
        <Badge type={statusParsedObject[consigned.status].badgeType}>
          {statusParsedObject[consigned.status].text}
        </Badge>
      ),
      actions: (
        <TableButtonContainer>
          <TableButton
            styleType="info"
            icon={FiEye}
            onClick={() => handleGoToDetailsPage(consigned.id)}
            title="Ver detalhes"
          />
          {/* <TableButton
            styleType="info"
            icon={FiDownload}
            onClick={() => handleGoToFilesPage(consigned.id)}
          /> */}
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [consignedList, statusParsedObject, handleGoToDetailsPage]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome da empresa',
        selector: 'companyName',
      },
      {
        name: 'Nome do cliente',
        selector: 'search',
      },
      {
        name: 'Situação',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  return (
    <>
      {!consignedList ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Cred Cash', 'Consignado', 'Lista']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Lista dos créditos consignados</h1>
              </CardHeader>

              <CardContent>
                <DataTable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
