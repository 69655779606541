import { addMonths, isAfter, isBefore, isToday } from 'date-fns';
import * as Yup from 'yup';

import { IFormData } from './interfaces';

export class CustomError {
  public readonly message: string;

  constructor(message: string) {
    this.message = message;
  }
}

export const handleInsertValueFormValidation = async (
  data: IFormData,
): Promise<void> => {
  const validationShape = Yup.object().shape({
    amount: Yup.number()
      .transform((va, ov) => {
        if (ov === '') {
          return null;
        }

        return va;
      })
      .required('Insira um valor!')
      .typeError('Insira um número válido!')
      .max(2000, 'O máximo é R$ 2000,00!')
      .min(1, 'O mínimo é R$ 1,00!'),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};

export const handleInfoValidationForm = async (
  data: IFormData,
): Promise<void> => {
  const validationShape = Yup.object().shape({
    pixKey: Yup.string().required('Campo obrigatório!'),
    paymentDate: Yup.string().required('Campo obrigatório!'),
    description: Yup.string(),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};

export const handlePaymentDateValidation = (dateString: string): void => {
  const [day, month, year] = dateString.split('/').map(item => Number(item));

  const date = new Date(year, month - 1, day);
  const currentDate = new Date();
  const datePlusThreeMonths = addMonths(currentDate, 3);

  if (!isToday(date)) {
    if (isBefore(date, currentDate)) {
      throw new CustomError('A data não pode ser anterior a atual!');
    }

    if (isAfter(date, datePlusThreeMonths)) {
      throw new CustomError('A data não pode ser posterior a 3 meses de hoje!');
    }
  }
};
