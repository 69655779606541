import { states } from '@helpers/getAllStates';

interface IFranchise {
  id: string;
  name: string;
}

type IReturnType = Array<{ value: string; label: string }>;

export const rolesOptions = [
  { value: 'client', label: 'Cliente' },
  { value: 'master', label: 'Franqueado master' },
  { value: 'business', label: 'Franqueado business' },
  { value: 'admin', label: 'Administrador' },
  { value: 'seller', label: 'Vendedor' },
];

export const statusOptions = [
  { value: 'ativo', label: 'Ativo' },
  { value: 'pendente', label: 'Pendente' },
];

export const statesOption = Object.entries(states).map(item => ({
  value: item[0],
  label: item[1],
}));

export const personTypesOptons = [
  { value: 'PF', label: 'Pessoa fisica' },
  { value: 'PJ', label: 'Pessoa jurídica' },
];

export const loadFranchiseOptions = (franchises: IFranchise[]): IReturnType => {
  return franchises.map(franchise => ({
    value: franchise.id,
    label: franchise.name,
  }));
};

export const maritalStatusOptions = [
  { value: 'solteiro', label: 'Solteiro' },
  { value: 'casado', label: 'Casado' },
  { value: 'viuvo', label: 'Viúvo' },
];
