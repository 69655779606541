export const banks = [
  '001 - Banco Do Brasil S.A (BB)',
  '237 - Bradesco S.A.',
  '335 - Banco Digio S.A.',
  '260 - Nu Pagamentos S.A (Nubank)',
  '290 - PagSeguro Internet S.A.',
  '323 - Mercado Pago – Conta Do Mercado Livre',
  '237 - Next Bank',
  '637 - Banco Sofisa S.A (Sofisa Direto)',
  '077 - Banco Inter S.A.',
  '341 - Itaú Unibanco S.A.',
  '104 - Caixa Econômica Federal (CEF)',
  '033 - Banco Santander Brasil S.A.',
  '212 - Banco Original S.A.',
  '756 - Bancoob – Banco Cooperativo Do Brasil S.A.',
  '655 - Banco Votorantim S.A.',
  '655 - Neon Pagamentos S.A',
  '041 - Banrisul – Banco Do Estado Do Rio Grande Do Sul S.A.',
  '389 - Banco Mercantil Do Brasil S.A.',
  '422 - Banco Safra S.A.',
  '070 - BRB – Banco De Brasília S.A.',
  '136 - Unicred Cooperativa LTDA',
  '741 - Banco Ribeirão Preto S.A.',
  '739 - Banco Cetelem S.A.',
  '743 - Banco Semear S.A.',
  '100 - Planner Corretora De Valores S.A.',
  '096 - Banco B3 S.A.',
  '747 - Banco Rabobank Internacional Do Brasil S.A.',
  '748 - Banco Cooperativa Sicredi S.A.',
  '752 - Banco BNP Paribas Brasil S.A.',
  '091 - Unicred Central Rs',
  '399 - Kirton Bank S.A. – Banco Múltiplo',
  '108 - Portocred S.A.',
  '757 - Banco Keb Hana Do Brasil S.A.',
  '102 - XP Investimentos S.A.',
  '348 - Banco XP S/A',
  '340 - Super Pagamentos S/A (Superdital)',
  '084 - Uniprime Norte Do Paraná',
  '180 - Cm Capital Markets Cctvm Ltda',
  '066 - Banco Morgan Stanley S.A.',
  '015 - UBS Brasil Cctvm S.A.',
  '143 - Treviso Cc S.A.',
  '062 - Hipercard Banco Múltiplo S.A.',
  '074 - Banco J. Safra S.A.',
  '099 - Uniprime Central Ccc Ltda',
  '025 - Banco Alfa S.A.',
  '075 - Bco Abn Amro S.A.',
  '040 - Banco Cargill S.A.',
  '190 - Servicoop',
  '063 - Banco Bradescard',
  '191 - Nova Futura Ctvm Ltda',
  '064 - Goldman Sachs Do Brasil Bm S.A.',
  '097 - Ccc Noroeste Brasileiro Ltda',
  '016 - Ccm Desp Trâns Sc E Rs',
  '012 - Banco Inbursa',
  '003 - Banco Da Amazônia S.A.',
  '060 - Confidence Cc S.A.',
  '037 - Banco Do Estado Do Pará S.A.',
  '159 - Casa do Crédito S.A.',
  '172 - Albatross Ccv S.A.',
  '085 - Cooperativa Central de Créditos – Ailos',
  '114 - Central Cooperativa De Crédito no Estado do Espírito Santo',
  '036 - Banco Bradesco BBI S.A.',
  '394 - Banco Bradesco Financiamentos S.A.',
  '004 - Banco Do Nordeste Do Brasil S.A.',
  '320 - China Construction Bank – Ccb Brasil S.A.',
  '189 - Hs Financeira',
  '105 - Lecca Cfi S.A.',
  '076 - Banco KDB Brasil S.A.',
  '082 - Banco Topázio S.A.',
  '286 - Cooperativa de Crédito Rural de De Ouro',
  '093 - PóloCred Scmepp Ltda',
  '273 - Ccr De São Miguel Do Oeste',
  '157 - Icap Do Brasil Ctvm Ltda',
  '183 - Socred S.A.',
  '014 - Natixis Brasil S.A.',
  '130 - Caruana Scfi',
  '127 - Codepe Cvc S.A.',
  '079 - Banco Original Do Agronegócio S.A.',
  '081 - Bbn Banco Brasileiro De Negocios S.A.',
  '118 - Standard Chartered Bi S.A.',
  '133 - Cresol Confederação',
  '121 - Banco Agibank S.A.',
  '083 - Banco Da China Brasil S.A.',
  '138 - Get Money Cc Ltda',
  '024 - Banco Bandepe S.A.',
  '095 - Banco Confidence De Câmbio S.A.',
  '094 - Banco Finaxis',
  '276 - Senff S.A.',
  '137 - Multimoney Cc Ltda',
  '092 - BRK S.A.',
  '047 - Banco do Estado de Sergipe S.A.',
  '144 - Bexs Banco De Cambio S.A.',
  '126 - BR Partners Banco de Investimento S.A.',
  '301 - BPP Instituição De Pagamentos S.A.',
  '173 - BRL Trust Dtvm Sa',
  '119 - Banco Western Union do Brasil S.A.',
  '254 - Paraná Banco S.A.',
  '268 - Barigui Companhia Hipotecária',
  '107 - Banco Bocom BBM S.A.',
  '412 - Banco Capital S.A.',
  '124 - Banco Woori Bank Do Brasil S.A.',
  '149 - Facta S.A. Cfi',
  '197 - Stone Pagamentos S.A.',
  '142 - Broker Brasil Cc Ltda',
  '389 - Banco Mercantil Do Brasil S.A.',
  '184 - Banco Itaú BBA S.A.',
  '634 - Banco Triangulo S.A (Banco Triângulo)',
  '545 - Senso Ccvm S.A.',
  '132 - ICBC do Brasil Bm S.A.',
  '298 - Vip’s Cc Ltda',
  '129 - UBS Brasil Bi S.A.',
  '128 - Ms Bank S.A Banco De Câmbio',
  '194 - Parmetal Dtvm Ltda',
  '310 - VORTX Dtvm Ltda',
  '163 - Commerzbank Brasil S.A Banco Múltiplo',
  '280 - Avista S.A.',
  '146 - Guitta Cc Ltda',
  '279 - Ccr De Primavera Do Leste',
  '182 - Dacasa Financeira S/A',
  '278 - Genial Investimentos Cvm S.A.',
  '271 - Ib Cctvm Ltda',
  '021 - Banco Banestes S.A.',
  '246 - Banco Abc Brasil S.A.',
  '751 - Scotiabank Brasil',
  '208 - Banco BTG Pactual S.A.',
  '746 - Banco Modal S.A.',
  '241 - Banco Classico S.A.',
  '612 - Banco Guanabara S.A.',
  '604 - Banco Industrial Do Brasil S.A.',
  '505 - Banco Credit Suisse (Brl) S.A.',
  '196 - Banco Fair Cc S.A.',
  '300 - Banco La Nacion Argentina',
  '477 - Citibank N.A.',
  '266 - Banco Cedula S.A.',
  '122 - Banco Bradesco BERJ S.A.',
  '376 - Banco J.P. Morgan S.A.',
  '473 - Banco Caixa Geral Brasil S.A.',
  '745 - Banco Citibank S.A.',
  '120 - Banco Rodobens S.A.',
  '265 - Banco Fator S.A.',
  '007 - BNDES (Banco Nacional Do Desenvolvimento Social)',
  '188 - Ativa S.A Investimentos',
  '134 - BGC Liquidez Dtvm Ltda',
  '641 - Banco Alvorada S.A.',
  '029 - Banco Itaú Consignado S.A.',
  '243 - Banco Máxima S.A.',
  '078 - Haitong Bi Do Brasil S.A.',
  '111 - Banco Oliveira Trust Dtvm S.A.',
  '017 - Bny Mellon Banco S.A.',
  '174 - Pernambucanas Financ S.A.',
  '495 - La Provincia Buenos Aires Banco',
  '125 - Brasil Plural S.A Banco',
  '488 - Jpmorgan Chase Bank',
  '065 - Banco Andbank S.A.',
  '492 - Ing Bank N.V.',
  '250 - Banco Bcv',
  '145 - Levycam Ccv Ltda',
  '494 - Banco Rep Oriental Uruguay',
  '253 - Bexs Cc S.A.',
  '269 - Hsbc Banco De Investimento',
  '213 - Bco Arbi S.A.',
  '139 - Intesa Sanpaolo Brasil S.A.',
  '018 - Banco Tricury S.A.',
  '630 - Banco Intercap S.A.',
  '224 - Banco Fibra S.A.',
  '600 - Banco Luso Brasileiro S.A.',
  '623 - Banco Pan S.A.',
  '204 - Banco Bradesco Cartoes S.A.',
  '479 - Banco ItauBank S.A.',
  '456 - Banco MUFG Brasil S.A.',
  '464 - Banco Sumitomo Mitsui Brasil S.A.',
  '613 - Omni Banco S.A.',
  '652 - Itaú Unibanco Holding Bm S.A.',
  '653 - Banco Indusval S.A.',
  '069 - Banco Crefisa S.A.',
  '370 - Banco Mizuho S.A.',
  '249 - Banco Investcred Unibanco S.A.',
  '318 - Banco BMG S.A.',
  '626 - Banco Ficsa S.A.',
  '270 - Sagitur Cc Ltda',
  '366 - Banco Societe Generale Brasil',
  '113 - Magliano S.A.',
  '131 - Tullett Prebon Brasil Cvc Ltda',
  '011 - C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)',
  '611 - Banco Paulista',
  '755 - Bofa Merrill Lynch Bm S.A.',
  '089 - Ccr Reg Mogiana',
  '643 - Banco Pine S.A.',
  '140 - Easynvest – Título Cv S.A.',
  '707 - Banco Daycoval S.A.',
  '288 - Carol Dtvm Ltda',
  '101 - Renascença Dtvm Ltda',
  '487 - Deutsche Bank S.A (Banco Alemão)',
  '233 - Banco Cifra S.A.',
  '177 - Guide Investimentos S.A. Corretora de Valores',
  '633 - Banco Rendimento S.A.',
  '218 - Banco Bs2 S.A.',
  '292 - BS2 Distribuidora De Títulos E Investimentos',
  '169 - Banco Olé Bonsucesso Consignado S.A.',
  '293 - Lastro Rdv Dtvm Ltda',
  '285 - Frente Cc Ltda',
  '080 - B&T Cc Ltda',
  '753 - Novo Banco Continental S.A Bm',
  '222 - Banco Crédit Agricole Br S.A.',
  '754 - Banco Sistema S.A.',
  '098 - Credialiança Ccr',
  '610 - Banco VR S.A.',
  '712 - Banco Ourinvest S.A.',
  '010 - CREDICOAMO CRÉDITO RURAL COOPERATIVA',
  '283 - RB Capital Investimentos Dtvm Ltda',
  '217 - Banco John Deere S.A.',
  '117 - Advanced Cc Ltda',
  '336 - Banco C6 S.A – C6 Bank',
  '654 - Banco A.J. Renner S.A.',
  '272 - AGK Corretora de Câmbio S.A.',
  '330 - Banco Bari de Investimentos e Financiamentos S.A.',
  '362 - CIELO S.A.',
  '322 - Cooperativa de Crédito Rural de Abelardo Luz – Sulcredi/Crediluz',
  '350 - Cooperativa De Crédito Rural De Pequenos Agricultores E Da Reforma Agrária Do Ce',
  '091 - Central De Cooperativas De Economia E Crédito Mútuo Do Estado Do Rio Grande Do S',
  '379 - COOPERFORTE – Cooperativa De Economia E Crédito Mútuo Dos Funcionários De Instit',
  '378 - BBC LEASING S.A. – Arrendamento Mercantil',
  '360 - TRINUS Capital Distribuidora De Títulos E Valores Mobiliários S.A.',
  '084 - UNIPRIME NORTE DO PARANÁ – COOPERATIVA DE CRÉDITO LTDA',
  '387 - Banco Toyota do Brasil S.A.',
  '326 - PARATI – CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  '315 - PI Distribuidora de Títulos e Valores Mobiliários S.A.',
  '307 - Terra Investimentos Distribuidora de Títulos e Valores Mobiliários Ltda.',
  '296 - VISION S.A. CORRETORA DE CAMBIO',
  '382 - FIDÚCIA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE L',
  '097 - Credisis – Central de Cooperativas de Crédito Ltda.',
  '016 - COOPERATIVA DE CRÉDITO MÚTUO DOS DESPACHANTES DE TRÂNSITO DE SANTA CATARINA E RI',
  '299 - SOROCRED   CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  '359 - ZEMA CRÉDITO, FINANCIAMENTO E INVESTIMENTO S/A',
  '391 - COOPERATIVA DE CRÉDITO RURAL DE IBIAM – SULCREDI/IBIAM',
  '368 - Banco CSF S.A.',
  '259 - MONEYCORP BANCO DE CÂMBIO S.A.',
  '364 - GERENCIANET S.A.',
  '014 - STATE STREET BRASIL S.A. – BANCO COMERCIAL',
  '081 - Banco Seguro S.A.',
  '384 - GLOBAL FINANÇAS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO',
  '088 - BANCO RANDON S.A.',
  '319 - OM DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  '274 - MONEY PLUS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORT',
  '095 - Travelex Banco de Câmbio S.A.',
  '332 - Acesso Soluções de Pagamento S.A.',
  '325 - Órama Distribuidora de Títulos e Valores Mobiliários S.A.',
  '331 - Fram Capital Distribuidora de Títulos e Valores Mobiliários S.A.',
  '396 - HUB PAGAMENTOS S.A.',
  '309 - CAMBIONET CORRETORA DE CÂMBIO LTDA.',
  '313 - AMAZÔNIA CORRETORA DE CÂMBIO LTDA.',
  '377 - MS SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA',
  '321 - CREFAZ SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E A EMPRESA DE PEQUENO PORTE LT',
  '383 - BOLETOBANCÁRIO.COM TECNOLOGIA DE PAGAMENTOS LTDA.',
  '324 - CARTOS SOCIEDADE DE CRÉDITO DIRETO S.A.',
  '380 - PICPAY SERVICOS S.A.',
  '343 - FFA SOCIEDADE DE CRÉDITO AO MICROEMPREENDEDOR E À EMPRESA DE PEQUENO PORTE LTDA.',
  '349 - AL5 S.A. CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  '374 - REALIZE CRÉDITO, FINANCIAMENTO E INVESTIMENTO S.A.',
  '352 - TORO CORRETORA DE TÍTULOS E VALORES MOBILIÁRIOS LTDA',
  '329 - QI Sociedade de Crédito Direto S.A.',
  '342 - Creditas Sociedade de Crédito Direto S.A.',
  '397 - LISTO SOCIEDADE DE CRÉDITO DIRETO S.A.',
  '355 - ÓTIMO SOCIEDADE DE CRÉDITO DIRETO S.A.',
  '367 - VITREO DISTRIBUIDORA DE TÍTULOS E VALORES MOBILIÁRIOS S.A.',
  '373 - UP.P SOCIEDADE DE EMPRÉSTIMO ENTRE PESSOAS S.A.',
  '408 - BÔNUSCRED SOCIEDADE DE CRÉDITO DIRETO S.A.',
  '404 - SUMUP SOCIEDADE DE CRÉDITO DIRETO S.A.',
  '403 - CORA SOCIEDADE DE CRÉDITO DIRETO S.A.',
  '306 - PORTOPAR DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA.',
  '174 - PEFISA S.A. – CRÉDITO, FINANCIAMENTO E INVESTIMENTO',
  '354 - NECTON INVESTIMENTOS S.A. CORRETORA DE VALORES MOBILIÁRIOS E COMMODITIES',
  '630 - Banco Smartbank S.A.',
  '393 - Banco Volkswagen S.A.',
  '390 - BANCO GM S.A.',
  '381 - BANCO MERCEDES-BENZ DO BRASIL S.A.',
  '626 - BANCO C6 CONSIGNADO S.A.',
  '755 - Bank of America Merrill Lynch Banco Múltiplo S.A.',
  '089 - CREDISAN COOPERATIVA DE CRÉDITO',
  '363 - SOCOPA SOCIEDADE CORRETORA PAULISTA S.A.',
  '365 - SOLIDUS S.A. CORRETORA DE CAMBIO E VALORES MOBILIARIOS',
  '281 - Cooperativa de Crédito Rural Coopavel',
  '654 - BANCO DIGIMAIS S.A.',
  '371 - WARREN CORRETORA DE VALORES MOBILIÁRIOS E CÂMBIO LTDA.',
  '289 - DECYSEO CORRETORA DE CAMBIO LTDA.',
];
