import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { useRouteMatch } from 'react-router-dom';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { OptionTypeBase } from 'react-select';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeInputMask } from '@helpers/removeInputMask';
import { toCamelCase } from '@helpers/toCamelCase';

import {
  statusOpions,
  paymentFormOptions,
  sellingTypeOptions,
  businessMethodOptions,
  portionsOptions,
} from './selectOptions';
import { URLPath, Card, CardHeader, CardContent } from './styles';
import { IParams, IConvenience, IFormData } from './interfaces';
import { formValidation } from './validations';

const EditConveniences: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();

  const [convenience, setConvenience] = useState<IConvenience>();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    async function loadConvenience() {
      const { data } = await api.get(
        `/bbank-pay/conveniences/${params.convenienceId}`,
        {
          params: {
            relations: ['client', 'franchisee'],
          },
        },
      );

      setConvenience(data);
      setPrice(data.payment_value);
    }

    const timer = setTimeout(() => {
      loadConvenience();
    }, 1500);

    return () => {
      clearInterval(timer);
    };
  }, [params.convenienceId]);

  const bbankPayPaymentValueObject = useMemo(() => {
    const bbankPayPaymentValue = price;

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    const parsedValue = numberFormat.format(bbankPayPaymentValue);

    return {
      parsedValue,
      bbankPayPaymentValue,
    };
  }, [price]);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const formData = Object.entries(data).reduce<IFormData>(
          (acc, [key, value]) => {
            const parsedKey = toCamelCase(key);

            acc[parsedKey] = value;

            return acc;
          },
          {},
        );

        delete formData.client;
        delete formData.franchisee;

        const valuesWithoutMasks = removeInputMask();

        const parsedValuesWithoutMask = valuesWithoutMasks.map(
          ({ name, value }) => {
            const parsedName = toCamelCase(name);

            return { name: parsedName, value };
          },
        );

        const parsedFormData = parsedValuesWithoutMask.reduce(
          (acc, { name, value }) => {
            acc[name] = value;

            return acc;
          },
          formData,
        );

        parsedFormData.paymentValue =
          bbankPayPaymentValueObject.bbankPayPaymentValue;

        const { data: convenienceUpdated } = await api.put(
          `/bbank-pay/conveniences/${params.convenienceId}`,
          parsedFormData,
        );

        addToast({
          title: 'Dados atualizados com sucesso!',
          type: 'success',
        });

        setConvenience(convenienceUpdated);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [
      addToast,
      params.convenienceId,
      bbankPayPaymentValueObject.bbankPayPaymentValue,
    ],
  );

  const handleChangeOfPrice = useCallback((option: OptionTypeBase | null) => {
    if (!option) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const { value } = option;

    const ref = formRef.current?.getFieldRef('payment_value');

    switch (value) {
      case 'accession':
        setPrice(198);
        ref.value = numberFormat(198);
        break;

      case 'sells_a930':
        setPrice(676);
        ref.value = numberFormat(676);
        break;

      default:
        setPrice(556);
        ref.value = numberFormat(556);
        break;
    }
  }, []);

  return (
    <>
      {!convenience ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>BbankPay</li>
              <li>{'>'}</li>
              <li>Estabelecimentos</li>
              <li>{'>'}</li>
              <li>Editar/Visualizar</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Estabelecimento</h1>

                {convenience.status === 'ativo' && (
                  <Badge type="success">Ativo</Badge>
                )}

                {convenience.status === 'parado' && (
                  <Badge type="danger">Parado</Badge>
                )}

                {convenience.status === 'pendente' && (
                  <Badge type="warning">Pendente</Badge>
                )}
              </CardHeader>

              <CardContent>
                <Form
                  onSubmit={handleFormSubmit}
                  ref={formRef}
                  initialData={convenience}
                >
                  <FormRow>
                    <InputGroup>
                      <label>Status</label>
                      <Select name="status" options={statusOpions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Franqueado responsável</label>
                      <Input name="franchisee.name" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Cliente do fraqueado responsável</label>
                      <Input name="client.name" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CPF</label>
                      <Input
                        name="cpf"
                        readOnly={convenience.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Número do SN</label>
                      <Input
                        name="infinity_id"
                        placeholder="acc51515"
                        upperCase={false}
                        readOnly={convenience.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Endereço</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>CEP</label>
                      <Input name="client.zipcode" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Endereço</label>
                      <Input name="client.address" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="client.city" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="client.city" readOnly />
                    </InputGroup>

                    <InputGroup>
                      <label>Cidade</label>
                      <Input name="client.state" readOnly />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Complemento (deixe vazio caso não exista)</label>
                      <Input
                        name="complement"
                        readOnly={convenience.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Número (deixe vazio caso não exista)</label>
                      <InputMask
                        mask="9999999999"
                        noUnmask
                        name="address_number"
                        readOnly={convenience.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Contrato</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Modelo de negócio</label>
                      <Select
                        name="business_method"
                        options={businessMethodOptions}
                        readonly={convenience.status !== 'pendente'}
                        onChange={handleChangeOfPrice}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Tipo de venda</label>
                      <Select
                        name="selling_type"
                        options={sellingTypeOptions}
                        readonly={convenience.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow separator>
                    <h1>Método de pagamento</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor a ser pago</label>
                      <Input
                        name="payment_value"
                        readOnly
                        defaultValue={bbankPayPaymentValueObject.parsedValue}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Forma de pagamento</label>
                      <Select
                        name="payment_form"
                        options={paymentFormOptions}
                        readonly={convenience.status !== 'pendente'}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Parcelas</label>
                      <Select
                        name="payment_portions"
                        options={portionsOptions}
                        readonly={convenience.status !== 'pendente'}
                      />
                    </InputGroup>
                  </FormRow>

                  {convenience.selling_type === 'sells_with_cashback' && (
                    <FormRow>
                      <InputGroup>
                        <label>Porcentagem de cashback</label>
                        <Input
                          type="number"
                          name="cashback_percentage"
                          readOnly={convenience.status !== 'pendente'}
                        />
                      </InputGroup>
                    </FormRow>
                  )}

                  <FormRow buttonWrapper>
                    <Button
                      type="submit"
                      styleType="info"
                      icon={FiSave}
                      loading={loadingRequest}
                    >
                      Salvar alterações
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { EditConveniences };
