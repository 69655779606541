import { FC, useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { FiAlertCircle, FiMoreHorizontal, FiSave } from 'react-icons/fi';
import * as Yup from 'yup';

import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';
import { FormRow } from '@components/elements/Form/FormRow';
import { Button } from '@components/elements/Button';
import { Row } from '@components/layouts/Grid/Row';

import { useToast } from '@hooks/toast';

import bbankApi from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { URLPath, Card, CardHeader, CardContent } from './styles';

interface IFaqFormData {
  title: string;
  description: string;
}

const NewFaqCategory: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = useCallback(
    async (data: IFaqFormData, { reset }) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        const shape = Yup.object().shape({
          title: Yup.string().required('Preencha este campo!'),
          description: Yup.string().required('Preencha este campo!'),
        });

        await shape.validate(data, {
          abortEarly: false,
        });

        await bbankApi.post('/faq-categories', data);

        addToast({
          title: 'Sucesso!',
          type: 'success',
          message: 'Categoria cadastrada com sucesso',
        });

        reset();
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const { message, status } = getClientErrors(err.response);

        addToast({
          title: `[${status}] Algum erro aconteceu`,
          type: 'error',
          message,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  return (
    <>
      <Row>
        <URLPath>
          <li>FAQ</li>

          <li>&gt;</li>

          <li>Categoria</li>

          <li>&gt;</li>

          <li>Novo</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Nova categoria</h1>
          </CardHeader>

          <CardContent>
            <Form ref={formRef} onSubmit={handleFormSubmit}>
              <FormRow>
                <Input
                  name="title"
                  icon={FiAlertCircle}
                  placeholder="Título"
                  upperCase={false}
                />

                <Input
                  name="description"
                  icon={FiMoreHorizontal}
                  placeholder="Descrição"
                  upperCase={false}
                />
              </FormRow>

              <FormRow buttonWrapper>
                <Button
                  loading={loading}
                  icon={FiSave}
                  type="submit"
                  styleType="success"
                >
                  Cadastrar
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewFaqCategory };
