import { FormHandles } from '@unform/core';
import { FC, useCallback, useRef, useState, useMemo } from 'react';
import { ValidationError } from 'yup';
import { format } from 'date-fns';

import { AuthModal } from '@components/bank/layouts/AuthModal';
import { Button } from '@components/elements/Button';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { URLPath } from '@components/layouts/UrlPath';
import { Textarea } from '@components/elements/Form/Textarea';
import { InputMask } from '@components/elements/Form/InputMask';
import { Input } from '@components/elements/Form/Input';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import { ownApi } from '@services/ownApi';
import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';

import { IFormData, IPixInfo } from './interfaces';
import { Card, CardContent, CardHeader, PixConfirmation } from './styles';
import {
  handleInsertValueFormValidation,
  handlePaymentDateValidation,
  handleInfoValidationForm,
  CustomError,
} from './validations';

const NewTransfer: FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [currentStep, setCurrentStep] = useState(1);
  const [amount, setAmount] = useState(0);
  const [paymentDate, setPaymentDate] = useState('');
  const [pixInfo, setPixInfo] = useState<IPixInfo>();
  const [loadingRequest, setLoadingRequest] = useState(false);

  const handleInsertValueFormSubmit = useCallback(async (data: IFormData) => {
    try {
      await handleInsertValueFormValidation(data);

      setAmount(data.amount as number);
      setCurrentStep(2);
    } catch (err: any) {
      if (err instanceof ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);
      }
    }
  }, []);

  const handleInfoFormSubmit = useCallback(
    async data => {
      try {
        await handleInfoValidationForm(data);
        handlePaymentDateValidation(data.paymentDate);

        setLoadingRequest(true);

        const { data: responseData } = await ownApi.get(
          `/services/pix/addresses`,
          {
            params: {
              chave: data.pixKey,
              cpfCnpj: user.document,
            },
          },
        );

        setPixInfo({
          bankName: responseData.nomePsp,
          documentMask: responseData.cpfCnpjMask,
          endToEnd: responseData.endToEnd,
          key: responseData.chave,
          keyType: responseData.tipoChave,
          name: responseData.nome,
        });
        setLoadingRequest(false);
        setPaymentDate(data.paymentDate);
        setCurrentStep(3);
      } catch (err: any) {
        setLoadingRequest(false);

        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err instanceof CustomError) {
          formRef.current?.setErrors({ paymentDate: err.message });
          return;
        }

        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              const {
                data: { message },
              } = err.response;

              addToast({
                title: message,
                type: 'info',
              });
            }
          }
        }
      }
    },
    [user.document, addToast],
  );

  const handlePaymentFormSubmit = useCallback(
    async (data, { reset }) => {
      try {
        setLoadingRequest(true);

        const pixRequest = {
          campoLivre: data.description,
          chaveEnderecamento: pixInfo?.key, // CHAVE DE QUEM IRA RECEBER
          codAgenciaBeneficiario: null,
          nroContaBeneficiario: null,
          codInstituicaoBeneficiario: null,
          cpfCnpjBeneficiario: null,
          cpfCnpjPagador: user.document,
          dataPagamento: paymentDate || null,
          endToEnd: pixInfo?.endToEnd,
          referenciaInterna: '',
          tipoContaBeneficiario: 'CACC', // CAMPO FIXO
          valorOperacao: amount,
        };

        await ownApi.post(
          '/services/pix/payment-management/payment-order',
          pixRequest,
        );

        await api.post('/financing/reports', {
          clientId: user.id,
          operationValue: amount,
          relation: 'Transferência PIX',
          operationDate: format(new Date(), 'dd/MM/yyyy'),
        });

        setAmount(0);
        setCurrentStep(1);
        setPixInfo(undefined);
        setLoadingRequest(false);
        setTimeout(() => {
          reset();
        }, 100);
      } catch (err: any) {
        setLoadingRequest(false);

        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              const {
                data: { message },
              } = err.response;

              addToast({
                title: message,
                type: 'info',
              });
            }
          }
        }
      }
    },
    [amount, user, paymentDate, pixInfo, addToast],
  );

  const handlers = useMemo(
    () => [
      handleInsertValueFormSubmit,
      handleInfoFormSubmit,
      handlePaymentFormSubmit,
    ],
    [
      handleInsertValueFormSubmit,
      handleInfoFormSubmit,
      handlePaymentFormSubmit,
    ],
  );

  return (
    <>
      <AuthModal />

      <Row>
        <URLPath paths={['Transferencia', 'Nova Transferencia']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Nova transferencia</h1>
          </CardHeader>

          <CardContent>
            <Form
              onSubmit={handlers[currentStep - 1]}
              ref={formRef}
              progressBar={{ active: true, steps: 3, currentStep }}
            >
              {currentStep === 1 && (
                <>
                  <FormRow separator>
                    <h1>Primeiro de tudo, insira o valor!</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor</label>
                      <InputCurrency name="amount" />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper style={{ marginTop: 20 }}>
                    <Button type="submit" styleType="success">
                      Avançar
                    </Button>
                  </FormRow>
                </>
              )}

              {currentStep === 2 && (
                <>
                  <FormRow separator>
                    <h1>Continue! Estamos quase lá...</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Chave PIX</label>
                      <Input name="pixKey" upperCase={false} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data para pagamento</label>
                      <InputMask
                        name="paymentDate"
                        mask="99/99/9999"
                        noUnmask
                        defaultValue={format(new Date(), 'dd/MM/yyyy')}
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup textarea>
                      <label>Mensagem</label>
                      <Textarea
                        name="message"
                        rows={10}
                        placeholder="Mensagem aqui..."
                      />
                    </InputGroup>
                  </FormRow>

                  <FormRow buttonWrapper style={{ marginTop: 20 }}>
                    <Button
                      type="button"
                      styleType="warning"
                      onClick={() => setCurrentStep(oldState => oldState - 1)}
                    >
                      Voltar
                    </Button>
                    <Button
                      type="submit"
                      styleType="success"
                      loading={loadingRequest}
                    >
                      Avançar
                    </Button>
                  </FormRow>
                </>
              )}

              {currentStep === 3 && (
                <PixConfirmation>
                  <header>
                    <h1>Transferindo...</h1>

                    <strong>
                      {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(amount || 0)}
                    </strong>

                    <p>
                      para&nbsp;
                      <strong>{pixInfo?.name || '...'}</strong>
                    </p>
                  </header>

                  <main>
                    <div className="datePicker">
                      <span>Quando</span>

                      <strong>
                        {paymentDate === format(new Date(), 'dd/MM/yyyy')
                          ? 'Agora'
                          : paymentDate}
                      </strong>
                    </div>

                    <div>
                      <span>Chave</span>

                      <strong>{pixInfo?.key || '...'}</strong>
                    </div>

                    <div>
                      <span>Tipo de chave</span>

                      <strong>{pixInfo?.keyType || '...'}</strong>
                    </div>

                    <div>
                      <div>
                        <span>
                          {pixInfo?.documentMask?.length === 14
                            ? 'CPF'
                            : 'CNPJ'}
                        </span>
                        <span>{pixInfo?.documentMask || '...'}</span>
                      </div>

                      <div>
                        <span>Instituição financeira</span>
                        <span>{pixInfo?.bankName || '...'}</span>
                      </div>
                    </div>
                  </main>

                  <footer>
                    <Button
                      type="button"
                      styleType="warning"
                      onClick={() => setCurrentStep(oldState => oldState - 1)}
                    >
                      Voltar
                    </Button>
                    <Button
                      type="submit"
                      styleType="info"
                      loading={loadingRequest}
                    >
                      Confirmar...
                    </Button>
                  </footer>
                </PixConfirmation>
              )}
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewTransfer };
