import styled from 'styled-components';

export const Container = styled.div`
  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 40px;
    width: 100%;

    padding: 0 25px;

    margin-top: 21px;

    h1 {
      font-size: 0.85rem;
      color: #3f4254;
      font-weight: 600;

      text-transform: uppercase;

      letter-spacing: 0.7px;
    }
  }

  > ul {
    list-style: none;

    width: 100%;

    display: flex;
    flex-direction: column;
  }
`;
