import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FiEdit2,
  FiMoreHorizontal,
  FiPlusCircle,
  FiTrash,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { URLPath } from '@components/layouts/UrlPath';
import { Dropdown } from '@components/elements/Dropdown';

import api from '@services/bbankApi';

import { Card, CardHeader, CardContent } from './styles';
import { IDepartment, ILoadedDepartment } from './interfaces';

const ListDepartments: FC = () => {
  const { push } = useHistory();

  const [departments, setDepartments] = useState<IDepartment[]>();
  const [loadingDeleteButton, setLoadingDeleteButton] = useState(false);

  useEffect(() => {
    async function getDepartments() {
      const { data } = await api.get<ILoadedDepartment[]>('/departments');

      const parsedData = data.map(department => {
        return {
          id: department.id,
          name: department.name,
          createdAt: department.created_at,
          updatedAt: department.updated_at,
        };
      });

      setDepartments(parsedData);
    }

    const timer = setTimeout(() => {
      getDepartments();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleExcludeDepartment = useCallback(async (departmentId: string) => {
    setLoadingDeleteButton(true);

    await api.delete(`/departments/${departmentId}`);

    setLoadingDeleteButton(false);
    setDepartments(oldState => {
      if (!oldState) {
        return [];
      }

      const updatedState = oldState.filter(state => state.id !== departmentId);

      return updatedState;
    });
  }, []);

  const handleNavigateToViewDepartmentPage = useCallback(
    (departmentId: string) => {
      push(`/departments/${departmentId}/view`);
    },
    [push],
  );

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome do departamento',
        selector: 'search',
      },
      {
        name: 'Criado em',
        selector: 'createdAt',
      },
      {
        name: 'Última atualização em',
        selector: 'updatedAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!departments) {
      return [];
    }

    const data = departments.map(department => {
      return {
        id: department.id,
        search: department.name,
        createdAt: format(new Date(department.createdAt), 'dd/MM/yyyy'),
        updatedAt: format(new Date(department.updatedAt), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="warning"
              onClick={() => handleNavigateToViewDepartmentPage(department.id)}
              icon={FiEdit2}
            />
            <TableButton
              styleType="danger"
              loading={loadingDeleteButton}
              onDoubleClick={() => handleExcludeDepartment(department.id)}
              title="Clique duas vezes para deletar!"
              icon={FiTrash}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [
    departments,
    loadingDeleteButton,
    handleExcludeDepartment,
    handleNavigateToViewDepartmentPage,
  ]);

  const handleNavigateToAddDepartmentsPage = useCallback(() => {
    push('/departments/new');
  }, [push]);

  return (
    <>
      {!departments ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Departamentos', 'Todos']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Departamentos cadastrados</h1>

                <Dropdown
                  styles={{ textColor: '#fff', togglerBackground: '#c8c6c0' }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <button
                      type="button"
                      onClick={handleNavigateToAddDepartmentsPage}
                    >
                      <FiPlusCircle />
                      Adicionar novo
                    </button>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListDepartments };
