import {
  FC,
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
  Fragment,
} from 'react';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import { v4 } from 'uuid';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import { format } from 'date-fns/esm';

import { Row } from '@components/layouts/CardMenuContainer/styles';
import { URLPath } from '@components/layouts/UrlPath';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Form } from '@components/elements/Form';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { InputMask } from '@components/elements/Form/InputMask';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import { IClient, IFormData, IMessage } from './interface';
import { Card, CardContent, CardHeader } from './styles';
import { formValidation } from './validations';
import { getClientOptions } from './selectOpitions';

const NewBillet: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const {
    user: { id: userId },
  } = useAuth();

  const [clients, setClients] = useState<IClient[]>();
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { addToast } = useToast();
  const [messages, setMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    async function loadClients() {
      const { data } = await api.get('/users-indicated');

      setClients(data);
    }

    const timer = setTimeout(() => {
      loadClients();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  const handleAddMessages = useCallback(() => {
    setMessages(oldState => {
      if (oldState.length === 7) {
        return oldState;
      }

      const message = {
        id: v4(),
      };

      return [...oldState, message];
    });
  }, []);

  const handleRemoveMessages = useCallback((messageId: string) => {
    setMessages(oldState => {
      const updatedState = oldState.filter(state => state.id !== messageId);

      return updatedState;
    });
  }, []);

  const messagesControl = useMemo<Record<number, string>>(() => {
    return {
      1: 'messageThree',
      2: 'messageFour',
      3: 'messageFive',
      4: 'messageSix',
      5: 'messageSeven',
      6: 'messageEight',
      7: 'messageNine',
    };
  }, []);

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const messagesObj: Record<string, string> = {};

        if (messages && messages.length > 0) {
          data.messages.forEach((message, index) => {
            const key = messagesControl[index + 1];

            messagesObj[key] = message;
          });
        }

        const parsedPaymentValue = data.paymentValue.replace('.', ',');

        const parsedData: Record<string, string> = {
          issueDate: data.issueDate,
          dueDate: data.dueDate,
          messageOne: data.messageOne,
          messageTwo: data.messageTwo,
          paymentValue: parsedPaymentValue,
          assignorId: userId,
          payerId: data.payerId,
          ...messagesObj,
        };

        await api.post('/billets', parsedData);

        addToast({
          title: 'Muito bom!',
          type: 'success',
          message: 'O seu boleto foi gerado com sucesso!',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status >= 400 && status < 499) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, userId, messagesControl, messages],
  );

  const clientOptions = useMemo(() => {
    return clients ? getClientOptions(clients) : [];
  }, [clients]);

  return (
    <>
      {!clients ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath paths={['Boleto', 'Novo Boleto']} />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Novo Boleto</h1>

                <Button
                  styleType="info"
                  icon={FiPlusCircle}
                  onClick={handleAddMessages}
                  disabled={messages.length === 7}
                >
                  Adicionar mensagem
                </Button>
              </CardHeader>

              <CardContent>
                <Form onSubmit={handleFormSubmit} ref={formRef}>
                  <FormRow separator>
                    <h1>Preencha os dados para gerar o boleto!</h1>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Valor do boleto</label>
                      <InputCurrency name="paymentValue" />
                    </InputGroup>

                    <InputGroup>
                      <label>Selecione o cliente</label>
                      <Select name="payerId" options={clientOptions} />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Data de emissão</label>
                      <Input
                        name="issueDate"
                        defaultValue={format(new Date(), 'dd/MM/yyyy')}
                        readOnly
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Data de vencimento</label>
                      <InputMask mask="99/99/9999" name="dueDate" />
                    </InputGroup>
                  </FormRow>

                  <FormRow>
                    <InputGroup>
                      <label>Mensagem 1</label>
                      <Input
                        name="messageOne"
                        placeholder="Exemplo: Pagamento referente a XXX"
                        upperCase={false}
                      />
                    </InputGroup>

                    <InputGroup>
                      <label>Mensagem 2</label>
                      <Input
                        name="messageTwo"
                        placeholder="Exemplo: Pagar no banco X"
                        upperCase={false}
                      />
                    </InputGroup>
                  </FormRow>

                  {messages.length > 0 &&
                    messages.map((message, index) => (
                      <div
                        key={message.id}
                        style={{ display: 'flex', flex: 1, marginTop: 20 }}
                      >
                        <FormRow>
                          <InputGroup>
                            <label>Nova mensagem</label>
                            <Input
                              name={`messages[${index}]`}
                              maxLength={255}
                              upperCase={false}
                            />
                          </InputGroup>
                        </FormRow>

                        <Button
                          styleType="danger"
                          icon={FiMinusCircle}
                          onClick={() => handleRemoveMessages(message.id)}
                          style={{
                            padding: 11,
                            marginLeft: 6,
                            alignSelf: 'flex-end',
                          }}
                        />
                      </div>
                    ))}

                  <FormRow buttonWrapper style={{ marginTop: 20 }}>
                    <Button
                      type="submit"
                      styleType="info"
                      loading={loadingRequest}
                    >
                      Gerar boleto!
                    </Button>
                  </FormRow>
                </Form>
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { NewBillet };
