import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    name: Yup.string()
      .required('This field is required!')
      .max(255, 'Limite de caracteres máximo atingido!'),
    income: Yup.number()
      .transform((ac, ov) => {
        const changedPointAndCommaPositionValue = ov
          .replaceAll(',', '.')
          .replaceAll('.', ',');
        const findComma = changedPointAndCommaPositionValue.search(',');

        if (findComma > 0) {
          const parsedValue = changedPointAndCommaPositionValue.replaceAll(
            ',',
            '',
          );

          return Number(parsedValue);
        }

        return Number(ac);
      })
      .typeError('Insira um valor numérico!')
      .max(1000000.0, 'O máximo é 1.000.000')
      .required('Preencha este campo!'),
    period: Yup.number()
      .typeError('Insira um valor numérico!')
      .required('Preencha este campo!'),
    value: Yup.number()
      .transform((ac, ov) => {
        const changedPointAndCommaPositionValue = ov
          .replaceAll(',', '.')
          .replaceAll('.', ',');
        const findComma = changedPointAndCommaPositionValue.search(',');

        if (findComma > 0) {
          const parsedValue = changedPointAndCommaPositionValue.replaceAll(
            ',',
            '',
          );

          return Number(parsedValue);
        }

        return Number(ac);
      })
      .typeError('Insira um valor numérico!')
      .max(1000000.0, 'O máximo é 1.000.000')
      .required('Preencha este campo!'),
    percentage: Yup.number()
      .typeError('Insira um valor numérico!')
      .required('Preencha este campo!'),
    items: Yup.array()
      .min(1, 'Insira ao menos ')
      .of(
        Yup.object().shape({
          relation: Yup.string().required('Preencha este campo!'),
          value: Yup.string().required('Preencha este campo!'),
        }),
      ),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
