import { FC } from 'react';

import { AsideProvider } from '../contexts/aside';
import { MenuProvider } from '../contexts/menu';
import { ToastProvider } from '../contexts/toast';
import { AuthProvider } from '../contexts/auth';
import { TermsOfUseProvider } from '../contexts/termsOfUse';
import { BankProvider } from '../contexts/bank';

const Providers: FC = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <TermsOfUseProvider>
        <AsideProvider>
          <MenuProvider>
            <BankProvider>{children}</BankProvider>
          </MenuProvider>
        </AsideProvider>
      </TermsOfUseProvider>
    </AuthProvider>
  </ToastProvider>
);

export { Providers };
