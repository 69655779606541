import { v4 } from 'uuid';
import { ChangeEvent, FC, useCallback, useRef, useState } from 'react';
import { FiAlertCircle, FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Textarea } from '@components/elements/Form/Textarea';
import { Row } from '@components/layouts/Grid/Row';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { validation } from './validations';
import { IFile, IFormData } from './interfaces';
import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  InputFileContainer,
  InputFile,
} from './styles';

const CreateNews: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [imagesPreview, setImagesPreview] = useState<string[]>([]);
  const [files, setFiles] = useState<IFile[]>([]);

  const handleImagePreview = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    if (e.target.files.length === 0) {
      return;
    }

    const filesFromInput = e.target.files;

    const arrayFromFiles = Array.from(filesFromInput);

    const validatedFiles = arrayFromFiles.filter(file => {
      const fileExtension = file.name.split('.').reverse()[0];

      if (
        fileExtension !== 'png' &&
        fileExtension !== 'jpg' &&
        fileExtension !== 'jpeg'
      ) {
        return;
      }

      return file;
    });

    const imagesPreviewUrl = validatedFiles.map(file => {
      const url = URL.createObjectURL(file);

      return url;
    });

    setImagesPreview(imagesPreviewUrl);
    setFiles(_ => {
      const parsedFiles = validatedFiles.map(file => {
        return {
          id: v4(),
          file,
        };
      });

      return parsedFiles;
    });
  }, []);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        formRef.current?.setErrors({});

        await validation(data);

        if (files.length === 0) {
          addToast({
            title: 'Solicitação não processada!',
            message: 'Adicione arquivos!',
            type: 'error',
          });
          return;
        }

        const formData = new FormData();

        files.forEach(iFile => {
          const { file } = iFile;

          formData.append('images', file);
        });

        Object.entries(data).forEach(([name, value]) => {
          formData.append(name, value);
        });

        await api.post('/news', formData);

        reset();
        setImagesPreview([]);
        setFiles([]);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              message,
              type: 'error',
            });
          }
        }
      }
    },
    [files, addToast],
  );

  return (
    <>
      <Row>
        <URLPath>
          <li>Notícias</li>
          <li>{'>'}</li>
          <li>Nova</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Nova notícia</h1>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow>
                <InputGroup>
                  <label>Título da notícia</label>
                  <Input name="title" upperCase={false} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup textarea>
                  <label>Descrição da notícia</label>
                  <Textarea name="description" rows={10} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup textarea>
                  <label>Conteúdo da notícia</label>
                  <Textarea name="content" rows={15} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputFileContainer>
                  <div>
                    <label>Imagens da notícia</label>
                    <span>
                      <FiAlertCircle size={18} color="#ffa800" />
                      Tenha preferência por imagens retângulares
                    </span>
                  </div>
                  <InputFile>
                    {imagesPreview.length !== 0 ? (
                      <div>
                        {imagesPreview.map(preview => {
                          return (
                            <img src={preview} alt="Preview" key={preview} />
                          );
                        })}
                      </div>
                    ) : (
                      <div />
                    )}
                    <input
                      type="file"
                      multiple
                      name="images"
                      onChange={handleImagePreview}
                    />
                    {imagesPreview.length === 0 && (
                      <span>Nenhum arquivo selecionado</span>
                    )}
                  </InputFile>
                </InputFileContainer>
              </FormRow>

              <FormRow buttonWrapper>
                <Button type="submit" styleType="success" icon={FiSave}>
                  Salvar
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { CreateNews };
