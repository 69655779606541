import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { FiEye, FiMoreHorizontal, FiPlusCircle, FiTrash } from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';

import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { Dropdown } from '@components/elements/Dropdown';

import api from '@services/bbankApi';

import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IPlatform } from './interfaces';
import { Card, CardHeader, CardContent } from './styles';

export const ListPlatforms: FC = () => {
  const { push } = useHistory();

  const [platforms, setPlatforms] = useState<IPlatform[]>();

  useEffect(() => {
    async function loadPlatforms() {
      const { data } = await api.get<any[]>('/platforms');

      const parsedData = data.map(item =>
        parseObjectPropertiesToCamelCase<IPlatform>(item),
      );

      setPlatforms(parsedData);
    }

    const timer = setTimeout(() => loadPlatforms(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleGoToDetailsPage = useCallback(
    (platformId: string) => {
      push(`/platforms/${platformId}/edit`);
    },
    [push],
  );

  const handleDeletePlatforms = useCallback(async (platformId: string) => {
    await api.delete(`/platforms/${platformId}`);

    setPlatforms(oldState => {
      if (!oldState) {
        return [];
      }

      const updatedState = oldState.filter(
        platform => platform.id !== platformId,
      );

      return updatedState;
    });
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Nome da plataforma',
        selector: 'search',
      },
      {
        name: 'Data de criação',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!platforms) {
      return [];
    }

    const data = platforms.map(platform => {
      return {
        id: platform.id,
        search: platform.name,
        createdAt: format(new Date(platform.createdAt), 'dd/MM/yyyy'),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              icon={FiEye}
              onClick={() => handleGoToDetailsPage(platform.id)}
            />
            <TableButton
              styleType="danger"
              onDoubleClick={() => handleDeletePlatforms(platform.id)}
              title="Clique duas vezes para deletar!"
              icon={FiTrash}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [platforms, handleGoToDetailsPage, handleDeletePlatforms]);

  return (
    <>
      {!platforms ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <Card>
              <CardHeader>
                <h1>Plataformas registradas</h1>

                <Dropdown
                  styles={{ textColor: '#fff', togglerBackground: '#c8c6c0' }}
                  icon={FiMoreHorizontal}
                >
                  <li>
                    <Link to="/platforms/new">
                      <FiPlusCircle />
                      Nova plataforma
                    </Link>
                  </li>
                </Dropdown>
              </CardHeader>

              <CardContent>
                <DataTable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
