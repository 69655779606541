import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 8px;

  cursor: pointer;

  > label {
    cursor: pointer;
  }
`;
