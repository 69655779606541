import { FC, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';

import { Master } from '@components/layouts/Master';
import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';

import api from '@services/bbankApi';

import { URLPath, Card, CardContent, CardHeader } from './styles';

interface ICredit {
  id: string;
  type: string;
  modality: string;
  product: string;
  created_at: string;
  status: string;
}

interface ITableData {
  id: string;
  type: string;
  modality: string;
  product: string;
  search: string;
  status: JSX.Element;
  details: JSX.Element;
}

const AllClientCredits: FC = () => {
  const { push } = useHistory();

  const [credits, setCredits] = useState<Array<ICredit>>([]);

  const tableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'Tipo do crédito',
        selector: 'type',
      },
      {
        name: 'Modalidade de crédito',
        selector: 'modality',
      },
      {
        name: 'Produto',
        selector: 'product',
      },
      {
        name: 'Data da solicitação',
        selector: 'search',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Detalhes',
        selector: 'details',
      },
    ];
  }, []);

  const tableData = useMemo<Array<ITableData>>(() => {
    return credits.map(
      ({ modality, created_at, product, type, status, id }) => {
        let badgeType: 'danger' | 'success' | 'info' | 'warning';

        switch (status) {
          case 'aprovado':
            badgeType = 'success';
            break;

          case 'analise':
            badgeType = 'info';
            break;

          case 'negado':
            badgeType = 'danger';
            break;

          default:
            badgeType = 'warning';
            break;
        }

        function productType(): string {
          if (modality === 'com_garantia') {
            switch (product) {
              case 'property':
                return 'Imóvel';
              default:
                return 'Veículo';
            }
          }

          if (type === 'PJ') {
            return 'BNDES';
          }

          return 'CredLuz';
        }

        return {
          id,
          modality:
            modality === 'com_garantia' ? 'Com garantia' : 'Sem garantia',
          product: productType(),
          type,
          search: format(new Date(created_at), 'dd/MM/yyyy'),
          status: <Badge type={badgeType}>{status}</Badge>,
          details: (
            <Button
              styleType="success"
              onClick={() => push(`/client/credit/${id}/details`)}
            >
              Ver detalhes
            </Button>
          ),
        };
      },
    );
  }, [credits, push]);

  useEffect(() => {
    async function loadClientCredits() {
      const { data } = await api.get('/client-credits');

      setCredits(data.credits);
    }

    loadClientCredits();
  }, []);

  return (
    <Master>
      <Row>
        <URLPath>
          <li>Créditos</li>
          <li>{'>'}</li>
          <li>Acompanhamento</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Meus créditos</h1>
          </CardHeader>

          <CardContent>
            <DataTable
              columns={tableColumns}
              data={tableData}
              filterPlaceholder="Filtrar por data"
            />
          </CardContent>
        </Card>
      </Row>
    </Master>
  );
};

export { AllClientCredits };
