import { banks } from '@helpers/getBanks';
import { states } from '@helpers/getAllStates';

interface IUser {
  name: string;
  id: string;
}

type IReturnType = Array<{ value: string; label: string }>;

export const loadUsers = (users: IUser[]): IReturnType => {
  return users.map(user => ({
    value: user.id,
    label: user.name,
  }));
};

export const loadBanks = (): IReturnType => {
  return banks.map(bank => {
    const bankSpliced = bank.split(' - ');

    return {
      value: bankSpliced[0],
      label: bankSpliced[1],
    };
  });
};

export const loadStates = (): IReturnType => {
  return Object.entries(states).map(item => {
    const initials = item[0];
    const stateName = item[1];

    return {
      value: initials,
      label: stateName,
    };
  });
};

export const creditProducts = [
  { value: 'sem_garantia', label: 'Sem garantia' },
  { value: 'com_garantia', label: 'Com garantia' },
];

export const maritalStatusOptions = [
  { value: 'casado', label: 'Casado' },
  { value: 'solteiro', label: 'Solteiro' },
];

export const propertyType = [
  { value: 'comercial', label: 'Comercial' },
  { value: 'residencial', label: 'Residencial' },
  { value: 'misto', label: 'Misto' },
];

export const ownPropertyOptions = [
  { value: '1', label: 'Sim' },
  { value: '0', label: 'Não' },
];
