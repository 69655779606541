interface IMaskHandler {
  mask: IMaskTypes;
  payload: string;
}

type IMaskTypes = 'cpf' | 'cnpj' | 'phone' | 'cellphone' | 'date' | 'cep';

export function maskHandler({ payload, mask }: IMaskHandler): string {
  const masks = {
    cpf: {
      regEx: /(\d{3})?(\d{3})?(\d{3})?(\d{2})/,
      replaceString: '$1.$2.$3-$4',
    },
    cnpj: {
      regEx: /(\d{2})?(\d{3})?(\d{3})(\d{4})?(\d{2})/,
      replaceString: '$1.$2.$3/$4-$5',
    },
    phone: {
      regEx: /(\d{2})?(\d{4})?(\d{4})/,
      replaceString: '($1) $2-$3',
    },
    cellphone: {
      regEx: /(\d{2})?(\d{5})?(\d{4})/,
      replaceString: '($1) $2-$3',
    },
    date: {
      regEx: /(\d{2})?(\d{2})?(\d{4})/,
      replaceString: '$1/$2/$3',
    },
    cep: {
      regEx: /(\d{5})?(\d{3})/,
      replaceString: '$1-$2',
    },
  };

  const { regEx, replaceString } = masks[mask];

  return payload.replace(regEx, replaceString);
}
