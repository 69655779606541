import styled from 'styled-components';

export const Container = styled.footer`
  max-width: 1340px;
  margin: 0 auto;
  padding: 30px 25px;
  padding-bottom: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;

    span {
      font-size: 1.1rem;
      color: #3f4254;

      &:nth-child(1) {
        display: block;

        color: #b5b5c3;
        font-weight: 600;

        margin-right: 8px;
      }
    }

    ul {
      list-style: none;

      display: flex;
      align-items: center;

      li {
        padding: 0 20px;

        color: #b5b5c3;
        font-size: 1.1rem;

        &:nth-last-child(1) {
          padding-right: 0;
        }

        a {
          color: inherit;
          text-decoration: inherit;

          &:hover {
            color: #762772;
            text-decoration: underline;
          }
        }
      }
    }
  }
`;
