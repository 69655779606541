import { IOption } from '@utils/interfaces';

import { IPlatform, IDepartment } from './interfaces';

export const getPlatformsOptions = (data: IPlatform[]): IOption[] => {
  const options = data.map(platform => ({
    value: platform.id,
    label: platform.name,
  }));

  return options;
};

export const getDepartmentsOptions = (data: IDepartment[]): IOption[] => {
  const options = data.map(department => ({
    value: department.id,
    label: department.name,
  }));

  return options;
};
