import { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FiEye } from 'react-icons/fi';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { URLPath } from '@components/layouts/UrlPath';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { IClient, IParams, ISeller } from './interfaces';
import { Card, CardHeader, CardContent } from './styles';

const ListSellerIndications: FC = () => {
  const { params } = useRouteMatch<IParams>();
  const { push } = useHistory();
  const { user } = useAuth();

  const [sellerClients, setSellerClients] = useState<IClient[]>();
  const [seller, setSeller] = useState<ISeller>();

  useEffect(() => {
    async function loadSeller() {
      const { data } = await api.get(`/users/${params.sellerId}`);

      setSeller(data);
    }

    async function loadSellerIndications() {
      const { data } = await api.get(`/users-indicated/${params.sellerId}`);

      setSellerClients(data);
    }

    const timer = setTimeout(async () => {
      await loadSeller();
      loadSellerIndications();
    }, 1500);

    return () => clearInterval(timer);
  }, [params.sellerId]);

  const handleNavigationToClientDetails = useCallback(
    (clientId: string) => {
      const { role } = user;

      if (!role) {
        return;
      }

      push(
        `/${
          role === 'master' ? 'master' : 'business'
        }/clients/${clientId}/edit`,
      );
    },
    [push, user],
  );

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'Nome do cliente',
        selector: 'search',
      },
      {
        name: 'Email do cliente',
        selector: 'email',
      },
      {
        name: 'Status do cliente',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!sellerClients) {
      return [];
    }

    const data = sellerClients.map(client => {
      return {
        id: client.id,
        search: client.name,
        email: client.email,
        status: (
          <Badge type={client.status === 'ativo' ? 'success' : 'warning'}>
            {client.status === 'ativo' ? 'Ativo' : 'Pendente'}
          </Badge>
        ),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              onClick={() => handleNavigationToClientDetails(client.id)}
              title="Visualizar cliente"
              icon={FiEye}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [sellerClients, handleNavigationToClientDetails]);

  return (
    <>
      {!sellerClients ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={['Vendedores', seller?.name || 'Vendedor', 'Clientes']}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Clientes do vendedor</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por nome..."
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListSellerIndications };
