import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const shape = Yup.object().shape({
    creditLimit: Yup.number()
      .typeError('Insira um número')
      .required('Preencha este campo!'),
    userId: Yup.string().required('Preencha este campo!').uuid('Inválido'),
    document: Yup.string().required('Preencha este campo!'),
    indicationId: Yup.string()
      .required('Preencha este campo!')
      .uuid('Inválido'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
