import { states } from '@helpers/getAllStates';

export const statesOption = Object.entries(states).map(item => ({
  value: item[0],
  label: item[1],
}));

export const personTypesOptons = [
  { value: 'PF', label: 'Pessoa fisica' },
  { value: 'PJ', label: 'Pessoa jurídica' },
];
