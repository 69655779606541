import {
  FC,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
  useMemo,
} from 'react';
import { useRouteMatch } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import format from 'date-fns/format';

import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Button } from '@components/elements/Button';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { Badge } from '@components/elements/Badge';
import { Aside } from '@components/layouts/Aside';
import { CardMenuContainer } from '@components/layouts/CardMenuContainer';
import { Footer } from '@components/layouts/Footer';
import { Header } from '@components/layouts/Header';
import { Main } from '@components/layouts/Main';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';

import { IZipcode } from '@utils/interfaces';

import { removeInputMask } from '@helpers/removeInputMask';
import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { parseObjectPropertiesToCamelCase } from '@helpers/parseObjectPropertiesToCamelCase';

import { IFinancing, IParams, IFormData } from './interfaces';
import { Card, CardContent, CardHeader, URLPath } from './styles';
import {
  banksOptions,
  deadlineOptions,
  statesOptions,
  maritalStatusOptions,
} from './selectOptions';
import { formValidation } from './validations';

export const FinancingDetails: FC = () => {
  const { params } = useRouteMatch<IParams>();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [financing, setFinancing] = useState<IFinancing>();

  useEffect(() => {
    async function getFinancing() {
      const { data } = await api.get(
        `/financing/property/${params.financingId}`,
        {
          params: {
            relations: ['indication'],
          },
        },
      );

      const parsedData = parseObjectPropertiesToCamelCase(data) as any;

      setFinancing(parsedData);
    }

    const timer = setTimeout(() => getFinancing(), 1500);

    return () => clearInterval(timer);
  }, [params.financingId]);

  const handleFindZipcode = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const { data: zipcodeData } = await viaCepApi.get<IZipcode>(
        `/${value}/json`,
      );

      if (zipcodeData.erro) {
        return;
      }

      const addressInputRef = formRef.current?.getFieldRef('propertyAddress');
      const numberAddressInputRef = formRef.current?.getFieldRef(
        'propertyAddressNumber',
      );
      const cityInputRef = formRef.current?.getFieldRef('propertyCity');
      // const stateInputRef = formRef.current?.getFieldRef('propertyState');

      // const stateValue = statesOptions.find(
      //   item => item.value === zipcodeData.uf,
      // );

      addressInputRef.value = zipcodeData.logradouro;
      cityInputRef.value = zipcodeData.localidade;
      // stateInputRef.select.setValue(stateValue);
      numberAddressInputRef.focus();
    },
    [],
  );

  const handleFormSubmit = useCallback(
    async (data: IFormData) => {
      try {
        formRef.current?.setErrors({});

        const initialData = data;

        delete initialData.indication;

        await formValidation(initialData);

        const valuesWithoutMask = removeInputMask();

        const parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, initialData);

        const { data: financingUpdated } = await api.put(
          `/financing/property/${params.financingId}`,
          parsedData,
        );

        const parsedResponse = parseObjectPropertiesToCamelCase(
          financingUpdated,
        ) as any;

        addToast({
          title: 'Muito bom!',
          message: 'Os dados foram atualizados com sucesso!',
          type: 'success',
        });

        setFinancing(parsedResponse);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [addToast, params.financingId],
  );

  const getBadgeType = useCallback((status: string):
    | 'success'
    | 'danger'
    | 'warning'
    | 'info' => {
    let badgeType: 'success' | 'danger' | 'warning' | undefined;

    switch (status) {
      case 'aprovado':
        badgeType = 'success';
        break;

      case 'negado':
        badgeType = 'danger';
        break;

      default:
        badgeType = 'warning';
        break;
    }

    return badgeType;
  }, []);

  const parsedStatusText = useMemo(() => {
    return {
      analise: 'Em análise',
      negado: 'Negado',
      aprovado: 'Aprovado',
      pendente: 'Pendente',
      pre_aprovado: 'Pré aprovado',
    };
  }, []);

  return (
    <>
      <Header />

      <Aside />

      <Main>
        <CardMenuContainer />

        {!financing ? (
          <LoadingPage />
        ) : (
          <>
            <Row>
              <URLPath>
                <li>Cred Cash</li>
                <li>{'>'}</li>
                <li>Financiamentos</li>
                <li>{'>'}</li>
                <li>Imóveis</li>
                <li>{'>'}</li>
                <li>Detalhes do financiamento</li>
              </URLPath>
            </Row>

            <Row>
              <Card>
                <CardHeader>
                  <h1>Detalhes de um financiamento de imóveis</h1>

                  <Badge type={getBadgeType(financing.status)}>
                    {parsedStatusText[financing.status]}
                  </Badge>
                </CardHeader>

                <CardContent>
                  <Form
                    onSubmit={handleFormSubmit}
                    ref={formRef}
                    initialData={financing}
                  >
                    <FormRow separator>
                      <h1>Dados básicos</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Franqueado responsável</label>
                        <Input readOnly name="indication.name" />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Estado civil</label>
                        <Select
                          name="maritalStatus"
                          readonly={financing.status !== 'pendente'}
                          options={maritalStatusOptions}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Data de casamento</label>
                        <InputMask
                          mask="99/99/9999"
                          noUnmask
                          name="weddingDate"
                          readOnly={financing.status !== 'pendente'}
                          defaultValue={format(
                            new Date(financing.weddingDate),
                            'dd/MM/yyyy',
                          )}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Número do RG (registro geral)</label>
                        <Input
                          name="generalRegistry"
                          readOnly={financing.status !== 'pendente'}
                          maxLength={255}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Data de expedição do RG (registro geral)</label>
                        <InputMask
                          mask="99/99/9999"
                          noUnmask
                          name="generalRegistryExpeditionDate"
                          readOnly={financing.status !== 'pendente'}
                          defaultValue={format(
                            new Date(financing.generalRegistryExpeditionDate),
                            'dd/MM/yyyy',
                          )}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Órgão expeditor</label>
                        <Input
                          name="dispatchingAgency"
                          readOnly={financing.status !== 'pendente'}
                          maxLength={255}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Naturalidade</label>
                        <Input
                          name="naturality"
                          readOnly={financing.status !== 'pendente'}
                          maxLength={255}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Nome da mãe</label>
                        <Input
                          name="motherName"
                          readOnly={financing.status !== 'pendente'}
                          maxLength={255}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Dados trabalhistas</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Renda bruta</label>
                        <InputCurrency
                          name="grossIncome"
                          readOnly={financing.status !== 'pendente'}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Profissão</label>
                        <Input
                          name="profession"
                          readOnly={financing.status !== 'pendente'}
                          maxLength={255}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Nome da empresa em que trabalha</label>
                        <Input
                          name="companyName"
                          readOnly={financing.status !== 'pendente'}
                          maxLength={255}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>CNPJ da empresa em que trabalha</label>
                        <Input
                          name="companyDocument"
                          readOnly={financing.status !== 'pendente'}
                          maxLength={14}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Data de admissão na empresa</label>
                        <InputMask
                          name="admissionDate"
                          readOnly={financing.status !== 'pendente'}
                          mask="99/99/9999"
                          noUnmask
                          defaultValue={format(
                            new Date(financing.admissionDate),
                            'dd/MM/yyyy',
                          )}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Telefone da empresa em que trabalha</label>
                        <InputMask
                          mask="(99) 9999-9999"
                          name="companyPhoneNumber"
                          readOnly={financing.status !== 'pendente'}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Email da empresa em que trabalha</label>
                        <Input
                          name="companyEmailAddress"
                          readOnly={financing.status !== 'pendente'}
                          upperCase={false}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Banco</label>
                        <Select
                          name="bank"
                          readonly={financing.status !== 'pendente'}
                          options={banksOptions}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Informações do financiamento</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Valor do imóvel</label>
                        <InputCurrency
                          name="propertyValue"
                          readOnly={financing.status !== 'pendente'}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Valor do financiamento</label>
                        <InputCurrency
                          name="financingValue"
                          readOnly={financing.status !== 'pendente'}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Prazo</label>
                        <Select
                          name="deadline"
                          readonly={financing.status !== 'pendente'}
                          options={deadlineOptions}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Localização do imóvel</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>CEP</label>
                        <InputMask
                          mask="99999-999"
                          name="propertyZipcode"
                          readOnly={financing.status !== 'pendente'}
                          onBlur={handleFindZipcode}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Endereço</label>
                        <Input
                          name="propertyAddress"
                          readOnly={financing.status !== 'pendente'}
                          maxLength={255}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Número (insira 0 caso não exista)</label>
                        <Input
                          name="propertyAddressNumber"
                          readOnly={financing.status !== 'pendente'}
                          type="number"
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Cidade</label>
                        <Input
                          name="propertyCity"
                          readOnly={financing.status !== 'pendente'}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Estado</label>
                        <Select
                          name="propertyState"
                          readonly={financing.status !== 'pendente'}
                          options={statesOptions}
                        />
                      </InputGroup>
                    </FormRow>

                    {financing.status === 'pendente' && (
                      <FormRow buttonWrapper>
                        <Button styleType="success" type="submit">
                          Atualizar financiamento
                        </Button>
                      </FormRow>
                    )}
                  </Form>
                </CardContent>
              </Card>
            </Row>
          </>
        )}
      </Main>

      <Footer />
    </>
  );
};
