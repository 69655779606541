import { FC, useCallback, useState, useRef } from 'react';
import { FiSave } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

import { Button } from '@components/elements/Button';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputMask } from '@components/elements/Form/InputMask';
import { Row } from '@components/layouts/Grid/Row';
import { URLPath } from '@components/layouts/UrlPath';
import { Select } from '@components/elements/Form/Select';

import { useToast } from '@hooks/toast';
import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeInputMask } from '@helpers/removeInputMask';

import { Card, CardContent, CardHeader } from './styles';
import { IFormData } from './interfaces';
import { formValidation } from './validations';
import { sponsorsNumberOptions } from './selectOptions';

export const SellerNewConsignedProspect: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        setLoading(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        const dataWithoutMask = removeInputMask();

        const parsedData = dataWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, data);

        parsedData.indicationId = user.id;

        await api.post('/consigned/prospects', parsedData);

        reset();

        addToast({
          title: 'Muito bom!',
          message: 'Os dados foram enviados com sucesso!',
          type: 'success',
        });
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, user.id],
  );

  return (
    <>
      <Row>
        <URLPath paths={['Cred Cash', 'Consignado', 'Novo']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Novo consignado</h1>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow separator>
                <h1>Dados</h1>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Documento</label>
                  <InputMask mask="99.999.999/9999-99" name="document" />
                </InputGroup>

                <InputGroup>
                  <label>Site da empresa</label>
                  <Input name="companyWebsite" upperCase={false} />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Nome do contato do RH</label>
                  <Input name="humanResourcesContactName" />
                </InputGroup>

                <InputGroup>
                  <label>Email do RH</label>
                  <Input type="email" name="humanResourcesContactEmail" />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Telefone do contato do RH</label>
                  <InputMask
                    mask="(99) 99999-9999"
                    name="humanResourcesPhone"
                  />
                </InputGroup>

                <InputGroup>
                  <label>Número de colaboradores</label>
                  <Select
                    name="sponsorsNumber"
                    options={sponsorsNumberOptions}
                  />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button styleType="success" icon={FiSave} loading={loading}>
                  Enviar
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};
