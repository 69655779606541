import { FC, useEffect, useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiDownload, FiEye } from 'react-icons/fi';

import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { DataTable } from '@components/elements/Datatable';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';

import { useAuth } from '@hooks/auth';

import api from '@services/bbankApi';

import { Card, CardContent, CardHeader } from './styles';
import { IFinancing } from './interfaces';

export const ListPropertyFinancing: FC = () => {
  const { user } = useAuth();
  const { push } = useHistory();

  const [financing, setFinancing] = useState<IFinancing[]>();

  useEffect(() => {
    async function getFinancing() {
      const { data } = await api.get(`/financing/property`, {
        params: {
          relations: ['user', 'indication'],
        },
      });

      setFinancing(data);
    }

    const timer = setTimeout(() => getFinancing(), 1500);

    return () => clearInterval(timer);
  }, [user.id]);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Cliente',
        selector: 'search',
      },
      {
        name: 'Indicação',
        selector: 'indication',
      },
      {
        name: 'Registro geral (RG)',
        selector: 'generalRegistry',
      },
      {
        name: 'Documento',
        selector: 'document',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handlePushToDetailsPage = useCallback(
    (financingId: string) => {
      push(`/cred-cash/financing/property/${financingId}/details`);
    },
    [push],
  );

  const handlePushToFileListPage = useCallback(
    (financingId: string) => {
      push(`/cred-cash/financing/property/${financingId}/files`);
    },
    [push],
  );

  const parsedStatusText = useMemo(() => {
    return {
      analise: 'Em análise',
      negado: 'Negado',
      aprovado: 'Aprovado',
      pendente: 'Pendente',
      pre_aprovado: 'Pré aprovado',
    };
  }, []);

  const tableData = useMemo(() => {
    if (!financing) {
      return [];
    }

    const data = financing.map(item => {
      let badgeType: 'success' | 'danger' | 'warning' | undefined;

      switch (item.status) {
        case 'aprovado':
          badgeType = 'success';
          break;

        case 'negado':
          badgeType = 'danger';
          break;

        default:
          badgeType = 'warning';
          break;
      }

      return {
        id: item.id,
        search: item.user.name,
        indication: item.indication.name,
        generalRegistry: item.general_registry,
        document: item.user.document,
        status: <Badge type={badgeType}>{parsedStatusText[item.status]}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              icon={FiEye}
              styleType="success"
              onClick={() => handlePushToDetailsPage(item.id)}
              title="Visualizar dados"
            />

            <TableButton
              icon={FiDownload}
              styleType="info"
              title="Ir até a lista de arquivos"
              onClick={() => handlePushToFileListPage(item.id)}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [
    financing,
    handlePushToDetailsPage,
    parsedStatusText,
    handlePushToFileListPage,
  ]);

  return (
    <>
      {!financing ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={[
                'Cred Cash',
                'Financiamentos',
                'Imóveis',
                'Financiamentos solicitados',
              ]}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Financiamentos totais: </h1>

                <Badge type="info">{financing.length}</Badge>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar pelo nome do cliente"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};
