import { HTMLAttributes, forwardRef } from 'react';

import { Container } from './styles';

interface IFormRowProps extends HTMLAttributes<HTMLDivElement> {
  separator?: boolean;
  buttonWrapper?: boolean;
}

const FormRow = forwardRef<HTMLDivElement, IFormRowProps>(
  ({ separator, buttonWrapper, children, ...rest }, ref) => {
    return (
      <Container
        {...rest}
        separator={separator}
        buttonWrapper={Number(buttonWrapper)}
        ref={ref}
      >
        {children}
      </Container>
    );
  },
);

export { FormRow };
