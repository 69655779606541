import { IProspect } from './interfaces';

interface IOption {
  value: string;
  label: string;
}

export const questionOne = [
  { label: 'Excelente', value: '1' },
  { label: 'Boa', value: '3' },
  { label: 'Necessita de cuidados', value: '5' },
];

export const questionTwo = [
  { label: 'Sim', value: '5' },
  { label: 'Não', value: '1' },
];

export const questionFour = [
  { label: 'Uma vez a cada 3 meses', value: '5' },
  { label: 'Uma vez a cada 6 meses', value: '3' },
  { label: 'Uma vez por ano', value: '1' },
  { label: 'Somente quando necessário', value: '1' },
];

export const questionFive = [
  { label: 'Excelente', value: '1' },
  { label: 'Bom', value: '3' },
  { label: 'Ruim', value: '5' },
  { label: 'Péssimo', value: '5' },
];

export const questionSix = [
  { label: 'Sim ', value: '-10' },
  { label: 'Não', value: '5' },
  { label: 'Já possui', value: '1' },
];

export const questionTen = [
  { label: 'De R$ 50,00 a R$ 100,00', value: '5' },
  { label: 'De R$ 100,00 a R$ 300,00', value: '5' },
  { label: 'De R$ 300,00 a R$ 1000,00', value: '5' },
  { label: 'Acima de R$ 1000,00', value: '5' },
];

export const getProspectsOptions = (prospects: IProspect[]): IOption[] => {
  return prospects.map(prospect => ({
    value: prospect.id,
    label: prospect.name,
  }));
};
