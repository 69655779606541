import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiBookOpen,
  FiCornerDownRight,
  FiSave,
} from 'react-icons/fi';
import * as Yup from 'yup';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { Input } from '@components/elements/Form/Input';
import { FormRow } from '@components/elements/Form/FormRow';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';

import { useToast } from '@hooks/toast';

import bbankApi from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { getFaqCategoriesOptions } from './selectOptions';

interface IFaqCategories {
  id: string;
  title: string;
}

interface IFaqFormData {
  categoryTitle: string;
  title: string;
  content: string;
  subCategory: string;
}

const NewFaq: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [faqCategories, setFaqCategories] = useState<IFaqCategories[]>([]);
  const [loading, setLoading] = useState(false);

  const handleFormSubmit = useCallback(
    async (data: IFaqFormData, { reset }) => {
      setLoading(true);

      try {
        formRef.current?.setErrors({});

        const shape = Yup.object().shape({
          categoryTitle: Yup.string().required('Escolha um item da lista'),
          title: Yup.string().required('Preencha este campo!'),
          content: Yup.string().required('Preencha este campo!'),
          subCategory: Yup.string().required('Preencha este campo!'),
        });

        await shape.validate(data, {
          abortEarly: false,
        });

        await bbankApi.post('/faqs', data);

        addToast({
          title: 'Sucesso!',
          type: 'success',
          message: 'FAQ cadastrada com sucesso',
        });

        reset();
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        const { status, message } = getClientErrors(err.response);

        addToast({
          title: `[${status}] Algum erro aconteceu`,
          type: 'error',
          message,
        });
      } finally {
        setLoading(false);
      }
    },
    [addToast],
  );

  useEffect(() => {
    async function loadCategories() {
      const { data } = await bbankApi.get<Array<IFaqCategories>>(
        '/faq-categories',
      );

      setFaqCategories(oldState => {
        const newState = data.map(item => {
          return {
            title: item.title,
            id: item.id,
          };
        });

        return [...oldState, ...newState];
      });
    }

    loadCategories();
  }, []);

  const faqCategoriesOptions = useMemo(() => {
    return getFaqCategoriesOptions(faqCategories);
  }, [faqCategories]);

  return (
    <>
      <Row>
        <URLPath>
          <li>FAQ</li>

          <li>&gt;</li>

          <li>Novo</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Nova FAQ</h1>
          </CardHeader>

          <CardContent>
            <Form ref={formRef} onSubmit={handleFormSubmit} noValidate>
              <FormRow>
                <Select
                  name="categoryTitle"
                  placeholder="Escolha uma categoria"
                  icon={FiBookOpen}
                  options={faqCategoriesOptions}
                />

                <Input
                  name="subCategory"
                  icon={FiCornerDownRight}
                  placeholder="Sub categoria"
                  upperCase={false}
                />
              </FormRow>

              <FormRow>
                <Input
                  name="title"
                  icon={FiAlertCircle}
                  placeholder="Título"
                  upperCase={false}
                />
              </FormRow>

              <FormRow>
                <Input
                  name="content"
                  icon={FiCheckCircle}
                  placeholder="Conteúdo"
                  upperCase={false}
                />
              </FormRow>

              <FormRow buttonWrapper>
                <Button
                  loading={loading}
                  type="submit"
                  icon={FiSave}
                  styleType="success"
                >
                  Cadastrar
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { NewFaq };
