import styled, { css } from 'styled-components';

interface IProgressBarDot {
  afterWidth?: string;
}

export const ProgressBar = styled.div`
  height: 4px;

  background-color: #e6fffa;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
`;

export const ProgressBarDot = styled.div<IProgressBarDot>`
  width: 15px;
  height: 15px;

  background-color: #2e656a;

  font-size: 14px;

  border-radius: 50%;

  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 11px;

  position: relative;

  &::after {
    content: '';

    position: absolute;

    height: 4px;

    ${props =>
      props.afterWidth &&
      css`
        width: calc(${props.afterWidth || 0}px - 22px);
      `}

    left: 100%;
    top: 50%;

    transform: translateY(-50%);

    transition: width 0.4s;

    background-color: #2e656a;
  }
`;
