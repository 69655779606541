import { banks } from '@helpers/getBanks';

export const banksOptions = banks.map(bank => {
  const bankName = bank.split(' - ')[1];

  return {
    value: bank,
    label: bankName,
  };
});
