interface IOption {
  value: string;
  label: string;
}

interface IUser {
  id: string;
  name: string;
}

export const getUsersOptions = (users: IUser[]): IOption[] => {
  const options = users.map(user => ({ value: user.id, label: user.name }));

  return options;
};
