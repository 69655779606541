import { toCamelCase } from './toCamelCase';

export function parseObjectPropertiesToCamelCase<T>(
  object: Record<string, any>,
): T {
  const parsedResponse = Object.entries(object).reduce((acc, [name, value]) => {
    const parsedProperty = toCamelCase(name);

    acc[parsedProperty] = value;

    return acc;
  }, {} as any);

  return parsedResponse as T;
}
