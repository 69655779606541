import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { Badge } from '@components/elements/Badge';
import { Button } from '@components/elements/Button';

import api from '@services/bbankApi';

import { URLPath, Card, CardHeader, CardContent } from './styles';

interface ICredit {
  id: string;
  cnpj?: string;
  status: string;
  created_at: string;
  name_fantasy: string;
  type: string;
  owner: {
    document: string;
    name: string;
  };
}

interface ICreditFile {
  credit: ICredit;
}

interface ITableData {
  search: string;
  name: string;
  created_at: string;
  status: JSX.Element;
  type: JSX.Element;
  actions: JSX.Element;
}

const DownloadCredCashFiles: FC = () => {
  const { push } = useHistory();

  const [credits, setCredits] = useState<ICredit[]>([]);

  // Load credits with files from API
  useEffect(() => {
    async function loadFiles() {
      const parsedData: Array<ICredit> = [];

      const { data: files } = await api.get<Array<ICreditFile>>(
        '/credit-files',
      );

      files.map(file => {
        const findFile = parsedData.find(find => find.id === file.credit.id);

        if (findFile) {
          return;
        }

        return parsedData.push(file.credit);
      });

      setCredits(parsedData);
    }

    loadFiles();
  }, []);

  // Table columns
  const tableColumns = useMemo<Array<TableColumn>>(() => {
    return [
      {
        name: 'CPF/CNPJ',
        selector: 'search',
      },
      {
        name: 'Nome',
        selector: 'name',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Data da solicitação',
        selector: 'created_at',
      },
      {
        name: 'Tipo',
        selector: 'type',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  // Handle switch of pages
  const handleNavigationToFilesList = useCallback(
    (creditId: string) => {
      push(`/credit/${creditId}/files`);
    },
    [push],
  );

  // Table data
  const tableData = useMemo<Array<ITableData>>(() => {
    return credits.map(credit => {
      let badgeType: 'warning' | 'success' | 'danger' | 'info';

      switch (credit.status) {
        case 'pendente':
          badgeType = 'warning';
          break;

        case 'aprovado':
          badgeType = 'success';
          break;

        case 'negado':
          badgeType = 'danger';
          break;

        default:
          badgeType = 'info';
          break;
      }

      return {
        search: credit.cnpj || credit.owner.document,
        status: <Badge type={badgeType}>{credit.status}</Badge>,
        created_at: format(new Date(credit.created_at), 'dd/MM/yyyy'),
        name: credit.name_fantasy || credit.owner.name,
        type: (
          <>
            {credit.type === 'PF' ? (
              <Badge type="info">{credit.type}</Badge>
            ) : (
              <Badge type="success">{credit.type}</Badge>
            )}
          </>
        ),
        actions: (
          <Button
            styleType="success"
            onClick={() => handleNavigationToFilesList(credit.id)}
          >
            Ver arquivos
          </Button>
        ),
      };
    });
  }, [credits, handleNavigationToFilesList]);

  // Page
  return (
    <>
      <Row>
        <URLPath>
          <li>Download</li>
          <li>{'>'}</li>
          <li>CredCash</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Créditos que possuem arquivos</h1>
          </CardHeader>

          <CardContent>
            <DataTable
              filterPlaceholder="Filtar por CPF/CNPJ"
              data={tableData}
              columns={tableColumns}
            />
          </CardContent>
        </Card>
      </Row>
    </>
  );
};

export { DownloadCredCashFiles };
