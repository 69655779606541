import styled, { css } from 'styled-components';

interface ITableButton {
  styleType: 'warning' | 'danger' | 'info' | 'success';
}

const buttonTypes = {
  success: css`
    color: #408401;
    background-color: #cbffc0;

    &:hover {
      color: #fff;
      background-color: #408401;
    }
  `,

  danger: css`
    color: #f64e60;
    background-color: #ffe2e5;

    &:hover {
      color: #fff;
      background-color: #f64e60;
    }
  `,

  info: css`
    color: #8950fc;
    background-color: #eee5ff;

    &:hover {
      color: #fff;
      background-color: #8950fc;
    }
  `,

  warning: css`
    color: #ffa800;
    background-color: #fff4de;

    &:hover {
      color: #fff;
      background-color: #ffa800;
    }
  `,
};

export const Card = styled.div`
  width: 100%;

  border: none;
  border-radius: 10px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 5%);

  background-color: white;
`;

export const CardHeader = styled.div`
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 30px;

  border-bottom: 1px solid #ebedf3;

  > h1 {
    color: #181c32;
    font-size: 25px;
  }

  > a {
    text-decoration: none;
    border-radius: 10px;

    font-weight: 700;
  }
`;

export const CardContent = styled.div`
  padding: 30px;
`;

export const TableButton = styled.button<ITableButton>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  transition: background-color 0.2s, color 0.2s;

  ${props => buttonTypes[props.styleType]}
`;

export const TableButtonContainer = styled.div`
  width: 200px;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;
