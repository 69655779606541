import { IDepartment } from './interfaces';

interface IOption {
  value: string;
  label: string;
}

export const getDepartmentsOptions = (
  departments: IDepartment[],
): IOption[] => {
  const options = departments.map(department => {
    return {
      value: department.id,
      label: department.name,
    };
  });

  return options;
};

export const platformOptions = [
  { value: 'BBankPay', label: 'BbankPay' },
  { value: 'CredCash', label: 'CredCash' },
];
