import { FC, useCallback, useMemo, useState, Fragment, useRef } from 'react';
import { FiMinusCircle, FiPlusCircle, FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';
import { v4 } from 'uuid';

import { Form } from '@components/elements/Form';
import { Master } from '@components/layouts/Master';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputMask } from '@components/elements/Form/InputMask';
import { Select } from '@components/elements/Form/Select';
import { Button } from '@components/elements/Button';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getClientErrors } from '@helpers/getClientErrors';
import { getValidationErrors } from '@helpers/getValidationErrors';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  DependentsContainer,
  RemoveDependentButton,
} from './styles';
import { maritalStatusOptions } from './selectOptions';
import { IBbankConnectDependent, IBbankConnectData } from './interfaces';
import { bbankConnectValidation } from './validations';

const ClientNewTelemedicine: FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [dependents, setDependents] = useState<JSX.Element[]>([]);
  const [price, setPrice] = useState(39.9);

  const handleRemoveDependents = useCallback((dependentFormKey: string) => {
    setPrice(oldState => {
      return oldState - 29.9;
    });
    setDependents(oldState => {
      const updatedDependentsForm = oldState.filter(
        item => item.key !== dependentFormKey,
      );

      return updatedDependentsForm;
    });
  }, []);

  const bbankConnectPriceObject = useMemo(() => {
    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const priceFormatted = numberFormat(price);

    const priceFieldRef = formRef.current?.getFieldRef(
      'telemedicine-paymentValue',
    );

    if (priceFieldRef) {
      priceFieldRef.value = priceFormatted;
    }

    return {
      priceFormatted,
      price: price.toFixed(2),
    };
  }, [price]);

  const getDependentsForm = useCallback(() => {
    const key = v4();

    return (
      <Fragment key={key}>
        <FormRow separator>
          <h1>
            Dependente&nbsp;
            {dependents.length + 1}
          </h1>

          <RemoveDependentButton
            onClick={() => handleRemoveDependents(key)}
            type="button"
          >
            <FiMinusCircle size={20} />
            Remover
          </RemoveDependentButton>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>CPF</label>
            <InputMask
              name={`dependents-cpf[${dependents.length}]`}
              mask="99999999999"
            />
          </InputGroup>

          <InputGroup>
            <label>Nome</label>
            <Input name={`dependents-name[${dependents.length}]`} />
          </InputGroup>

          <InputGroup>
            <label>Sexo</label>
            <Input name={`dependents-sex[${dependents.length}]`} />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Data de nascimento</label>
            <InputMask
              name={`dependents-birthday[${dependents.length}]`}
              mask="99/99/9999"
              noUnmask
            />
          </InputGroup>

          <InputGroup>
            <label>Estado civil</label>
            <Select
              name={`dependents-maritalStatus[${dependents.length}]`}
              options={maritalStatusOptions}
            />
          </InputGroup>

          <InputGroup>
            <label>Relação como dependente</label>
            <Input name={`dependents-relationship[${dependents.length}]`} />
          </InputGroup>
        </FormRow>

        <FormRow>
          <InputGroup>
            <label>Número do cartão nacional de saúde</label>
            <Input
              name={`dependents-nationalHealthCard[${dependents.length}]`}
            />
          </InputGroup>

          <InputGroup>
            <label>Nome da mãe</label>
            <Input name={`dependents-motherName[${dependents.length}]`} />
          </InputGroup>
        </FormRow>
      </Fragment>
    );
  }, [dependents, handleRemoveDependents]);

  const handleAddDependents = useCallback(() => {
    const dependentsForm = getDependentsForm();

    setPrice(oldState => {
      return oldState + 29.9;
    });
    setDependents(oldState => {
      return [...oldState, dependentsForm];
    });
  }, [getDependentsForm]);

  const handleFormSubmit = useCallback(
    async (data, { reset }) => {
      try {
        const bbankConnectData: IBbankConnectData = {};
        const bbankConnectDependents: IBbankConnectDependent[] = [];

        await bbankConnectValidation(data);

        Object.entries(data).forEach(([index, value]) => {
          if (index.search('bbankConnect-') >= 0) {
            const parsedIndex = index.replace('bbankConnect-', '');

            bbankConnectData[parsedIndex] = value as string;
          }
        });

        dependents.forEach((_, index) => {
          const bbankConnectDependent = {
            birthday: data[`dependents-birthday`][index],
            cpf: data[`dependents-cpf`][index],
            maritalStatus: data[`dependents-maritalStatus`][index],
            motherName: data[`dependents-motherName`][index],
            name: data[`dependents-name`][index],
            nationalHealthCard: data[`dependents-nationalHealthCard`][index],
            relationship: data[`dependents-relationship`][index],
            sex: data[`dependents-sex`][index],
          };

          bbankConnectDependents.push(bbankConnectDependent);
        });

        bbankConnectData.paymentValue = bbankConnectPriceObject.price;

        const { data: bbankConnect } = await api.post('/telemedicine', {
          ...bbankConnectData,
          userId: user.id,
        });

        if (dependents.length > 0) {
          await api.post('/telemedicine-dependents/many', {
            bbankConnectId: bbankConnect.id,
            data: bbankConnectDependents,
          });
        }

        addToast({
          title: 'Solicitação feita com sucesso!',
          type: 'success',
          message: 'Aguarde, entraremos em contatos para maiores informações!',
        });

        reset();
        setDependents([]);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro.',
            });
          }
        }
      }
    },
    [dependents, user, addToast, bbankConnectPriceObject.price],
  );

  return (
    <Master>
      <Row>
        <URLPath>
          <li>Bbank Connect</li>
          <li>{'>'}</li>
          <li>Telemedicina</li>
          <li>{'>'}</li>
          <li>Novo</li>
        </URLPath>
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Novo telemedicina</h1>

            <Button
              styleType="info"
              icon={FiPlusCircle}
              type="button"
              onClick={handleAddDependents}
            >
              Adicionar dependentes
            </Button>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              <FormRow>
                <InputGroup>
                  <label>Registro geral (RG)</label>
                  <Input name="bbankConnect-generalRegistry" />
                </InputGroup>

                <InputGroup>
                  <label>Data de nascimento</label>
                  <InputMask
                    mask="99/99/9999"
                    name="bbankConnect-birthday"
                    noUnmask
                  />
                </InputGroup>

                <InputGroup>
                  <label>Sexo</label>
                  <Input name="bbankConnect-sex" />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Estado civil</label>
                  <Select
                    name="bbankConnect-maritalStatus"
                    options={maritalStatusOptions}
                  />
                </InputGroup>

                <InputGroup>
                  <label>Nome da mãe</label>
                  <Input name="bbankConnect-motherName" />
                </InputGroup>

                <InputGroup>
                  <label>Número do cartão nacional de saúde</label>
                  <Input name="bbankConnect-nationalHealthCard" />
                </InputGroup>
              </FormRow>

              <FormRow separator>
                <h1>Endereço</h1>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Bairro</label>
                  <Input name="bbankConnect-neighborhood" />
                </InputGroup>

                <InputGroup>
                  <label>Complemento</label>
                  <Input name="bbankConnect-complement" />
                </InputGroup>
              </FormRow>

              <DependentsContainer>
                {dependents.map(item => item)}
              </DependentsContainer>

              <FormRow buttonWrapper>
                <Button type="submit" styleType="success" icon={FiSave}>
                  Enviar
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </Master>
  );
};

export { ClientNewTelemedicine };
