import * as Yup from 'yup';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const validationShape = Yup.object().shape({
    cpf: Yup.string().required('Preencha este campo!'),
    infinityId: Yup.string(),
    clientId: Yup.string()
      .uuid('Insira um ID válido!')
      .required('Preencha este campo!'),
    complement: Yup.string(),
    addressNumber: Yup.string(),
    sellingType: Yup.string()
      .oneOf(
        ['sells_with_cashback', 'sells_without_cashback'],
        'Valor inválido!',
      )
      .required('Preencha este campo!'),
    paymentForm: Yup.string()
      .oneOf(['credit_card', 'debit', 'billet'], 'Valor inválido!')
      .required('Preencha este campo!'),
    cashbackPercentage: Yup.number()
      .transform((cv, ov) => {
        return ov === '' ? undefined : cv;
      })
      .min(10, 'O minimo é 10')
      .max(99, 'O máximo é 99')
      .when({
        is: (_: string) => data.sellingType === 'sells_with_cashback',
        then: Yup.number().typeError('Insira um número válido').required(),
      }),
    businessMethod: Yup.string()
      .oneOf(['accession', 'sells_a930', 'sells_a50'], 'Valor inválido!')
      .required('Preencha este campo!'),
    paymentPortions: Yup.number()
      .typeError('Insira um número válido!')
      .required(),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
