import { states } from '@helpers/getAllStates';

interface IReturnType {
  value: string;
  label: string;
}

export const creditProductOptions = [
  { value: 'vehicle', label: 'Veículo' },
  { value: 'property', label: 'Imóvel' },
  { value: 'bndes', label: 'BNDES' },
  { value: 'credluz', label: 'CredLuz' },
  { value: 'gyro', label: 'Capital de giro' },
  { value: 'pic_machines', label: 'PIC máquinas' },
];

export const creditModalityOptions = [
  { value: 'sem_garantia', label: 'Sem garantia' },
  { value: 'com_garantia', label: 'Com garantia' },
];

export const maritalStatusOptions = [
  { value: 'solteiro', label: 'Solteiro' },
  { value: 'casado', label: 'Casado' },
];

export const getStatesOptions = (): IReturnType[] => {
  const options = Object.entries(states).map(([code, stateName]) => {
    return {
      value: code,
      label: stateName,
    };
  });

  return options;
};

export const ownPropertyOptions = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

export const propertyType = [
  { value: 'comercial', label: 'Comercial' },
  { value: 'residencial', label: 'Residencial' },
  { value: 'misto', label: 'Misto' },
];
