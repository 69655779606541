import { ChangeEvent, FC, useCallback, useMemo, useState, useRef } from 'react';
import { Form } from '@unform/web';
import { FiSave } from 'react-icons/fi';
import { ValidationError } from 'yup';
import { FormHandles } from '@unform/core';

import { Select } from '@components/elements/Form/Select';
import { FormRow } from '@components/elements/Form/FormRow';
import { Row } from '@components/layouts/CardMenuContainer/styles';
import { URLPath } from '@components/layouts/UrlPath';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Input } from '@components/elements/Form/Input';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { InputFile } from '@components/elements/Form/InputFile';
import { Button } from '@components/elements/Button';

import { useAuth } from '@hooks/auth';
import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';

import { IClient, IFormData, IFile } from './interfaces';
import { Card, CardHeader, CardContent } from './styles';
import { formValidation } from './validations';

export const NewECommerce: FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [clients, setClients] = useState<IClient[]>();
  const [forMe, setForMe] = useState(true);
  const [files, setFiles] = useState<IFile[]>([]);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        formRef.current?.setErrors({});

        const parsedData = data;

        if (forMe) {
          parsedData.userId = user.id;
        }

        await formValidation(parsedData);

        delete parsedData.file;

        parsedData.indicationId = user.id;

        const { data: ecommerce } = await api.post('/ecommerce', parsedData);

        const formFiles = new FormData();

        files.forEach(({ file, title }) => {
          formFiles.append('files', file);
          formFiles.append('titles', title);
        });

        formFiles.append('ecommerceId', ecommerce.id);

        await api.post('/ecommerce/files', formFiles);

        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [forMe, user.id, addToast, files],
  );

  const handleGetClients = useCallback(async () => {
    const { data } = await api.get(`/users-indicated`);

    setClients(data);
  }, []);

  const handleCheckboxChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked && !clients) {
        await handleGetClients();
      }

      setForMe(event.target.checked);
    },
    [handleGetClients, clients],
  );

  const clientsOptions = useMemo(() => {
    if (!clients) {
      return [];
    }

    const data = clients.map(client => ({
      label: client.name,
      value: client.id,
    }));

    return data;
  }, [clients]);

  const handleInputFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, key: string, title: string) => {
      const inputFileRef = formRef.current?.getFieldRef(e.target.name);

      if (!e.target.files || e.target.files.length === 0) {
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      const file = e.target.files[0];

      const fileExtension = file.name.split('.').reverse()[0];
      const fileSize = file.size * 0.000001;

      if (fileExtension !== 'pdf' && fileExtension !== 'png') {
        addToast({
          title: 'Arquivo inválido!',
          message: 'São aceitos apenas arquivos PDF ou PNG',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      if (fileSize > 2.1) {
        addToast({
          title: 'Arquivo grande demais!',
          message: 'São aceitos apenas arquivos com 2MB ou menos',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      setFiles(oldState => {
        const findDuplicated = oldState.findIndex(item => item.key === key);

        if (findDuplicated >= 0) {
          oldState.splice(findDuplicated, 1);
        }

        return [...oldState, { file, key, title }];
      });
    },
    [addToast],
  );

  return (
    <>
      <Row>
        <URLPath paths={['ECommerce', 'Cadastro']} />
      </Row>

      <Row>
        <Card>
          <CardHeader>
            <h1>Novo cadastro de ECommerce</h1>

            <div>
              <label htmlFor="forMe">Para mim</label>
              <input
                type="checkbox"
                id="forMe"
                onChange={handleCheckboxChange}
                defaultChecked
              />
            </div>
          </CardHeader>

          <CardContent>
            <Form onSubmit={handleFormSubmit} ref={formRef}>
              {!forMe && (
                <FormRow>
                  <InputGroup>
                    <label>Clientes</label>
                    <Select name="userId" options={clientsOptions} />
                  </InputGroup>
                </FormRow>
              )}

              <FormRow>
                <InputGroup>
                  <label>Documento (CPF/CNPJ)</label>
                  <Input name="document" maxLength={14} />
                </InputGroup>

                <InputGroup>
                  <label>Limite de crédito</label>
                  <InputCurrency name="creditLimit" />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Comprovante de residência</label>
                  <InputFile
                    name="file"
                    onChange={event =>
                      handleInputFileChange(
                        event,
                        'HY65',
                        'Comprovante de residência',
                      )
                    }
                  />
                </InputGroup>

                <InputGroup>
                  <label>Comprovante de estabelecimento comercial</label>
                  <InputFile
                    name="file"
                    onChange={event =>
                      handleInputFileChange(
                        event,
                        'olTr',
                        'Comprovante de estabelecimento comercial',
                      )
                    }
                  />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Foto segurando RG</label>
                  <InputFile
                    name="file"
                    onChange={event =>
                      handleInputFileChange(event, 'PLye', 'Foto segurando RG')
                    }
                  />
                </InputGroup>
              </FormRow>

              <FormRow>
                <InputGroup>
                  <label>Referencia pessoa 1 (RG/CPF/self)</label>
                  <InputFile
                    name="file"
                    onChange={event =>
                      handleInputFileChange(
                        event,
                        'lp98',
                        'Referencia pessoa 1 (RG/CPF/self)',
                      )
                    }
                  />
                </InputGroup>

                <InputGroup>
                  <label>Referencia pessoa 2 (RG/CPF/self)</label>
                  <InputFile
                    name="file"
                    onChange={event =>
                      handleInputFileChange(
                        event,
                        'yUIP',
                        'Referencia pessoa 2 (RG/CPF/self)',
                      )
                    }
                  />
                </InputGroup>
              </FormRow>

              <FormRow buttonWrapper>
                <Button type="submit" styleType="success" icon={FiSave}>
                  Cadastrar
                </Button>
              </FormRow>
            </Form>
          </CardContent>
        </Card>
      </Row>
    </>
  );
};
