import { FC } from 'react';

import { Header } from '@components/layouts/Header';
import { Aside } from '@components/layouts/Aside';
import { Main } from '@components/layouts/Main';
import { Footer } from '@components/layouts/Footer';
import { CardMenuContainer } from '@components/layouts/CardMenuContainer';

const Master: FC = ({ children }) => (
  <>
    <Header />

    <Aside />

    <Main>
      <CardMenuContainer />

      {children}
    </Main>

    <Footer />
  </>
);

export { Master };
