import { FC } from 'react';

import { Container, Content } from './styles';
import { IMain } from './interfaces';

const Main: FC<IMain> = ({ children, ...rest }) => (
  <Container {...rest}>
    <Content>{children}</Content>
  </Container>
);

export { Main };
