import * as Yup from 'yup';

import bbankProspectsProducts from '@utils/bbankProspectsProducts';

import { states } from '@helpers/getAllStates';

import { IFormData } from './interfaces';

export const formValidation = async (data: IFormData): Promise<void> => {
  const stateOptions = Object.entries(states).map(([value]) => {
    return value;
  });

  const shape = Yup.object().shape({
    name: Yup.string()
      .max(255, 'O máximo de caracteres é 255')
      .required('Preencha este campo!'),
    email: Yup.string()
      .email('Insira um email válido!')
      .required('Preencha este campo!'),
    phone: Yup.string().required('Preencha este campo!'),
    state: Yup.string()
      .oneOf(stateOptions, 'Valor inválido!')
      .required('Preencha este campo!'),
    product: Yup.string()
      .oneOf(bbankProspectsProducts, 'Valor inválido!')
      .required('Preencha este campo!'),
  });

  await shape.validate(data, {
    abortEarly: false,
  });
};
