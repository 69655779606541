import { FC, useEffect, useState, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FiDollarSign, FiEye } from 'react-icons/fi';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { URLPath } from '@components/layouts/UrlPath';

import api from '@services/bbankApi';

import {
  Card,
  CardHeader,
  CardContent,
  TableButtonContainer,
  TableButton,
} from './styles';
import { IConvenience, IConvenienceFromApi, IParams } from './interfaces';

const ListConveniencesByIndicationId: FC = () => {
  const { push } = useHistory();
  const { params } = useRouteMatch<IParams>();

  const [conveniences, setConveniences] = useState<IConvenience[]>();

  useEffect(() => {
    async function loadConveniences() {
      const { data } = await api.get<IConvenienceFromApi[]>(
        `/bbank-pay/conveniences/franchisees/${params.indicationId}`,
        {
          params: {
            relations: ['client', 'franchisee'],
          },
        },
      );

      const state = data.map(convenience => {
        return {
          id: convenience.id,
          infinityId: convenience.infinity_id,
          cpf: convenience.cpf,
          client: convenience.client,
          status: convenience.status,
          franchisee: convenience.franchisee,
        };
      });

      setConveniences(state);
    }

    const timeout = setTimeout(() => {
      loadConveniences();
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [params.indicationId]);

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'Documento do estabelecimento (CPF)',
        selector: 'search',
      },
      {
        name: 'Nome do cliente',
        selector: 'userName',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Infinity ID',
        selector: 'infinityId',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!conveniences) {
      return [];
    }

    const data = conveniences.map(convenience => {
      let badgeType: 'danger' | 'success' | 'warning' = 'success';

      if (convenience.status === 'parado') {
        badgeType = 'danger';
      }

      if (convenience.status === 'pendente') {
        badgeType = 'warning';
      }

      return {
        id: convenience.id,
        search: convenience.cpf,
        infinityId: convenience.infinityId,
        userName: convenience.client.name,
        status: <Badge type={badgeType}>{convenience.status}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              title="Visualizar"
              onClick={() =>
                push(
                  `/franchisees/bbank-pay/conveniences/view/${convenience.id}`,
                )
              }
            >
              <FiEye size={20} />
            </TableButton>
            <TableButton
              styleType="info"
              title="Transações"
              onClick={() =>
                push(
                  `/franchisees/bbank-pay/conveniences/${convenience.id}/transactions`,
                )
              }
            >
              <FiDollarSign size={20} />
            </TableButton>
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [conveniences, push]);

  return (
    <>
      {!conveniences ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath
              paths={[
                'BbankPay',
                'Estabelecimentos',
                'Vendedores',
                (conveniences.length !== 0 &&
                  conveniences[0].franchisee.name) ||
                  'Franqueado',
                'Todos',
              ]}
            />
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Estabelecimentos</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por CPF..."
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListConveniencesByIndicationId };
