import {
  FC,
  useState,
  useCallback,
  ChangeEvent,
  useRef,
  useEffect,
  useMemo,
} from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';
import { OptionTypeBase } from 'react-select';

import { Form } from '@components/elements/Form';
import { Row } from '@components/layouts/Grid/Row';
import { FormRow } from '@components/elements/Form/FormRow';
import { Input } from '@components/elements/Form/Input';
import { Select } from '@components/elements/Form/Select';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { InputFile } from '@components/elements/Form/InputFile';
import { Button } from '@components/elements/Button';
import { InputMask } from '@components/elements/Form/InputMask';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Aside } from '@components/layouts/Aside';
import { CardMenuContainer } from '@components/layouts/CardMenuContainer';
import { Footer } from '@components/layouts/Footer';
import { Header } from '@components/layouts/Header';
import { Main } from '@components/layouts/Main';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { removeInputMask } from '@helpers/removeInputMask';
import { getClientErrors } from '@helpers/getClientErrors';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  CashBackPercentageFormRow,
  ChooseAFile,
} from './styles';
import {
  statusOpions,
  getFranchiseesOptions,
  getFranchiseeClientsOptions,
  paymentFormOptions,
  businessMethodOptions,
  portionsOptions,
  sellingTypeOptions,
} from './selectOptions';
import {
  IFormData,
  IClient,
  IFile,
  IFranchisee,
  IParsedFormData,
} from './interfaces';
import { formValidation } from './validations';

const NewConveniences: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const rgInputFileFormRow = useRef<HTMLDivElement>(null);
  const cnhInputGroupRef = useRef<HTMLDivElement>(null);
  const { addToast } = useToast();

  const [price, setPrice] = useState(0);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [files, setFiles] = useState<IFile[]>([]);
  const [franchisees, setFranchisees] = useState<IFranchisee[]>();
  const [franchiseeClients, setFranchiseeClients] = useState<IClient[]>();
  const [formRowShown, setFormRowShown] = useState(false);

  useEffect(() => {
    async function loadFranchisees() {
      const { data } = await api.get('/franchisees');

      setFranchisees(data);
    }

    const timer = setTimeout(async () => {
      loadFranchisees();
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  const handlInputFileChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>, key: string, title: string) => {
      const inputFileRef = formRef.current?.getFieldRef(e.target.name);

      if (!e.target.files || e.target.files.length === 0) {
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      const file = e.target.files[0];

      const fileExtension = file.name.split('.').reverse()[0];
      const fileSize = file.size * 0.000001;

      if (fileExtension !== 'pdf' && fileExtension !== 'png') {
        addToast({
          title: 'Arquivo inválido!',
          message: 'São aceitos apenas arquivos PDF ou PNG',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      if (fileSize > 2.1) {
        addToast({
          title: 'Arquivo grande demais!',
          message: 'São aceitos apenas arquivos com 2MB ou menos',
          type: 'info',
        });
        inputFileRef.value = '';
        setFiles(oldState => {
          const findDuplicated = oldState.findIndex(item => item.key === key);

          if (findDuplicated >= 0) {
            oldState.splice(findDuplicated, 1);
          }

          return [...oldState];
        });
        return;
      }

      setFiles(oldState => {
        const findDuplicated = oldState.findIndex(item => item.key === key);

        if (findDuplicated >= 0) {
          oldState.splice(findDuplicated, 1);
        }

        return [...oldState, { file, key, title }];
      });
    },
    [addToast],
  );

  const handleSellingTypeSelectChange = useCallback(
    (value: OptionTypeBase | null) => {
      if (!value) {
        return;
      }

      if (value.value === 'sells_with_cashback') {
        setFormRowShown(true);
      } else {
        setFormRowShown(false);
      }
    },
    [],
  );

  const bbankPayPaymentValueObject = useMemo(() => {
    const bbankPayPaymentValue = price;

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });

    const parsedValue = numberFormat.format(bbankPayPaymentValue);

    return {
      parsedValue,
      bbankPayPaymentValue,
    };
  }, [price]);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      const fieldsToDelete = [
        'fileOne',
        'fileTwo',
        'fileThree',
        'fileFour',
        'fileFive',
      ];

      try {
        setLoadingRequest(true);

        formRef.current?.setErrors({});

        await formValidation(data);

        if (
          rgInputFileFormRow.current?.style.display !== 'none' &&
          files.length !== 3
        ) {
          addToast({
            title: 'Insira os arquivos solicitados!',
            type: 'error',
            message:
              'Todos os arquivos são obrigatórios (3). Insira todos para poder continuar!',
          });
          return;
        }

        if (
          rgInputFileFormRow.current?.style.display === 'none' &&
          files.length !== 2
        ) {
          addToast({
            title: 'Insira os arquivos solicitados!',
            type: 'error',
            message:
              'Todos os arquivos são obrigatórios (2). Insira todos para poder continuar!',
          });
          return;
        }

        const parsedMaskedData = removeInputMask();

        const formData: IParsedFormData = {
          ...data,
        };

        delete formData.files;

        parsedMaskedData.forEach(item => {
          formData[item.name] = item.value;
        });

        formData.paymentValue = bbankPayPaymentValueObject.bbankPayPaymentValue;

        fieldsToDelete.forEach(fieldToDelete => {
          delete formData[fieldToDelete];
        });

        // return console.log(formData);

        const { data: convenience } = await api.post(
          '/bbank-pay/conveniences',
          formData,
        );

        const formFiles = new FormData();

        files.forEach(({ file, title }) => {
          formFiles.append('files', file);
          formFiles.append('titles', title);
        });

        formFiles.append('convenienceId', convenience.id);

        await api.post('/bbank-pay/conveniences/files', formFiles);

        addToast({
          title: 'Muito bem!',
          type: 'success',
          message:
            'Os dados deste estabelecimento foram computados com sucesso!',
        });

        reset();
        setFiles([]);
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      } finally {
        setLoadingRequest(false);
      }
    },
    [addToast, files, bbankPayPaymentValueObject.bbankPayPaymentValue],
  );

  const handleFranchiseesSelectChange = useCallback(
    async (value: OptionTypeBase | null) => {
      if (!value) {
        return;
      }

      api.get(`/users-indicated/${value.value}`).then(response => {
        const { data } = response;

        setFranchiseeClients(data);
      });
    },
    [],
  );

  const franchiseesOptions = useMemo(() => {
    return franchisees ? getFranchiseesOptions(franchisees) : [];
  }, [franchisees]);

  const franchiseeClientsOptions = useMemo(() => {
    return franchiseeClients
      ? getFranchiseeClientsOptions(franchiseeClients)
      : [];
  }, [franchiseeClients]);

  const handleChangeOfPrice = useCallback((option: OptionTypeBase | null) => {
    if (!option) {
      return;
    }

    const numberFormat = new Intl.NumberFormat('pt-BR', {
      currency: 'BRL',
      style: 'currency',
    }).format;

    const { value } = option;

    const ref = formRef.current?.getFieldRef('paymentValue');

    switch (value) {
      case 'accession':
        setPrice(198);
        ref.value = numberFormat(198);
        break;

      case 'sells_a930':
        setPrice(676);
        ref.value = numberFormat(676);
        break;

      default:
        setPrice(556);
        ref.value = numberFormat(556);
        break;
    }
  }, []);

  const handleChooseAFile = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!rgInputFileFormRow.current || !cnhInputGroupRef.current) {
      return;
    }

    const { value } = e.target;

    if (value === 'rgCpfFile') {
      rgInputFileFormRow.current.style.display = 'flex';
      cnhInputGroupRef.current.style.display = 'none';
    } else {
      rgInputFileFormRow.current.style.display = 'none';
      cnhInputGroupRef.current.style.display = 'flex';
    }
  }, []);

  return (
    <>
      <Header />

      <Aside />

      <Main>
        <CardMenuContainer />

        {!franchisees ? (
          <LoadingPage />
        ) : (
          <>
            <Row>
              <URLPath>
                <li>BbankPay</li>
                <li>{'>'}</li>
                <li>Estabelecimento</li>
                <li>{'>'}</li>
                <li>Pessoa física</li>
                <li>{'>'}</li>
                <li>Novo</li>
              </URLPath>
            </Row>

            <Row>
              <Card>
                <CardHeader>
                  <h1>Novo estabelecimento, pessoa física</h1>
                </CardHeader>

                <CardContent>
                  <Form onSubmit={handleFormSubmit} ref={formRef}>
                    <FormRow>
                      <InputGroup>
                        <label>Status</label>
                        <Select name="status" options={statusOpions} />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Franqueado responsável</label>
                        <Select
                          name="franchiseeId"
                          options={franchiseesOptions}
                          onChange={handleFranchiseesSelectChange}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Cliente do fraqueado responsável</label>
                        <Select
                          name="clientId"
                          options={franchiseeClientsOptions}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Dados complementares</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>CPF</label>
                        <InputMask
                          mask="999.999.999-99"
                          name="cpf"
                          placeholder="000.000.000-00"
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Número do SN</label>
                        <Input
                          name="infinityId"
                          placeholder="acc51515"
                          upperCase={false}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Endereço</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Complemento (deixe vazio caso não exista)</label>
                        <Input name="complement" />
                      </InputGroup>

                      <InputGroup>
                        <label>Número (deixe vazio caso não exista)</label>
                        <InputMask
                          mask="9999999999"
                          noUnmask
                          name="addressNumber"
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Contrato</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Modelo de negócio</label>
                        <Select
                          name="businessMethod"
                          options={businessMethodOptions}
                          onChange={handleChangeOfPrice}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Tipo de venda</label>
                        <Select
                          name="sellingType"
                          options={sellingTypeOptions}
                          onChange={handleSellingTypeSelectChange}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Método de pagamento</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Preço a ser pago</label>
                        <Input
                          name="paymentValue"
                          readOnly
                          defaultValue={bbankPayPaymentValueObject.parsedValue}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Forma de pagamento</label>
                        <Select
                          name="paymentForm"
                          options={paymentFormOptions}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Parcelas</label>
                        <Select
                          name="paymentPortions"
                          options={portionsOptions}
                        />
                      </InputGroup>
                    </FormRow>

                    <CashBackPercentageFormRow isShown={formRowShown}>
                      <InputGroup>
                        <label>Porcentagem de cashback</label>
                        <Input
                          name="cashbackPercentage"
                          placeholder="O percentual não pode ser menor que 10 e nem maior que 99"
                        />
                      </InputGroup>
                    </CashBackPercentageFormRow>

                    <FormRow separator>
                      <h1>Arquivos</h1>

                      <ChooseAFile>
                        <div>
                          <label htmlFor="cnhFile">CNH</label>
                          <input
                            name="chooseAFile"
                            type="radio"
                            id="cnhFile"
                            defaultValue="cnhFile"
                            onChange={handleChooseAFile}
                            defaultChecked
                          />
                        </div>
                        <div>
                          <label htmlFor="rgCpfFile">RG/CPF</label>
                          <input
                            name="chooseAFile"
                            type="radio"
                            id="rgCpfFile"
                            defaultValue="rgCpfFile"
                            onChange={handleChooseAFile}
                          />
                        </div>
                      </ChooseAFile>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Comprovante de residência</label>
                        <InputFile
                          name="fileOne"
                          onChange={e =>
                            handlInputFileChange(
                              e,
                              'aWqt',
                              'Comprovante de residência',
                            )
                          }
                        />
                      </InputGroup>

                      <InputGroup ref={cnhInputGroupRef}>
                        <label>CNH</label>
                        <InputFile
                          name="fileThree"
                          onChange={e => handlInputFileChange(e, 'nyhZ', 'CNH')}
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow
                      ref={rgInputFileFormRow}
                      style={{ display: 'none' }}
                    >
                      <InputGroup>
                        <label>RG (frente)</label>
                        <InputFile
                          name="fileFour"
                          onChange={e =>
                            handlInputFileChange(e, 'OPiuI', 'RG (frente)')
                          }
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>RG (verso)</label>
                        <InputFile
                          name="fileFive"
                          onChange={e =>
                            handlInputFileChange(e, 'PnuT', 'RG (verso)')
                          }
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow buttonWrapper>
                      <Button
                        type="submit"
                        styleType="info"
                        icon={FiCheckCircle}
                        loading={loadingRequest}
                      >
                        Enviar
                      </Button>
                    </FormRow>
                  </Form>
                </CardContent>
              </Card>
            </Row>
          </>
        )}
      </Main>

      <Footer />
    </>
  );
};

export { NewConveniences };
