import { FC, useEffect, useState, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { FiDollarSign, FiEye } from 'react-icons/fi';
import { useLocation, useHistory } from 'react-router-dom';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Badge } from '@components/elements/Badge';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import api from '@services/bbankApi';

import { URLPath, Card, CardHeader, CardContent } from './styles';
import { IConvenience, IConvenienceFromApi } from './interfaces';

const ListByStatusNameConveniences: FC = () => {
  const { push } = useHistory();
  const { search } = useLocation();

  const [conveniences, setConveniences] = useState<IConvenience[] | undefined>(
    undefined,
  );

  useEffect(() => {
    setConveniences(undefined);

    async function loadConveniences() {
      const { data } = await api.get<IConvenienceFromApi[]>(
        `/bbank-pay/conveniences/status?s=${search.replace('?s=', '')}`,
        {
          params: {
            relations: ['client', 'franchisee'],
          },
        },
      );

      const state = data.map(convenience => {
        return {
          id: convenience.id,
          infinityId: convenience.infinity_id,
          cpf: convenience.cpf,
          client: convenience.client,
          status: convenience.status,
        };
      });

      setConveniences(state);
    }

    const timeout = setTimeout(() => {
      loadConveniences();
    }, 1500);

    return () => {
      clearInterval(timeout);
    };
  }, [search]);

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'Documento do estabelecimento (CPF)',
        selector: 'search',
      },
      {
        name: 'Nome do cliente',
        selector: 'userName',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Infinity ID',
        selector: 'infinityId',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!conveniences) {
      return [];
    }

    const data = conveniences.map(convenience => {
      let badgeType: 'danger' | 'success' | 'warning' = 'success';

      if (convenience.status === 'parado') {
        badgeType = 'danger';
      }

      if (convenience.status === 'pendente') {
        badgeType = 'warning';
      }

      return {
        id: convenience.id,
        search: convenience.cpf,
        infinityId: convenience.infinityId,
        userName: convenience.client.name,
        status: <Badge type={badgeType}>{convenience.status}</Badge>,
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="success"
              title="Visualizar"
              onClick={() =>
                push(`/bbank-pay/conveniences/view/${convenience.id}`)
              }
              icon={FiEye}
            />

            <TableButton
              styleType="info"
              title="Transações"
              onClick={() =>
                push(`/bbank-pay/conveniences/${convenience.id}/transactions`)
              }
              icon={FiDollarSign}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [conveniences, push]);

  return (
    <>
      {!conveniences ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>BbankPay</li>
              <li>{'>'}</li>
              <li>Estabelecimentos</li>
              <li>{'>'}</li>
              <li>Todos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Estabelecimentos</h1>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={tableColumns}
                  data={tableData}
                  filterPlaceholder="Filtrar por CPF..."
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListByStatusNameConveniences };
