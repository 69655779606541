import { FiDownload, FiTrash } from 'react-icons/fi';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';

import { DataTable } from '@components/elements/Datatable';
import { Row } from '@components/layouts/Grid/Row';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';

import api from '@services/bbankApi';

import { IFile, IParams, ILoadedFile, ICategory } from './interfaces';
import { URLPath, Card, CardHeader, CardContent, TableLink } from './styles';

const ListFilesByCategoryId: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [files, setFiles] = useState<IFile[]>();
  const [category, setCategory] = useState<ICategory>();

  useEffect(() => {
    async function loadCategory() {
      const { data } = await api.get(
        `/marketing/categories/${params.marketingCategoryId}`,
      );

      setCategory(data);
    }

    async function loadFilesByCategoryId() {
      const { data } = await api.get<ILoadedFile[]>(
        `/marketing/files/${params.marketingCategoryId}`,
        {
          params: {
            category: true,
          },
        },
      );

      const parsedData = data.map(item => ({
        id: item.id,
        name: item.name,
        description: item.description,
        marketingCategory: item.marketing_category,
        fileUrl: item.file_url,
        createdAt: item.created_at,
      }));

      setFiles(parsedData);
    }

    const timer = setTimeout(async () => {
      await loadCategory();
      loadFilesByCategoryId();
    }, 1500);

    return () => clearInterval(timer);
  }, [params.marketingCategoryId]);

  const handleDeleteFiles = useCallback(async (marketingFileId: string) => {
    await api.delete(`/marketing/files/${marketingFileId}`);

    setFiles(oldState => {
      if (!oldState) {
        return [];
      }

      const updatedState = oldState.filter(item => item.id !== marketingFileId);

      setFiles(updatedState);
    });
  }, []);

  const tableColumns = useMemo(() => {
    return [
      {
        name: 'Descrição do arquivo',
        selector: 'description',
      },
      {
        name: 'Nome da categoria que pertence',
        selector: 'search',
      },
      {
        name: 'Data do upload',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!files) {
      return [];
    }

    const data = files.map(file => ({
      id: file.id,
      name: file.name,
      description: file.description,
      search: file.marketingCategory.name,
      createdAt: format(new Date(file.createdAt), 'dd/MM/yyyy'),
      actions: (
        <TableButtonContainer>
          <TableLink
            styleType="info"
            href={file.fileUrl}
            target="_blank"
            rel="noreferrer"
          >
            <FiDownload size={22} />
          </TableLink>

          <TableButton
            styleType="danger"
            onDoubleClick={() => handleDeleteFiles(file.id)}
            title="Clique duas vezes para deletar este arquivo!"
            icon={FiTrash}
          />
        </TableButtonContainer>
      ),
    }));

    return data;
  }, [files, handleDeleteFiles]);

  return (
    <>
      {!files ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Marketing</li>
              <li>{'>'}</li>
              <li>Arquivos</li>
              <li>{'>'}</li>
              <li>{category?.name}</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>
                  Arquivos da categoria&nbsp;
                  {`"${category?.name}"`}
                </h1>
              </CardHeader>

              <CardContent>
                <DataTable columns={tableColumns} data={tableData} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListFilesByCategoryId };
