import { FC, useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useRouteMatch } from 'react-router-dom';
import { FiDownload } from 'react-icons/fi';
import { TableColumn } from 'react-data-table-component';

import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { Badge } from '@components/elements/Badge';
import { LoadingPage } from '@components/layouts/LoadingPage';

import api from '@services/bbankApi';

import {
  URLPath,
  Card,
  CardHeader,
  CardContent,
  DownloadButton,
} from './styles';
import { IParams, IMerchantFile, IMerchantFileLoaded } from './interfaces';

const MerchantFiles: FC = () => {
  const { params } = useRouteMatch<IParams>();

  const [merchantFiles, setMerchantFiles] = useState<IMerchantFile[]>();

  useEffect(() => {
    async function loadMerchantFiles() {
      const { data } = await api.get<IMerchantFileLoaded[]>(
        `/bbank-pay/merchant-files/${params.merchantId}`,
      );

      const parsedData = data.map(item => {
        return {
          id: item.id,
          title: item.title,
          createdAt: format(new Date(item.created_at), 'dd/MM/yyyy'),
          fileUrl: item.fileUrl,
        };
      });

      setMerchantFiles(parsedData);
    }

    const timer = setTimeout(() => {
      loadMerchantFiles();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [params.merchantId]);

  const tableColumns = useMemo<TableColumn[]>(() => {
    return [
      {
        name: 'Titulo do arquivo',
        selector: 'search',
      },
      {
        name: 'Data do upload',
        selector: 'createdAt',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const tableData = useMemo(() => {
    if (!merchantFiles) {
      return [];
    }

    const data = merchantFiles.map(merchantFile => {
      return {
        search: merchantFile.title,
        createdAt: merchantFile.createdAt,
        actions: (
          <DownloadButton
            href={merchantFile.fileUrl}
            target="_blank"
            rel="noreferrer"
          >
            <FiDownload size={20} />
          </DownloadButton>
        ),
      };
    });

    return data;
  }, [merchantFiles]);

  return (
    <>
      {!merchantFiles ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Download</li>
              <li>{'>'}</li>
              <li>Estabelecimentos</li>
              <li>{'>'}</li>
              <li>Arquivos</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>Arquivos</h1>

                <Badge type={merchantFiles.length === 0 ? 'danger' : 'success'}>
                  Arquivos encontrados:&nbsp;
                  {merchantFiles.length}
                </Badge>
              </CardHeader>

              <CardContent>
                <DataTable data={tableData} columns={tableColumns} />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { MerchantFiles };
