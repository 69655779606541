import { IOption } from '@utils/interfaces';

import { banks } from '@helpers/getBanks';
import { states } from '@helpers/getAllStates';

import { IClient } from './interfaces';

export const getClientsOptions = (clients: IClient[]): IOption[] => {
  const options = clients.map(client => ({
    label: client.name,
    value: client.id,
  }));

  return options;
};

export const banksOptions = banks.map(bank => {
  const bankSplitted = bank.split(' - ');

  return {
    value: bankSplitted[0],
    label: bankSplitted[1],
  };
});

export const statesOptions = Object.entries(states).map(item => {
  const initials = item[0];
  const stateName = item[1];

  return {
    value: initials,
    label: stateName,
  };
});

export const creditModalities = [
  { value: 'sem_garantia', label: 'Sem garantia' },
  { value: 'com_garantia', label: 'Com garantia' },
];

export const guaranteesType = [
  { value: 'vehicle', label: 'Veículo' },
  { value: 'property', label: 'Imóvel' },
];

export const maritalStatusOptions = [
  { value: 'casado', label: 'Casado' },
  { value: 'solteiro', label: 'Solteiro' },
];

export const propertyType = [
  { value: 'comercial', label: 'Comercial' },
  { value: 'residencial', label: 'Residencial' },
  { value: 'misto', label: 'Misto' },
];

export const ownPropertyOptions = [
  { value: '1', label: 'Sim' },
  { value: '0', label: 'Não' },
];

export const servicesOptions = [
  { value: 'analytical', label: 'Analítico' },
  { value: 'pre_approved', label: 'Pré aprovado' },
];

export const paymentPortionsOptions = Array.from(
  Array(12).keys(),
).map(item => ({ value: item + 1, label: String(item + 1) }));

export const paymentFormOptions = [
  { value: 'credit_card', label: 'Cartão de crédito' },
  { value: 'debit', label: 'Débito' },
  { value: 'billet', label: 'Boleto' },
];
