import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TableColumn } from 'react-data-table-component';
import { FiEye, FiPlusCircle } from 'react-icons/fi';

import { Badge } from '@components/elements/Badge';
import { Row } from '@components/layouts/Grid/Row';
import { DataTable } from '@components/elements/Datatable';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { TableButtonContainer } from '@components/elements/Table/TableButtonContainer';
import { TableButton } from '@components/elements/Table/TableButtonContainer/TableButton';
import { Button } from '@components/elements/Button';

import api from '@services/bbankApi';

import { rolesTranslated } from '@utils/rolesTranslated';

import { URLPath, Card, CardContent, CardHeader } from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type IColumns = Array<TableColumn<Record<string, any>>>;

interface IUser {
  id: string;
  name: string;
  email: string;
  status: 'ativo' | 'pendente' | 'inativo';
  document: string;
}

interface IDataTableData {
  id: string;
  search: string;
  email: string;
  badge: JSX.Element;
  actions: JSX.Element;
}

const ListUsersByRoleName: FC = () => {
  const { search } = useLocation();
  const { push } = useHistory();

  const [users, setUsers] = useState<IUser[]>();

  const query = search.replace('?n=', '') as 'client' | 'business' | 'master';

  useEffect(() => {
    setUsers(undefined);

    async function loadUsers() {
      const { data } = await api.get('/roles/find', {
        params: { role: query },
      });

      setUsers(data.users);
    }

    const timer = setTimeout(() => {
      loadUsers();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [query]);

  const dataTableColumns = useMemo<IColumns>(() => {
    return [
      {
        name: 'Nome',
        selector: 'search',
      },
      {
        name: 'Email',
        selector: 'email',
      },
      {
        name: 'Documento',
        selector: 'document',
      },
      {
        name: 'Papel',
        selector: 'badge',
      },
      {
        name: 'Status',
        selector: 'status',
      },
      {
        name: 'Ações',
        selector: 'actions',
      },
    ];
  }, []);

  const handlePushToUserView = useCallback(
    (id: string) => {
      push(`/users/view/${id}`);
    },
    [push],
  );

  const statusBadgeColor = useMemo<{
    [key: string]: 'success' | 'warning' | 'danger';
  }>(() => {
    return {
      ativo: 'success',
      inativo: 'warning',
      pendente: 'danger',
    };
  }, []);

  const dataTableData = useMemo<IDataTableData[]>(() => {
    if (!users) {
      return [];
    }

    const data = users.map<IDataTableData>(item => {
      return {
        id: item.id,
        search: item.name,
        email: item.email,
        document: item.document,
        badge: <Badge type="info">{rolesTranslated[query]}</Badge>,
        status: (
          <Badge type={statusBadgeColor[item.status]}>{item.status}</Badge>
        ),
        actions: (
          <TableButtonContainer>
            <TableButton
              styleType="info"
              onClick={() => handlePushToUserView(item.id)}
              icon={FiEye}
            />
          </TableButtonContainer>
        ),
      };
    });

    return data;
  }, [users, query, handlePushToUserView, statusBadgeColor]);

  return (
    <>
      {!users ? (
        <LoadingPage />
      ) : (
        <>
          <Row>
            <URLPath>
              <li>Usuários</li>
              <li>&gt;</li>
              <li>{rolesTranslated[query]}</li>
            </URLPath>
          </Row>

          <Row>
            <Card>
              <CardHeader>
                <h1>
                  Usuários&nbsp;
                  {rolesTranslated[query]}
                </h1>

                <Button
                  styleType="success"
                  type="button"
                  icon={FiPlusCircle}
                  onClick={() => push('/users/new')}
                >
                  Novo
                </Button>
              </CardHeader>

              <CardContent>
                <DataTable
                  columns={dataTableColumns}
                  data={dataTableData}
                  filterPlaceholder="Filtrar por nome"
                />
              </CardContent>
            </Card>
          </Row>
        </>
      )}
    </>
  );
};

export { ListUsersByRoleName };
