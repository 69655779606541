/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useCallback } from 'react';
import {
  FiUser,
  FiAlignLeft,
  FiBarChart2,
  FiSearch,
  FiLogOut,
  FiSettings,
  FiLock,
} from 'react-icons/fi';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';

import { Input } from '@components/elements/Form/Input';
import { Dropdown } from '@components/elements/Dropdown';

import { useAside } from '@hooks/aside';
import { useAuth } from '@hooks/auth';

import logoImg from '@assets/img/bbank.png';

import {
  Container,
  Menu,
  MenuLeft,
  MenuRight,
  CustomButton,
  UserName,
} from './styles';
import { Notifications } from '../Notifications';

const Header: FC = () => {
  const { handleAsideVisibility } = useAside();
  const { signOut, user } = useAuth();
  const { push } = useHistory();

  const handleSearch = useCallback(() => {
    // CODE
  }, []);

  const handleSignOut = useCallback(() => {
    push('/');
    signOut();
  }, [signOut, push]);

  const handleGoToMyProfile = useCallback(() => {
    push(`/me`);
  }, [push]);

  return (
    <Container>
      <Menu>
        <MenuLeft>
          <button type="button" onClick={handleAsideVisibility}>
            <FiAlignLeft size={29} />
          </button>

          <Link to="/dashboard">
            <img src={logoImg} alt="Bbank | Logo" />
          </Link>

          <Form onSubmit={handleSearch}>
            <Input
              icon={FiSearch}
              name="search"
              placeholder="Faça sua pesquisa"
              upperCase={false}
            />
          </Form>
        </MenuLeft>

        <MenuRight>
          <a href="#" style={{ marginLeft: '10px' }}>
            <FiBarChart2 size={23} />
          </a>

          <Notifications />

          <Dropdown
            styles={{
              togglerBackground: '#fff',
              textColor: '#ccc',
              backgroundOnHover: '#f3f6f9',
              colorOnHover: '#c8c6c0',
            }}
            icon={FiUser}
          >
            <UserName>
              <span>
                Olá,&nbsp;
                {user.name.split(' ')[0]}
              </span>
            </UserName>
            <li>
              <CustomButton type="button" onClick={handleGoToMyProfile}>
                <FiSettings size={20} />
                Ver perfil
              </CustomButton>
            </li>
            {user.role === 'superAdmin' && (
              <li>
                <CustomButton
                  type="button"
                  onClick={() => push('/super-admin/access-control-panel')}
                >
                  <FiLock size={20} />
                  Gerenciar acessos
                </CustomButton>
              </li>
            )}
            <li>
              <CustomButton type="button" onClick={handleSignOut}>
                <FiLogOut size={20} />
                Sair
              </CustomButton>
            </li>
          </Dropdown>
        </MenuRight>
      </Menu>
    </Container>
  );
};

export { Header };
