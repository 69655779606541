import styled from 'styled-components';
import { animated } from 'react-spring';

export const Container = styled(animated.div)`
  width: 100%;

  padding: 15px;

  box-shadow: 0 0 30px 0 rgb(82 63 105 / 10%);

  border: 1px solid #ebedf3;
  border-radius: 10px;

  height: 70px;

  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 40px;

  cursor: pointer;

  > h1 {
    font-size: 22px;

    @media screen and (max-width: 1440px) {
      font-size: 20px;
    }

    @media screen and (max-width: 1024px) {
      font-size: 17px;
    }

    @media screen and (max-width: 768px) {
      font-size: 15px;
    }

    @media screen and (max-width: 500px) {
      font-size: 14px;
    }
  }

  > svg {
    transition: transform 0.4s;
  }
`;

export const Content = styled.div`
  margin-top: 15px;
`;
