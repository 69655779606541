import { FC } from 'react';

import { Container } from './styles';

interface ICheckboxGroupProps {
  labelText: string;
  labelHtmlFor: string;
}

const CheckboxGroup: FC<ICheckboxGroupProps> = ({
  labelText,
  labelHtmlFor,
  children,
}) => {
  return (
    <Container>
      {children}
      <label htmlFor={labelHtmlFor}>{labelText}</label>
    </Container>
  );
};

export { CheckboxGroup };
