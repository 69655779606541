import { FC } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel as CarouselComponent } from 'react-responsive-carousel';
import { v4 } from 'uuid';

import { ICarouselProps } from './interfaces';

export const Carousel: FC<ICarouselProps> = ({ imagesUrls }) => {
  return (
    <CarouselComponent autoPlay>
      {imagesUrls.map(image => (
        <div key={v4()}>
          <img src={image} alt="" />
        </div>
      ))}
    </CarouselComponent>
  );
};
