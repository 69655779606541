import * as Yup from 'yup';

interface IData {
  [key: string]: string;
}

export const bbankConnectValidation = async (data: IData): Promise<void> => {
  const validationShape = Yup.object().shape({
    'bbankConnect-birthday': Yup.string().required('Preencha este campo!'),
    'bbankConnect-generalRegistry': Yup.string().required(
      'Preencha este campo!',
    ),
    'bbankConnect-maritalStatus': Yup.string().required('Preencha este campo!'),
    'bbankConnect-motherName': Yup.string().required('Preencha este campo!'),
    'bbankConnect-sex': Yup.string().required('Preencha este campo!'),
    'bbankConnect-userId': Yup.string().required('Escolha um item!'),
    'bbankConnect-paymentForm': Yup.string()
      .oneOf(['credit_card', 'debit', 'billet'], 'Valor inválido!')
      .required('Preencha este campo!'),
    'bbankConnect-paymentPortions': Yup.number()
      .typeError('Insira um número válido!')
      .required(),
  });

  await validationShape.validate(data, {
    abortEarly: false,
  });
};
