import {
  FC,
  useCallback,
  useRef,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';
import { FormHandles } from '@unform/core';
import { ValidationError } from 'yup';

import { Row } from '@components/layouts/Grid/Row';
import { Form } from '@components/elements/Form';
import { FormRow } from '@components/elements/Form/FormRow';
import { InputMask } from '@components/elements/Form/InputMask';
import { InputGroup } from '@components/elements/Form/InputGroup';
import { Select } from '@components/elements/Form/Select';
import { Input } from '@components/elements/Form/Input';
import { Button } from '@components/elements/Button';
import { InputFile } from '@components/elements/Form/InputFile';
import { InputCurrency } from '@components/elements/Form/InputCurrency';
import { LoadingPage } from '@components/layouts/LoadingPage';
import { Aside } from '@components/layouts/Aside';
import { CardMenuContainer } from '@components/layouts/CardMenuContainer';
import { Footer } from '@components/layouts/Footer';
import { Header } from '@components/layouts/Header';
import { Main } from '@components/layouts/Main';

import { useToast } from '@hooks/toast';

import api from '@services/bbankApi';
import viaCepApi from '@services/viaCepApi';

import { IZipcode } from '@utils/interfaces';

import { getValidationErrors } from '@helpers/getValidationErrors';
import { getClientErrors } from '@helpers/getClientErrors';
import { removeInputMask } from '@helpers/removeInputMask';

import { Card, CardContent, CardHeader, URLPath, ChooseAFile } from './styles';
import { IFormData, IFile, IUserInformation } from './interfaces';
import {
  maritalStatusOptions,
  banksOptions,
  deadlineOptions,
  statesOptions,
} from './selectOptions';
import { formValidation } from './validations';

export const NewPropertyFinancing: FC = () => {
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const [files, setFiles] = useState<IFile[]>([]);
  const [cnhVisibility, setCnhVisibility] = useState(true);
  const [rgVisibility, setRgVisibility] = useState(false);
  const [userInfo, setUserInfo] = useState<IUserInformation>();

  useEffect(() => {
    async function getMyInfo() {
      const { data } = await api.get('/users/me');

      setUserInfo(data);
    }

    const timer = setTimeout(() => getMyInfo(), 1500);

    return () => clearInterval(timer);
  }, []);

  const handleFormSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      try {
        formRef.current?.setErrors({});

        const initialData = data;

        const fieldsToBeRemoved = [
          'fileOne',
          'fileTwo',
          'fileThree',
          'fileFour',
          'fileFive',
        ];

        fieldsToBeRemoved.forEach(property => {
          delete initialData[property];
        });

        initialData.indicationId = userInfo?.indication || '';
        initialData.userId = userInfo?.id || '';

        await formValidation(initialData);

        if (rgVisibility && files.length !== 4) {
          addToast({
            title: 'Arquivos obrigatórios!',
            message: 'Insira todos os arquivos para continuar!',
            type: 'info',
          });
          return;
        }

        if (cnhVisibility && files.length !== 3) {
          addToast({
            title: 'Arquivos obrigatórios!',
            message: 'Insira todos os arquivos para continuar!',
            type: 'info',
          });
          return;
        }

        const valuesWithoutMask = removeInputMask();

        const parsedData = valuesWithoutMask.reduce((acc, { name, value }) => {
          acc[name] = value;

          return acc;
        }, initialData);

        const formData = new FormData();

        files.forEach(({ file }) => {
          formData.append('files', file);
        });

        const { data: financing } = await api.post(
          '/financing/property',
          parsedData,
        );

        formData.append('financingId', financing.id);

        await api.post('/financing/property/files', formData);

        addToast({
          title: 'Muito bom!',
          message: 'O financiamento preenchido foi computado com sucesso!',
          type: 'success',
        });

        reset();
      } catch (err: any) {
        if (err instanceof ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          return;
        }

        if (err.response) {
          const { message, status } = getClientErrors(err.response);

          if (status === 400 || status === 404) {
            addToast({
              title: 'Solicitação não processada!',
              type: 'error',
              message,
            });
          }

          if (status === 500) {
            addToast({
              title: 'Algum erro aconteceu!',
              type: 'error',
              message:
                'Por favor, contate o administrador do sistema e reporte o erro!',
            });
          }
        }
      }
    },
    [addToast, files, cnhVisibility, rgVisibility, userInfo],
  );

  const handleFilesChange = useCallback(
    (fileId: string, event: ChangeEvent<HTMLInputElement>) => {
      const { files: eventFiles, name: eventTargetName } = event.target;
      const inputFileRef = formRef.current?.getFieldRef(eventTargetName);

      if (!eventFiles || eventFiles.length === 0) {
        setFiles(oldState => {
          const updatedState = oldState.filter(file => file.id !== fileId);

          return updatedState;
        });

        inputFileRef.value = '';

        return;
      }

      const eventFile = eventFiles[0];
      const { name, size } = eventFile;
      const fileExtension = name.split('.').reverse()[0];

      if (fileExtension !== 'png' && fileExtension !== 'pdf') {
        addToast({
          title: 'Arquivo inválido!',
          message: 'São aceitos somente arquivos PNG e PDF!',
          type: 'error',
        });

        inputFileRef.value = '';

        return;
      }

      if (size > 4194304) {
        addToast({
          title: 'Arquivo muito grande!',
          message: 'O arquivo não pode ser maior que 4MB!',
          type: 'error',
        });

        inputFileRef.value = '';

        return;
      }

      const fileState = {
        id: fileId,
        file: eventFile,
      };

      setFiles(oldState => [...oldState, fileState]);
    },
    [addToast],
  );

  const handleFilesVisibility = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (value === 'cnhChosen') {
        setFiles(oldState => {
          const updatedState = oldState.filter(
            file => file.id !== 'nvBy' && file.id !== 'PuyT',
          );

          return updatedState;
        });
      }

      if (value === 'rgChosen') {
        setFiles(oldState => {
          const updatedState = oldState.filter(file => file.id !== 'lqRt');

          return updatedState;
        });
      }

      setRgVisibility(oldState => !oldState);
      setCnhVisibility(oldState => !oldState);
    },
    [],
  );

  const handleFindZipcode = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      const { data: zipcodeData } = await viaCepApi.get<IZipcode>(
        `/${value}/json`,
      );

      if (zipcodeData.erro) {
        return;
      }

      const addressInputRef = formRef.current?.getFieldRef('propertyAddress');
      const numberAddressInputRef = formRef.current?.getFieldRef(
        'propertyAddressNumber',
      );
      const cityInputRef = formRef.current?.getFieldRef('propertyCity');
      // const stateInputRef = formRef.current?.getFieldRef('propertyState');

      // const stateValue = statesOptions.find(
      //   item => item.value === zipcodeData.uf,
      // );

      addressInputRef.value = zipcodeData.logradouro;
      cityInputRef.value = zipcodeData.localidade;
      // stateInputRef.select.setValue(stateValue);
      numberAddressInputRef.focus();
    },
    [],
  );

  return (
    <>
      <Header />

      <Aside />

      <Main>
        <CardMenuContainer />

        {!userInfo ? (
          <LoadingPage />
        ) : (
          <>
            <Row>
              <URLPath>
                <li>Cred Cash</li>
                <li>{'>'}</li>
                <li>Financiamentos</li>
                <li>{'>'}</li>
                <li>Imóveis</li>
                <li>{'>'}</li>
                <li>Novo financiamento</li>
              </URLPath>
            </Row>

            <Row>
              <Card>
                <CardHeader>
                  <h1>Novo financiamento de imóveis</h1>
                </CardHeader>

                <CardContent>
                  <Form onSubmit={handleFormSubmit} ref={formRef}>
                    <FormRow separator>
                      <h1>Dados básicos</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Estado civil</label>
                        <Select
                          name="maritalStatus"
                          options={maritalStatusOptions}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Data de casamento</label>
                        <InputMask
                          mask="99/99/9999"
                          noUnmask
                          name="weddingDate"
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Número do RG (registro geral)</label>
                        <Input name="generalRegistry" maxLength={255} />
                      </InputGroup>

                      <InputGroup>
                        <label>Data de expedição do RG (registro geral)</label>
                        <InputMask
                          mask="99/99/9999"
                          noUnmask
                          name="generalRegistryExpeditionDate"
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Órgão expeditor</label>
                        <Input name="dispatchingAgency" maxLength={255} />
                      </InputGroup>

                      <InputGroup>
                        <label>Naturalidade</label>
                        <Input name="naturality" maxLength={255} />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Nome da mãe</label>
                        <Input name="motherName" maxLength={255} />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Dados trabalhistas</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Renda bruta</label>
                        <InputCurrency name="grossIncome" />
                      </InputGroup>

                      <InputGroup>
                        <label>Profissão</label>
                        <Input name="profession" maxLength={255} />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Nome da empresa em que trabalha</label>
                        <Input name="companyName" maxLength={255} />
                      </InputGroup>

                      <InputGroup>
                        <label>CNPJ da empresa em que trabalha</label>
                        <Input name="companyDocument" maxLength={14} />
                      </InputGroup>

                      <InputGroup>
                        <label>Data de admissão na empresa</label>
                        <InputMask
                          name="admissionDate"
                          mask="99/99/9999"
                          noUnmask
                        />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Telefone da empresa em que trabalha</label>
                        <InputMask
                          mask="(99) 9999-9999"
                          name="companyPhoneNumber"
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Email da empresa em que trabalha</label>
                        <Input name="companyEmailAddress" upperCase={false} />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Banco</label>
                        <Select name="bank" options={banksOptions} />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Informações do financiamento</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Valor do imóvel</label>
                        <InputCurrency name="propertyValue" />
                      </InputGroup>

                      <InputGroup>
                        <label>Valor do financiamento</label>
                        <InputCurrency name="financingValue" />
                      </InputGroup>

                      <InputGroup>
                        <label>Prazo</label>
                        <Select name="deadline" options={deadlineOptions} />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Localização do imóvel</h1>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>CEP</label>
                        <InputMask
                          mask="99999-999"
                          name="propertyZipcode"
                          onBlur={handleFindZipcode}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Endereço</label>
                        <Input name="propertyAddress" maxLength={255} />
                      </InputGroup>

                      <InputGroup>
                        <label>Número (insira 0 caso não exista)</label>
                        <Input name="propertyAddressNumber" type="number" />
                      </InputGroup>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Cidade</label>
                        <Input name="propertyCity" />
                      </InputGroup>

                      <InputGroup>
                        <label>Estado</label>
                        <Select name="propertyState" options={statesOptions} />
                      </InputGroup>
                    </FormRow>

                    <FormRow separator>
                      <h1>Arquivos necessários</h1>

                      <ChooseAFile>
                        <div>
                          <label htmlFor="chooseCnh">CNH</label>
                          <input
                            type="radio"
                            value="cnhChosen"
                            name="chooseAFile"
                            id="chooseCnh"
                            defaultChecked
                            onChange={handleFilesVisibility}
                          />
                        </div>

                        <div>
                          <label htmlFor="chooseRg">RG</label>
                          <input
                            type="radio"
                            value="rgChosen"
                            name="chooseAFile"
                            id="chooseRg"
                            onChange={handleFilesVisibility}
                          />
                        </div>
                      </ChooseAFile>
                    </FormRow>

                    <FormRow>
                      <InputGroup>
                        <label>Matrícula do imóvel</label>
                        <InputFile
                          name="fileOne"
                          onChange={e => handleFilesChange('IovF', e)}
                        />
                      </InputGroup>

                      <InputGroup>
                        <label>Contra cheque dos últimos três meses</label>
                        <InputFile
                          name="fileTwo"
                          onChange={e => handleFilesChange('WPkl', e)}
                        />
                      </InputGroup>
                    </FormRow>

                    {cnhVisibility && (
                      <FormRow>
                        <InputGroup>
                          <label>CNH (aberta e sem o plástico)</label>
                          <InputFile
                            name="fileThree"
                            onChange={e => handleFilesChange('lqRt', e)}
                          />
                        </InputGroup>
                      </FormRow>
                    )}

                    {rgVisibility && (
                      <FormRow>
                        <InputGroup>
                          <label>RG (frente)</label>
                          <InputFile
                            name="fileFour"
                            onChange={e => handleFilesChange('nvBy', e)}
                          />
                        </InputGroup>

                        <InputGroup>
                          <label>RG (verso)</label>
                          <InputFile
                            name="fileFive"
                            onChange={e => handleFilesChange('PuyT', e)}
                          />
                        </InputGroup>
                      </FormRow>
                    )}

                    <FormRow buttonWrapper>
                      <Button styleType="success" type="submit">
                        Salvar novo financiamento
                      </Button>
                    </FormRow>
                  </Form>
                </CardContent>
              </Card>
            </Row>
          </>
        )}
      </Main>

      <Footer />
    </>
  );
};
