import bbankProspectsProducts from '@utils/bbankProspectsProducts';

import { states } from '@helpers/getAllStates';

interface IOption {
  value: string;
  label: string;
}

export const productsOptions = bbankProspectsProducts.map(item => ({
  value: item,
  label: item,
}));

export const statesOptions = Object.entries(states).reduce<IOption[]>(
  (acc, [value, label]) => {
    const option = { label, value };

    acc.push(option);

    return acc;
  },
  [],
);
